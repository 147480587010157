import { AwardViewState } from '../award-view-state'

export type SearchPageAwardSliceType =
  | SearchPageAwardSliceTypeInitial
  | SearchPageAwardSliceTypeReady

export type SearchPageAwardSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageAwardSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: AwardViewState
}

export type SearchPageAwardSliceViewType = Exclude<
  SearchPageAwardSliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageAwardSliceViewType> = Extract<
  SearchPageAwardSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageAwardSliceReady(
  s: SearchPageAwardSliceType
): s is SearchPageAwardSliceTypeReady {
  return s.state === 'ready'
}
