import {
  CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS,
  SavedSearch2,
  SearchTargets,
} from '@nextweb/common'
import _ from 'lodash'
import {
  CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE,
  CONTRACT_VEHICLE_VIEW_SORT_BY_NAME,
  CONTRACT_VEHICLE_VIEW_SORT_BY_NUM_PRIME_AWARDS,
  CONTRACT_VEHICLE_VIEW_SORT_BY_POTENTIAL_VALUE,
  CONTRACT_VEHICLE_VIEW_SORT_BY_SORT_BY_NUM_TASK_ORDERS,
} from '../components/ContractVehicleViewSortByDropdown'
import {
  CONTRACT_VEHICLE_VIEW_DEFAULT_STATE,
  CONTRACT_VEHICLE_VIEW_EMPTY_STATE,
  ContractVehicleViewPersisted,
} from '../contract-vehicle-view-state'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeContractVehicleViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE:
      return CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.currentValue
    case CONTRACT_VEHICLE_VIEW_SORT_BY_POTENTIAL_VALUE:
      return CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.potentialValue
    case CONTRACT_VEHICLE_VIEW_SORT_BY_NAME:
      return CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.name
    case CONTRACT_VEHICLE_VIEW_SORT_BY_NUM_PRIME_AWARDS:
      return CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.numPrimeAwards
    case CONTRACT_VEHICLE_VIEW_SORT_BY_SORT_BY_NUM_TASK_ORDERS:
      return CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.numTaskOrders
    default:
      return null
  }
}

function deserializeContractVehicleViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.currentValue:
      return CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE
    case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.potentialValue:
      return CONTRACT_VEHICLE_VIEW_SORT_BY_POTENTIAL_VALUE
    case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.name:
      return CONTRACT_VEHICLE_VIEW_SORT_BY_NAME
    case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.numPrimeAwards:
      return CONTRACT_VEHICLE_VIEW_SORT_BY_NUM_PRIME_AWARDS
    case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.sorts.numTaskOrders:
      return CONTRACT_VEHICLE_VIEW_SORT_BY_SORT_BY_NUM_TASK_ORDERS
    default:
      return null
  }
}

export function convertContractVehicleViewToSavedSearch(
  view: ContractVehicleViewPersisted
): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeContractVehicleViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.contractVehicles.key,
  }
}

export function convertSavedSearchToContractVehicleView(
  savedSearch: SavedSearch2.SavedSearch
): ContractVehicleViewPersisted {
  const view: ContractVehicleViewPersisted = _.cloneDeep(CONTRACT_VEHICLE_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeContractVehicleViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case CONTRACT_VEHICLE_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
