import { useSelector } from '@src/redux/use-selector'
import {
  SearchPageGovWinSliceTypeReady,
  isSearchPageGovWinSliceReady,
} from './SearchPageGovWinSlice.types'
import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'

export function useGovWinSelector<T extends (state: SearchPageGovWinSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageGovWinSliceReady(state.searchGovWinView))
      return selector(state.searchGovWinView)
    else return null
  })
}

export function useGovWinQuery(): string {
  const value = useGovWinSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useGovWinReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useGovWinActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useGovWinSelector((state) => {
    return state.view.savedSearch
  })
}

export function useGovWinSelectedListing(): any {
  return useGovWinSelector((state) => {
    return state.view.selectedListing
  })
}

export function useGovWinPageFrom(): number | null {
  return useGovWinSelector((state) => {
    return state.view.pageFrom
  })
}

export function useGovWinHitCount(): number | null {
  return useGovWinSelector((state) => {
    return state.view.hitCount
  })
}

export function useGovWinSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useGovWinSortByDirection(): 'asc' | 'desc' | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useGovWinMarket(): {
  id: string
  value: string
  label: string
} | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.market
  })
}

export function useGovWinSolDate(): DateFilterValue | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.solDate
  })
}

export function useGovWinResponseDate(): DateFilterValue | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.responseDate
  })
}

export function useGovWinOppUpdatedDate(): DateFilterValue | null {
  return useGovWinSelector((state) => {
    return state.view.persisted.oppUpdatedDate
  })
}

export const GOVWIN_PAGE_SIZE = 100
