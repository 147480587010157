import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageAwardSliceActions } from '../redux/SearchPageAwardSlice'
import { useAwardSortBy, useAwardSortByDirection } from '../redux/selectors'

export const AWARD_VIEW_SORT_BY_PERFORMANCE_START = 'period-of-performance-start'
export const AWARD_VIEW_SORT_BY_PERFORMANCE_END = 'period-of-performance-end'
export const AWARD_VIEW_SORT_BY_SORT_BY_LAST_MODIFIED = 'last-modified'
export const AWARD_VIEW_SORT_BY_CURRENT_VALUE = 'current-value'
export const AWARD_VIEW_SORT_BY_POTENTIAL_VALUE = 'potential-value'

const SORT_BY_OPTIONS = [
  {
    id: 'period-of-performance-start',
    label: 'POP Start Date',
    value: AWARD_VIEW_SORT_BY_PERFORMANCE_START,
  },
  {
    id: 'period-of-performance-end',
    label: 'Current POP End Date',
    value: AWARD_VIEW_SORT_BY_PERFORMANCE_END,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: AWARD_VIEW_SORT_BY_SORT_BY_LAST_MODIFIED,
  },
  {
    id: 'current-value',
    label: 'Current Value',
    value: AWARD_VIEW_SORT_BY_CURRENT_VALUE,
  },
  {
    id: 'potential-value',
    label: 'Potential Value',
    value: AWARD_VIEW_SORT_BY_POTENTIAL_VALUE,
  },
]

interface AwardViewSortByDropdownProps {
  disabled?: boolean
}

export const AwardViewSortByDropdown = (props: AwardViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useAwardSortBy()
  const direction = useAwardSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageAwardSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageAwardSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
