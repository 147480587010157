import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageFedConnectSliceActions } from '../redux/SearchPageFedConnectSlice'
import { useFedConnectSortBy, useFedConnectSortByDirection } from '../redux/selectors'

export const FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE = 'issue-date'
export const FEDCONNECT_VIEW_SORT_BY_RESPONSE_DUE_DATE = 'response-due-date'

export const FEDCONNECT_VIEW_SORT_BY_OPTIONS = [
  {
    id: 'issue-date',
    label: 'Issue Date',
    value: FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE,
  },
  {
    id: 'response-due-date',
    label: 'Response Due Date',
    value: FEDCONNECT_VIEW_SORT_BY_RESPONSE_DUE_DATE,
  },
]

interface FedConnectViewSortByDropdownProps {
  disabled?: boolean
}

export const FedConnectViewSortByDropdown = (props: FedConnectViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useFedConnectSortBy()
  const direction = useFedConnectSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageFedConnectSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageFedConnectSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={FEDCONNECT_VIEW_SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
