// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import CohereSentry from 'cohere-sentry'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (
  process.env.NODE_ENV === 'production' &&
  process.env.NEXT_PUBLIC_DISABLE_SENTRY !== 'true'
) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://c5d89d0bba61470bb0cd8a68f6c35bff@o1167360.ingest.sentry.io/6258342',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    integrations: [new CohereSentry()],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
