import { WorkspaceConfigFragment, useGetWorkspaceQuery } from '@interfaces/graphql'
import { NextStageDbContext } from '@src/indexeddb/NextStageDbContext'
import {
  clearFromAccountStore,
  getFromAccountStore,
  saveToAccountStore,
} from '@src/indexeddb/openNextStageDatabase'
import { WorkspaceSlice, setWorkspace } from '@src/redux/reducers/WorkspaceSlice'
import { setUsers } from '@src/redux/reducers/WorkspaceUsersSlice'
import { useSelector } from '@src/redux/use-selector'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export function getWorkspaceSliceTypeFromGraphQLWorkspaceType(workspace: {
  id: number
  ciospIndex: boolean
  ebuyIndex: boolean
  symphonyIndex: boolean
  seaportIndex: boolean
  enableGoogleSSO: boolean
  enableMicrosoftSSO: boolean
  enablePasswordLogin: boolean
  enforceMfa: boolean
  govWinEnabled: boolean
  inviteOnly: boolean
  name: string
  primaryLogo?: string | null
  sidebarLogo?: string | null
  workspaceId: string
  workspaceAbbrev?: string | null
  alexandria: boolean
  zapier: boolean
  documentChat: boolean
  enforceDirectoryProvisioning: boolean
  restrictRoleProvisioningToDirectory: boolean
  updatesV2: boolean
  enableProposalManagement: boolean
}): Partial<WorkspaceSlice> {
  return {
    ciospIndex: workspace.ciospIndex ?? undefined,
    ebuyIndex: workspace.ebuyIndex ?? undefined,
    symphonyIndex: workspace.symphonyIndex ?? undefined,
    enableGoogleSSO: workspace.enableGoogleSSO ?? undefined,
    enableMicrosoftSSO: workspace.enableMicrosoftSSO ?? undefined,
    enablePasswordLogin: workspace.enablePasswordLogin ?? undefined,
    enforceMfa: workspace.enforceMfa ?? undefined,
    govWinEnabled: workspace.govWinEnabled ?? undefined,
    inviteOnly: workspace.inviteOnly ?? undefined,
    name: workspace.name ?? undefined,
    primaryLogo: workspace.primaryLogo,
    seaportIndex: workspace.seaportIndex ?? undefined,
    sidebarLogo: workspace.sidebarLogo,
    workspaceId: workspace.id ?? undefined,
    workspaceWorkspaceId: workspace.workspaceId,
    workspaceAbbrev: workspace.workspaceAbbrev,
    alexandria: workspace.alexandria,
    zapier: workspace.zapier,
    documentChat: workspace.documentChat,
    enforceDirectoryProvisioning: workspace.enforceDirectoryProvisioning,
    restrictRoleProvisioningToDirectory: workspace.restrictRoleProvisioningToDirectory,
    updatesV2: workspace.updatesV2,
    enableProposalManagement: workspace.enableProposalManagement,
  }
}

export function dispatchGetWorkspaceResult(
  dispatch: (any) => void,
  workspace: WorkspaceConfigFragment
): { workspace: Partial<WorkspaceSlice> } {
  dispatch(setUsers(workspace.users))
  const slice = getWorkspaceSliceTypeFromGraphQLWorkspaceType(workspace)

  return {
    workspace: slice,
  }
}

/**
 * TODO: This is a bit much; how to improve?
 */
export function useGetWorkspace() {
  const dispatch = useDispatch()

  const nextstageDb = useContext(NextStageDbContext)

  const initialWorkspace = useSelector((state) => state.workspace)

  const [result, setResult] = useState<Partial<WorkspaceSlice> | null>(initialWorkspace)

  const { loading, error, data, refetch } = useGetWorkspaceQuery({
    notifyOnNetworkStatusChange: true,
    onError: async (results) => {
      if (nextstageDb) {
        clearFromAccountStore(nextstageDb, 'workspace')
        dispatch(setWorkspace({}))
        dispatch(setUsers([]))
      }
    },
    onCompleted: async (results) => {
      const { workspace: workspaceSlice } = dispatchGetWorkspaceResult(
        dispatch,
        results.getWorkspace
      )

      setResult(workspaceSlice)

      if (nextstageDb) {
        await saveToAccountStore(nextstageDb, 'workspace', {
          type: 'workspace',
          value: results.getWorkspace,
        })
      }
    },
  })

  useEffect(() => {
    if (nextstageDb && !result) {
      getFromAccountStore(nextstageDb, 'workspace').then((cached) => {
        if (cached && !result) {
          if (cached.type === 'workspace') {
            const workspace = cached.value
            setResult({
              ciospIndex: workspace.ciospIndex ?? undefined,
              ebuyIndex: workspace.ebuyIndex ?? undefined,
              symphonyIndex: workspace.symphonyIndex ?? undefined,
              enableGoogleSSO: workspace.enableGoogleSSO ?? undefined,
              enableMicrosoftSSO: workspace.enableMicrosoftSSO ?? undefined,
              enablePasswordLogin: workspace.enablePasswordLogin ?? undefined,
              enforceMfa: workspace.enforceMfa ?? undefined,
              govWinEnabled: workspace.govWinEnabled ?? undefined,
              inviteOnly: workspace.inviteOnly ?? undefined,
              name: workspace.name ?? undefined,
              primaryLogo: workspace.primaryLogo,
              seaportIndex: workspace.seaportIndex ?? undefined,
              sidebarLogo: workspace.sidebarLogo,
              workspaceId: workspace.id ?? undefined,
              workspaceWorkspaceId: workspace.workspaceId,
              workspaceAbbrev: workspace.workspaceAbbrev,
              alexandria: workspace.alexandria,
              zapier: workspace.zapier,
              documentChat: workspace.documentChat,
              enforceDirectoryProvisioning: workspace.enforceDirectoryProvisioning,
              restrictRoleProvisioningToDirectory:
                workspace.restrictRoleProvisioningToDirectory,
              updatesV2: workspace.updatesV2,
              enableProposalManagement: workspace.enableProposalManagement,
            })
          }
        }
      })
    }
  }, [nextstageDb])

  useEffect(() => {
    if (result) {
      dispatch(setWorkspace(result))
    }
  }, [result])

  return {
    loading,
    error,
    data: result
      ? {
          getWorkspace: result,
        }
      : undefined,
    refetch,
  }
}
