import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageAPFSSliceActions } from '../redux/SearchPageAPFSSlice'
import { useAPFSSortBy, useAPFSSortByDirection } from '../redux/selectors'

export const APFS_VIEW_SORT_BY_RFP_RELEASE_DATE = 'rfp-release-date'
export const APFS_VIEW_SORT_BY_APFS_PUBLISH_DATE = 'apfs-publish-date'

export const APFS_VIEW_SORT_BY_OPTIONS = [
  {
    id: 'rfp-release-date',
    label: 'RFP Release Date',
    value: APFS_VIEW_SORT_BY_RFP_RELEASE_DATE,
  },
  {
    id: 'apfs-publish-date',
    label: 'APFS Publish Date',
    value: APFS_VIEW_SORT_BY_APFS_PUBLISH_DATE,
  },
]

interface APFSViewSortByDropdownProps {
  disabled?: boolean
}

export const APFSViewSortByDropdown = (props: APFSViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useAPFSSortBy()
  const direction = useAPFSSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageAPFSSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageAPFSSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={APFS_VIEW_SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
