import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import Moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Datepicker from 'react-datepicker'
import styles from './DatePicker.module.scss'

interface CustomDatePickerProps {
  value: string | undefined | null
  format?: string | undefined
  className?: string
  clearable?: boolean
  required?: boolean
  disabled?: boolean
  placeholder?: string
  // Returns a date object in the current timezone.
  onChange: (date: Date | null) => void

  input?: JSX.Element

  preventOverflow?: boolean
  Container?: React.ComponentType<{ children: React.ReactNode }>
}

/**
 * This is used from javascript files so the input types may not be correct.
 * Coerce undefined => null.
 * @param props
 * @returns
 */
const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {
    format,
    value,
    onChange,
    className,
    clearable = true,
    disabled = false,
    input,
    Container,
    placeholder,
    preventOverflow = false,
    required = false,
  } = props

  const inputStartDate = useMemo(() => {
    return value && Moment(value, format).isValid() ? Moment(value, format).toDate() : null
  }, [value])
  const [calendarPickerStartDate, setCalendarPickerStartDate] = useState<Date | null>(
    inputStartDate
  )

  const inputDisplayValue = useMemo(() => {
    const displayValue =
      value && Moment(value, format).isValid()
        ? Moment(value, format).format('MM-DD-yyyy')
        : null

    return displayValue
  }, [value])
  const [displayedValue, setDisplayedValue] = useState<string | null>(
    inputDisplayValue ?? null
  )

  useEffect(() => {
    setCalendarPickerStartDate(
      value && Moment(value, format).isValid() ? Moment(value, format).toDate() : null
    )

    setDisplayedValue(inputDisplayValue ?? null)
  }, [value, inputDisplayValue])

  const onChangeRef = useRef(onChange)
  onChangeRef.current = onChange

  const debouncedPropogateChange = useCallback(
    _.debounce((value: string | undefined | null, onFail: () => void) => {
      if (!value || value.length === 0) {
        setCalendarPickerStartDate(null)
        onChangeRef.current(null)
        return
      } else if (value.split('-').length === 3) {
        const dt = Moment(value)

        if (dt.isValid()) {
          const asDate = dt.toDate()

          setCalendarPickerStartDate(asDate)
          onChangeRef.current(asDate)

          return
        }
      }

      onFail()
    }, 5000),
    []
  )

  const onTypedDate = useCallback(
    (typedValue: string | undefined | null) => {
      setDisplayedValue(typedValue ?? null)
      return debouncedPropogateChange(typedValue, () =>
        setDisplayedValue(inputDisplayValue ?? null)
      )
    },
    [inputDisplayValue]
  )

  useEffect(() => {
    const onFocusOut = () => {
      debouncedPropogateChange.flush()
    }

    window.addEventListener('focusout', onFocusOut)

    return () => {
      window.removeEventListener('focusout', onFocusOut)
      debouncedPropogateChange.cancel()
    }
  }, [])

  return (
    <Datepicker
      {...props}
      shouldCloseOnSelect
      popperContainer={
        Container as (props: { children: React.ReactNode }) => React.ReactNode
      }
      popperModifiers={{
        preventOverflow: {
          enabled: preventOverflow,
        },
      }}
      disabled={disabled}
      required={required}
      isClearable={clearable}
      className={(input ? undefined : styles.datepicker) + ' ' + className}
      customInput={input || undefined}
      selected={calendarPickerStartDate}
      placeholderText={placeholder ? placeholder : 'N/A'}
      value={displayedValue ?? undefined}
      dateFormat="MM-dd-yyyy"
      onKeyDown={(e) => {
        if (e.key.toLowerCase() === 'enter') {
          debouncedPropogateChange.flush()
        } else {
          onTypedDate(displayedValue)
        }
      }}
      onChangeRaw={(e) => {
        onTypedDate(e.target.value)
      }}
      onChange={(date: Date, event) => {
        if (event && event.type === 'change') {
          // The user is typing into the date field.
          return
        }

        debouncedPropogateChange.cancel()

        if (!date) {
          onChange(null)
          setCalendarPickerStartDate(null)
        } else if (!Moment(date, 'MM-DD-YYYY').isValid()) {
          setCalendarPickerStartDate(null)
        } else {
          onChange(date)
          setCalendarPickerStartDate(date)
        }
      }}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div
          style={{
            margin: 10,
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <FontAwesomeIcon
            className={styles.carets}
            icon={faCaretLeft}
            onClick={decreaseMonth}
          />

          <select
            className={styles.select}
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className={styles.select}
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value as any)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <FontAwesomeIcon
            className={styles.carets}
            icon={faCaretRight}
            onClick={increaseMonth}
          />

          <div
            className="hover-blue"
            style={{ position: 'absolute', top: '0.25rem', right: '0.25rem' }}
            onClick={() => {
              onChange(null)
              setCalendarPickerStartDate(null)
            }}
          >
            Clear
          </div>
        </div>
      )}
    />
  )
}

export default CustomDatePicker

const years = Array.from({ length: 50 / 1 + 1 }, (_, i) => 2010 + i * 1)

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
