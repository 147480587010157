import { scls } from '@helpers/scls'
import styles from './CommonFilterWrapper.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useIsMobileOrTablet } from '@helpers/hooks/IsMobileHook'

export const CommonFilterDropdownIndicator = (props) => (
  <FontAwesomeIcon
    icon={faAngleDown}
    className={scls(styles, 'angle-down')}
    style={{ fontSize: '18px', color: 'rgba(10,10,10,0.87)' }}
  />
)

interface CommonFilterWrapperProps {
  children: React.ReactNode
  className?: string
}

export const CommonFilterWrapper = (props: CommonFilterWrapperProps) => {
  const { children, className } = props

  const isMobile = useIsMobileOrTablet()

  return (
    <div className={scls(styles, 'filter', isMobile && 'mobile') + ` ${className}`}>
      {children}
    </div>
  )
}
