import { SavedSearch2, SearchTargets, VENDOR_SAVED_SEARCH_FIELDS } from '@nextweb/common'
import _ from 'lodash'
import { VENDOR_VIEW_EMPTY_STATE, VendorViewPersisted } from '../vendor-view-state'

export function convertVendorViewToSavedSearch(
  view: VendorViewPersisted
): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: VENDOR_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.vendors.key,
  }
}

export function convertSavedSearchToVendorView(
  savedSearch: SavedSearch2.SavedSearch
): VendorViewPersisted {
  const view: VendorViewPersisted = _.cloneDeep(VENDOR_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case VENDOR_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case VENDOR_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
