import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SaveSearchModal2Actions } from '@src/partials/searchv2/components/SaveSearchModal2/SaveSearchModal2.actions'
import { LEGACY_Bookmark } from '@src/partials/searchv2/redux/LEGACY_BookmarkOptimisticSlice.types'
import {
  SearchPageSliceActions,
  commonUpdateSelectedListing,
} from '@src/partials/searchv2/redux/SearchPageSlice'
import { SearchPageQuerySliceActions } from '@src/partials/searchv2/widgets/DefaultQueryWithSaveButtonAndFiltersWidget/SearchPageQuerySlice'
import { createContractVehicleViewFromQuery } from '../contract-vehicle-view-state'
import {
  SearchPageContractVehicleSliceType,
  isSearchPageContractVehicleSliceReady,
} from './SearchPageContractVehicleSlice.types'

const initState: SearchPageContractVehicleSliceType = {
  state: 'initial',
}

/**
 * This slice contains some common actions used by all the views.
 */
const SearchPageContractVehicleSlice = createSlice({
  name: 'SearchPageContractVehicleSlice',
  initialState: initState as SearchPageContractVehicleSliceType,
  reducers: {
    setActiveSavedSearch: (
      slice,
      action: PayloadAction<{ id: string; value: string; label: string } | null>
    ): void => {
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.savedSearch = action.payload
    },
    setSortBy: (
      slice,
      action: PayloadAction<{
        field: { id: string; value: string; label: string } | null
        direction: 'asc' | 'desc' | null
      }>
    ): void => {
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.sortBy = action.payload.field
      slice.view.persisted.sortByDirection = action.payload.direction
    },
    setPageFrom: (slice, action: PayloadAction<number>): void => {
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.pageFrom = action.payload
    },
    setAdvanced: (slice, action: PayloadAction<boolean | null>): void => {
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.advanced = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(
      SearchPageSliceActions.setSelectedListing,
      (
        slice,
        action: PayloadAction<{
          listing: any | null
          source: string
          externalId: string
        } | null>
      ): void => {
        if (!isSearchPageContractVehicleSliceReady(slice)) return
        commonUpdateSelectedListing(slice, action.payload)
      }
    )
    builder.addCase(
      SearchPageSliceActions.onLoadedListings,
      (
        state,
        action: PayloadAction<{
          // As with the legacy code, this expects parallel arrays
          listings: Array<{ id: string }>
          bookmarks: Array<LEGACY_Bookmark | null>
          numHits: number
        }>
      ) => {
        if (!isSearchPageContractVehicleSliceReady(state)) return
        state.view.hitCount = action.payload.numHits
      }
    )
    builder.addCase(SearchPageSliceActions.init, (slice, action) => {
      const { query } = action.payload

      const view = createContractVehicleViewFromQuery(query)
      if (view) {
        return {
          state: 'ready',
          view: view,
        }
      }
    })
    builder.addCase(SearchPageSliceActions.reset, (slice, action) => {
      return initState
    })
    builder.addCase(SaveSearchModal2Actions.created, (slice, action) => {
      const { savedSearchName, savedSearchUUId } = action.payload
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.savedSearch = {
        id: savedSearchUUId,
        value: savedSearchUUId,
        label: savedSearchName,
      }
    })
    builder.addCase(SearchPageQuerySliceActions.queryChange, (slice, action) => {
      const { query } = action.payload
      if (!isSearchPageContractVehicleSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.queryString = query
    })
  },
})

export const SearchPageContractVehicleSliceActions = SearchPageContractVehicleSlice.actions
export const SearchPageContractVehicleReducer = SearchPageContractVehicleSlice.reducer
