import { useState, useEffect } from 'react'

export function isMobileGlobal() {
  if (typeof window === 'undefined') {
    // SSR
    return false
  } else {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    return isMobile(userAgent)
  }
}

export function isMobile(ua = '') {
  const mobileUserAgent = Boolean(
    ua.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i)
  )
  return mobileUserAgent
}

/**
 * TODO: This is a HACK!
 *
 * SSR means we can't just check the user agent.
 * @returns
 */
export default function useIsMobile() {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (!process.browser) {
      return
    }

    setMobile(isMobileGlobal())
  }, [])

  return mobile
}

export function useIsMobileOrTablet() {
  const [mobileOrTablet, setMobileOrTablet] = useState(false)

  useEffect(() => {
    if (!process.browser) {
      return
    }

    setMobileOrTablet(isMobileOrTabletGlobal())
  }, [])

  return mobileOrTablet
}

export function isMobileOrTabletGlobal() {
  if (typeof window === 'undefined') {
    // SSR
    return false
  } else {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    return isMobileOrTablet(userAgent)
  }
}

export function isMobileOrTablet(ua = '') {
  const mobileUserAgent = Boolean(
    ua.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop|iPad|Tablet/i)
  )
  return mobileUserAgent
}
