import { BookmarkReason } from '@interfaces/graphql'
import {
  SavedSearch2,
  SearchTargets,
  convertQueryParamsToSavedSearch,
} from '@nextweb/common'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToGovWinView } from './saved-search/convert-govwin-view-and-saved-search'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const GOVWIN_VIEW_KEY = SearchTargets.govwin.label

export function isGovWinViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === GOVWIN_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface GovWinViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null

  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  market: { id: string; value: string; label: string } | null
  oppUpdatedDate: DateFilterValue | null
  solDate: DateFilterValue | null
  responseDate: DateFilterValue | null

  // "The" query string.
  queryString: string
}

export interface GovWinViewState {
  type: typeof GOVWIN_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: GovWinViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const GOVWIN_VIEW_DEFAULT_STATE: GovWinViewPersisted = {
  reviewStatus: null,
  sortBy: null,
  sortByDirection: 'desc',

  market: null,
  oppUpdatedDate: null,
  solDate: null,
  responseDate: null,

  // "The" query string.
  queryString: '',
}

export const GOVWIN_VIEW_EMPTY_STATE: GovWinViewPersisted = {
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  market: null,
  oppUpdatedDate: null,
  solDate: null,
  responseDate: null,

  // "The" query string.
  queryString: '',
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createGovWinViewFromQuery(
  query: Record<string, string | string[]>
): GovWinViewState | null {
  // This should be checked before calling this function ideally.
  if (!isGovWinViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: GOVWIN_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToGovWinView(savedSearch)
        : GOVWIN_VIEW_DEFAULT_STATE,
  }
}
