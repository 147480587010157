// External
import { createAction, createSlice } from '@reduxjs/toolkit'

export interface WorkspaceSlice {
  // This is the 'id'
  workspaceId: number
  // This is the 'workspaceId'
  workspaceWorkspaceId: string
  enableGoogleSSO: boolean
  enableMicrosoftSSO: boolean
  enablePasswordLogin: boolean
  name: string
  inviteOnly: boolean
  enforceMfa: boolean
  ebuyIndex: boolean
  symphonyIndex: boolean
  ciospIndex: boolean
  seaportIndex: boolean
  alexandria: boolean
  documentChat: boolean
  updatesV2: boolean
  zapier: boolean
  workspaceAbbrev: null | string
  sidebarLogo: null | string
  govWinEnabled: boolean
  enforceDirectoryProvisioning: boolean
  restrictRoleProvisioningToDirectory: boolean
  primaryLogo: null | string
  enableProposalManagement: boolean
}

const initState: Partial<WorkspaceSlice> = {}

const setWorkspaceAction = createAction<Partial<WorkspaceSlice>>('setWorkspaceAction')

const workspaceSlice = createSlice({
  name: 'workspaceSlice',
  initialState: initState,
  reducers: {
    resetWorkspace: () => initState,
  },
  extraReducers: (builder) => {
    builder.addCase(setWorkspaceAction, (slice, action) => {
      const {
        ciospIndex,
        ebuyIndex,
        symphonyIndex,
        enableGoogleSSO,
        enforceMfa,
        enableMicrosoftSSO,
        enablePasswordLogin,
        govWinEnabled,
        inviteOnly,
        name,
        primaryLogo,
        seaportIndex,
        sidebarLogo,
        workspaceAbbrev,
        workspaceId,
        alexandria,
        zapier,
        documentChat,
        workspaceWorkspaceId,
        enforceDirectoryProvisioning,
        restrictRoleProvisioningToDirectory,
        updatesV2,
        enableProposalManagement,
      } = action.payload

      slice.ciospIndex = ciospIndex
      slice.ebuyIndex = ebuyIndex
      slice.symphonyIndex = symphonyIndex
      slice.enableGoogleSSO = enableGoogleSSO
      slice.enforceMfa = enforceMfa
      slice.enableMicrosoftSSO = enableMicrosoftSSO
      slice.enablePasswordLogin = enablePasswordLogin
      slice.govWinEnabled = govWinEnabled
      slice.sidebarLogo = sidebarLogo
      slice.workspaceAbbrev = workspaceAbbrev
      slice.primaryLogo = primaryLogo
      slice.inviteOnly = inviteOnly
      slice.name = name
      slice.seaportIndex = seaportIndex
      slice.workspaceId = workspaceId
      slice.workspaceWorkspaceId = workspaceWorkspaceId
      slice.alexandria = alexandria
      slice.zapier = zapier
      slice.documentChat = documentChat
      slice.updatesV2 = updatesV2
      slice.enforceDirectoryProvisioning = enforceDirectoryProvisioning
      slice.restrictRoleProvisioningToDirectory = restrictRoleProvisioningToDirectory
      slice.enableProposalManagement = enableProposalManagement
    })
  },
})

export const { resetWorkspace } = workspaceSlice.actions
export const setWorkspace = setWorkspaceAction
export default workspaceSlice.reducer
