import { EBuyViewState } from '../ebuy-view-state'

export type SearchPageEBuySliceType =
  | SearchPageEBuySliceTypeInitial
  | SearchPageEBuySliceTypeReady

export type SearchPageEBuySliceTypeInitial = {
  state: 'initial'
}

export type SearchPageEBuySliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: EBuyViewState
}

export type SearchPageEBuySliceViewType = Exclude<
  SearchPageEBuySliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageEBuySliceViewType> = Extract<
  SearchPageEBuySliceTypeReady['view'],
  { type: T }
>

export function isSearchPageEBuySliceReady(
  s: SearchPageEBuySliceType
): s is SearchPageEBuySliceTypeReady {
  return s.state === 'ready'
}
