import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageAlexandriaSliceActions } from '../redux/SearchPageAlexandriaSlice'
import {
  useAlexandriaSortBy,
  useAlexandriaSortByDirection,
  useAlexandriaSource,
} from '../redux/selectors'

export const ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE = 'received-date'
export const ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED = 'last-modified'
export const ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY = 'respond-by'
export const ALEXANDRIA_VIEW_SORT_BY_POSTED_ON = 'posted-on'
export const ALEXANDRIA_VIEW_SORT_BY_QA_CUTOFF = 'qa-cutoff'

const DEFAULT_SORT_BY_OPTIONS = [
  {
    id: 'received-date',
    label: 'Received Date',
    value: ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED,
  },
]

const ITES_OR_SEAPORT_SORT_BY_OPTIONS = [
  {
    id: 'received-date',
    label: 'Received Date',
    value: ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED,
  },
  {
    id: 'posted-on',
    label: 'Posted On',
    value: ALEXANDRIA_VIEW_SORT_BY_POSTED_ON,
  },
  {
    id: 'respond-by',
    label: 'Respond By',
    value: ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY,
  },
]

const SEWP_SORT_BY_OPTIONS = [
  {
    id: 'received-date',
    label: 'Received Date',
    value: ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED,
  },
  {
    id: 'posted-on',
    label: 'Posted On',
    value: ALEXANDRIA_VIEW_SORT_BY_POSTED_ON,
  },
  {
    id: 'respond-by',
    label: 'Respond By',
    value: ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY,
  },
  {
    id: 'qa-cutoff',
    label: 'QA Cutoff',
    value: ALEXANDRIA_VIEW_SORT_BY_QA_CUTOFF,
  },
]

interface AlexandriaViewSortByDropdownProps {
  disabled?: boolean
}

export const AlexandriaViewSortByDropdown = (props: AlexandriaViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useAlexandriaSortBy()
  const direction = useAlexandriaSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageAlexandriaSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageAlexandriaSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  const source = useAlexandriaSource()
  const ddOptions = useMemo(() => {
    /**
     * Attention! If the alexandria source starts to deviate too much from "just a few extra filters",
     * then consider making a separate view for it.
     */
    switch (source) {
      case 'seaport':
      case 'ites':
        return ITES_OR_SEAPORT_SORT_BY_OPTIONS
      case 'sewp':
        return SEWP_SORT_BY_OPTIONS
      default:
        return DEFAULT_SORT_BY_OPTIONS
    }
  }, [source])

  return (
    <SortByDropdown
      onChange={onChange}
      options={ddOptions}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
