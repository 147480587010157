import { useState, useEffect } from 'react'
import Select from 'react-select'
import styles from './Dropdown.module.scss'
import _ from 'lodash'

const defaultTitleStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(10,10,10,.7)',
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: '0',
  }),
  container: (provided) => ({
    ...provided,
    width: 'auto',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '24px',
    padding: '2px 0px',
  }),
  singleValue: () => ({
    fontSize: '24px',
    fontWeight: '500',
  }),
  menuPortal: (provided) => ({ ...provided, width: 'auto', minWidth: '16em' }),
  menuList: (provided) => ({ ...provided, width: 'auto', minWidth: '16em' }),
  menu: (provided) => ({ ...provided, minWidth: '16em' }),
  clearIndicator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: '$blue-primary',
    backgroundColor: state.isSelected || state.isFocused ? 'rgba(45,156,219,.15)' : null,
    minWidth: '14em',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

interface TextDropdownProps {
  name?: string
  inputId?: string
  options: any
  value?: any
  closeMenuOnSelect?: boolean
  onChange?: (any) => void
  onInputChange?: (any) => void
  titleText?: string
  className?: string
  styles?: any
  placeholder?: string
  disabled?: boolean
  isMulti?: boolean
  isClearable?: boolean
}

export const TextDropdown = ({
  options,
  value,
  onChange,
  onInputChange,
  closeMenuOnSelect,
  disabled = false,
  titleText = '',
  className = '',
  styles = defaultTitleStyles,
  placeholder = 'Select...',
  name,
  inputId,
  ...props
}: TextDropdownProps) => {
  const [val, setVal] = useState(value)

  useEffect(() => {
    if (value === null) return

    if (typeof value !== 'undefined') {
      if (typeof value === 'string') {
        const opt = options.find((o) => o.id === value || o.value === value)

        setVal(
          value ? { id: opt?.id ?? value, value: value, label: opt?.label ?? value } : null
        )
      } else {
        setVal(value)
      }
    }
  }, [value, options])

  return (
    <TextDropdownControlled
      name={name}
      inputId={inputId}
      options={options}
      value={val}
      onChange={(v) => {
        setVal(v)
        onChange?.(v)
      }}
      onInputChange={onInputChange}
      closeMenuOnSelect={closeMenuOnSelect}
      disabled={disabled}
      titleText={titleText}
      className={className}
      styles={styles}
      placeholder={placeholder}
      {...props}
    />
  )
}

export const TextDropdownControlled = ({
  options,
  value,
  onChange,
  onInputChange,
  closeMenuOnSelect,
  disabled = false,
  titleText = '',
  className = '',
  styles = defaultTitleStyles,
  placeholder = 'Select...',
  ...props
}: TextDropdownProps) => {
  return (
    <div className="flexBoxCol" style={{ minWidth: '90px', paddingRight: '0.75rem' }}>
      {titleText}
      <Select
        isDisabled={disabled}
        isMulti={false}
        className={`${className}`}
        options={options}
        styles={styles}
        value={value}
        isClearable={false}
        isSearchable={false}
        placeholder={placeholder}
        onInputChange={onInputChange ? (val) => onInputChange(val) : undefined}
        onChange={(v) => {
          onChange?.(v)
        }}
        {...props}
      />
    </div>
  )
}
export default TextDropdown
