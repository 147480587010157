import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'

export interface PageErrorProps extends React.HTMLAttributes<HTMLElement> {
  disableVerticalCentering?: boolean
  header?: string
}

const PageError = (props: PageErrorProps) => {
  const {
    className: propsClassName,
    disableVerticalCentering,
    header,
    children,
    ...forwarded
  } = props

  const className = classNames(
    styles.error,
    styles.message,
    {
      [styles.messageCenterContent]: !disableVerticalCentering,
      [styles.messageNoHeader]: !header,
    },
    propsClassName
  )

  return (
    <div className={className} {...forwarded}>
      {header && <h2 className={styles.messageHeader}>{header}</h2>}
      <p className={styles.messageContent}>{children}</p>
    </div>
  )
}

export default PageError
