import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import {
  SearchPageSymphonySliceTypeReady,
  isSearchPageSymphonySliceReady,
} from './SearchPageSymphonySlice.types'

export function useSymphonySelector<
  T extends (state: SearchPageSymphonySliceTypeReady) => any,
>(selector: T): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageSymphonySliceReady(state.searchSymphonyView))
      return selector(state.searchSymphonyView)
    else return null
  })
}

export function useSymphonyOpportunitySource(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.opportunitySource
  })
}

export function useSymphonyOpportunityType(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.opportunityType
  })
}

export function useSymphonyRespondByDateFilter(): DateFilterValue | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.respondByDate
  })
}

export function useSymphonyPostedOnDateFilter(): DateFilterValue | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.postedOnDate
  })
}

export function useSymphonySortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useSymphonySortByDirection(): 'asc' | 'desc' | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useSymphonyReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useSymphonyAdvanced(): boolean | null {
  return useSymphonySelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useSymphonyQuery(): string {
  const value = useSymphonySelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useSymphonyActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useSymphonySelector((state) => {
    return state.view.savedSearch
  })
}

export function useSymphonySelectedListing(): any {
  return useSymphonySelector((state) => {
    return state.view.selectedListing
  })
}

export function useSymphonyPageFrom(): number | null {
  return useSymphonySelector((state) => {
    return state.view.pageFrom
  })
}

export function useSymphonyHitCount(): number | null {
  return useSymphonySelector((state) => {
    return state.view.hitCount
  })
}

export const SYMPHONY_PAGE_SIZE = 40
