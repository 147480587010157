import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import {
  SearchPageSAMSliceTypeReady,
  isSearchPageSAMSliceReady,
} from './SearchPageSAMSlice.types'

export function useSAMSelector<T extends (state: SearchPageSAMSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageSAMSliceReady(state.searchSAMView)) return selector(state.searchSAMView)
    else return null
  })
}

export function useSAMNAICSFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSAMSelector((state) => {
    return state.view.persisted.naics
  })
}

export function useSAMAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSAMSelector((state) => {
    return state.view.persisted.agency
  })
}

export function useSAMPlaceOfPerformanceFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSAMSelector((state) => {
    return state.view.persisted.placeOfPerformance
  })
}

export function useSAMAwardTypeFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSAMSelector((state) => {
    return state.view.persisted.awardType
  })
}

export function useSAMSetAsideFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useSAMSelector((state) => {
    return state.view.persisted.setaside
  })
}

export function useSAMOpenFilter(): boolean | null {
  return useSAMSelector((state) => {
    return state.view.persisted.open
  })
}

export function useSAMRespondByDateFilter(): DateFilterValue | null {
  return useSAMSelector((state) => {
    return state.view.persisted.respondByDate
  })
}

export function useSAMPostedOnDateFilter(): DateFilterValue | null {
  return useSAMSelector((state) => {
    return state.view.persisted.postedOnDate
  })
}

export function useSAMSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useSAMSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useSAMSortByDirection(): 'asc' | 'desc' | null {
  return useSAMSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useSAMReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useSAMSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useSAMAdvanced(): boolean | null {
  return useSAMSelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useSAMQuery(): string {
  const value = useSAMSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useSAMActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useSAMSelector((state) => {
    return state.view.savedSearch
  })
}

export function useSAMSelectedListing(): any {
  return useSAMSelector((state) => {
    return state.view.selectedListing
  })
}

export function useSAMPageFrom(): number | null {
  return useSAMSelector((state) => {
    return state.view.pageFrom
  })
}

export function useSAMHitCount(): number | null {
  return useSAMSelector((state) => {
    return state.view.hitCount
  })
}

export const SAM_PAGE_SIZE = 40
