import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client'
import { GetServerSidePropsContext } from 'next'
import { createApolloClient, getRequestHeadersFromNextSSRContext } from './apolloClient'
import Head from 'next/head'
import App from 'next/app'

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<any> | null = null

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} [initialState]
 * @param  {NextPageContext} [ctx]
 */
export function initApolloClient(
  // This will be an empty object on CSR
  ctx: Partial<GetServerSidePropsContext>,
  initialState = {},
  errorLink: ApolloLink | null = null
): ApolloClient<any> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(
      getRequestHeadersFromNextSSRContext(ctx),
      initialState,
      errorLink ?? undefined
    )
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(
      getRequestHeadersFromNextSSRContext(ctx),
      initialState,
      errorLink ?? undefined
    )
  }

  return globalApolloClient
}

export function getGlobalApolloClient(): ApolloClient<any> {
  if (!globalApolloClient) console.error('Global Apollo Client not initialized')
  return globalApolloClient!
}

function tryParseApolloState(apolloState: string | null): any {
  try {
    if (!apolloState) return null
    return JSON.parse(apolloState)
  } catch (e) {
    console.error(`Failed to parse apollo state: ${apolloState}`, e)
    return null
  }
}

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 * @param  {Object} withApolloOptions
 * @param  {Boolean} [withApolloOptions.ssr=false]
 * @returns {(PageComponent: ReactNode) => ReactNode}
 */
export function withApollo({ ssr = false } = {}) {
  return function createComponentWithApollo(PageComponent: React.ComponentType<any>) {
    const WithApollo = ({ apolloClient, apolloState, ...other }) => {
      let client
      if (apolloClient) {
        // Happens on: getDataFromTree & next.js ssr
        client = apolloClient
      } else {
        // Happens on: next.js csr
        const state = tryParseApolloState(other?.pageProps?.apolloState) ?? apolloState
        client = initApolloClient({}, state)
      }

      return (
        <ApolloProvider client={client}>
          <PageComponent {...other} />
        </ApolloProvider>
      )
    }

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
      const displayName = PageComponent.displayName || PageComponent.name || 'Component'
      WithApollo.displayName = `withApollo(${displayName})`
    }

    /**
     * TODO: I'm not sure what this does. I removed it and we saw some weird slowness on prod.
     * I'm not sure if this is the cause but I added it back.
     */
    if (ssr || (PageComponent as any).getInitialProps) {
      WithApollo.getInitialProps = async (ctx) => {
        const inAppContext = Boolean(ctx.ctx)
        const apolloClient = initApolloClient(ctx)

        // Run wrapped getInitialProps methods
        let pageProps = {}
        if ((PageComponent as any).getInitialProps) {
          pageProps = await (PageComponent as any).getInitialProps(ctx)
        } else if (inAppContext) {
          pageProps = await (App as any).getInitialProps(ctx)
        }

        // Only on the server:
        if (typeof window === 'undefined') {
          const { AppTree } = ctx
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res && ctx.res.finished) {
            return pageProps
          }

          // Only if dataFromTree is enabled
          if (ssr && AppTree) {
            try {
              // Import `@apollo/react-ssr` dynamically.
              // We don't want to have this in our client bundle.
              const { getDataFromTree } = await import('@apollo/client/react/ssr')

              // Since AppComponents and PageComponents have different context types
              // we need to modify their props a little.
              let props
              if (inAppContext) {
                props = { ...pageProps, apolloClient }
              } else {
                props = { pageProps: { ...pageProps, apolloClient } }
              }

              // console.log('SSR apolloClient keys: ', Object.keys(apolloClient))
              // console.log('ssr AppTree props: ', props)
              // console.log('ssr AppTree getDataFromTree: ', getDataFromTree)
              // console.log('ssr AppTree apolloClient: ', apolloClient.link)
              // console.log('ssr AppTree apolloClient: ', apolloClient.link.request)

              // Take the Next.js AppTree, determine which queries are needed to render,
              // and fetch them. This method can be pretty slow since it renders
              // your entire AppTree once for every query. Check out apollo fragments
              // if you want to reduce the number of rerenders.
              // https://www.apollographql.com/docs/react/data/fragments/
              await getDataFromTree(<AppTree {...props} />)
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              console.error('Error while running `getDataFromTree`', error)
            }

            // getDataFromTree does not call componentWillUnmount
            // head side effect therefore need to be cleared manually
            ;(Head as any).rewind()
          }
        }

        const apolloState = apolloClient.cache.extract()
        delete apolloState.res
        delete apolloState.req
        // const apolloClientCtx = ctx.apolloClient

        // console.log('apolloState: ', Object.keys(apolloState))
        // console.log('apolloClient: ', ctx.apolloClient)
        // console.log('withApollo returning')

        return {
          ...pageProps,
          // Extract query data from the Apollo store
          apolloState: apolloState,
          // Provide the client for ssr. As soon as this payload
          // gets JSON.stringified it will remove itself.
          apolloClient: ctx.apolloClient,
        }
      }
    }

    return WithApollo
  }
}
