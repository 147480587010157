import { ContractVehicleViewState } from '../contract-vehicle-view-state'

export type SearchPageContractVehicleSliceType =
  | SearchPageContractVehicleSliceTypeInitial
  | SearchPageContractVehicleSliceTypeReady

export type SearchPageContractVehicleSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageContractVehicleSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: ContractVehicleViewState
}

export type SearchPageContractVehicleSliceViewType = Exclude<
  SearchPageContractVehicleSliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageContractVehicleSliceViewType> =
  Extract<SearchPageContractVehicleSliceTypeReady['view'], { type: T }>

export function isSearchPageContractVehicleSliceReady(
  s: SearchPageContractVehicleSliceType
): s is SearchPageContractVehicleSliceTypeReady {
  return s.state === 'ready'
}
