import {
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faPlus,
  faStar,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import styles from './Button.module.scss'

export const BUTTON_STYLES_CSS = styles

interface ButtonProps {
  id?: string
  children?: any
  type?: any
  onClick?: any
  btnType?: 'primary' | 'red' | 'blue' | 'green' | 'redSoft' | 'lpCta' | 'lpCtaAlt'
  dropdown?: boolean
  plus?: boolean
  trash?: boolean
  star?: boolean
  cross?: boolean
  caretLeft?: boolean
  caretRight?: boolean
  customStyles?: any
  loading?: boolean
  loadingText?: string
  className?: string
  outerClassName?: string
  disabled?: boolean
  tooltip?: boolean
  smallText?: boolean
  containerRef?: any
}

const Button = (props: ButtonProps) => {
  const {
    id,
    children,
    type,
    onClick,
    btnType,
    plus,
    trash,
    star,
    cross,
    caretLeft,
    caretRight,
    customStyles,
    loading,
    loadingText,
    className,
    outerClassName = '',
    dropdown,
    disabled,
    tooltip = false,
    smallText = false,
    containerRef,
    ...otherProps
  } = props

  let style = disabled
    ? styles.buttonDisabled
    : className
      ? `${styles.button} ${className}`
      : btnType == 'primary'
        ? styles.buttonPrimary
        : btnType == 'red'
          ? styles.buttonRed
          : btnType == 'blue'
            ? styles.buttonBlue
            : btnType == 'green'
              ? styles.buttonGreen
              : btnType == 'redSoft'
                ? styles.buttonRedSoft
                : btnType == 'lpCta'
                  ? styles.buttonLpCta
                  : btnType == 'lpCtaAlt'
                    ? styles.buttonLpCtaAlt
                    : disabled
                      ? styles.buttonDisabled
                      : styles.button

  return (
    <div className={outerClassName}>
      <button
        id={id}
        className={style}
        onClick={onClick}
        style={customStyles}
        type={type}
        disabled={disabled}
        ref={containerRef}
        {...otherProps}
      >
        {plus && <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.25em' }} />}
        {trash && <FontAwesomeIcon icon={faTrash} style={{ marginRight: '0.25em' }} />}
        {star && <FontAwesomeIcon icon={faStar} style={{ marginRight: '0.25em' }} />}
        {cross && <FontAwesomeIcon icon={faTimes} style={{ marginRight: '0.25em' }} />}
        {caretLeft && (
          <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: '0.25em' }} />
        )}
        {caretRight && (
          <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '0.25em' }} />
        )}
        {loading ? (
          (loadingText ?? 'Working...')
        ) : (
          <span className={`${smallText && 'textMed'}`}>{children}</span>
        )}
        {dropdown && (
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ marginLeft: '0.35em', marginTop: '0.1em' }}
          />
        )}
      </button>
    </div>
  )
}

export default Button
