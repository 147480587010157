import { CIOSPViewState } from '../ciosp-view-state'

export type SearchPageCIOSPSliceType =
  | SearchPageCIOSPSliceTypeInitial
  | SearchPageCIOSPSliceTypeReady

export type SearchPageCIOSPSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageCIOSPSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: CIOSPViewState
}

export type SearchPageCIOSPSliceViewType = Exclude<
  SearchPageCIOSPSliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageCIOSPSliceViewType> = Extract<
  SearchPageCIOSPSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageCIOSPSliceReady(
  s: SearchPageCIOSPSliceType
): s is SearchPageCIOSPSliceTypeReady {
  return s.state === 'ready'
}
