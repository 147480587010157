import Tooltip from '@components/tooltip/Tooltip'

interface GovWinSearchRestrictionsTooltipProps {
  visible: boolean
}

export function GovWinSearchRestrictionsTooltip(
  props: GovWinSearchRestrictionsTooltipProps
) {
  return props.visible ? (
    <Tooltip
      id="govwin-search-restrictions-tooltip"
      tooltipContent={
        <div style={{ maxWidth: '18rem' }}>
          Due to limitations with the GovWin API, we are unable to support filters while
          showing opportunities that have been Passed or Saved. Please select "All
          Opportunities" to activate other filters.
          <br />
          Due to limitations with the GovWin API, we can only show the 30 most recent
          bookmarks.
        </div>
      }
    />
  ) : null
}
