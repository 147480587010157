import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SearchPageSliceType } from './SearchPageSlice.types'
import { firstString } from './utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../url-management/search-view-query-param-key'
import { LEGACY_Bookmark } from './LEGACY_BookmarkOptimisticSlice.types'
import { SavedSearch2 } from '@nextweb/common'

const initState: SearchPageSliceType = {
  state: 'initial',
}

/**
 * This slice contains some common actions used by all the views.
 *
 * We don't really need to keep track of the query here because
 * each view does so itself (as it needs to specially manage the state
 * of the query), but we have a copy here anyway.
 */
const SearchPageSlice = createSlice({
  name: 'SearchPageSlice',
  initialState: initState as SearchPageSliceType,
  reducers: {
    init(slice, action: PayloadAction<{ query: Record<string, string | string[]> }>) {
      const { query } = action.payload
      return {
        state: 'ready',
        query: query,
        view: query[SEARCH_VIEW_QUERY_PARAM_KEY]
          ? firstString(query[SEARCH_VIEW_QUERY_PARAM_KEY]).toLowerCase()
          : null,
      }
    },
    /**
     * This slice contains some common actions used by all the views.
     *
     * We don't really need to keep track of the query here because
     * each view does so itself (as it needs to specially manage the state
     * of the query)
     */
    onLoadedListings(
      state,
      action: PayloadAction<{
        // As with the legacy code, this expects parallel arrays
        listings: Array<{ id: string }>
        bookmarks: Array<LEGACY_Bookmark | null>
        numHits: number
        search: SavedSearch2.SavedSearch | null
      }>
    ): void {},
    setSelectedListing(
      state,
      action: PayloadAction<{
        listing: any | null
        source: string
        externalId: string
      } | null>
    ): void {},
    reset() {
      return {
        state: 'initial',
      }
    },
  },
})

export function commonUpdateSelectedListing(
  slice: { view: { selectedListing: any } },
  payload: {
    listing: any | null
    source: string
    externalId: string
  } | null
) {
  if (!payload) {
    slice.view.selectedListing = null
    return
  }

  const { listing } = payload
  if (slice.view.selectedListing?.id === listing?.id) {
    slice.view.selectedListing = null
  } else if (listing) {
    slice.view.selectedListing = {
      ...listing,
    }
  }
}

export const SearchPageSliceActions = SearchPageSlice.actions
export const SearchPageReducer = SearchPageSlice.reducer
