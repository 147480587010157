import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageEBuySliceActions } from '../redux/SearchPageEBuySlice'
import { useEBuySortBy, useEBuySortByDirection } from '../redux/selectors'

export const EBUY_VIEW_SORT_BY_POSTED_ON = 'posted-on'
export const EBUY_VIEW_SORT_BY_RESPOND_BY = 'respond-by'
export const EBUY_VIEW_SORT_BY_LAST_MODIFIED = 'last-modified'
const SORT_BY_OPTIONS = [
  {
    id: 'posted-on',
    label: 'Posted On',
    value: EBUY_VIEW_SORT_BY_POSTED_ON,
  },
  {
    id: 'respond-by',
    label: 'Respond By',
    value: EBUY_VIEW_SORT_BY_RESPOND_BY,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: EBUY_VIEW_SORT_BY_LAST_MODIFIED,
  },
]

interface EBuyViewSortByDropdownProps {
  disabled?: boolean
}

export const EBuyViewSortByDropdown = (props: EBuyViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useEBuySortBy()
  const direction = useEBuySortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageEBuySliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageEBuySliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
