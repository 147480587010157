import CustomDatePicker from '@components/datepicker/DatePicker'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { DropdownV2 } from '@components/dropdown/DropdownV2'
import {
  CommonFilterDropdownIndicator,
  CommonFilterWrapper,
} from '../CommonFilterWrapper/CommonFilterWrapper'
import styles from './CommonDateFilter.module.scss'
import _ from 'lodash'
import { scls } from '@helpers/scls'
import { useIsMobileOrTablet } from '@helpers/hooks/IsMobileHook'

export type DateFilterAbsoluteDateTime = {
  type: 'DateFilterAbsoluteDateTime'
  timestampISOString: string
}

function isDateFilterAbsoluteDateTime(s: any): s is DateFilterAbsoluteDateTime {
  return s && s.type === 'DateFilterAbsoluteDateTime'
}

export type DateFilterDateRangeValue = {
  type: 'DateFilterDateRangeValue'
  begin: DateFilterAbsoluteDateTime | null
  end: DateFilterAbsoluteDateTime | null
}

export function isDateFilterDateRangeValue(s: any): s is DateFilterDateRangeValue {
  return s && s.type === 'DateFilterDateRangeValue'
}

// This component supports date ranges as well as some specific date filters
// from a dropdown
export type DateFilterPreSupportedDateFilterValue = {
  type: 'DateFilterPreSupportedDateFilterValue'
  value: { label: string; value: string }
}

export function isDateFilterPreSupportedDateFilterValue(
  s: any
): s is DateFilterPreSupportedDateFilterValue {
  return s && s.type === 'DateFilterPreSupportedDateFilterValue'
}

export type DateFilterValue = {
  type: 'DateFilterValue'
  value: DateFilterDateRangeValue | DateFilterPreSupportedDateFilterValue | null
}

function isDateFilterValue(s: any): s is DateFilterValue {
  return s && s.type === 'DateFilterValue'
}

function isDefaultOrDropdownDateFilterValue(value: DateFilterValue | null): boolean {
  if (!value || !isDateFilterValue(value) || !value.value) return true

  if (value.value.type === 'DateFilterPreSupportedDateFilterValue') {
    return true
  }

  return false
}

const BACK_TO_PRECOMPUTED_OPTION = {
  id: 'precomputed',
  label: 'Use relative dates...',
  value: 'precomputed',
}

const BACK_TO_PRECOMPUTED_OPTIONS = [BACK_TO_PRECOMPUTED_OPTION]

const USE_CUSTOM_DATE_RANGE_OPTION = {
  id: 'custom',
  label: 'Custom...',
  value: 'custom',
}

export const DEFAULT_COMMON_DATE_FILTER_OPTIONS = [
  USE_CUSTOM_DATE_RANGE_OPTION,
  {
    id: 'next-7-days',
    label: 'Next 7 days',
    value: 'next-7-days',
  },
  {
    id: 'last-7-days',
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    id: 'next-30-days',
    label: 'Next 30 days',
    value: 'next-30-days',
  },
  {
    id: 'last-30-days',
    label: 'Last 30 days',
    value: 'last-30-days',
  },
  {
    id: 'next-60-days',
    label: 'Next 60 days',
    value: 'next-60-days',
  },
  {
    id: 'last-60-days',
    label: 'Last 60 days',
    value: 'last-60-days',
  },
  {
    id: 'next-90-days',
    label: 'Next 90 days',
    value: 'next-90-days',
  },
  {
    id: 'last-90-days',
    label: 'Last 90 days',
    value: 'last-90-days',
  },
  {
    id: 'next-180-days',
    label: 'Next 180 days',
    value: 'next-180-days',
  },
  {
    id: 'last-180-days',
    label: 'Last 180 days',
    value: 'last-180-days',
  },
  {
    id: 'calendar-year',
    label: 'This Calendar Year',
    value: 'calendar-year',
  },
  {
    id: 'fiscal-year',
    label: 'This Fiscal Year',
    value: 'fiscal-year',
  },
]

function getDateRangeFromDateFilterValue(
  filterValue: DateFilterValue | null
): [Date | null, Date | null] {
  if (!filterValue || !isDateFilterDateRangeValue(filterValue.value)) {
    return [null, null]
  }

  const rangeValue = filterValue.value

  let begin: Date | null = null
  let end: Date | null = null
  if (rangeValue.begin && isDateFilterAbsoluteDateTime(rangeValue.begin)) {
    begin = moment(rangeValue.begin.timestampISOString).toDate()
  }

  if (rangeValue.end && isDateFilterAbsoluteDateTime(rangeValue.end)) {
    end = moment(rangeValue.end.timestampISOString).toDate()
  }

  return [begin, end]
}

interface CommonDateFilterProps {
  value: DateFilterValue | null

  className?: string

  disabled?: boolean
  placeholder: string
  onChange: (selection: DateFilterValue | null) => void

  // Note! If you change these, you have to make sure the functions receiving these values
  // can handle them. convertCommonDateFilterPreSupportedValueToDateFilterValue
  overrideOptions?: Array<{ id: string; label: string; value: string }>
}

// From josh
// next/last 7, 30, 60, 90, 180, calendar year, fiscal year (offset 1 quarter)
export const CommonDateFilter = (props: CommonDateFilterProps) => {
  const { onChange, value, disabled, placeholder, overrideOptions, className } = props
  const [isDropdownMode, setDropdownMode] = useState(
    isDefaultOrDropdownDateFilterValue(value)
  )

  const isMobile = useIsMobileOrTablet()
  useEffect(() => {
    setDropdownMode(isDefaultOrDropdownDateFilterValue(value))
  }, [value])

  const onDropdownChange = useCallback(
    (selected: { id: string; label: string; value: string } | null) => {
      if (!selected) {
        onChange(null)
        return
      }
      if (selected.id === USE_CUSTOM_DATE_RANGE_OPTION.id) {
        setDropdownMode(false)

        // Switching to absolute dates will clear the field.
        onChange(null)
      } else if (selected.id === BACK_TO_PRECOMPUTED_OPTION.id) {
        setDropdownMode(true)

        // Switching back to relative dates will clear the field.
        onChange(null)
      } else {
        onChange({
          type: 'DateFilterValue',
          value: {
            type: 'DateFilterPreSupportedDateFilterValue',
            value: {
              value: selected.value,
              label: selected.label,
            },
          },
        })
      }
    },
    [onChange]
  )

  const onRangeBoundaryChange = useCallback(
    (picked: Date | null, edge: 'begin' | 'end') => {
      const filterValue = value?.value
      let currentBegin: DateFilterAbsoluteDateTime | null = null
      let currentEnd: DateFilterAbsoluteDateTime | null = null
      if (isDateFilterDateRangeValue(filterValue)) {
        currentBegin = filterValue.begin
        currentEnd = filterValue.end
      }

      /**
       * Since this widget only picks dates and not times,
       * assume that the user wants the beginning of the day for the start
       * date and the end of the day for the end date.
       */
      const rounded =
        edge === 'begin' ? moment(picked).startOf('day') : moment(picked).endOf('day')

      console.log(
        'rounded',
        rounded.toISOString(),
        moment(rounded).utc(true).startOf('day').toISOString(),
        moment(rounded).utc(true).endOf('day').toISOString()
      )

      const asAbsoluteDateValue: DateFilterAbsoluteDateTime | null =
        picked === null
          ? null
          : {
              type: 'DateFilterAbsoluteDateTime',
              timestampISOString: rounded.toISOString(),
            }

      onChange({
        type: 'DateFilterValue',
        value: {
          type: 'DateFilterDateRangeValue',
          begin: edge === 'begin' ? asAbsoluteDateValue : currentBegin,
          end: edge === 'end' ? asAbsoluteDateValue : currentEnd,
        },
      })
    },
    [value]
  )

  const ddValue = useMemo(() => {
    if (!value || !isDateFilterValue(value)) {
      return null
    }

    const filterValue = value.value

    if (isDateFilterPreSupportedDateFilterValue(filterValue)) {
      const matching = DEFAULT_COMMON_DATE_FILTER_OPTIONS.find(
        (opt) => opt.value === filterValue.value.value
      )
      return (
        matching ?? {
          id: 'custom-from-input',
          value: filterValue.value.value,
          label: filterValue.value.label,
        }
      )
    } else return null
  }, [value])

  const ddOptions = useMemo(() => {
    if (!overrideOptions) {
      return DEFAULT_COMMON_DATE_FILTER_OPTIONS
    } else {
      const opts = _.clone(overrideOptions)
      if (!opts.some((opt) => opt.value === USE_CUSTOM_DATE_RANGE_OPTION.value)) {
        opts.unshift(USE_CUSTOM_DATE_RANGE_OPTION)
        return opts
      } else {
        return opts
      }
    }
  }, [overrideOptions])

  if (isDropdownMode) {
    return (
      <CommonFilterWrapper className={scls(styles, isMobile && 'mobile') + className}>
        <DropdownV2
          customDropdownIndicator={CommonFilterDropdownIndicator}
          disabled={disabled}
          clearable
          searchable
          placeholder={placeholder}
          options={ddOptions}
          value={ddValue}
          closeMenuOnSelect
          onChange={onDropdownChange}
        />
      </CommonFilterWrapper>
    )
  } else {
    const [start, end] = getDateRangeFromDateFilterValue(value)

    return (
      <CommonFilterWrapper
        className={
          scls(styles, 'bounded-range-container', isMobile && 'mobile') + className
        }
      >
        <div className={styles['range-label'] + ' cdf-range-label'}>
          <DropdownV2
            customDropdownIndicator={CommonFilterDropdownIndicator}
            disabled={disabled}
            clearable
            searchable
            placeholder={placeholder}
            // This is always null because the only option is to go back...
            value={null}
            options={BACK_TO_PRECOMPUTED_OPTIONS}
            closeMenuOnSelect
            onChange={onDropdownChange}
          />
        </div>
        <div className={styles['date-range'] + ' cdf-range-bound-begin'}>
          <CustomDatePicker
            placeholder="From Date"
            className={styles['date-pick']}
            onChange={(date) => {
              onRangeBoundaryChange(date, 'begin')
            }}
            value={start === null ? start : start.toISOString()}
          />
          <CustomDatePicker
            placeholder="To Date"
            className={styles['date-pick'] + ' cdf-range-bound-begin'}
            onChange={(date) => {
              onRangeBoundaryChange(date, 'end')
            }}
            value={end === null ? end : end.toISOString()}
          />
        </div>
      </CommonFilterWrapper>
    )
  }
}
