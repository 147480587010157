import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import useResizeHelper from '@helpers/hooks/useResizeHelper/useResizeHelper'

export interface PageDrawerProps extends React.HTMLAttributes<HTMLElement> {
  minWidth?: number
  maxWidth?: number
  disableScroll?: boolean
}

const DEFAULT_MIN_WIDTH = 200
const DEFAULT_MAX_WIDTH = 800

const PageDrawer = (props: PageDrawerProps) => {
  const {
    className: propsClassName,
    children,
    minWidth = DEFAULT_MIN_WIDTH,
    maxWidth = DEFAULT_MAX_WIDTH,
    disableScroll,
    ...forwarded
  } = props

  const { resizing, handle, container } = useResizeHelper<{
    Container: HTMLDivElement
    Handle: HTMLButtonElement
  }>({ axis: 'x', minWidth, maxWidth })

  const className = classNames(
    styles.drawer,
    {
      [styles.drawerResizing]: resizing,
    },
    propsClassName
  )

  return (
    <div className={className} {...forwarded} ref={container.ref} style={container.style}>
      <div
        className={classNames(styles.drawerContent, {
          [styles.drawerContentDisableScroll]: disableScroll,
          [styles.drawerContentScroll]: !disableScroll,
        })}
      >
        {children}
      </div>
      <button
        className={styles.drawerHandle}
        {...handle.props}
        aria-label="Resize Drawer"
      />
    </div>
  )
}

export default PageDrawer
