import {
  APOLLO_ERROR_BAD_AUTH_MESSAGE,
  APOLLO_ERROR_METHOD_NOT_PERMITTED_MESSAGE,
  APOLLO_ERROR_MFA_REQUIRED_MESSAGE,
  GOVWIN_CREDENTIALS_BAD,
  GOVWIN_CREDENTIALS_EXPIRED,
  PIPELINE_NO_PIPELINES_AVAILABLE,
} from '@nextweb/common'
import { PUBLIC_ROUTES } from '@src/partials/app/public-routes'
import { isEmbeddedUrl } from './mfa-redirect'

/**
 * We need to have a consistent way to know when to redirect both on BE (SSR) and FE,
 * so the output of this function actuall just returns commands which needs to be implemented for both BE and FE.
 */
export function onApolloError(
  message: string | null | undefined,
  context: {
    pathname: string
    query: Record<string, string | string[]>

    // NextJs has the path that is visible in the browser stored in this property.
    // The actually path (as in, not 'asPath') is some weird js file that nextjs manages.
    asPath: string

    // Additional information stored on the error object.
    extensions: Record<string, string | boolean>
  }
):
  | { type: 'None' }
  | { type: 'SignOut' }
  | { type: 'Redirect'; url: string }
  | { type: 'Client::GovWinExpired' }
  | { type: 'Client::GovWinBad' }
  | { type: 'Client::WorkspaceChange'; workspaceId: number }
  | { type: 'Client::Snackbar'; message: string } {
  const { pathname, query, asPath, extensions } = context
  if (typeof message !== 'string') return { type: 'None' }

  const isPublic = PUBLIC_ROUTES.some((publicRoute) =>
    publicRoute.length > 1
      ? pathname.includes(`/${publicRoute}`)
      : pathname === `/${publicRoute}`
  )

  // Sign out if the user's authentication is not consistent with workspace requirements.
  if (message.includes(APOLLO_ERROR_METHOD_NOT_PERMITTED_MESSAGE)) {
    // window.localStorage.clear()
    // router.push('/signout', '/signout')
    return {
      type: 'SignOut',
    }
  } else if (message.includes(PIPELINE_NO_PIPELINES_AVAILABLE)) {
    console.error('No pipelines available. Redirecting to create pipeline.')
    return {
      type: 'Redirect',
      url: '/pipeline/none',
    }
  } else if (
    // MFA Required should not redirect to signout because we actually display
    // an MFA Prompt page.
    message.includes(APOLLO_ERROR_MFA_REQUIRED_MESSAGE) &&
    !pathname.startsWith('/verify')
  ) {
    console.error('Verification needed. Redirecting to verify.', pathname)
    const redirectQuery = new URLSearchParams()
    redirectQuery.set('redirect', asPath)
    if (!isEmbeddedUrl(pathname, query as Record<string, string | string[]>)) {
      console.error("Non-embedded URL, redirecting to '/verify'.", pathname, query)
      return {
        type: 'Redirect',
        url: `/verify?${redirectQuery.toString()}`,
      }
    } else {
      console.error("Embedded URL, redirecting to '/verify/embedded'.", pathname, query)
      return {
        type: 'Redirect',
        url: `/verify/embedded?${redirectQuery.toString()}`,
      }
    }
  } else if (message.includes(APOLLO_ERROR_BAD_AUTH_MESSAGE) && !isPublic) {
    console.error('Authentication needed. Redirecting to login.')
    const redirectQuery = new URLSearchParams()
    redirectQuery.set('redirect', asPath)
    const url = `/login?${redirectQuery.toString()}`

    return {
      type: 'Redirect',
      url: url,
    }
  } else if (message.includes(GOVWIN_CREDENTIALS_EXPIRED)) {
    if (typeof window !== 'undefined') {
      //   window.dispatchEvent(new GovWinEvent('Expired'))
      return {
        type: 'Client::GovWinExpired',
      }
    }
  } else if (message.includes(GOVWIN_CREDENTIALS_BAD)) {
    if (typeof window !== 'undefined') {
      //   window.dispatchEvent(new GovWinEvent('Bad'))
      return {
        type: 'Client::GovWinBad',
      }
    }
  } else if (typeof extensions['workspaceTarget'] === 'number') {
    if (typeof window !== 'undefined') {
      //   window.dispatchEvent(
      //     new WorkspaceScopeEvent('Workspace change needed', extensions['workspaceTarget'])
      //   )
      return {
        type: 'Client::WorkspaceChange',
        workspaceId: extensions['workspaceTarget'],
      }
    }
  } else if (extensions['snackbar'] === true) {
    if (typeof window !== 'undefined') {
      //   window.dispatchEvent(new SnackbarEvent(message))
      return {
        type: 'Client::Snackbar',
        message: message,
      }
    }
  }

  return { type: 'None' }
}
