import { SAM_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValuesFromFilterValues,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonMultiDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import {
  SAM_VIEW_SORT_BY_LAST_MODIFIED,
  SAM_VIEW_SORT_BY_POSTED_ON,
  SAM_VIEW_SORT_BY_RESPOND_BY,
} from '../components/SAMViewSortByDropdown'
import { SAMViewPersisted, SAM_VIEW_EMPTY_STATE } from '../sam-view-state'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeSAMViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case SAM_VIEW_SORT_BY_RESPOND_BY:
    case 'repondBy':
    case 'repondByDate':
      return SAM_SAVED_SEARCH_FIELDS.sorts.respondByDate
    case SAM_VIEW_SORT_BY_POSTED_ON:
    case 'postedOn':
    case 'postedOnDate':
      return SAM_SAVED_SEARCH_FIELDS.sorts.postedOnDate
    case SAM_VIEW_SORT_BY_LAST_MODIFIED:
    case 'lastModified':
    case 'lastModifiedDate':
      return SAM_SAVED_SEARCH_FIELDS.sorts.lastModified
    default:
      return null
  }
}

function deserializeSAMViewSortByValue(value: string) {
  switch (value) {
    case SAM_SAVED_SEARCH_FIELDS.sorts.respondByDate:
      return SAM_VIEW_SORT_BY_RESPOND_BY
    case SAM_SAVED_SEARCH_FIELDS.sorts.postedOnDate:
      return SAM_VIEW_SORT_BY_POSTED_ON
    case SAM_SAVED_SEARCH_FIELDS.sorts.lastModified:
      return SAM_VIEW_SORT_BY_LAST_MODIFIED
    default:
      return null
  }
}

export function convertSAMViewToSavedSearch(
  view: SAMViewPersisted
): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  // Note! The key in the state happens to coincide with the "Field" value,
  // but make no mistake, they are not the same, you can store the value
  // in any field you want on the client side, but the "Field" value must match
  // a SAM field on the BE.
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'agency', //
    SAM_SAVED_SEARCH_FIELDS.filters.agency
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'awardType',
    SAM_SAVED_SEARCH_FIELDS.filters.awardType
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'naics', //
    SAM_SAVED_SEARCH_FIELDS.filters.naics
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'placeOfPerformance',
    SAM_SAVED_SEARCH_FIELDS.filters.placeOfPerformance
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'setaside',
    SAM_SAVED_SEARCH_FIELDS.filters.setaside
  )

  if (view.open !== null) {
    filters.push({
      type: 'MatchFilter',
      field: SAM_SAVED_SEARCH_FIELDS.filters.status,
      display: view.open ? 'Open' : 'Closed',
      value: view.open ? 'Open' : 'Closed',
    })
  }

  if (view.postedOnDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.postedOnDate,
      SAM_SAVED_SEARCH_FIELDS.filters.postedOnDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.respondByDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.respondByDate,
      SAM_SAVED_SEARCH_FIELDS.filters.respondByDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: SAM_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values:
        view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeSAMViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: SAM_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.sam.key,
  }
}

export function convertSavedSearchToSAMView(
  savedSearch: SavedSearch2.SavedSearch
): SAMViewPersisted {
  const view: SAMViewPersisted = _.cloneDeep(SAM_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case SAM_SAVED_SEARCH_FIELDS.filters.agency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.agency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for agency')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.awardType: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.awardType = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for awardType')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.naics: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.naics = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for naics')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.placeOfPerformance: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.placeOfPerformance = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for placeOfPerformance')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.setaside: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.setaside = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for setaside')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.status: {
        if (filter.type === 'MatchFilter') {
          view.open =
            filter.value.toString().toLowerCase() === 'true' ||
            filter.value.toString().toLowerCase() === 'open'
        } else {
          console.error('Unexpected filter type for open')
        }
        break
      }
      case 'postedOn':
      case SAM_SAVED_SEARCH_FIELDS.filters.postedOnDate: {
        if (filter.type === 'DateFilter') {
          view.postedOnDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for postedOnDate')
        }
        break
      }
      case 'respondBy':
      case SAM_SAVED_SEARCH_FIELDS.filters.respondByDate: {
        if (filter.type === 'DateFilter') {
          view.respondByDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for respondByDate')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case SAM_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeSAMViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case SAM_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
