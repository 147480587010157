import { IDBPDatabase, openDB } from 'idb'

const ME_STORE = 'me'
const WORKSPACES_STORE = 'workspaces'
const OPPS_STORE = 'opps'
const PIPELINES_STORE = 'pipelines'

export async function openNextStageDatabase() {
  const db = await openDB('nextstage', 3, {
    upgrade: async (db, oldVersionNumber, newVersionNumber, transaction) => {
      const oppsStore = await db.createObjectStore(OPPS_STORE, {
        keyPath: 'oppId',
      })
      oppsStore.createIndex('pipelineId', 'pipelineId', {})
      const pipelinesStore = await db.createObjectStore(PIPELINES_STORE, { keyPath: 'id' })
      pipelinesStore.createIndex('workspaceId', 'workspaceId', {})
      db.createObjectStore(WORKSPACES_STORE, { keyPath: 'workspaceId' })
      db.createObjectStore(ME_STORE)
    },
  })

  return db
}

export async function clearFromAccountStore(
  db: IDBPDatabase<any>,
  key: 'me' | 'perms' | 'workspace'
): Promise<any> {
  const transaction = db.transaction(ME_STORE, 'readwrite')
  const store = transaction.objectStore(ME_STORE)
  store.delete(key)
}

// TODO: Type
export async function getFromAccountStore(
  db: IDBPDatabase<any>,
  key: 'me' | 'perms' | 'workspace'
): Promise<any> {
  const me = await db.get(ME_STORE, key)
  return me
}

export async function saveToAccountStore(
  db: IDBPDatabase<any>,
  key: 'me' | 'perms' | 'workspace',
  value: any
) {
  const transaction = db.transaction(ME_STORE, 'readwrite')
  const store = transaction.objectStore(ME_STORE)
  store.delete(key)
  store.add(value, key)
}

export async function getFromPipelineStore(
  db: IDBPDatabase<any>,
  args:
    | { pipelineId: number; type: 'pipeline' }
    | { workspaceId: number; type: 'workspace' }
) {
  if (args.type === 'pipeline') {
    return db.get(PIPELINES_STORE, IDBKeyRange.only(args.pipelineId))
  } else {
    const index = db
      .transaction(PIPELINES_STORE, 'readonly')
      .objectStore(PIPELINES_STORE)
      .index('workspaceId')

    const workspaces = await index.getAll(IDBKeyRange.only(args.workspaceId))

    return workspaces
  }
}

export async function clearPipelineStoreForWorkspace(
  db: IDBPDatabase<any>,
  args: { workspaceId: number }
) {
  const { workspaceId } = args

  const transaction = db.transaction(PIPELINES_STORE, 'readwrite')
  const store = transaction.objectStore(PIPELINES_STORE)
  const index = store.index('workspaceId')
  const keys = await index.getAllKeys(IDBKeyRange.only(workspaceId))
  for (const key of keys) {
    store.delete(key)
  }
}

export async function saveToPipelineStore<T extends { id: number; workspaceId: number }>(
  db: IDBPDatabase<any>,
  pipeline: T | T[]
) {
  const ps = !Array.isArray(pipeline) ? [pipeline] : pipeline

  const transaction = db.transaction(PIPELINES_STORE, 'readwrite')
  const store = transaction.objectStore(PIPELINES_STORE)
  for (const pipeline of ps) {
    const keyfor = IDBKeyRange.only(pipeline.id)
    if (typeof store.get(keyfor) !== 'undefined') {
      store.delete(keyfor)
    }
    store.add(pipeline)
  }
}

export async function getFromOppsStore(db: IDBPDatabase<any>, pipelineId: number) {
  const transaction = db.transaction(OPPS_STORE, 'readonly')
  const store = transaction.objectStore(OPPS_STORE)
  const index = store.index('pipelineId')
  const opps = await index.getAll(IDBKeyRange.only(pipelineId))

  return opps
}

export async function clearOppsStoreForPipeline(db: IDBPDatabase<any>, pipelineId: number) {
  const transaction = db.transaction(OPPS_STORE, 'readwrite')
  const store = transaction.objectStore(OPPS_STORE)
  const index = store.index('pipelineId')
  const keys = await index.getAllKeys(IDBKeyRange.only(pipelineId))
  for (const key of keys) {
    store.delete(key)
  }
}

export async function saveToOppsStore<
  T extends { pipelineId: number; id: number; oppId: string; canonId: string },
>(db: IDBPDatabase<any>, opps: T[]) {
  const transaction = db.transaction(OPPS_STORE, 'readwrite')
  const store = transaction.objectStore(OPPS_STORE)
  for (const opp of opps) {
    const keyfor = IDBKeyRange.only(opp.oppId)
    if (typeof store.get(keyfor) !== 'undefined') {
      store.delete(keyfor)
    }
    store.add(opp)
  }
}
