import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'

export interface PageMessageProps extends React.HTMLAttributes<HTMLElement> {
  disableVerticalCentering?: boolean
  header?: string
}

const PageMessage = (props: PageMessageProps) => {
  const {
    className: propsClassName,
    disableVerticalCentering,
    header,
    children,
    ...forwarded
  } = props

  const className = classNames(
    styles.welcome,
    styles.message,
    {
      [styles.messageCenterContent]: !disableVerticalCentering,
      [styles.messageNoHeader]: !header,
    },
    propsClassName
  )

  return (
    <div className={className} {...forwarded}>
      {header && <h2 className={styles.messageHeader}>{header}</h2>}
      <p className={styles.messageContent}>{children}</p>
    </div>
  )
}

export default PageMessage
