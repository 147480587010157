// import Image from 'next/image'

// Internal
import styles from './Image.module.scss'

// const CustomImage = ({
//   src,
//   alt,
//   round = false,
//   priority = false,
//   width = undefined,
//   height = undefined,
//   className = '',
//   layout = 'fill',
//   customStyles = {},
// }) => {
//   let classes = round ? `${styles.image} ${styles.round}` : styles.image
//   if (className) {
//     classes = `${classes} ${className}`
//   }

//   return (
//     <Image
//       src={src}
//       alt={alt}
//       priority={priority}
//       className={classes}
//       layout={layout}
//       width={width}
//       height={height}
//       style={customStyles}
//     />
//   )
// }

// export default CustomImage

const Image = ({
  src,
  alt,
  round = false,
  width = -1,
  height = -1,
  className = '',
  customStyles = {},
}) => {
  let classes = round ? `${styles.image} ${styles.round}` : styles.image
  if (className) {
    classes = `${classes} ${className}`
  }

  return (
    <img
      src={src}
      alt={alt}
      className={classes}
      width={width === -1 ? undefined : width}
      height={height === -1 ? undefined : height}
      style={customStyles}
    />
  )
}

export default Image
