import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { getGraphQLOrigin, getWSOrigin } from '@helpers/origin'
import { GetServerSidePropsContext } from 'next'
import router from 'next/router'
import fetch from 'node-fetch'
import { GovWinEvent } from './govwin/GovWinErrors'
import { onApolloError } from './on-apollo-error'
import { SnackbarEvent } from './snackbar/SnackbarEvent'
import { WorkspaceScopeEvent } from './workspace/WorkspaceScopeEvent'
import _ from 'lodash'

const WS_ORIGIN = getWSOrigin()

const httpLink = new HttpLink({
  uri: `${getGraphQLOrigin()}/graphql`,
  credentials: 'include',
  fetch: fetch as any,
  fetchOptions: {
    mode: 'cors',
  },
})

function createSsrHttpLink(
  headers: Record<string, string | string[]>,
  errorLink: ApolloLink
) {
  const httpLink = new HttpLink({
    uri: `${getGraphQLOrigin()}/graphql`,
    credentials: 'include',
    fetch: fetch as any,
    headers: headers,
    fetchOptions: {
      mode: 'cors',
      credentials: 'include',
    },
  })

  return errorLink.concat(httpLink)
}

export const ssrErrorLink = onError(({ graphQLErrors, networkError }) => {
  console.error(
    `SSR Error Link Received Errors: GQL Error Count - ${graphQLErrors?.length}; Network Error - ${networkError?.message}`
  )

  for (const error of graphQLErrors ?? []) {
    console.error('SSR Error Link Received GQL Error:', error)
  }
})

/**
 * You can send a special error code to the frontend like (in BaseNextStageError on the BE)
 *  UNSAFE_clientMessage: `MY_ERROR_CODE: ...`
 *
 * And the FE may know how to specially handle that error code.
 *
 * You can also send the FE a message to show in a snackbar.
 *  showSnackbar: true
 *
 * If you want to send an error with special handling AND a snackbar,
 * you can use ':!' to tell the FE to remove the error code before sending the text
 * to the snackbar
 *
 *  UNSAFE_clientMessage: `MY_ERROR_CODE:! my snackbar message`
 *
 * @param message
 * @returns
 */
function removeClientSideErrorMessageTag(message: string): string {
  const ind = message.indexOf(':!')

  return ind === -1 ? message : message.slice(ind + 2)
}

/**
 * We have to redirect on the client because the errors we receive would be AJAX errors which won't cause browser redirects.
 */
const clientSideErrorLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) return

  if (typeof window === 'undefined') {
    console.error('Client side error link used on server side')
    return
  }

  errorLoop: for (const error of graphQLErrors) {
    const operation = onApolloError(error.message, {
      pathname: router.pathname,
      query: router.query as Record<string, string | string[]>,
      asPath: router.asPath,
      extensions: error.extensions ?? {},
    })

    switch (operation.type) {
      case 'SignOut': {
        if (typeof window !== 'undefined') {
          window.localStorage.clear()
        }
        router.push('/signout', '/signout')
        break errorLoop
      }
      case 'Redirect': {
        router.push(operation.url)
        break errorLoop
      }
      case 'Client::GovWinExpired': {
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new GovWinEvent('Expired'))
        }
        break errorLoop
      }
      case 'Client::GovWinBad': {
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new GovWinEvent('Bad'))
        }
        break errorLoop
      }
      case 'Client::WorkspaceChange': {
        if (typeof window !== 'undefined') {
          window.dispatchEvent(
            new WorkspaceScopeEvent('Workspace change needed', operation.workspaceId)
          )
        }
        break errorLoop
      }
      case 'Client::Snackbar': {
        if (typeof window !== 'undefined') {
          const cleanedMessage = removeClientSideErrorMessageTag(operation.message)
          window.dispatchEvent(new SnackbarEvent(cleanedMessage))
        }
        break errorLoop
      }
    }
  }
})

const concatLink = clientSideErrorLink.concat(httpLink)

const wsLink = process.browser
  ? new WebSocketLink({
      uri: `${WS_ORIGIN}/graphql`,

      options: {
        reconnect: true,

        lazy: true,
      },
    })
  : null

const link = process.browser
  ? split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink!,
      concatLink
    )
  : concatLink

export function getRequestHeadersFromNextSSRContext(
  ctx: Partial<GetServerSidePropsContext>
): Record<string, string | undefined> {
  const IP_HTTP_HEADER = process.env.LOGGING_IP_HTTP_HEADER ?? 'x-forwarded-for'
  const TRACE_HTTP_HEADER = process.env.LOGGING_TRACE_HTTP_HEADER ?? 'x-cloud-trace-context'

  return {
    cookie: ctx?.req?.headers['cookie'] as string | undefined,
    'x-forwarded-for': ctx?.req?.headers[IP_HTTP_HEADER] as string | undefined,
    'x-trace-id': ctx?.req?.headers[TRACE_HTTP_HEADER] as string | undefined,
  }
}

export function createApolloClient(
  contextHeaders: {
    cookie?: string
    'x-forwarded-for'?: string
    'x-trace-id'?: string
  },
  initialState: any = {},
  // Default the error link to the correct error link depending on execution context (browser vs. node)
  errorLink: ApolloLink = typeof window === 'undefined' ? ssrErrorLink : clientSideErrorLink
): ApolloClient<any> {
  const headers: Record<string, string> = {}
  if (contextHeaders['cookie']) {
    headers['cookie'] = contextHeaders['cookie']
  }
  if (contextHeaders['x-forwarded-for']) {
    headers['x-forwarded-for'] = contextHeaders['x-forwarded-for'] as string
  }
  if (contextHeaders['x-trace-id']) {
    headers['x-trace-id'] = contextHeaders['x-trace-id'] as string
  }

  const aLink = typeof window === 'undefined' ? createSsrHttpLink(headers, errorLink) : link

  return new ApolloClient({
    link: aLink,
    credentials: 'include',
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache({
      typePolicies: {
        SavedSearchSubscriber: {
          keyFields: ['savedSearchId', 'subscriberId'],
        },
        Query: {
          fields: {
            getPlanningOpportunities: {
              merge(existing = [], incoming) {
                return _.uniqBy([...existing, ...incoming], '__ref')
              },
            },
            getPlanningOrganizations: {
              merge(existing = [], incoming) {
                return _.uniqBy([...existing, ...incoming], '__ref')
              },
            },
            getPlanningContacts: {
              merge(existing = [], incoming) {
                return _.uniqBy([...existing, ...incoming], '__ref')
              },
            },
          },
        },
      },
    }).restore(initialState),
    headers: headers,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  })
}
