import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageCIOSPSliceActions } from '../redux/SearchPageCIOSPSlice'
import { useCIOSPSortBy, useCIOSPSortByDirection } from '../redux/selectors'

export const CIOSP_VIEW_SORT_BY_POSTED_ON = 'posted-on'
export const CIOSP_VIEW_SORT_BY_SELECTION_DEADLINE = 'selection-deadline'
export const CIOSP_VIEW_SORT_BY_PROPOSAL_DEADLINE = 'proposal-deadline'
const SORT_BY_OPTIONS = [
  {
    id: 'posted-on',
    label: 'Posted On',
    value: CIOSP_VIEW_SORT_BY_POSTED_ON,
  },
  {
    id: 'selection-deadline',
    label: 'Selection Deadline',
    value: CIOSP_VIEW_SORT_BY_SELECTION_DEADLINE,
  },
  {
    id: 'proposal-deadline',
    label: 'Proposal Deadline',
    value: CIOSP_VIEW_SORT_BY_PROPOSAL_DEADLINE,
  },
]

interface CIOSPViewSortByDropdownProps {
  disabled?: boolean
}

export const CIOSPViewSortByDropdown = (props: CIOSPViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useCIOSPSortBy()
  const direction = useCIOSPSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageCIOSPSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageCIOSPSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
