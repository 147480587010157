import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageContractVehicleSliceActions } from '../redux/SearchPageContractVehicleSlice'
import {
  useContractVehicleSortBy,
  useContractVehicleSortByDirection,
} from '../redux/selectors'

export const CONTRACT_VEHICLE_VIEW_SORT_BY_NAME = 'name'
export const CONTRACT_VEHICLE_VIEW_SORT_BY_NUM_PRIME_AWARDS = 'num-prime-awards'
export const CONTRACT_VEHICLE_VIEW_SORT_BY_SORT_BY_NUM_TASK_ORDERS = 'num-task-orders'
export const CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE = 'current-value'
export const CONTRACT_VEHICLE_VIEW_SORT_BY_POTENTIAL_VALUE = 'potential-value'

export const CONTRACT_VEHICLE_SORT_BY_OPTIONS = [
  {
    id: 'name',
    label: 'Name',
    value: CONTRACT_VEHICLE_VIEW_SORT_BY_NAME,
  },
  {
    id: 'num-prime-awards',
    label: '# Prime Awards',
    value: CONTRACT_VEHICLE_VIEW_SORT_BY_NUM_PRIME_AWARDS,
  },
  {
    id: 'num-task-orders',
    label: '# Task Orders',
    value: CONTRACT_VEHICLE_VIEW_SORT_BY_SORT_BY_NUM_TASK_ORDERS,
  },
  {
    id: 'current-value',
    label: 'Current Value',
    value: CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE,
  },
  {
    id: 'potential-value',
    label: 'Potential Value',
    value: CONTRACT_VEHICLE_VIEW_SORT_BY_POTENTIAL_VALUE,
  },
]

interface ContractVehicleViewSortByDropdownProps {
  disabled?: boolean
}

export const ContractVehicleViewSortByDropdown = (
  props: ContractVehicleViewSortByDropdownProps
) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useContractVehicleSortBy()
  const direction = useContractVehicleSortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageContractVehicleSliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageContractVehicleSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={CONTRACT_VEHICLE_SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
