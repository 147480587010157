import { Fragment, useCallback, useMemo } from 'react'
import { CommonTextDropdown } from '../CommonTextDropdown/CommonTextDropdown'
import { AscDescToggle } from '../AscDescToggle/AscDescToggle'

import styles from './SortByDropdown.module.scss'
import { scls } from '@helpers/scls'

interface SortByDropdownProps {
  disabled?: boolean
  options: Array<{ id: string; value: string; label: string }>
  value: { id: string; value: string; label: string } | null
  direction: 'asc' | 'desc'
  onChange: (
    nextValue: { id: string; label: string; value: any } | null,
    direction: 'asc' | 'desc'
  ) => void
}

export const SortByDropdown = (props: SortByDropdownProps) => {
  const { direction, disabled, value, options, onChange } = props

  const onFieldChange = useCallback(
    (nextValue: { id: string; label: string; value: any } | null) => {
      onChange(nextValue, 'asc')
    },
    [onChange, direction]
  )

  const onDirectionChange = useCallback(
    (newDirection: 'asc' | 'desc') => {
      onChange(value, newDirection)
    },
    [onChange, value]
  )

  const ddValue = useMemo(() => {
    if (!value) return null

    const foundValue = options.find((o) => o.value === value.value)
    if (foundValue) {
      return foundValue
    } else if (value.label.length !== 0) {
      return value
    } else {
      return {
        id: value.id,
        value: value.value,
        label: 'Sort',
      }
    }
  }, [value, options])

  return (
    <div className={scls(styles, 'container')}>
      <CommonTextDropdown
        onChange={onFieldChange}
        options={options}
        placeholder="Default"
        value={ddValue}
        label="Sort By"
        disabled={disabled}
      />
      <AscDescToggle
        className={scls(
          styles,
          'direction-toggle',
          value !== null && 'direction-toggle-visible'
        )}
        value={direction}
        onChange={onDirectionChange}
      />
    </div>
  )
}
