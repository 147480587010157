import { SAMViewState } from '../sam-view-state'

export type SearchPageSAMSliceType =
  | SearchPageSAMSliceTypeInitial
  | SearchPageSAMSliceTypeReady

export type SearchPageSAMSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageSAMSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: SAMViewState
}

export type SearchPageSAMSliceViewType = Exclude<
  SearchPageSAMSliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageSAMSliceViewType> = Extract<
  SearchPageSAMSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageSAMSliceReady(
  s: SearchPageSAMSliceType
): s is SearchPageSAMSliceTypeReady {
  return s.state === 'ready'
}
