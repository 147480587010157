import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import {
  SearchPageFedConnectSliceTypeReady,
  isSearchPageFedConnectSliceReady,
} from './SearchPageFedConnectSlice.types'

export function useFedConnectSelector<
  T extends (state: SearchPageFedConnectSliceTypeReady) => any,
>(selector: T): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageFedConnectSliceReady(state.searchFedConnectView))
      return selector(state.searchFedConnectView)
    else return null
  })
}

export function useFedConnectResponseDueDateFilter(): DateFilterValue | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.responseDueDate
  })
}

export function useFedConnectIssueDateFilter(): DateFilterValue | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.issueDate
  })
}

export function useFedConnectSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useFedConnectSortByDirection(): 'asc' | 'desc' | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useFedConnectReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useFedConnectQuery(): string {
  const value = useFedConnectSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useFedConnectActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useFedConnectSelector((state) => {
    return state.view.savedSearch
  })
}

export function useFedConnectSelectedListing(): any {
  return useFedConnectSelector((state) => {
    return state.view.selectedListing
  })
}

export function useFedConnectPageFrom(): number | null {
  return useFedConnectSelector((state) => {
    return state.view.pageFrom
  })
}

export function useFedConnectHitCount(): number | null {
  return useFedConnectSelector((state) => {
    return state.view.hitCount
  })
}

export function useFedConnectNAICSFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.naics
  })
}

export function useFedConnectSetAsideFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.setAside
  })
}

export function useFedConnectPlaceOfPerformanceFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.placeOfPerformance
  })
}

export function useFedConnectAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.agency
  })
}

export function useFedConnectTypeFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.type
  })
}

export function useFedConnectPSCFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.psc
  })
}

export function useFedConnectAdvanced(): boolean | null {
  return useFedConnectSelector((state) => {
    return state.view.persisted.advanced
  })
}

export const FEDCONNECT_PAGE_SIZE = 40
