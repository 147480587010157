import { Action, PayloadAction } from '@reduxjs/toolkit'
import { ResizeAxis, ResizeHelperInit, ResizeHelperTypeMap } from './types'

type Size = { height: number; width: number }
type Coordinates = { x: number; y: number }

export type ResizeHelperState<T extends ResizeHelperTypeMap> = {
  axis: 'x' | 'y' | 'both'
  container: {
    element: T['Container'] | null
    size: Size
    style: React.CSSProperties
    min: Size
    max: Partial<Size>
  }
  resize: null | {
    size: Size
    origin: Coordinates
  }
}

export type ResizeHelperAction<T extends ResizeHelperTypeMap> =
  | PayloadAction<{ x: number; y: number }, 'resize-helper/resize/start'>
  | PayloadAction<{ x: number; y: number }, 'resize-helper/resize/move'>
  | PayloadAction<ResizeAxis, 'resize-helper/axis/set'>
  | PayloadAction<T['Container'] | null, 'resize-helper/container/set-element'>
  | Action<'resize-helper/container/update-size'>
  | Action<'resize-helper/resize/end'>

function isEqualSize(prev: Size, next: Size) {
  return prev.height === next.height && prev.width === next.width
}

function average(...values: number[]) {
  return values.reduce((sum, value) => sum + value, 0) / values.length
}

export function initializer<T extends ResizeHelperTypeMap>(
  init: ResizeHelperInit
): ResizeHelperState<T> {
  const { axis = 'both', minHeight = 0, maxHeight, minWidth = 0, maxWidth } = init
  const height = maxHeight ? average(minHeight, maxHeight) : undefined
  const width = maxWidth ? average(minWidth, maxWidth) : undefined

  return {
    axis,
    container: {
      element: null,
      size: {
        height: height ?? 0,
        width: width ?? 0,
      },
      style: {
        width,
        minWidth: width,
        maxWidth: width,
        height,
        minHeight: height,
        maxHeight: height,
      },
      min: { height: minHeight, width: minWidth },
      max: { height: maxHeight, width: maxWidth },
    },
    resize: null,
  }
}

function getNextSize(params: {
  start: Size
  origin: Coordinates
  pointer: Coordinates
}): Size {
  const { start, origin, pointer } = params
  const deltaX = pointer.x - origin.x
  const deltaY = pointer.y - origin.y

  const width = start.width + deltaX
  const height = start.height + deltaY

  return { height, width }
}

function clamp(params: { value: number; min: number; max?: number }) {
  const min = Math.max(params.value, params.min)
  return Math.min(min, params.max ?? min)
}

function setContainerSize<T extends ResizeHelperTypeMap>(
  state: ResizeHelperState<T>,
  size: Size
): void {
  if (!state.resize) return
  if (!state.container.element) return

  if (state.axis !== 'y' && size.height !== state.resize.size.height) {
    const width = clamp({
      value: size.width,
      min: state.container.min.width,
      max: state.container.max.width,
    })

    state.container.element.style.width = `${width}px`
    state.container.element.style.minWidth = `${width}px`
    state.container.element.style.maxWidth = `${width}px`
  }

  if (state.axis !== 'x' && size.height !== state.resize.size.height) {
    const height = clamp({
      value: size.height,
      min: state.container.min.height,
      max: state.container.max.height,
    })

    state.container.element.style.height = `${height}px`
    state.container.element.style.minHeight = `${height}px`
    state.container.element.style.maxHeight = `${height}px`
  }
}

function getElementSize<T extends ResizeHelperTypeMap>(
  element: T['Container'] | null
): Size {
  if (!element) return { height: 0, width: 0 }
  const { height, width } = element.getBoundingClientRect()
  return { height, width }
}

function reducer<T extends ResizeHelperTypeMap>(
  state: ResizeHelperState<T>,
  action: ResizeHelperAction<T>
): ResizeHelperState<T> {
  switch (action.type) {
    case 'resize-helper/container/update-size': {
      if (state.resize) return state

      const size = getElementSize<T>(state.container.element)

      if (isEqualSize(state.container.size, size)) return state
      return { ...state, container: { ...state.container, size } }
    }
    case 'resize-helper/container/set-element': {
      if (state.resize) return state
      const { payload: element } = action

      return reducer(
        state.container.element === element
          ? state
          : { ...state, container: { ...state.container, element } },
        { type: 'resize-helper/container/update-size' }
      )
    }
    case 'resize-helper/resize/start': {
      if (state.resize || !state.container) return state

      const { payload: origin } = action
      const stateWithUpdatedSize = reducer(state, {
        type: 'resize-helper/container/update-size',
      })

      return {
        ...stateWithUpdatedSize,
        resize: { origin, size: stateWithUpdatedSize.container.size },
      }
    }
    case 'resize-helper/resize/move': {
      if (!state.resize) return state
      const { payload: pointer } = action

      const size = getNextSize({
        start: state.container.size,
        origin: state.resize.origin,
        pointer,
      })

      setContainerSize(state, size)

      return {
        ...state,
        resize: { ...state.resize, size },
        container: { ...state.container },
      }
    }
    case 'resize-helper/resize/end': {
      if (!state.resize) return state
      return reducer(
        {
          ...state,
          resize: null,
        },
        { type: 'resize-helper/container/update-size' }
      )
    }
    case 'resize-helper/axis/set': {
      if (state.resize) return state
      if (state.axis === action.payload) return state
      return { ...state, axis: action.payload }
    }
    default:
      return state
  }
}

export default reducer
