import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import { Title, TitleProps } from '@mantine/core'

export interface PageHeaderTitleProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * @default 'lg'
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'

  /**
   * @default 1
   */
  order?: TitleProps['order']

  /**
   * @default 1
   */
  lineClamp?: TitleProps['lineClamp']
}

const toMantineSize = (size: PageHeaderTitleProps['size']): TitleProps['size'] => {
  if (size === 'xs') return 'h5'
  if (size === 'sm') return 'h4'
  if (size === 'md') return 'h3'
  if (size === 'lg') return 'h2'
  return 'h1'
}

const PageHeaderTitle: React.FC<PageHeaderTitleProps> = (props: PageHeaderTitleProps) => {
  const {
    className: propsClassName,
    children,
    order = 1,
    lineClamp = 1,
    size = 'lg',
    ...forwarded
  } = props

  const className = classNames(styles.headerTitle, propsClassName)

  return (
    <Title
      className={className}
      {...forwarded}
      order={order}
      size={toMantineSize(size)}
      lineClamp={1}
    >
      {children}
    </Title>
  )
}

export default PageHeaderTitle
