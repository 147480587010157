import React, { useCallback, useMemo, useEffect, useReducer, useLayoutEffect } from 'react'
import { useElementSize } from '@mantine/hooks'
import reducer, { initializer } from './reducer'
import { ResizeHelperInit, ResizeHelperTypeMap } from './types'

export interface ResizeHelper<T extends ResizeHelperTypeMap> {
  container: {
    ref: React.RefObject<T['Container']>
    style: React.CSSProperties
  }

  handle: {
    props: Pick<React.HTMLAttributes<T['Handle']>, 'onPointerDown'>
  }

  resizing: boolean
}

/**
 *
 * @param params
 * @returns
 */
function useResizeHelper<T extends ResizeHelperTypeMap>(
  init: ResizeHelperInit = {}
): ResizeHelper<T> {
  const { ref, width, height } = useElementSize<T['Container']>()
  const element = ref.current ?? null

  const [state, dispatch] = useReducer(reducer, init, initializer)

  const resizing = !!state.resize
  const { style } = state.container
  const { axis } = state

  useEffect(() => {
    dispatch({ type: 'resize-helper/container/update-size' })
  }, [dispatch, width, height])

  useEffect(() => {
    dispatch({ type: 'resize-helper/container/set-element', payload: element })
  }, [dispatch, element])

  const resizeStart = useCallback(
    (event: React.PointerEvent<T['Handle']>) => {
      const x = event.clientX
      const y = event.clientY

      dispatch({ type: 'resize-helper/resize/start', payload: { x, y } })
    },
    [dispatch]
  )

  const resizeMove = React.useCallback(
    (event: PointerEvent) => {
      const x = event.clientX
      const y = event.clientY

      dispatch({ type: 'resize-helper/resize/move', payload: { x, y } })
    },
    [dispatch]
  )

  const resizeEnd = React.useCallback(() => {
    dispatch({ type: 'resize-helper/resize/end' })
  }, [dispatch])

  React.useLayoutEffect(() => {
    if (!resizing) {
      window.removeEventListener('pointermove', resizeMove)
      window.removeEventListener('pointerup', resizeEnd)
      return
    }

    window.addEventListener('pointermove', resizeMove)
    window.addEventListener('pointerup', resizeEnd)

    const originalCursor = document.body.style.cursor ?? 'auto'
    document.body.style.cursor =
      axis === 'x' ? 'ew-resize' : axis === 'y' ? 'ns-resize' : 'nwse-resize'

    return () => {
      window.removeEventListener('pointermove', resizeMove)
      window.removeEventListener('pointerup', resizeEnd)
      document.body.style.cursor = originalCursor
    }
  }, [resizing, axis, resizeMove, resizeEnd])

  const handle: ResizeHelper<T>['handle'] = useMemo(
    () => ({ props: { onPointerDown: resizeStart } }),
    [resizeStart]
  )

  return { resizing, handle, container: { ref, style } }
}

export default useResizeHelper
