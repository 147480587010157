import { GovWinViewState } from '../govwin-view-state'

export type SearchPageGovWinSliceType =
  | SearchPageGovWinSliceTypeInitial
  | SearchPageGovWinSliceTypeReady

export type SearchPageGovWinSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageGovWinSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: GovWinViewState
}

export type SearchPageGovWinSliceViewType = Exclude<
  SearchPageGovWinSliceTypeReady['view'],
  null
>['type']

export type SearchPageViewWithType<T extends SearchPageGovWinSliceViewType> = Extract<
  SearchPageGovWinSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageGovWinSliceReady(
  s: SearchPageGovWinSliceType
): s is SearchPageGovWinSliceTypeReady {
  return s.state === 'ready'
}
