import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Json: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  allowGoogleSSO: Scalars['Boolean'];
  allowMicrosoftSSO: Scalars['Boolean'];
  allowPasswordAuth: Scalars['Boolean'];
  authUrls: Array<AuthUrl>;
  hasPendingInvite?: Maybe<Scalars['Boolean']>;
  inviteOnly?: Maybe<Scalars['Boolean']>;
  isExistingAccount: Scalars['Boolean'];
  isVerifiedEmail?: Maybe<Scalars['Boolean']>;
  workspaceExists: Scalars['Boolean'];
};

export enum AuthMode {
  External = 'EXTERNAL',
  Login = 'LOGIN',
  Signup = 'SIGNUP'
}

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user: User;
};

export type AuthUrl = {
  __typename?: 'AuthUrl';
  type: AuthUrlType;
  url: Scalars['String'];
};

export enum AuthUrlType {
  Adfssaml = 'ADFSSAML',
  Adpoidc = 'ADPOIDC',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  Cassaml = 'CASSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  VMwareSaml = 'VMwareSAML'
}

export type Bookmark = {
  __typename?: 'Bookmark';
  bookmarkId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  index: Scalars['String'];
  notes: Array<Note>;
  reason: BookmarkReason;
  source: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum BookmarkReason {
  NotAFit = 'NOT_A_FIT',
  Saved = 'SAVED',
  Unbookmarked = 'UNBOOKMARKED'
}

export type CaptureTeam = {
  __typename?: 'CaptureTeam';
  captureTeamId: Scalars['String'];
  id: Scalars['Int'];
  isProposalContributor?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  tasks: Array<Task>;
  users: Array<User>;
  workspaceId: Scalars['Int'];
};

export type Chart = {
  __typename?: 'Chart';
  chartId?: Maybe<Scalars['String']>;
  chartType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  dashboard?: Maybe<Dashboard>;
  dashboardId: Scalars['Int'];
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  layout?: Maybe<Scalars['Json']>;
  query: Scalars['Json'];
  showValueLabels?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContactOnPrivOpps = {
  __typename?: 'ContactOnPrivOpps';
  contact?: Maybe<PrivContact>;
  contactId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
};

export type ContactTag = {
  __typename?: 'ContactTag';
  color?: Maybe<Scalars['String']>;
  contactTagUUId: Scalars['String'];
  contacts?: Maybe<Array<Maybe<PrivContact>>>;
  id: Scalars['String'];
  name: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export type ContractVehicle = {
  __typename?: 'ContractVehicle';
  _nsSolicitationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  basePeriod?: Maybe<Scalars['Int']>;
  contractSharedCeiling?: Maybe<Scalars['Int']>;
  contractVehicleId: Scalars['String'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informationWebsite: Scalars['String'];
  name: Scalars['String'];
  optionPeriod?: Maybe<Scalars['Int']>;
  solicitationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['String']>;
  summaryCountPrimeAwards: Scalars['Int'];
  summaryCountTaskOrders: Scalars['Int'];
  summaryCurrentValue: Scalars['Float'];
  summaryFederalActionObligation: Scalars['Float'];
  summaryPotentialValue: Scalars['Float'];
  type?: Maybe<ContractVehicleType>;
};

export enum ContractVehicleType {
  Bpa = 'BPA',
  Fss = 'FSS',
  Gwac = 'GWAC',
  Idc = 'IDC'
}

export type CurrentWorkspace = {
  __typename?: 'CurrentWorkspace';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userWorkspaceId?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  accessUsers?: Maybe<Array<Maybe<DashboardUserRoles>>>;
  charts?: Maybe<Array<Maybe<Chart>>>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  dashboardId?: Maybe<Scalars['String']>;
  favoriteDashboards?: Maybe<Array<Maybe<FavoriteDashboards>>>;
  id?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DashboardRole = {
  __typename?: 'DashboardRole';
  dashboardUserRoles?: Maybe<Array<Maybe<DashboardUserRoles>>>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DashboardUserRoles = {
  __typename?: 'DashboardUserRoles';
  dashboard?: Maybe<Dashboard>;
  dashboardId?: Maybe<Scalars['Int']>;
  dashboardRoleId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<DashboardRole>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type DefaultPipelineNexusType = {
  __typename?: 'DefaultPipelineNexusType';
  pipelineId: Scalars['Int'];
  pipelineUUId: Scalars['String'];
  pipelineViewUUId?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  documentId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  spId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ElasticSearchResult = {
  __typename?: 'ElasticSearchResult';
  aggregations?: Maybe<Scalars['Json']>;
  bookmarks: Array<Maybe<Bookmark>>;
  hits: Array<Scalars['Json']>;
  numHits: Scalars['Int'];
  source?: Maybe<ExternalSourceNexusType>;
};

export type Event = {
  __typename?: 'Event';
  captureTeam?: Maybe<CaptureTeam>;
  captureTeamId?: Maybe<Scalars['Int']>;
  contact?: Maybe<PrivContact>;
  contactId?: Maybe<Scalars['Int']>;
  dashboard?: Maybe<Dashboard>;
  dashboardId?: Maybe<Scalars['Int']>;
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']>;
  eventCode?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventProps?: Maybe<Scalars['Json']>;
  eventTs?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  pipeline?: Maybe<Pipeline>;
  pipelineId?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrg?: Maybe<PrivOrg>;
  privOrgId?: Maybe<Scalars['Int']>;
  savedSearch?: Maybe<SavedSearch>;
  savedSearchId?: Maybe<Scalars['Int']>;
  stage?: Maybe<Stage>;
  stageId?: Maybe<Scalars['Int']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userFirstName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  userLastName?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type EventOrderByInput = {
  eventTs?: Maybe<SortDirection>;
};

export enum ExternalSourceNexusType {
  Alexandria = 'alexandria',
  Apfs = 'apfs',
  Award = 'award',
  Ciosp = 'ciosp',
  Ebuy = 'ebuy',
  Fedconnect = 'fedconnect',
  Govwin = 'govwin',
  Sol = 'sol',
  Symphony = 'symphony'
}

export type FavoriteDashboards = {
  __typename?: 'FavoriteDashboards';
  dashboard?: Maybe<Dashboard>;
  dashboardId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type FieldValuesNexusType = {
  __typename?: 'FieldValuesNexusType';
  field: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum FilterMode {
  MatchAll = 'MATCH_ALL',
  MatchAny = 'MATCH_ANY'
}

export type GetContactsFilterOptionsResolverResult = {
  __typename?: 'GetContactsFilterOptionsResolverResult';
  cursor?: Maybe<Scalars['String']>;
  orgIndustry: Array<Scalars['String']>;
  orgNames: Array<Scalars['String']>;
  orgTags: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

export type GetContactsPaginationResolverResult = {
  __typename?: 'GetContactsPaginationResolverResult';
  order: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type GetContactsResolverResult = {
  __typename?: 'GetContactsResolverResult';
  contacts: Array<PrivContact>;
  total: Scalars['Int'];
};

export type GetOpportunitiesResolverResult = {
  __typename?: 'GetOpportunitiesResolverResult';
  opps: Array<PrivOpp>;
  order: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type GetPipelineOpportunitiesResolverResult = {
  __typename?: 'GetPipelineOpportunitiesResolverResult';
  results: Array<PrivOpp>;
  total: Scalars['Int'];
};

export type GetPrivOrgsFilterOptionsResolverResult = {
  __typename?: 'GetPrivOrgsFilterOptionsResolverResult';
  accountOwnersFirstLastSeparatedByColonColon: Array<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  industry: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  types: Array<Scalars['String']>;
};

export type GetPrivOrgsPaginationResolverResult = {
  __typename?: 'GetPrivOrgsPaginationResolverResult';
  order: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type GetPrivOrgsResolverResult = {
  __typename?: 'GetPrivOrgsResolverResult';
  orgs: Array<PrivOrg>;
  total: Scalars['Int'];
};

export type GqlChatDocumentRequest = {
  documentId?: Maybe<Scalars['String']>;
  documentName: Scalars['String'];
  documentPath: Scalars['String'];
  externalSource?: Maybe<ExternalSourceNexusType>;
  kind: GqlDocumentChatDocumentProcessingKind;
  listingId?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['String']>;
  sharePointAccessToken?: Maybe<Scalars['String']>;
};

export type GqlComplianceMatrixGenerationJob = {
  __typename?: 'GqlComplianceMatrixGenerationJob';
  createdAt: Scalars['DateTime'];
  messages: Array<Scalars['String']>;
  status: GqlComplianceMatrixGenerationJobStatus;
  subscriptionError?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export enum GqlComplianceMatrixGenerationJobStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type GqlDocumentChat = {
  __typename?: 'GqlDocumentChat';
  chat: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdByUserId: Scalars['Int'];
  documentChatUUId: Scalars['String'];
  lockUntil?: Maybe<Scalars['DateTime']>;
  subscriptionError?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type GqlDocumentChatAuthorizationContext = {
  sharePointAccessToken?: Maybe<Scalars['String']>;
};

export enum GqlDocumentChatDocumentProcessingKind {
  AlexandriaDefault = 'AlexandriaDefault',
  CiospDefault = 'CIOSPDefault',
  DocTableDownloadGcsDefault = 'DocTableDownloadGCSDefault',
  DocTableUploadGcsDefault = 'DocTableUploadGCSDefault',
  EBuyDefault = 'EBuyDefault',
  FedConnectDefault = 'FedConnectDefault',
  GovWinDefault = 'GovWinDefault',
  None = 'None',
  PublicUrlDefault = 'PublicURLDefault',
  SamControlled = 'SAMControlled',
  SamDefault = 'SAMDefault',
  SamExternal = 'SAMExternal',
  SharepointDefault = 'SharepointDefault',
  SharepointDriveItem = 'SharepointDriveItem',
  SymphonyDefault = 'SymphonyDefault'
}

export type GqlDocumentChatSystemPrompt = {
  kind: GqlDocumentChatSystemPromptKind;
  promptTemplateUUId?: Maybe<Scalars['String']>;
};

export enum GqlDocumentChatSystemPromptKind {
  MarketResearch = 'MarketResearch',
  PromptWriter = 'PromptWriter',
  ProposalWriter = 'ProposalWriter'
}

export enum GqlInlinePromptUsageDocumentKind {
  Proposal = 'PROPOSAL',
  Update = 'UPDATE'
}

export type GqlListingRequest = {
  listingAssociationKindUrl?: Maybe<Scalars['String']>;
  listingId: Scalars['String'];
  source: ExternalSourceNexusType;
};

export type GqlOpportunityDocumentOption = {
  __typename?: 'GqlOpportunityDocumentOption';
  associationKind: GqlOpportunityDocumentOptionAssociationKind;
  documentId?: Maybe<Scalars['String']>;
  documentIdentifierHelper: Scalars['String'];
  documentUrl: Scalars['String'];
  listingUrl?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['String']>;
};

export enum GqlOpportunityDocumentOptionAssociationKind {
  Document = 'Document',
  Listing = 'Listing',
  None = 'None',
  SharePointDocument = 'SharePointDocument'
}

export type GqlOpportunityDocumentOptionRequest = {
  associationKind: GqlOpportunityDocumentOptionAssociationKind;
  documentId?: Maybe<Scalars['String']>;
  documentUrl: Scalars['String'];
  listingUrl?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['String']>;
};

export type GqlOpportunityListingOption = {
  __typename?: 'GqlOpportunityListingOption';
  associatedDocumentUrls: Array<Scalars['String']>;
  associationKind: GqlOpportunityListingOptionAssociationKind;
  legacyAssociationKind?: Maybe<Scalars['String']>;
  listingIdentifierHelper: Scalars['String'];
  listingTitle: Scalars['String'];
  listingUrl: Scalars['String'];
  privOppSourceName?: Maybe<Scalars['String']>;
  privOppSourceUUId?: Maybe<Scalars['String']>;
};

export enum GqlOpportunityListingOptionAssociationKind {
  Custom = 'Custom',
  LegacyAssociation = 'LegacyAssociation',
  PrivOppSourceAssociation = 'PrivOppSourceAssociation'
}

export type GqlOpportunityListingOptionRequest = {
  associatedDocumentUrls: Array<Scalars['String']>;
  associationKind: GqlOpportunityListingOptionAssociationKind;
  legacyAssociationKind?: Maybe<Scalars['String']>;
  listingTitle: Scalars['String'];
  listingUrl: Scalars['String'];
  privOppSourceName?: Maybe<Scalars['String']>;
  privOppSourceUUId?: Maybe<Scalars['String']>;
};

export type GqlOpportunityOption = {
  __typename?: 'GqlOpportunityOption';
  document?: Maybe<GqlOpportunityDocumentOption>;
  kind: GqlOpportunityOptionKind;
  listing?: Maybe<GqlOpportunityListingOption>;
  sharePointSite?: Maybe<GqlOpportunitySharePointSite>;
  sharePointSiteError?: Maybe<GqlOpportunitySharePointSiteError>;
  sharePointSiteNotSignedIn?: Maybe<GqlOpportunitySharePointSiteNotSignedIn>;
  unreachableDocument?: Maybe<GqlOpportunityUnreachableDocumentOption>;
};

export enum GqlOpportunityOptionKind {
  Document = 'Document',
  Listing = 'Listing',
  SharePointSite = 'SharePointSite',
  SharePointSiteError = 'SharePointSite_Error',
  SharePointSiteSignInRequired = 'SharePointSite_SignInRequired',
  UnreachableDocument = 'UnreachableDocument'
}

export type GqlOpportunityOptionRequest = {
  document?: Maybe<GqlOpportunityDocumentOptionRequest>;
  kind: GqlOpportunityOptionRequestKind;
  listing?: Maybe<GqlOpportunityListingOptionRequest>;
};

export enum GqlOpportunityOptionRequestKind {
  Document = 'Document',
  Listing = 'Listing'
}

export type GqlOpportunitySharePointSite = {
  __typename?: 'GqlOpportunitySharePointSite';
  associatedDocumentUrls: Array<Scalars['String']>;
  documentUrlsToFolderPaths: Scalars['Json'];
  name: Scalars['String'];
  siteId: Scalars['String'];
};

export type GqlOpportunitySharePointSiteError = {
  __typename?: 'GqlOpportunitySharePointSiteError';
  message: Scalars['String'];
};

export type GqlOpportunitySharePointSiteNotSignedIn = {
  __typename?: 'GqlOpportunitySharePointSiteNotSignedIn';
  name: Scalars['String'];
  siteId: Scalars['String'];
};

export type GqlOpportunityUnreachableDocumentOption = {
  __typename?: 'GqlOpportunityUnreachableDocumentOption';
  associationKind: GqlOpportunityDocumentOptionAssociationKind;
  documentId?: Maybe<Scalars['String']>;
  documentIdentifierHelper: Scalars['String'];
  documentUrl: Scalars['String'];
  listingUrl?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type GqlPromptTemplate = {
  __typename?: 'GqlPromptTemplate';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<GqlPromptTemplateGroup>;
  groupUUId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  schema?: Maybe<GqlPromptTemplateSchema>;
  updatedAt: Scalars['DateTime'];
  usages?: Maybe<Array<Maybe<GqlPromptUsage>>>;
  uuid: Scalars['String'];
};

export type GqlPromptTemplateGroup = {
  __typename?: 'GqlPromptTemplateGroup';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  templates?: Maybe<Array<Maybe<GqlPromptTemplate>>>;
  type: GqlPromptType;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};

export type GqlPromptTemplateGroupSearchResult = {
  __typename?: 'GqlPromptTemplateGroupSearchResult';
  results: Array<GqlPromptTemplateGroup>;
  total: Scalars['Int'];
};

export enum GqlPromptTemplateGroupSearchSortByType {
  CreatedAt = 'createdAt',
  Name = 'name',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

export type GqlPromptTemplateSchema = {
  __typename?: 'GqlPromptTemplateSchema';
  additionalInstructions?: Maybe<GqlPromptTemplateSchemaAdditionalInstructions>;
  chatSectionOrder: Array<Maybe<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  fields: Array<Maybe<GqlPromptTemplateSchemaField>>;
  guide?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  llmSystemInstructions: Scalars['String'];
  llmUserInstructions: Scalars['String'];
};

export type GqlPromptTemplateSchemaAdditionalInstructions = {
  __typename?: 'GqlPromptTemplateSchemaAdditionalInstructions';
  guide?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type GqlPromptTemplateSchemaField = {
  __typename?: 'GqlPromptTemplateSchemaField';
  chatSection: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  guide?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type: GqlPromptTemplateSchemaFieldType;
};

export enum GqlPromptTemplateSchemaFieldType {
  Boolean = 'BOOLEAN',
  MultiselectDocumentAndListing = 'MULTISELECT__DOCUMENT_AND_LISTING',
  Text = 'TEXT'
}

export type GqlPromptTemplateSearchResult = {
  __typename?: 'GqlPromptTemplateSearchResult';
  results: Array<GqlPromptTemplate>;
  total: Scalars['Int'];
};

export enum GqlPromptTemplateSearchSortByType {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export enum GqlPromptType {
  Proposal = 'Proposal'
}

export type GqlPromptUsage = {
  __typename?: 'GqlPromptUsage';
  additionalInstructions?: Maybe<Scalars['String']>;
  chats: Array<Maybe<GqlDocumentChat>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserUUId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  inputs?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  opportunity?: Maybe<PrivOpp>;
  opportunityUUId: Scalars['String'];
  pipeline?: Maybe<Pipeline>;
  pipelineUUId: Scalars['String'];
  proposalDocument?: Maybe<GqlProposalDocument>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  template?: Maybe<GqlPromptTemplate>;
  templateSchema?: Maybe<Scalars['Json']>;
  templateUUId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  workspace?: Maybe<Workspace>;
  workspaceId: Scalars['Int'];
};

export type GqlPromptUsageInput = {
  key: Scalars['String'];
  payload: GqlPromptUsageInputValue;
};

export type GqlPromptUsageInputValue = {
  documentSelectInput?: Maybe<Array<GqlOpportunityOptionRequest>>;
  textInput?: Maybe<Scalars['String']>;
  type?: Maybe<GqlPromptTemplateSchemaFieldType>;
};

export type GqlPromptUsageSearchResult = {
  __typename?: 'GqlPromptUsageSearchResult';
  results: Array<GqlPromptUsage>;
  total: Scalars['Int'];
};

export enum GqlPromptUsageSearchSortByType {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type GqlProposalDocument = {
  __typename?: 'GqlProposalDocument';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  opportunity?: Maybe<PrivOpp>;
  opportunityUUId: Scalars['String'];
  pipeline?: Maybe<Pipeline>;
  pipelineUUId: Scalars['String'];
  richTextDocument: GqlRichTextDocument;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  workspace?: Maybe<Workspace>;
};

export type GqlProposalDocumentSearchResult = {
  __typename?: 'GqlProposalDocumentSearchResult';
  results: Array<GqlProposalDocument>;
  total: Scalars['Int'];
};

export enum GqlProposalDocumentSearchSortBy {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type GqlProposalPromptRewriteRequest = {
  promptKind: Scalars['String'];
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  userText: Scalars['String'];
};

export type GqlQueryWithProposalContentRequest = {
  proposalDocumentUUId: Scalars['String'];
  text: Scalars['String'];
};

export type GqlRichTextDocument = {
  __typename?: 'GqlRichTextDocument';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['String'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};


export type MeGraphQlType = {
  __typename?: 'MeGraphQLType';
  TOBEREMOVED_pipelinesWithUserManagementEnabled: Array<Scalars['String']>;
  TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultPipeline?: Maybe<Pipeline>;
  defaultPipelineView?: Maybe<PipelineView>;
  defaultTimelineView?: Maybe<TimelineViewNexusType>;
  email: Scalars['String'];
  emailPreferences?: Maybe<Scalars['Json']>;
  emailSubscriptions: Array<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  invited?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  isAuditingUser: Scalars['Boolean'];
  isVerifiedEmail: Scalars['Boolean'];
  isVerifiedPhone: Scalars['Boolean'];
  isZombie: Scalars['Boolean'];
  lastName: Scalars['String'];
  mfaMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions: Array<ResourcePermissionGraphQlType>;
  role?: Maybe<Role>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userWorkspaces?: Maybe<Array<Maybe<UserWorkspace>>>;
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateInvite?: Maybe<AuthPayload>;
  activateUserWorkspace?: Maybe<UserWorkspace>;
  addChart?: Maybe<Chart>;
  addContactTag?: Maybe<ContactTag>;
  addContactTagToContact?: Maybe<PrivContact>;
  addDashboard?: Maybe<Dashboard>;
  addField?: Maybe<Pipeline>;
  addFieldOption?: Maybe<Pipeline>;
  addOrEditScore?: Maybe<ScoreTemplate>;
  addOrEditUpdate?: Maybe<Update>;
  addOrgTag?: Maybe<OrgTag>;
  addSection?: Maybe<Pipeline>;
  addSubToTeam?: Maybe<Team>;
  addTagToOrg?: Maybe<PrivOrg>;
  addTeam?: Maybe<Team>;
  chatDocumentChat: GqlDocumentChat;
  chatDocumentChatStreaming: GqlDocumentChat;
  checkVerification?: Maybe<MeGraphQlType>;
  convertToTaskOrder?: Maybe<Scalars['Json']>;
  createCaptureTeam?: Maybe<CaptureTeam>;
  createContactOnPrivOpps?: Maybe<ContactOnPrivOpps>;
  createDocumentChat: GqlDocumentChat;
  createFieldValue?: Maybe<ScoreFieldValue>;
  createInlinePromptUsage: GqlPromptUsage;
  createNote?: Maybe<Note>;
  createOpportunity?: Maybe<PrivOpp>;
  createOrUpdatePrivContact?: Maybe<PrivContact>;
  createPipeline?: Maybe<Pipeline>;
  createPipelineView?: Maybe<PipelineView>;
  createPrivOppSource?: Maybe<PrivOpp>;
  createPrivOrg?: Maybe<PrivOrg>;
  createPromptUsage: GqlPromptUsage;
  createProposalDocument: GqlProposalDocument;
  createProposalStage: ProposalStage;
  createSavedSearch?: Maybe<SavedSearch>;
  createScoreTemplateCategory?: Maybe<Array<Maybe<ScoreTemplateCategory>>>;
  createScoreTemplateField?: Maybe<ScoreTemplateField>;
  createStage?: Maybe<Stage>;
  createUpdate: Update;
  createUpdateFromTemplate: Update;
  createUpdateTemplate: UpdateTemplate;
  createUploadPipelineJob?: Maybe<UploadPipelineJob>;
  createWorkspace?: Maybe<Workspace>;
  deactivateUserWorkspace?: Maybe<UserWorkspace>;
  deleteCaptureTeam?: Maybe<Scalars['Json']>;
  deleteChart?: Maybe<Chart>;
  deleteContact?: Maybe<PrivContact>;
  deleteContactTag?: Maybe<ContactTag>;
  deleteDashboard?: Maybe<Dashboard>;
  deleteDocumentChat?: Maybe<Scalars['Json']>;
  deleteKeywordRule?: Maybe<Scalars['Json']>;
  deleteManyPrivOpps?: Maybe<Scalars['Json']>;
  deleteNote?: Maybe<Note>;
  deleteOrgTag?: Maybe<OrgTag>;
  deletePipeline?: Maybe<Pipeline>;
  deletePipelineView?: Maybe<PipelineView>;
  deletePrivOrg?: Maybe<PrivOrg>;
  deleteProposalDocument: GqlProposalDocument;
  deleteProposalStage: Scalars['Boolean'];
  deleteSavedSearch?: Maybe<SavedSearch>;
  deleteScoreTemplateCategory?: Maybe<Array<Maybe<ScoreTemplateCategory>>>;
  deleteScoreTemplateField?: Maybe<ScoreTemplateField>;
  deleteStage?: Maybe<Stage>;
  deleteTaskOrder?: Maybe<Scalars['Json']>;
  deleteTeam?: Maybe<Team>;
  deleteTimelineView: Scalars['Json'];
  deleteUser?: Maybe<User>;
  deleteUserWorkspace?: Maybe<UserWorkspace>;
  duplicateOpportunity?: Maybe<PrivOpp>;
  duplicatePipeline?: Maybe<Pipeline>;
  editPipeline?: Maybe<Pipeline>;
  editPipelineUser?: Maybe<Scalars['Json']>;
  excludeEmail?: Maybe<Scalars['Json']>;
  favoriteDashboard?: Maybe<FavoriteDashboards>;
  generateComplianceMatrix: GqlComplianceMatrixGenerationJob;
  generateZapierApiKey?: Maybe<Workspace>;
  getUploadLink?: Maybe<Scalars['String']>;
  inviteUser?: Maybe<User>;
  login?: Maybe<AuthPayload>;
  logout?: Maybe<Scalars['Json']>;
  modifyOpportunityChatOption: GqlDocumentChat;
  pubDeleteDocs?: Maybe<Document>;
  pubPrivOppDeleteGovContacts?: Maybe<ContactOnPrivOpps>;
  pubPrivOppUpdateGovContacts?: Maybe<ContactOnPrivOpps>;
  pubUpdateDocs?: Maybe<Document>;
  pubWorkSpaceDeleteTask?: Maybe<Task>;
  pubWorkspaceAddTask?: Maybe<Task>;
  pubWorkspaceDeletePrivOpp?: Maybe<Workspace>;
  pubWorkspaceEditStage?: Maybe<Stage>;
  pubWorkspacePrivOppUpdate?: Maybe<PrivOpp>;
  pubWorkspaceUpdateTask?: Maybe<Task>;
  queryWithProposalContentDocumentChatStreaming: GqlDocumentChat;
  rebuildCache?: Maybe<Scalars['Json']>;
  removeContactTagToContact?: Maybe<PrivContact>;
  removeDefaultPipeline?: Maybe<User>;
  removeField?: Maybe<Pipeline>;
  removeOpportunityProposalStage?: Maybe<PrivOpp>;
  removeSection?: Maybe<Pipeline>;
  removeTagFromOrg?: Maybe<PrivOrg>;
  renameSection?: Maybe<Pipeline>;
  reorderField?: Maybe<Pipeline>;
  reorderProposalStage: Pipeline;
  reorderSection?: Maybe<Pipeline>;
  resetPassword?: Maybe<Scalars['Json']>;
  resetPasswordEmail?: Maybe<Scalars['Json']>;
  rewritePromptDocumentChatStreaming: GqlDocumentChat;
  saveStageTaskTemplate?: Maybe<Stage>;
  sendVerification?: Maybe<Scalars['Json']>;
  setComplianceMatrix?: Maybe<PrivOpp>;
  setDefaultPipeline?: Maybe<User>;
  setDefaultTimelineView?: Maybe<Scalars['Json']>;
  setOpportunityProposalStage?: Maybe<PrivOpp>;
  setOpportunityStage?: Maybe<PrivOpp>;
  signup?: Maybe<Scalars['Json']>;
  subscribeSavedSearch?: Maybe<SavedSearchSubscriber>;
  toggleEmailSubscription?: Maybe<User>;
  toggleUserLevelPermissions: Scalars['Json'];
  unFavoriteDashboard?: Maybe<FavoriteDashboards>;
  unsubscribeSavedSearch?: Maybe<SavedSearchSubscriber>;
  updateCaptureTeam?: Maybe<CaptureTeam>;
  updateCaptureTeams?: Maybe<Scalars['Json']>;
  updateChart?: Maybe<Chart>;
  updateChartLayout?: Maybe<Chart>;
  updateContactTag?: Maybe<ContactTag>;
  updateCurrentUserWorkspace?: Maybe<User>;
  updateDashboard?: Maybe<Dashboard>;
  updateDocumentChatDocuments: GqlDocumentChat;
  updateDocumentChatTitle: GqlDocumentChat;
  updateFieldValue?: Maybe<ScoreFieldValue>;
  updateMetadata?: Maybe<PrivOpp>;
  updateNote?: Maybe<Note>;
  updateOrgTag?: Maybe<OrgTag>;
  updatePipelineView?: Maybe<PipelineView>;
  updatePrivOpp?: Maybe<PrivOpp>;
  updatePrivOppSource?: Maybe<PrivOpp>;
  updatePrivOppStage?: Maybe<PrivOpp>;
  updatePrivOrg?: Maybe<PrivOrg>;
  updatePromptUsage: GqlPromptUsage;
  updateProposalDocument: GqlProposalDocument;
  updateProposalStage: ProposalStage;
  updateScoreTemplate?: Maybe<ScoreTemplate>;
  updateScoreTemplateCategory?: Maybe<Array<Maybe<ScoreTemplateCategory>>>;
  updateScoreTemplateField?: Maybe<ScoreTemplateField>;
  updateStageSettings?: Maybe<Stage>;
  updateTeam?: Maybe<Team>;
  updateUpdateTemplate: UpdateTemplate;
  updateUserEmailPreferences?: Maybe<User>;
  updateUserWorkspaceRole?: Maybe<UserWorkspace>;
  updateWorkspace?: Maybe<Workspace>;
  uploadContactsOrgs?: Maybe<Scalars['Json']>;
  upsertBookmark?: Maybe<Bookmark>;
  upsertBookmarkNote?: Maybe<Bookmark>;
  upsertKeywordRule?: Maybe<Scalars['Json']>;
  upsertSavedSearch2?: Maybe<SavedSearch>;
  upsertTimelineView: TimelineViewNexusType;
  workspaceBillingPortal?: Maybe<Scalars['Json']>;
};


export type MutationActivateInviteArgs = {
  email: Scalars['String'];
  invitationToken: Scalars['String'];
  password: Scalars['String'];
  workPhone?: Maybe<Scalars['String']>;
};


export type MutationActivateUserWorkspaceArgs = {
  userWorkspaceUUId: Scalars['String'];
};


export type MutationAddChartArgs = {
  chartType: Scalars['String'];
  dashboardId: Scalars['Int'];
  query?: Maybe<Scalars['Json']>;
  showValueLabels?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};


export type MutationAddContactTagArgs = {
  name: Scalars['String'];
};


export type MutationAddContactTagToContactArgs = {
  contactTagUUId: Scalars['String'];
  contactUUId: Scalars['String'];
};


export type MutationAddDashboardArgs = {
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationAddFieldArgs = {
  field: Scalars['String'];
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
};


export type MutationAddFieldOptionArgs = {
  field: Scalars['String'];
  pipelineId: Scalars['String'];
  value: Scalars['String'];
};


export type MutationAddOrEditScoreArgs = {
  id?: Maybe<Scalars['Int']>;
  newTemplate?: Maybe<Scalars['Json']>;
  oppId?: Maybe<Scalars['Int']>;
  pipelineId?: Maybe<Scalars['Int']>;
};


export type MutationAddOrEditUpdateArgs = {
  id?: Maybe<Scalars['Int']>;
  oppId?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['Json']>;
  pipelineId?: Maybe<Scalars['Int']>;
  saveTemplate?: Maybe<Scalars['Boolean']>;
  saveTemplateId?: Maybe<Scalars['Int']>;
};


export type MutationAddOrgTagArgs = {
  name: Scalars['String'];
};


export type MutationAddSectionArgs = {
  pipelineUUId: Scalars['String'];
  sectionName: Scalars['String'];
};


export type MutationAddSubToTeamArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  primeId: Scalars['String'];
  privOrgId: Scalars['Int'];
  workshare?: Maybe<Scalars['Int']>;
};


export type MutationAddTagToOrgArgs = {
  orgId: Scalars['String'];
  tagId?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
};


export type MutationAddTeamArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  incumbentTeam?: Maybe<Scalars['Boolean']>;
  isWinningTeam?: Maybe<Scalars['Boolean']>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  subcontractorIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  workshare?: Maybe<Scalars['Int']>;
  workspaceTeam?: Maybe<Scalars['Boolean']>;
};


export type MutationChatDocumentChatArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  query: Scalars['String'];
};


export type MutationChatDocumentChatStreamingArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  query: Scalars['String'];
};


export type MutationCheckVerificationArgs = {
  code: Scalars['String'];
  method?: Maybe<Scalars['String']>;
};


export type MutationConvertToTaskOrderArgs = {
  searchOppUUId: Scalars['String'];
};


export type MutationCreateCaptureTeamArgs = {
  isProposalContributor?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  oppId: Scalars['Int'];
};


export type MutationCreateContactOnPrivOppsArgs = {
  contactUUId: Scalars['String'];
  privOppUUId: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreateDocumentChatArgs = {
  documents: Array<GqlChatDocumentRequest>;
  listings?: Maybe<Array<GqlListingRequest>>;
  sourcesKey: Scalars['String'];
  systemPrompt: GqlDocumentChatSystemPrompt;
  title?: Maybe<Scalars['String']>;
};


export type MutationCreateFieldValueArgs = {
  oppId?: Maybe<Scalars['String']>;
  scoreTemplateFieldId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};


export type MutationCreateInlinePromptUsageArgs = {
  additionalInstructions?: Maybe<Scalars['String']>;
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentType: GqlInlinePromptUsageDocumentKind;
  name?: Maybe<Scalars['String']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  promptUsageInput: Array<GqlPromptUsageInput>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  richTextDocumentUUId?: Maybe<Scalars['String']>;
  updateUUId?: Maybe<Scalars['String']>;
};


export type MutationCreateNoteArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  noteType?: Maybe<NoteType>;
  payload?: Maybe<Scalars['Json']>;
  payloadType?: Maybe<NotePayloadType>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['String']>;
};


export type MutationCreateOpportunityArgs = {
  captureManagerId?: Maybe<Scalars['String']>;
  captureTeamIds?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  externalSourceId?: Maybe<Scalars['String']>;
  externalSourceType?: Maybe<ExternalSourceNexusType>;
  govWinId?: Maybe<Scalars['String']>;
  initialMetadata?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  pipelineId: Scalars['String'];
  stageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateOrUpdatePrivContactArgs = {
  contactOwnerId?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Json']>;
  mobilePhone?: Maybe<Scalars['String']>;
  privOrgId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
};


export type MutationCreatePipelineArgs = {
  basePipelineId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreatePipelineViewArgs = {
  defaultViewMode?: Maybe<PipelineViewMode>;
  filterMode?: Maybe<FilterMode>;
  filters?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  pipelineId: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  tableColumnOverrides?: Maybe<Scalars['Json']>;
};


export type MutationCreatePrivOppSourceArgs = {
  label: Scalars['String'];
  privOppUUId: Scalars['String'];
  source?: Maybe<Scalars['Json']>;
  type: PrivOppSourceEnum;
};


export type MutationCreatePrivOrgArgs = {
  id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  samId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  workspaceOrg?: Maybe<Scalars['Boolean']>;
};


export type MutationCreatePromptUsageArgs = {
  additionalInstructions?: Maybe<Scalars['String']>;
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  name?: Maybe<Scalars['String']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  promptUsageInput: Array<GqlPromptUsageInput>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  templateUUId: Scalars['String'];
};


export type MutationCreateProposalDocumentArgs = {
  name: Scalars['String'];
  opportunityUUId: Scalars['String'];
};


export type MutationCreateProposalStageArgs = {
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pipelineUUId: Scalars['String'];
};


export type MutationCreateSavedSearchArgs = {
  alexandriaSource?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  query: Scalars['Json'];
  savedSearchUUId?: Maybe<Scalars['String']>;
  subscribe?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};


export type MutationCreateScoreTemplateCategoryArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scoreTemplateId: Scalars['String'];
};


export type MutationCreateScoreTemplateFieldArgs = {
  description?: Maybe<Scalars['Json']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Int']>;
  scoreTemplateCategoryId?: Maybe<Scalars['String']>;
  scoreTemplateId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type MutationCreateStageArgs = {
  defaultPwin?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pipelineId: Scalars['Int'];
  stageIndex: Scalars['Int'];
};


export type MutationCreateUpdateArgs = {
  privOppId: Scalars['String'];
};


export type MutationCreateUpdateFromTemplateArgs = {
  privOppId: Scalars['String'];
  updateTemplateUUId: Scalars['String'];
};


export type MutationCreateUpdateTemplateArgs = {
  pipelineId: Scalars['Int'];
  updateTemplateHTML: Scalars['String'];
};


export type MutationCreateUploadPipelineJobArgs = {
  flatfileBatchId: Scalars['String'];
  mode: UploaderMode;
  pipelineId: Scalars['String'];
};


export type MutationCreateWorkspaceArgs = {
  emailDomain: Scalars['String'];
  workspaceName: Scalars['String'];
};


export type MutationDeactivateUserWorkspaceArgs = {
  userWorkspaceUUId: Scalars['String'];
};


export type MutationDeleteCaptureTeamArgs = {
  pipelineId: Scalars['Int'];
  teamName: Scalars['String'];
};


export type MutationDeleteChartArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContactTagArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDashboardArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationDeleteDocumentChatArgs = {
  documentChatUUId: Scalars['String'];
};


export type MutationDeleteKeywordRuleArgs = {
  ruleUUIds?: Maybe<Array<Scalars['String']>>;
  searchOppUUId: Scalars['String'];
};


export type MutationDeleteManyPrivOppsArgs = {
  privOppIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteNoteArgs = {
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationDeleteOrgTagArgs = {
  id: Scalars['String'];
};


export type MutationDeletePipelineArgs = {
  id: Scalars['String'];
};


export type MutationDeletePipelineViewArgs = {
  id: Scalars['String'];
};


export type MutationDeletePrivOrgArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProposalDocumentArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteProposalStageArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteSavedSearchArgs = {
  id: Scalars['String'];
};


export type MutationDeleteScoreTemplateCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteScoreTemplateFieldArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationDeleteStageArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTaskOrderArgs = {
  searchOppUUId: Scalars['String'];
};


export type MutationDeleteTeamArgs = {
  teamId?: Maybe<Scalars['String']>;
};


export type MutationDeleteTimelineViewArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteUserWorkspaceArgs = {
  userWorkspaceUUId: Scalars['String'];
};


export type MutationDuplicateOpportunityArgs = {
  oppId: Scalars['Int'];
};


export type MutationDuplicatePipelineArgs = {
  referencePipelineId: Scalars['String'];
};


export type MutationEditPipelineArgs = {
  customFields?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  oppTags?: Maybe<Scalars['Json']>;
  proposalTemplate?: Maybe<Scalars['Json']>;
  requiredFields?: Maybe<Array<RequiredFieldArgument>>;
  stageLayout?: Maybe<Array<Maybe<Scalars['Int']>>>;
  summaryTemplate?: Maybe<Scalars['Json']>;
};


export type MutationEditPipelineUserArgs = {
  pipelineUUId: Scalars['String'];
  roleUUId?: Maybe<Scalars['String']>;
  userUUId: Scalars['String'];
};


export type MutationExcludeEmailArgs = {
  baseOppUUId: Scalars['String'];
  searchOppUUId: Scalars['String'];
};


export type MutationFavoriteDashboardArgs = {
  dashboardId?: Maybe<Scalars['Int']>;
};


export type MutationGenerateComplianceMatrixArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  filename?: Maybe<Scalars['String']>;
  opportunityUUId: Scalars['String'];
  promptUsageInput: Array<GqlPromptUsageInput>;
  sharepointSiteId: Scalars['String'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  invite?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  anonymousId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationModifyOpportunityChatOptionArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  mode: Scalars['String'];
  request: GqlOpportunityOptionRequest;
};


export type MutationPubDeleteDocsArgs = {
  id: Scalars['Int'];
};


export type MutationPubPrivOppDeleteGovContactsArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationPubPrivOppUpdateGovContactsArgs = {
  id?: Maybe<Scalars['Int']>;
  privContactId?: Maybe<Scalars['Int']>;
  privOppId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
};


export type MutationPubUpdateDocsArgs = {
  contactId?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['Int']>;
  spId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationPubWorkSpaceDeleteTaskArgs = {
  id: Scalars['Int'];
};


export type MutationPubWorkspaceAddTaskArgs = {
  contactId?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  stageId?: Maybe<Scalars['Int']>;
  task?: Maybe<Scalars['Json']>;
};


export type MutationPubWorkspaceDeletePrivOppArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationPubWorkspaceEditStageArgs = {
  defaultPwin?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pipelineCustomFields?: Maybe<Scalars['Json']>;
  showNotes?: Maybe<Scalars['Boolean']>;
  stageColumnFields?: Maybe<Scalars['Json']>;
  stageIndex?: Maybe<Scalars['Int']>;
};


export type MutationPubWorkspacePrivOppUpdateArgs = {
  award?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['String']>;
  prevSol?: Maybe<Scalars['Json']>;
  sol?: Maybe<Scalars['Json']>;
  updateCustomFields?: Maybe<Scalars['Boolean']>;
};


export type MutationPubWorkspaceUpdateTaskArgs = {
  contactId?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['Json']>;
  taskId?: Maybe<Scalars['String']>;
};


export type MutationQueryWithProposalContentDocumentChatStreamingArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  request: GqlQueryWithProposalContentRequest;
};


export type MutationRemoveContactTagToContactArgs = {
  contactTagUUId: Scalars['String'];
  contactUUId: Scalars['String'];
};


export type MutationRemoveFieldArgs = {
  field: Scalars['String'];
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
};


export type MutationRemoveOpportunityProposalStageArgs = {
  uuid: Scalars['String'];
};


export type MutationRemoveSectionArgs = {
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
};


export type MutationRemoveTagFromOrgArgs = {
  orgId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationRenameSectionArgs = {
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
};


export type MutationReorderFieldArgs = {
  pipelineUUId: Scalars['String'];
  swapDestination: Scalars['Int'];
  swapField: Scalars['String'];
  swapSectionId: Scalars['String'];
};


export type MutationReorderProposalStageArgs = {
  index: Scalars['Int'];
  uuid: Scalars['String'];
};


export type MutationReorderSectionArgs = {
  pipelineUUId: Scalars['String'];
  swapDestination: Scalars['Int'];
  swapSectionId: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationRewritePromptDocumentChatStreamingArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  request: GqlProposalPromptRewriteRequest;
};


export type MutationSaveStageTaskTemplateArgs = {
  applyTemplate?: Maybe<Scalars['Boolean']>;
  stageId?: Maybe<Scalars['Int']>;
  taskConfig?: Maybe<Scalars['Json']>;
};


export type MutationSendVerificationArgs = {
  method?: Maybe<Scalars['String']>;
};


export type MutationSetComplianceMatrixArgs = {
  complianceMatrixDocumentRequestUrl?: Maybe<Scalars['String']>;
  privOppUUId: Scalars['String'];
};


export type MutationSetDefaultPipelineArgs = {
  pipelineId: Scalars['String'];
  viewId?: Maybe<Scalars['String']>;
};


export type MutationSetDefaultTimelineViewArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationSetOpportunityProposalStageArgs = {
  proposalStagePosition?: Maybe<Scalars['Float']>;
  proposalStageUUId: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationSetOpportunityStageArgs = {
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  stageId: Scalars['Int'];
};


export type MutationSignupArgs = {
  anonymousId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
  workPhone?: Maybe<Scalars['String']>;
};


export type MutationSubscribeSavedSearchArgs = {
  id: Scalars['String'];
};


export type MutationToggleEmailSubscriptionArgs = {
  oppId?: Maybe<Scalars['String']>;
};


export type MutationToggleUserLevelPermissionsArgs = {
  enable: Scalars['Boolean'];
  pipelineUUId: Scalars['String'];
};


export type MutationUnFavoriteDashboardArgs = {
  dashboardId?: Maybe<Scalars['Int']>;
};


export type MutationUnsubscribeSavedSearchArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCaptureTeamArgs = {
  isProposalContributor?: Maybe<Scalars['Boolean']>;
  oppId: Scalars['Int'];
  teamName: Scalars['String'];
  users?: Maybe<Scalars['Json']>;
};


export type MutationUpdateCaptureTeamsArgs = {
  captureTeams?: Maybe<Scalars['Json']>;
  pipelineId: Scalars['Int'];
};


export type MutationUpdateChartArgs = {
  chartType?: Maybe<Scalars['String']>;
  dashboardId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  layout?: Maybe<Scalars['Json']>;
  query?: Maybe<Scalars['Json']>;
  showValueLabels?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateChartLayoutArgs = {
  id: Scalars['Int'];
  layout?: Maybe<Scalars['Json']>;
};


export type MutationUpdateContactTagArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateCurrentUserWorkspaceArgs = {
  userWorkspaceId: Scalars['String'];
};


export type MutationUpdateDashboardArgs = {
  id?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateDocumentChatDocumentsArgs = {
  documentChatUUId: Scalars['String'];
  documents: Array<GqlChatDocumentRequest>;
};


export type MutationUpdateDocumentChatTitleArgs = {
  documentChatUUId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateFieldValueArgs = {
  scoreFieldValueId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};


export type MutationUpdateMetadataArgs = {
  field: Scalars['String'];
  id: Scalars['Int'];
  value: Scalars['Json'];
};


export type MutationUpdateNoteArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id: Scalars['String'];
  noteType?: Maybe<NoteType>;
  payload?: Maybe<Scalars['Json']>;
  payloadType?: Maybe<NotePayloadType>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['String']>;
};


export type MutationUpdateOrgTagArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdatePipelineViewArgs = {
  defaultViewMode?: Maybe<PipelineViewMode>;
  filterMode?: Maybe<FilterMode>;
  filters?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  tableColumnOverrides?: Maybe<Scalars['Json']>;
};


export type MutationUpdatePrivOppArgs = {
  awardId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  govWinId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isWin?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  pipelineId?: Maybe<Scalars['String']>;
  prevSolId?: Maybe<Scalars['String']>;
  solId?: Maybe<Scalars['String']>;
  stageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  winnerId?: Maybe<Scalars['String']>;
  winningPrice?: Maybe<Scalars['Float']>;
};


export type MutationUpdatePrivOppSourceArgs = {
  label?: Maybe<Scalars['String']>;
  privOppSourceUUId: Scalars['String'];
  privOppUUId: Scalars['String'];
  source?: Maybe<Scalars['Json']>;
};


export type MutationUpdatePrivOppStageArgs = {
  destStageId: Scalars['Int'];
  index: Scalars['Int'];
  privOppId: Scalars['Int'];
};


export type MutationUpdatePrivOrgArgs = {
  accountOwnerId?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  orgId: Scalars['Int'];
  parentOrganizationId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  samId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  workspaceOrg?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePromptUsageArgs = {
  additionalUserInstructions?: Maybe<Scalars['String']>;
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  documentChatUUId: Scalars['String'];
  promptUsageUUId: Scalars['String'];
  proposalDocumentUUId?: Maybe<Scalars['String']>;
};


export type MutationUpdateProposalDocumentArgs = {
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationUpdateProposalStageArgs = {
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationUpdateScoreTemplateArgs = {
  newTemplate?: Maybe<Scalars['Json']>;
  scoreTemplateId: Scalars['String'];
};


export type MutationUpdateScoreTemplateCategoryArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scoreTemplateId?: Maybe<Scalars['String']>;
};


export type MutationUpdateScoreTemplateFieldArgs = {
  description?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Int']>;
  scoreTemplateCategoryId?: Maybe<Scalars['String']>;
  scoreTemplateId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type MutationUpdateStageSettingsArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  stageIndex?: Maybe<Scalars['Int']>;
  taskConfig?: Maybe<Scalars['Json']>;
};


export type MutationUpdateTeamArgs = {
  contactIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  incumbentTeam?: Maybe<Scalars['Boolean']>;
  isWinningTeam?: Maybe<Scalars['Boolean']>;
  nda?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['String']>;
  ta?: Maybe<Scalars['Boolean']>;
  teamId?: Maybe<Scalars['String']>;
  workshare?: Maybe<Scalars['Int']>;
};


export type MutationUpdateUpdateTemplateArgs = {
  updateTemplateHTML: Scalars['String'];
  updateTemplateUUId: Scalars['String'];
};


export type MutationUpdateUserEmailPreferencesArgs = {
  emailPreferences?: Maybe<Scalars['Json']>;
};


export type MutationUpdateUserWorkspaceRoleArgs = {
  roleId: Scalars['String'];
  userWorkspaceUUId: Scalars['String'];
};


export type MutationUpdateWorkspaceArgs = {
  ciospEmail?: Maybe<Scalars['String']>;
  ciospPassword?: Maybe<Scalars['String']>;
  ebuyPassword?: Maybe<Scalars['String']>;
  enableGoogleSSO?: Maybe<Scalars['Boolean']>;
  enableMicrosoftSSO?: Maybe<Scalars['Boolean']>;
  enablePasswordLogin?: Maybe<Scalars['Boolean']>;
  enforceMfa?: Maybe<Scalars['Boolean']>;
  govWinClientId?: Maybe<Scalars['String']>;
  govWinClientSecret?: Maybe<Scalars['String']>;
  govWinPassword?: Maybe<Scalars['String']>;
  govWinUsername?: Maybe<Scalars['String']>;
  inviteOnly?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  symphonyPassword?: Maybe<Scalars['String']>;
  workspaceAbbrev?: Maybe<Scalars['String']>;
};


export type MutationUploadContactsOrgsArgs = {
  data?: Maybe<Scalars['Json']>;
};


export type MutationUpsertBookmarkArgs = {
  bookmarkReason: BookmarkReason;
  externalId: Scalars['String'];
  index?: Maybe<Scalars['String']>;
  source: ExternalSourceNexusType;
};


export type MutationUpsertBookmarkNoteArgs = {
  externalId: Scalars['String'];
  index?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['Json']>;
  noteUUId?: Maybe<Scalars['String']>;
  source: ExternalSourceNexusType;
};


export type MutationUpsertKeywordRuleArgs = {
  keyword: Scalars['String'];
  searchOppUUId: Scalars['String'];
};


export type MutationUpsertSavedSearch2Args = {
  name?: Maybe<Scalars['String']>;
  query: Scalars['Json'];
  savedSearchUUId?: Maybe<Scalars['String']>;
  subscribe?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertTimelineViewArgs = {
  config: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  query: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  Pipeline?: Maybe<Pipeline>;
  contacts: Array<PrivContact>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByFirstName?: Maybe<Scalars['String']>;
  createdByLastName?: Maybe<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['Int']>;
  documents?: Maybe<Scalars['Json']>;
  id: Scalars['Int'];
  noteId: Scalars['String'];
  noteSubscriber?: Maybe<Array<Maybe<NoteSubscriber>>>;
  noteType: NoteType;
  payload: Scalars['Json'];
  payloadType: NotePayloadType;
  pipelineId?: Maybe<Scalars['Int']>;
  plainTextContent?: Maybe<Scalars['String']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrg?: Maybe<PrivOrg>;
  privOrgId?: Maybe<Scalars['Int']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export enum NotePayloadType {
  Email = 'EMAIL',
  PlainText = 'PLAIN_TEXT',
  Rte = 'RTE'
}

export type NoteSubscriber = {
  __typename?: 'NoteSubscriber';
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  subscriber?: Maybe<User>;
  subscriberId?: Maybe<Scalars['Int']>;
};

export enum NoteType {
  Call = 'CALL',
  Email = 'EMAIL',
  Meeting = 'MEETING',
  Note = 'NOTE'
}

export type OppTag = {
  __typename?: 'OppTag';
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  pipeline: Pipeline;
  pipelineId: Scalars['Int'];
  privOpps: Array<PrivOpp>;
  tagId: Scalars['String'];
};

export type OrgTag = {
  __typename?: 'OrgTag';
  color?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  orgTagId: Scalars['String'];
  orgs: Array<PrivOrg>;
  workspaceId: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  crudType: Scalars['String'];
  id: Scalars['Int'];
  scope: Scalars['String'];
};

export type Pipeline = {
  __typename?: 'Pipeline';
  captureTeamTemplate?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['Json']>;
  dataSummaryTemplate: Scalars['Json'];
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  oppTags: Array<OppTag>;
  pipelineId: Scalars['String'];
  pipelineViews: Array<PipelineView>;
  privOpps: Array<PrivOpp>;
  proposalStages: Array<ProposalStage>;
  proposalTemplate?: Maybe<SummaryTemplate>;
  proposalTemplateId?: Maybe<Scalars['Int']>;
  requiredFields: Array<RequiredField>;
  scoreTemplates: Array<ScoreTemplate>;
  scoring?: Maybe<Scalars['Json']>;
  stageLayout?: Maybe<Array<Maybe<Scalars['Json']>>>;
  stages: Array<Stage>;
  summaryTemplate: SummaryTemplate;
  summaryTemplateId: Scalars['Int'];
  updateTemplates: Array<UpdateTemplate>;
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type PipelinePermissionsSettingsNexusType = {
  __typename?: 'PipelinePermissionsSettingsNexusType';
  isPermissionManagementEnabled: Scalars['Boolean'];
  pipeline: Pipeline;
  roles: Array<PipelinePermissionsSettingsRoleNexusType>;
  users: Array<PipelinePermissionsSettingsUserNexusType>;
};

export type PipelinePermissionsSettingsRoleNexusType = {
  __typename?: 'PipelinePermissionsSettingsRoleNexusType';
  roleDescription: Scalars['String'];
  roleName: Scalars['String'];
  roleUUId: Scalars['String'];
};

export type PipelinePermissionsSettingsUserNexusType = {
  __typename?: 'PipelinePermissionsSettingsUserNexusType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  pipelineRoleUUId?: Maybe<Scalars['String']>;
};

export type PipelineView = {
  __typename?: 'PipelineView';
  createdAt: Scalars['DateTime'];
  createdBy: User;
  defaultViewMode: PipelineViewMode;
  filterMode?: Maybe<FilterMode>;
  filters: Scalars['Json'];
  id: Scalars['String'];
  name: Scalars['String'];
  pipeline: Pipeline;
  pipelineId: Scalars['Int'];
  pipelineViewId: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  tableColumnOverrides?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export enum PipelineViewMode {
  Board = 'BOARD',
  Table = 'TABLE'
}

export type PrivContact = {
  __typename?: 'PrivContact';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  contactOnOpps?: Maybe<Array<Maybe<ContactOnPrivOpps>>>;
  contactOwner?: Maybe<User>;
  contactOwnerId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['Json']>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<Event>>>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  govWinContact?: Maybe<Scalars['Json']>;
  govWinContactId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  manager?: Maybe<PrivContact>;
  managerId?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Json']>;
  mobilePhone?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  privOrg?: Maybe<PrivOrg>;
  privOrgId?: Maybe<Scalars['Int']>;
  relatedOpps?: Maybe<Array<Maybe<Scalars['Json']>>>;
  reports?: Maybe<Array<Maybe<PrivContact>>>;
  searchOpps: Array<Maybe<Scalars['Json']>>;
  state?: Maybe<Scalars['String']>;
  tags: Array<ContactTag>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  teamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  title?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workspace: Workspace;
  workspaceId: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

export type PrivOpp = {
  __typename?: 'PrivOpp';
  award?: Maybe<Scalars['Json']>;
  bidAmount?: Maybe<Scalars['Float']>;
  cachedGovWinDetails?: Maybe<Scalars['Json']>;
  canonId: Scalars['String'];
  captureTeam: Array<CaptureTeam>;
  client?: Maybe<PrivOrg>;
  clientId?: Maybe<Scalars['Int']>;
  complianceMatrixDocumentRequestUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  currentScores?: Maybe<Scalars['Json']>;
  documents?: Maybe<Scalars['Json']>;
  events?: Maybe<Array<Maybe<Event>>>;
  govWinId?: Maybe<Scalars['String']>;
  governmentContacts?: Maybe<Array<Maybe<ContactOnPrivOpps>>>;
  histAwardDetails?: Maybe<Scalars['Json']>;
  id: Scalars['Int'];
  internalAwardId?: Maybe<Scalars['String']>;
  internalPrevSolId?: Maybe<Scalars['String']>;
  internalSolId?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isWin: Scalars['String'];
  lossReason?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  noBidReason?: Maybe<Scalars['Json']>;
  notes: Array<Note>;
  oppId: Scalars['String'];
  oppIndex?: Maybe<Scalars['Int']>;
  oppScoreTs?: Maybe<Scalars['Json']>;
  oppStatus?: Maybe<Scalars['String']>;
  pipeline: Pipeline;
  pipelineId: Scalars['Int'];
  prevSol?: Maybe<Scalars['Json']>;
  privOppStage?: Maybe<PrivOppStage>;
  proposalDocuments?: Maybe<Array<Maybe<GqlProposalDocument>>>;
  proposalStage?: Maybe<ProposalStage>;
  proposalStagePosition?: Maybe<Scalars['Float']>;
  rfiDetails?: Maybe<Scalars['Json']>;
  scoreTemplate?: Maybe<ScoreTemplate>;
  scoreTemplateCategories?: Maybe<ScoreTemplateCategory>;
  scoreTemplateCategoriesId?: Maybe<Scalars['Int']>;
  scoreTemplateId?: Maybe<Scalars['Int']>;
  scoreValues?: Maybe<Array<Maybe<ScoreFieldValue>>>;
  sol?: Maybe<Scalars['Json']>;
  solType?: Maybe<Scalars['String']>;
  solicitationDetails?: Maybe<Scalars['Json']>;
  sources?: Maybe<Array<PrivOppSourceNexusType>>;
  stage?: Maybe<Stage>;
  stageId?: Maybe<Scalars['Int']>;
  tags: Array<OppTag>;
  tasks: Array<Task>;
  teamingPartners: Array<PrivOrgsOnPrivOpps>;
  teams: Array<Team>;
  updatedAt: Scalars['DateTime'];
  updates: Array<Update>;
  winner?: Maybe<PrivOrg>;
  winnerId?: Maybe<Scalars['Int']>;
  winningPrice?: Maybe<Scalars['Float']>;
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export enum PrivOppSourceEnum {
  Award = 'AWARD',
  Forecast = 'FORECAST',
  Rfi = 'RFI'
}

export type PrivOppSourceNexusType = {
  __typename?: 'PrivOppSourceNexusType';
  label: Scalars['String'];
  privOppSourceUUId: Scalars['String'];
  source?: Maybe<Scalars['Json']>;
  sourceType: PrivOppSourceTypeNexusType;
};

export enum PrivOppSourceTypeNexusType {
  Award = 'AWARD',
  Forecast = 'FORECAST',
  Rfi = 'RFI'
}

export type PrivOppStage = {
  __typename?: 'PrivOppStage';
  id: Scalars['Int'];
  posId: Scalars['String'];
  privOpp: PrivOpp;
  privOppId: Scalars['Int'];
  stage: Stage;
  stageId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type PrivOrg = {
  __typename?: 'PrivOrg';
  accountOwner?: Maybe<User>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addresses?: Maybe<Scalars['String']>;
  childOrganizations?: Maybe<Array<Maybe<PrivOrg>>>;
  city?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<PrivContact>>>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['Json']>;
  emailDomain?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  govWinEntity?: Maybe<Scalars['Json']>;
  govWinEntityId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  mainPoc?: Maybe<PrivContact>;
  mainPocId?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  orgId: Scalars['String'];
  orgTags: Array<OrgTag>;
  parentOrganization?: Maybe<PrivOrg>;
  parentOrganizationId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  relatedOpps?: Maybe<Scalars['Json']>;
  samId?: Maybe<Scalars['String']>;
  selfOrg?: Maybe<Workspace>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  team?: Maybe<Array<Maybe<Team>>>;
  teamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  teams?: Maybe<Array<Maybe<PrivOrgsOnPrivOpps>>>;
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
  workspaceOrg?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

export type PrivOrgsOnPrivOpps = {
  __typename?: 'PrivOrgsOnPrivOpps';
  id?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrgs?: Maybe<Array<Maybe<PrivOrg>>>;
  role?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  workspace?: Maybe<Workspace>;
};

export type ProposalStage = {
  __typename?: 'ProposalStage';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  name: Scalars['String'];
  opportunities: Array<Maybe<PrivOpp>>;
  pipeline?: Maybe<Pipeline>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  workspace?: Maybe<Workspace>;
};

export enum PublicExternalSource {
  Award = 'award',
  ContractVehicles = 'contract_vehicles',
  Sol = 'sol'
}

export type Query = {
  __typename?: 'Query';
  canViewPipeline: Scalars['Boolean'];
  captcha: Scalars['Json'];
  chart?: Maybe<Chart>;
  checkGovWinAuth?: Maybe<Scalars['Boolean']>;
  contractVehiclePrimeAwards: ElasticSearchResult;
  contractVehicleTaskOrderAnalysis: ElasticSearchResult;
  contractVehicleTaskOrders: ElasticSearchResult;
  esAwardAgencies?: Maybe<Scalars['Json']>;
  esGetListing?: Maybe<Scalars['Json']>;
  esGetListings?: Maybe<Scalars['Json']>;
  esGetListingsMultiIndex?: Maybe<Scalars['Json']>;
  filterSearch?: Maybe<Scalars['Json']>;
  getAdminPortalLink?: Maybe<Scalars['String']>;
  getAuthInfo: AuthInfo;
  getAwardTaskOrderAnalysis: ElasticSearchResult;
  getAwardTaskOrders: ElasticSearchResult;
  getComplianceMatrixPromptTemplate?: Maybe<GqlPromptTemplate>;
  getContact: PrivContact;
  getContactTags: Array<Maybe<ContactTag>>;
  getContacts: GetContactsResolverResult;
  getContactsFilterOptions: GetContactsFilterOptionsResolverResult;
  getContactsPagination: GetContactsPaginationResolverResult;
  getContractVehicle?: Maybe<ContractVehicle>;
  getDashboard?: Maybe<Dashboard>;
  getDashboards?: Maybe<Scalars['Json']>;
  getDefaultPipeline: DefaultPipelineNexusType;
  getDocument?: Maybe<Scalars['String']>;
  getDocumentChat: GqlDocumentChat;
  getDocumentChatsBySourcesKey: Array<GqlDocumentChat>;
  getDocumentRequestLink: Scalars['String'];
  getEmailEndpoints?: Maybe<Scalars['Json']>;
  getEvents?: Maybe<Array<Maybe<Event>>>;
  getGovWinOpp?: Maybe<Scalars['Json']>;
  getGovWinPrivOpp?: Maybe<Scalars['Json']>;
  getInitialPipelinePageData: Pipeline;
  getInitialPlanningContacts: Array<Scalars['String']>;
  getInitialPlanningOpportunities: Array<Scalars['String']>;
  getInitialPlanningOrganizations: Array<Scalars['String']>;
  getJWT?: Maybe<Scalars['Json']>;
  getMe: MeGraphQlType;
  getOppRules?: Maybe<Scalars['Json']>;
  getOpportunities: GetOpportunitiesResolverResult;
  getOpportunitiesWithSearchOpp: Array<PrivOpp>;
  getOpportunity: PrivOpp;
  getOpportunityChatOptions: Array<GqlOpportunityOption>;
  getOrgTags: Array<OrgTag>;
  getOrganization?: Maybe<PrivOrg>;
  getPipelineByUUId: Pipeline;
  getPipelinePermissionSettings: PipelinePermissionsSettingsNexusType;
  getPipelineViews: Array<PipelineView>;
  getPipelines: Array<Pipeline>;
  getPlanning: Workspace;
  getPlanningContacts: Array<PrivContact>;
  getPlanningOpportunities: Array<PrivOpp>;
  getPlanningOrganizations: Array<PrivOrg>;
  getPrivOpp?: Maybe<PrivOpp>;
  getPrivOrgs: GetPrivOrgsResolverResult;
  getPrivOrgsFilterOptions: GetPrivOrgsFilterOptionsResolverResult;
  getPrivOrgsPagination: GetPrivOrgsPaginationResolverResult;
  getProducts?: Maybe<Scalars['Json']>;
  getPromptTemplate: GqlPromptTemplate;
  getPromptTemplateGroup: GqlPromptTemplateGroup;
  getPromptUsage: GqlPromptUsage;
  getProposalDocument: GqlProposalDocument;
  getProposalStage: ProposalStage;
  /** Get saved search meta for a list of saved searches */
  getSavedSearchMeta: Array<SavedSearchMeta>;
  getSavedSearches: Array<SavedSearch>;
  /** Get saved search meta for all saved searches that the user is subscribed to */
  getSubscribedSavedSearchMeta: Array<SavedSearchMeta>;
  getTask?: Maybe<Task>;
  getTaskOrderSources?: Maybe<Scalars['Json']>;
  getTimelineView: TimelineViewNexusType;
  getTimelineViews: Array<TimelineViewNexusType>;
  getUpdate: Update;
  getUpdateTemplate: UpdateTemplate;
  getUploadPipelineJob?: Maybe<UploadPipelineJob>;
  getUsers?: Maybe<Array<User>>;
  getWorkspace: Workspace;
  getWorkspaceLogos?: Maybe<Scalars['Json']>;
  getWorkspacePipelineUUId: Scalars['String'];
  getWorkspaceRolesAndUsers: Workspace;
  getWorkspaceSubscription?: Maybe<Scalars['Json']>;
  healthCheck?: Maybe<Scalars['Json']>;
  oppUpdates: Array<Update>;
  pipeline: Pipeline;
  queryPlaces?: Maybe<Scalars['Json']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  searchContacts: SearchContactsResolverResult;
  searchOpps?: Maybe<ElasticSearchResult>;
  searchPipelineOpportunities: GetPipelineOpportunitiesResolverResult;
  searchPrivContact?: Maybe<PrivContact>;
  searchPrivOpp?: Maybe<Array<Maybe<PrivOpp>>>;
  searchPrivOrgs: SearchPrivOrgsResolverResult;
  searchPromptTemplateGroups: GqlPromptTemplateGroupSearchResult;
  searchPromptUsages: GqlPromptUsageSearchResult;
  searchProposalDocuments: GqlProposalDocumentSearchResult;
  searchSources?: Maybe<ElasticSearchResult>;
  searchUsers?: Maybe<Array<User>>;
  /** Retreives a list of all users in the requesting users's current workspace */
  users?: Maybe<Array<Maybe<User>>>;
  vendorAwardsSearch?: Maybe<Scalars['Json']>;
  verifyEmailToken?: Maybe<Scalars['Json']>;
  whitelist: Scalars['Json'];
};


export type QueryCanViewPipelineArgs = {
  pipelineUUId: Scalars['String'];
};


export type QueryCaptchaArgs = {
  captchaToken: Scalars['String'];
};


export type QueryChartArgs = {
  id: Scalars['Int'];
};


export type QueryContractVehiclePrimeAwardsArgs = {
  contractVehicleId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};


export type QueryContractVehicleTaskOrderAnalysisArgs = {
  awardYears: Array<Scalars['Int']>;
  awardingAgencies: Array<Scalars['String']>;
  contractVehicleId: Scalars['String'];
  expirationYears: Array<Scalars['Int']>;
  includeSource?: Maybe<Scalars['Boolean']>;
  naics: Array<Scalars['String']>;
  psc: Array<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};


export type QueryContractVehicleTaskOrdersArgs = {
  awardYears: Array<Scalars['Int']>;
  awardingAgencies: Array<Scalars['String']>;
  contractVehicleId: Scalars['String'];
  expirationYears: Array<Scalars['Int']>;
  limit: Scalars['Int'];
  naics: Array<Scalars['String']>;
  offset: Scalars['Int'];
  psc: Array<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};


export type QueryEsGetListingArgs = {
  awardId: Scalars['String'];
  index: Scalars['String'];
};


export type QueryEsGetListingsArgs = {
  ids: Array<Scalars['String']>;
  index: PublicExternalSource;
};


export type QueryEsGetListingsMultiIndexArgs = {
  ids: Array<Scalars['String']>;
  indexes: Array<Scalars['String']>;
};


export type QueryFilterSearchArgs = {
  field: Scalars['String'];
  index: Scalars['String'];
  query?: Maybe<Scalars['String']>;
};


export type QueryGetAuthInfoArgs = {
  anonymousId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitationToken?: Maybe<Scalars['String']>;
  mode?: Maybe<AuthMode>;
  recaptchaToken?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
};


export type QueryGetAwardTaskOrderAnalysisArgs = {
  awardId: Scalars['String'];
};


export type QueryGetAwardTaskOrdersArgs = {
  awardId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  uuid: Scalars['String'];
};


export type QueryGetContactsArgs = {
  LEGACY_ids?: Maybe<Array<Scalars['Int']>>;
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetContactsFilterOptionsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetContactsPaginationArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orgIndustry?: Maybe<Array<Scalars['String']>>;
  orgNames?: Maybe<Array<Scalars['String']>>;
  orgTags?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetContractVehicleArgs = {
  contractVehicleId: Scalars['String'];
};


export type QueryGetDashboardArgs = {
  id: Scalars['Int'];
};


export type QueryGetDashboardsArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryGetDefaultPipelineArgs = {
  preferredPipelineId?: Maybe<Scalars['Int']>;
  preferredPipelineUUId?: Maybe<Scalars['String']>;
  preferredViewUUId?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentArgs = {
  docType?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  path: Scalars['String'];
  source?: Maybe<ExternalSourceNexusType>;
};


export type QueryGetDocumentChatArgs = {
  documentChatUUId: Scalars['String'];
};


export type QueryGetDocumentChatsBySourcesKeyArgs = {
  sourcesKey: Scalars['String'];
};


export type QueryGetDocumentRequestLinkArgs = {
  documentRequestURL: Scalars['String'];
};


export type QueryGetEventsArgs = {
  endDate?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetGovWinOppArgs = {
  govWinId?: Maybe<Scalars['String']>;
};


export type QueryGetGovWinPrivOppArgs = {
  canonId?: Maybe<Scalars['String']>;
};


export type QueryGetInitialPipelinePageDataArgs = {
  id: Scalars['Int'];
  includeArchivedOpportunities?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOppRulesArgs = {
  searchOppUUId: Scalars['String'];
};


export type QueryGetOpportunitiesArgs = {
  cursor?: Maybe<Scalars['String']>;
  estRfp?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  paged?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
};


export type QueryGetOpportunitiesWithSearchOppArgs = {
  searchOppUUId: Scalars['String'];
};


export type QueryGetOpportunityArgs = {
  uuid: Scalars['String'];
};


export type QueryGetOpportunityChatOptionsArgs = {
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
  opportunityUUId?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
};


export type QueryGetOrganizationArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPipelineByUuIdArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPipelinePermissionSettingsArgs = {
  pipelineUUId: Scalars['String'];
};


export type QueryGetPlanningContactsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPlanningOpportunitiesArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPlanningOrganizationsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPrivOppArgs = {
  canonId: Scalars['String'];
};


export type QueryGetPrivOrgsArgs = {
  LEGACY_ids?: Maybe<Array<Scalars['Int']>>;
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPrivOrgsFilterOptionsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetPrivOrgsPaginationArgs = {
  accountOwnersFirstLastSeparatedByColonColon?: Maybe<Array<Scalars['String']>>;
  cursor?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  types?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPromptTemplateArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPromptTemplateGroupArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPromptUsageArgs = {
  promptUsageUUId: Scalars['String'];
};


export type QueryGetProposalDocumentArgs = {
  uuid: Scalars['String'];
};


export type QueryGetProposalStageArgs = {
  uuid: Scalars['String'];
};


export type QueryGetSavedSearchMetaArgs = {
  savedSearchIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetTaskArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetTimelineViewArgs = {
  id: Scalars['String'];
};


export type QueryGetUpdateArgs = {
  updateUUId: Scalars['String'];
};


export type QueryGetUpdateTemplateArgs = {
  updateTemplateUUId: Scalars['String'];
};


export type QueryGetUploadPipelineJobArgs = {
  uploadId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetWorkspaceLogosArgs = {
  workspaceHumanId: Scalars['String'];
};


export type QueryGetWorkspacePipelineUuIdArgs = {
  pipelineId: Scalars['Int'];
};


export type QueryOppUpdatesArgs = {
  oppId: Scalars['Int'];
};


export type QueryPipelineArgs = {
  id: Scalars['Int'];
  includeArchived?: Maybe<Scalars['Boolean']>;
  oppIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryQueryPlacesArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QuerySearchContactsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orgIndustry?: Maybe<Array<Scalars['String']>>;
  orgNames?: Maybe<Array<Scalars['String']>>;
  orgTags?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type QuerySearchOppsArgs = {
  _source?: Maybe<Scalars['Json']>;
  advanced?: Maybe<Scalars['Boolean']>;
  alexandriaSource?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['Json']>;
  filters?: Maybe<Scalars['Json']>;
  freeform?: Maybe<Scalars['Json']>;
  from?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Json']>;
  mergeableOnly?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Json']>;
  trackTotalHits?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};


export type QuerySearchPipelineOpportunitiesArgs = {
  cursor?: Maybe<Scalars['String']>;
  estimatedRFP?: Maybe<Scalars['String']>;
  hasProposal?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  pipelineUUId: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  uuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySearchPrivContactArgs = {
  email: Scalars['String'];
};


export type QuerySearchPrivOppArgs = {
  listing?: Maybe<Scalars['Json']>;
  pipelineId: Scalars['Int'];
  pubOppMetaData?: Maybe<Scalars['Json']>;
  type: Scalars['String'];
};


export type QuerySearchPrivOrgsArgs = {
  accountOwnersFirstLastSeparatedByColonColon?: Maybe<Array<Scalars['String']>>;
  cursor?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  types?: Maybe<Array<Scalars['String']>>;
};


export type QuerySearchPromptTemplateGroupsArgs = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  pipelineUUId?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<GqlPromptTemplateGroupSearchSortByType>;
  sortOrder?: Maybe<SortDirection>;
  type?: Maybe<GqlPromptType>;
};


export type QuerySearchPromptUsagesArgs = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  pipelineUUId?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<GqlPromptUsageSearchSortByType>;
  sortOrder?: Maybe<SortDirection>;
  type?: Maybe<GqlPromptType>;
};


export type QuerySearchProposalDocumentsArgs = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  pipelineUUId: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<GqlProposalDocumentSearchSortBy>;
  sortOrder?: Maybe<SortDirection>;
};


export type QuerySearchSourcesArgs = {
  searchSpec: Scalars['Json'];
};


export type QuerySearchUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QueryVendorAwardsSearchArgs = {
  vendorId: Scalars['String'];
};


export type QueryVerifyEmailTokenArgs = {
  emailToken: Scalars['String'];
};

export enum RequireFieldStorageHint {
  CaptureTeam = 'capture_team',
  Metadata = 'metadata',
  Source = 'source'
}

export type RequiredField = {
  __typename?: 'RequiredField';
  fieldName: Scalars['String'];
  requiredFieldUUId: Scalars['String'];
  storageHint: RequireFieldStorageHint;
};

export type RequiredFieldArgument = {
  fieldName: Scalars['String'];
  storageHint: RequireFieldStorageHint;
};

export type ResourcePermissionGraphQlType = {
  __typename?: 'ResourcePermissionGraphQLType';
  excludeOperationType: Array<Scalars['String']>;
  excludeResourceId: Array<Scalars['String']>;
  excludeResourceType: Array<Scalars['String']>;
  id: Scalars['String'];
  includeOperationType: Array<Scalars['String']>;
  includeResourceId: Array<Scalars['String']>;
  includeResourceType: Array<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  roleId: Scalars['String'];
  usersCount: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  id: Scalars['String'];
  index: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['Int'];
  payload?: Maybe<Scalars['Json']>;
  savedSearchId?: Maybe<Scalars['String']>;
  savedSearchSubscriber: Array<SavedSearchSubscriber>;
  url: Scalars['String'];
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type SavedSearchMeta = {
  __typename?: 'SavedSearchMeta';
  recentCount: Scalars['Int'];
  savedSearch: SavedSearch;
  totalCount: Scalars['Int'];
};

export type SavedSearchSubscriber = {
  __typename?: 'SavedSearchSubscriber';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  savedSearch: SavedSearch;
  savedSearchId: Scalars['String'];
  subscriber: User;
  subscriberId: Scalars['Int'];
};

export type ScoreFieldValue = {
  __typename?: 'ScoreFieldValue';
  field?: Maybe<ScoreTemplateField>;
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline?: Maybe<Pipeline>;
  pipelineId?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  scoreFieldValueId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScoreTemplate = {
  __typename?: 'ScoreTemplate';
  categories?: Maybe<Array<Maybe<ScoreTemplateCategory>>>;
  fields?: Maybe<Array<Maybe<ScoreTemplateField>>>;
  id?: Maybe<Scalars['Int']>;
  scoreTemplateId?: Maybe<Scalars['String']>;
};

export type ScoreTemplateCategory = {
  __typename?: 'ScoreTemplateCategory';
  fields?: Maybe<Array<Maybe<ScoreTemplateField>>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  scoreTemplateCategoryId?: Maybe<Scalars['String']>;
  scoreTemplateId?: Maybe<Scalars['Int']>;
};

export type ScoreTemplateField = {
  __typename?: 'ScoreTemplateField';
  categoryId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Int']>;
  scoreTemplateFieldId?: Maybe<Scalars['String']>;
  scoreTemplateId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type SearchContactsResolverResult = {
  __typename?: 'SearchContactsResolverResult';
  contacts: Array<PrivContact>;
  total: Scalars['Int'];
};

export type SearchPrivOrgsResolverResult = {
  __typename?: 'SearchPrivOrgsResolverResult';
  orgs: Array<PrivOrg>;
  total: Scalars['Int'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stage = {
  __typename?: 'Stage';
  createdAt: Scalars['DateTime'];
  defaultPwin: Scalars['Int'];
  id: Scalars['Int'];
  layout: Array<Scalars['Json']>;
  name: Scalars['String'];
  pipeline: Pipeline;
  pipelineCustomFields?: Maybe<Scalars['Json']>;
  pipelineId: Scalars['Int'];
  privOppStages: Array<PrivOpp>;
  showNotes?: Maybe<Scalars['Boolean']>;
  stageColumnFields?: Maybe<Scalars['Json']>;
  stageId: Scalars['String'];
  stageIndex: Scalars['Int'];
  taskConfig?: Maybe<Scalars['Json']>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  streamChatDocumentChatQuery: GqlDocumentChat;
  streamComplianceMatrixJob: GqlComplianceMatrixGenerationJob;
};


export type SubscriptionStreamChatDocumentChatQueryArgs = {
  documentChatUUId: Scalars['String'];
};


export type SubscriptionStreamComplianceMatrixJobArgs = {
  complianceMatrixJobUUId: Scalars['String'];
};

export type SummaryTemplate = {
  __typename?: 'SummaryTemplate';
  definition: Scalars['Json'];
  id: Scalars['Int'];
};

export type Task = {
  __typename?: 'Task';
  assignees: Array<User>;
  captureTeam?: Maybe<CaptureTeam>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contact?: Maybe<PrivContact>;
  contactId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  dueDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  notes: Array<Note>;
  org?: Maybe<PrivOrg>;
  orgId?: Maybe<Scalars['Int']>;
  parent?: Maybe<Task>;
  parentId?: Maybe<Scalars['Int']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  stage?: Maybe<Stage>;
  stageId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subtasks: Array<Task>;
  taskId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type Team = {
  __typename?: 'Team';
  contacts?: Maybe<Array<Maybe<PrivContact>>>;
  id?: Maybe<Scalars['String']>;
  incumbentTeam?: Maybe<Scalars['Boolean']>;
  isWinningTeam?: Maybe<Scalars['Boolean']>;
  nda?: Maybe<Scalars['Boolean']>;
  prime?: Maybe<Team>;
  primeId?: Maybe<Scalars['Int']>;
  privContacts?: Maybe<Array<Maybe<PrivContact>>>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  privOrg?: Maybe<PrivOrg>;
  privOrgId?: Maybe<Scalars['String']>;
  subcontractors?: Maybe<Array<Maybe<Team>>>;
  ta?: Maybe<Scalars['Boolean']>;
  teamId?: Maybe<Scalars['String']>;
  workshare?: Maybe<Scalars['Int']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
  workspaceTeam?: Maybe<Scalars['Boolean']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  PrivOrgsOnPrivOpps?: Maybe<PrivOrgsOnPrivOpps>;
  PrivOrgsOnPrivOppsId?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Maybe<PrivContact>>>;
  id: Scalars['Int'];
  nda?: Maybe<Scalars['Boolean']>;
  privOrg?: Maybe<PrivOrg>;
  privOrgId?: Maybe<Scalars['String']>;
  ta?: Maybe<Scalars['Boolean']>;
  workshare?: Maybe<Scalars['Int']>;
  workspaceId: Scalars['Int'];
};

export type TimelineViewConfigDateFilterNexusType = {
  __typename?: 'TimelineViewConfigDateFilterNexusType';
  field: Scalars['String'];
  mode: Scalars['String'];
  valueISOString?: Maybe<Scalars['String']>;
};

export type TimelineViewConfigDisplayDateNexusType = {
  __typename?: 'TimelineViewConfigDisplayDateNexusType';
  color: Scalars['String'];
  field: Scalars['String'];
};

export type TimelineViewConfigNexusType = {
  __typename?: 'TimelineViewConfigNexusType';
  dateFilters: Array<TimelineViewConfigDateFilterNexusType>;
  displayDates: Array<TimelineViewConfigDisplayDateNexusType>;
  filters: Array<FieldValuesNexusType>;
  sort?: Maybe<TimelineViewConfigOrderByNexusType>;
};

export type TimelineViewConfigOrderByNexusType = {
  __typename?: 'TimelineViewConfigOrderByNexusType';
  direction: SortDirection;
  field: Scalars['String'];
};

export type TimelineViewNexusType = {
  __typename?: 'TimelineViewNexusType';
  definition?: Maybe<TimelineViewConfigNexusType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  timelineViewUUId: Scalars['String'];
};

export type Update = {
  __typename?: 'Update';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  hasUpdateV2Payload: Scalars['Boolean'];
  id: Scalars['Int'];
  payload?: Maybe<Scalars['Json']>;
  privOpp?: Maybe<PrivOpp>;
  privOppId?: Maybe<Scalars['Int']>;
  updateId?: Maybe<Scalars['String']>;
  updateStatus?: Maybe<Scalars['String']>;
  updateTemplate?: Maybe<UpdateTemplate>;
  updateTemplateId?: Maybe<Scalars['Int']>;
};

export type UpdateTemplate = {
  __typename?: 'UpdateTemplate';
  id: Scalars['Int'];
  payload?: Maybe<Scalars['Json']>;
  payloadV2?: Maybe<Scalars['String']>;
  pipeline: Pipeline;
  pipelineId: Scalars['Int'];
  updateTemplateId?: Maybe<Scalars['String']>;
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};


export type UploadPipelineJob = {
  __typename?: 'UploadPipelineJob';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']>;
  flatfileBatchId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  jobResults?: Maybe<Scalars['Json']>;
  pipeline?: Maybe<Pipeline>;
  pipelineId?: Maybe<Scalars['Int']>;
  progressPercent: Scalars['Float'];
  status?: Maybe<UploadPipelineJobStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadId?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export enum UploadPipelineJobStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export enum UploaderMode {
  Create = 'Create',
  Update = 'Update'
}

export type User = {
  __typename?: 'User';
  captureTeams?: Maybe<Array<Maybe<CaptureTeam>>>;
  charts?: Maybe<Array<Maybe<Chart>>>;
  contacts?: Maybe<Array<Maybe<PrivContact>>>;
  createdAt: Scalars['DateTime'];
  createdNotes?: Maybe<Note>;
  createdPrivOpps?: Maybe<Array<Maybe<PrivOpp>>>;
  dashboardUserRoles?: Maybe<Array<Maybe<DashboardUserRoles>>>;
  dashboards?: Maybe<Array<Maybe<Dashboard>>>;
  defaultPipeline?: Maybe<Pipeline>;
  defaultPipelineView?: Maybe<PipelineView>;
  defaultTimelineView?: Maybe<TimelineViewNexusType>;
  email: Scalars['String'];
  emailPreferences?: Maybe<Scalars['Json']>;
  emailSubscriptions: Array<PrivOpp>;
  favoriteDashboards?: Maybe<Array<Maybe<FavoriteDashboards>>>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  invited?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  isAuditingUser: Scalars['Boolean'];
  isVerifiedEmail: Scalars['Boolean'];
  isVerifiedPhone: Scalars['Boolean'];
  isZombie: Scalars['Boolean'];
  lastName: Scalars['String'];
  mfaMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  noteSubscriber?: Maybe<Array<Maybe<NoteSubscriber>>>;
  productName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  savedSearchSubscribers?: Maybe<Array<Maybe<SavedSearchSubscriber>>>;
  savedSearchesOwned?: Maybe<Array<Maybe<SavedSearch>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userContact?: Maybe<PrivContact>;
  userId: Scalars['String'];
  userWorkspaces?: Maybe<Array<Maybe<UserWorkspace>>>;
  workPhone?: Maybe<Scalars['String']>;
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type UserWorkspace = {
  __typename?: 'UserWorkspace';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isAuditingUser: Scalars['Boolean'];
  productName?: Maybe<Scalars['String']>;
  roleName: Scalars['String'];
  roleUUId: Scalars['String'];
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userFullName: Scalars['String'];
  userIsActive: Scalars['Boolean'];
  userIsVerifiedEmail: Scalars['Boolean'];
  userIsZombie: Scalars['Boolean'];
  userLastName: Scalars['String'];
  userUUId: Scalars['String'];
  userWorkspaceUUId: Scalars['String'];
  workspaceId: Scalars['Int'];
  workspaceName: Scalars['String'];
  workspaceUUId: Scalars['String'];
};

export type Workspace = {
  __typename?: 'Workspace';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  alexandria: Scalars['Boolean'];
  billingCustomerId?: Maybe<Scalars['String']>;
  cageCode?: Maybe<Scalars['String']>;
  certs?: Maybe<Scalars['Json']>;
  ciospEmail?: Maybe<Scalars['String']>;
  ciospEmailEndpoint?: Maybe<Scalars['String']>;
  ciospIndex: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  contactTags: Array<ContactTag>;
  contacts: Array<PrivContact>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dashboards: Array<Dashboard>;
  documentChat: Scalars['Boolean'];
  dunsNumber?: Maybe<Scalars['String']>;
  ebuyEmail?: Maybe<Scalars['String']>;
  ebuyIndex: Scalars['Boolean'];
  emailDomain?: Maybe<Scalars['String']>;
  enableGoogleSSO: Scalars['Boolean'];
  enableMicrosoftSSO: Scalars['Boolean'];
  enablePasswordLogin: Scalars['Boolean'];
  enableProposalManagement: Scalars['Boolean'];
  enforceDirectoryProvisioning: Scalars['Boolean'];
  enforceMfa: Scalars['Boolean'];
  freeTrial: Scalars['Boolean'];
  govWinClientId?: Maybe<Scalars['String']>;
  govWinEnabled: Scalars['Boolean'];
  govWinUsername?: Maybe<Scalars['String']>;
  hasEGosPassword: Scalars['Boolean'];
  hasEbuyPassword: Scalars['Boolean'];
  hasGovWinClientSecret?: Maybe<Scalars['Boolean']>;
  hasGovWinPassword: Scalars['Boolean'];
  hasStripeSubscription: Scalars['Boolean'];
  id: Scalars['Int'];
  indices?: Maybe<Scalars['Json']>;
  inviteOnly: Scalars['Boolean'];
  isPayingCustomer: Scalars['Boolean'];
  mfaMethods: Array<Scalars['String']>;
  naicsCodes?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  notes: Array<Note>;
  orgTags: Array<OrgTag>;
  paymentMethodId?: Maybe<Scalars['String']>;
  pipelineStages: Array<Stage>;
  pipelines: Array<Pipeline>;
  primaryLogo?: Maybe<Scalars['String']>;
  privOpps: Array<PrivOpp>;
  privOrgs: Array<PrivOrg>;
  profile?: Maybe<Scalars['Json']>;
  restrictRoleProvisioningToDirectory: Scalars['Boolean'];
  roles: Array<Role>;
  savedSearch: Array<SavedSearch>;
  seaportIndex: Scalars['Boolean'];
  sidebarLogo?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  symphonyEmail?: Maybe<Scalars['String']>;
  symphonyIndex: Scalars['Boolean'];
  tasks: Array<Task>;
  teams: Array<Team>;
  updatesV2: Scalars['Boolean'];
  userWorkspaces: Array<UserWorkspace>;
  users: Array<User>;
  workspaceAbbrev?: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
  zapier: Scalars['Boolean'];
  zapierApiKey?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ChatDocumentChatMutationVariables = Exact<{
  query: Scalars['String'];
  documentChatUUId: Scalars['String'];
}>;


export type ChatDocumentChatMutation = (
  { __typename?: 'Mutation' }
  & { chatDocumentChat: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ChatDocumentChatStreamingMutationVariables = Exact<{
  query: Scalars['String'];
  documentChatUUId: Scalars['String'];
}>;


export type ChatDocumentChatStreamingMutation = (
  { __typename?: 'Mutation' }
  & { chatDocumentChatStreaming: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateDocumentChatMutationVariables = Exact<{
  documents: Array<GqlChatDocumentRequest> | GqlChatDocumentRequest;
  listings?: Maybe<Array<GqlListingRequest> | GqlListingRequest>;
  systemPrompt: GqlDocumentChatSystemPrompt;
  title?: Maybe<Scalars['String']>;
  sourcesKey: Scalars['String'];
}>;


export type CreateDocumentChatMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentChat: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateInlinePromptUsageMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  additionalInstructions?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  updateUUId?: Maybe<Scalars['String']>;
  richTextDocumentUUId?: Maybe<Scalars['String']>;
  documentType: GqlInlinePromptUsageDocumentKind;
  promptUsageInput: Array<GqlPromptUsageInput> | GqlPromptUsageInput;
}>;


export type CreateInlinePromptUsageMutation = (
  { __typename?: 'Mutation' }
  & { createInlinePromptUsage: (
    { __typename?: 'GqlPromptUsage' }
    & Pick<GqlPromptUsage, 'id' | 'uuid' | 'name' | 'inputs' | 'additionalInstructions'>
    & { chats: Array<Maybe<(
      { __typename?: 'GqlDocumentChat' }
      & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type CreatePromptUsageMutationVariables = Exact<{
  templateUUId: Scalars['String'];
  promptUsageInput: Array<GqlPromptUsageInput> | GqlPromptUsageInput;
  name?: Maybe<Scalars['String']>;
  additionalInstructions?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  opportunityUUId?: Maybe<Scalars['String']>;
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
}>;


export type CreatePromptUsageMutation = (
  { __typename?: 'Mutation' }
  & { createPromptUsage: (
    { __typename?: 'GqlPromptUsage' }
    & Pick<GqlPromptUsage, 'id' | 'uuid' | 'name' | 'additionalInstructions'>
    & { chats: Array<Maybe<(
      { __typename?: 'GqlDocumentChat' }
      & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type DeleteDocumentChatMutationVariables = Exact<{
  documentChatUUId: Scalars['String'];
}>;


export type DeleteDocumentChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocumentChat'>
);

export type GenerateComplianceMatrixMutationVariables = Exact<{
  filename?: Maybe<Scalars['String']>;
  promptUsageInput: Array<GqlPromptUsageInput> | GqlPromptUsageInput;
  sharepointSiteId: Scalars['String'];
  opportunityUUId: Scalars['String'];
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
}>;


export type GenerateComplianceMatrixMutation = (
  { __typename?: 'Mutation' }
  & { generateComplianceMatrix: (
    { __typename?: 'GqlComplianceMatrixGenerationJob' }
    & Pick<GqlComplianceMatrixGenerationJob, 'uuid' | 'status'>
  ) }
);

export type GetComplianceMatrixPromptTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetComplianceMatrixPromptTemplateQuery = (
  { __typename?: 'Query' }
  & { getComplianceMatrixPromptTemplate?: Maybe<(
    { __typename?: 'GqlPromptTemplate' }
    & Pick<GqlPromptTemplate, 'id' | 'uuid' | 'name'>
    & { schema?: Maybe<(
      { __typename?: 'GqlPromptTemplateSchema' }
      & Pick<GqlPromptTemplateSchema, 'label' | 'description' | 'guide'>
      & { fields: Array<Maybe<(
        { __typename?: 'GqlPromptTemplateSchemaField' }
        & Pick<GqlPromptTemplateSchemaField, 'key' | 'type' | 'label' | 'guide' | 'placeholder' | 'required' | 'defaultValue'>
      )>>, additionalInstructions?: Maybe<(
        { __typename?: 'GqlPromptTemplateSchemaAdditionalInstructions' }
        & Pick<GqlPromptTemplateSchemaAdditionalInstructions, 'label' | 'guide'>
      )> }
    )> }
  )> }
);

export type GetDocumentChatQueryVariables = Exact<{
  documentChatUUId: Scalars['String'];
}>;


export type GetDocumentChatQuery = (
  { __typename?: 'Query' }
  & { getDocumentChat: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'chat' | 'lockUntil' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type GetDocumentChatsBySourcesKeyQueryVariables = Exact<{
  sourcesKey: Scalars['String'];
}>;


export type GetDocumentChatsBySourcesKeyQuery = (
  { __typename?: 'Query' }
  & { getDocumentChatsBySourcesKey: Array<(
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
    & { createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GetOpportunityChatOptionsQueryVariables = Exact<{
  opportunityUUId?: Maybe<Scalars['String']>;
  proposalDocumentUUId?: Maybe<Scalars['String']>;
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
}>;


export type GetOpportunityChatOptionsQuery = (
  { __typename?: 'Query' }
  & { getOpportunityChatOptions: Array<(
    { __typename?: 'GqlOpportunityOption' }
    & Pick<GqlOpportunityOption, 'kind'>
    & { document?: Maybe<(
      { __typename?: 'GqlOpportunityDocumentOption' }
      & Pick<GqlOpportunityDocumentOption, 'documentUrl' | 'associationKind' | 'documentId' | 'listingUrl' | 'documentIdentifierHelper'>
    )>, unreachableDocument?: Maybe<(
      { __typename?: 'GqlOpportunityUnreachableDocumentOption' }
      & Pick<GqlOpportunityUnreachableDocumentOption, 'documentUrl' | 'associationKind' | 'documentId' | 'listingUrl' | 'documentIdentifierHelper' | 'reason'>
    )>, listing?: Maybe<(
      { __typename?: 'GqlOpportunityListingOption' }
      & Pick<GqlOpportunityListingOption, 'listingUrl' | 'listingTitle' | 'associatedDocumentUrls' | 'associationKind' | 'legacyAssociationKind' | 'privOppSourceUUId' | 'privOppSourceName' | 'listingIdentifierHelper'>
    )>, sharePointSite?: Maybe<(
      { __typename?: 'GqlOpportunitySharePointSite' }
      & Pick<GqlOpportunitySharePointSite, 'name' | 'siteId' | 'associatedDocumentUrls' | 'documentUrlsToFolderPaths'>
    )>, sharePointSiteError?: Maybe<(
      { __typename?: 'GqlOpportunitySharePointSiteError' }
      & Pick<GqlOpportunitySharePointSiteError, 'message'>
    )>, sharePointSiteNotSignedIn?: Maybe<(
      { __typename?: 'GqlOpportunitySharePointSiteNotSignedIn' }
      & Pick<GqlOpportunitySharePointSiteNotSignedIn, 'name' | 'siteId'>
    )> }
  )> }
);

export type GetPromptUsageQueryVariables = Exact<{
  promptUsageUUId: Scalars['String'];
}>;


export type GetPromptUsageQuery = (
  { __typename?: 'Query' }
  & { getPromptUsage: (
    { __typename?: 'GqlPromptUsage' }
    & Pick<GqlPromptUsage, 'id' | 'uuid' | 'name' | 'inputs' | 'additionalInstructions'>
    & { chats: Array<Maybe<(
      { __typename?: 'GqlDocumentChat' }
      & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type ModifyOpportunityChatOptionMutationVariables = Exact<{
  documentChatUUId: Scalars['String'];
  request: GqlOpportunityOptionRequest;
  mode: Scalars['String'];
  authorization?: Maybe<GqlDocumentChatAuthorizationContext>;
}>;


export type ModifyOpportunityChatOptionMutation = (
  { __typename?: 'Mutation' }
  & { modifyOpportunityChatOption: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type QueryWithProposalContentDocumentChatStreamingMutationVariables = Exact<{
  request: GqlQueryWithProposalContentRequest;
  documentChatUUId: Scalars['String'];
}>;


export type QueryWithProposalContentDocumentChatStreamingMutation = (
  { __typename?: 'Mutation' }
  & { queryWithProposalContentDocumentChatStreaming: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RewritePromptDocumentChatStreamingMutationVariables = Exact<{
  request: GqlProposalPromptRewriteRequest;
  documentChatUUId: Scalars['String'];
}>;


export type RewritePromptDocumentChatStreamingMutation = (
  { __typename?: 'Mutation' }
  & { rewritePromptDocumentChatStreaming: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type StreamComplianceMatrixJobSubscriptionVariables = Exact<{
  complianceMatrixJobUUId: Scalars['String'];
}>;


export type StreamComplianceMatrixJobSubscription = (
  { __typename?: 'Subscription' }
  & { streamComplianceMatrixJob: (
    { __typename?: 'GqlComplianceMatrixGenerationJob' }
    & Pick<GqlComplianceMatrixGenerationJob, 'uuid' | 'status' | 'subscriptionError' | 'updatedAt'>
  ) }
);

export type StreamDocumentChatQuerySubscriptionVariables = Exact<{
  documentChatUUId: Scalars['String'];
}>;


export type StreamDocumentChatQuerySubscription = (
  { __typename?: 'Subscription' }
  & { streamChatDocumentChatQuery: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'subscriptionError' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateDocumentChatDocumentsMutationVariables = Exact<{
  documents: Array<GqlChatDocumentRequest> | GqlChatDocumentRequest;
  documentChatUUId: Scalars['String'];
}>;


export type UpdateDocumentChatDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentChatDocuments: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateDocumentChatTitleMutationVariables = Exact<{
  documentChatUUId: Scalars['String'];
  title: Scalars['String'];
}>;


export type UpdateDocumentChatTitleMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentChatTitle: (
    { __typename?: 'GqlDocumentChat' }
    & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'chat' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdatePromptUsageMutationVariables = Exact<{
  promptUsageUUId: Scalars['String'];
  additionalUserInstructions?: Maybe<Scalars['String']>;
  documentChatUUId: Scalars['String'];
}>;


export type UpdatePromptUsageMutation = (
  { __typename?: 'Mutation' }
  & { updatePromptUsage: (
    { __typename?: 'GqlPromptUsage' }
    & Pick<GqlPromptUsage, 'id' | 'uuid' | 'name' | 'inputs' | 'additionalInstructions'>
    & { chats: Array<Maybe<(
      { __typename?: 'GqlDocumentChat' }
      & Pick<GqlDocumentChat, 'documentChatUUId' | 'title' | 'lockUntil' | 'createdByUserId' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type ConvertToTaskOrderMutationVariables = Exact<{
  searchOppUUId: Scalars['String'];
}>;


export type ConvertToTaskOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'convertToTaskOrder'>
);

export type DeleteKeywordRuleMutationVariables = Exact<{
  searchOppUUId: Scalars['String'];
  ruleUUIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DeleteKeywordRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteKeywordRule'>
);

export type DeleteTaskOrderMutationVariables = Exact<{
  searchOppUUId: Scalars['String'];
}>;


export type DeleteTaskOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaskOrder'>
);

export type ExcludeEmailMutationVariables = Exact<{
  searchOppUUId: Scalars['String'];
  baseOppUUId: Scalars['String'];
}>;


export type ExcludeEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'excludeEmail'>
);

export type GetEmailEndpointsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailEndpointsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailEndpoints'>
);

export type GetOpportunitiesWithSearchOppQueryVariables = Exact<{
  searchOppUUId: Scalars['String'];
}>;


export type GetOpportunitiesWithSearchOppQuery = (
  { __typename?: 'Query' }
  & { getOpportunitiesWithSearchOpp: Array<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'canonId' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'name'>
    ) }
  )> }
);

export type GetOppRulesQueryVariables = Exact<{
  searchOppUUId: Scalars['String'];
}>;


export type GetOppRulesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOppRules'>
);

export type GetTaskOrderSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskOrderSourcesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTaskOrderSources'>
);

export type UpsertKeywordRuleMutationVariables = Exact<{
  searchOppUUId: Scalars['String'];
  keyword: Scalars['String'];
}>;


export type UpsertKeywordRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertKeywordRule'>
);

export type MeFragment = (
  { __typename?: 'MeGraphQLType' }
  & Pick<MeGraphQlType, 'id' | 'userId' | 'email' | 'fullName' | 'firstName' | 'lastName' | 'isVerifiedEmail' | 'isActive' | 'mfaMethods' | 'TOBEREMOVED_pipelinesWithUserManagementEnabled' | 'TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess' | 'emailPreferences' | 'workspaceId'>
  & { permissions: Array<(
    { __typename?: 'ResourcePermissionGraphQLType' }
    & Pick<ResourcePermissionGraphQlType, 'includeResourceId' | 'excludeResourceId' | 'includeOperationType' | 'excludeOperationType' | 'includeResourceType' | 'excludeResourceType'>
  )>, defaultPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId'>
  )>, defaultPipelineView?: Maybe<(
    { __typename?: 'PipelineView' }
    & Pick<PipelineView, 'id' | 'name'>
  )>, defaultTimelineView?: Maybe<(
    { __typename?: 'TimelineViewNexusType' }
    & Pick<TimelineViewNexusType, 'id' | 'timelineViewUUId' | 'name'>
  )>, userWorkspaces?: Maybe<Array<Maybe<(
    { __typename?: 'UserWorkspace' }
    & Pick<UserWorkspace, 'id' | 'workspaceName' | 'workspaceId' | 'workspaceUUId'>
  )>>>, workspace: (
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'name' | 'workspaceId' | 'freeTrial' | 'isPayingCustomer' | 'alexandria'>
  ), role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'scope' | 'crudType'>
    )> }
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { getMe: (
    { __typename?: 'MeGraphQLType' }
    & MeFragment
  ) }
);

export type GetAuthInfoQueryVariables = Exact<{
  anonymousId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitationToken?: Maybe<Scalars['String']>;
  mode?: Maybe<AuthMode>;
  recaptchaToken?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
}>;


export type GetAuthInfoQuery = (
  { __typename?: 'Query' }
  & { getAuthInfo: (
    { __typename?: 'AuthInfo' }
    & Pick<AuthInfo, 'allowPasswordAuth' | 'allowMicrosoftSSO' | 'allowGoogleSSO' | 'inviteOnly' | 'isExistingAccount' | 'isVerifiedEmail' | 'hasPendingInvite'>
    & { authUrls: Array<(
      { __typename?: 'AuthUrl' }
      & Pick<AuthUrl, 'type' | 'url'>
    )> }
  ) }
);

export type GetJwtQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJwtQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getJWT'>
);

export type CaptchaQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CaptchaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'captcha'>
);

export type WhitelistQueryVariables = Exact<{ [key: string]: never; }>;


export type WhitelistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'whitelist'>
);

export type CreateCaptureTeamMutationVariables = Exact<{
  oppId: Scalars['Int'];
  name: Scalars['String'];
  isProposalContributor?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCaptureTeamMutation = (
  { __typename?: 'Mutation' }
  & { createCaptureTeam?: Maybe<(
    { __typename?: 'CaptureTeam' }
    & Pick<CaptureTeam, 'id'>
  )> }
);

export type DeleteCaptureTeamMutationVariables = Exact<{
  teamName: Scalars['String'];
  pipelineId: Scalars['Int'];
}>;


export type DeleteCaptureTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCaptureTeam'>
);

export type UpdateCaptureTeamMutationVariables = Exact<{
  oppId: Scalars['Int'];
  teamName: Scalars['String'];
  users?: Maybe<Scalars['Json']>;
  isProposalContributor?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateCaptureTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateCaptureTeam?: Maybe<(
    { __typename?: 'CaptureTeam' }
    & Pick<CaptureTeam, 'id' | 'name' | 'isProposalContributor' | 'privOppId'>
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type UpdateCaptureTeamsMutationVariables = Exact<{
  pipelineId: Scalars['Int'];
  captureTeams: Scalars['Json'];
}>;


export type UpdateCaptureTeamsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCaptureTeams'>
);

export type RemoveContactTagToContactMutationVariables = Exact<{
  contactUUId: Scalars['String'];
  contactTagUUId: Scalars['String'];
}>;


export type RemoveContactTagToContactMutation = (
  { __typename?: 'Mutation' }
  & { removeContactTagToContact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id'>
  )> }
);

export type AddContactTagToContactMutationVariables = Exact<{
  contactUUId: Scalars['String'];
  contactTagUUId: Scalars['String'];
}>;


export type AddContactTagToContactMutation = (
  { __typename?: 'Mutation' }
  & { addContactTagToContact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id'>
  )> }
);

export type CreateOrUpdatePrivContactMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  contactOwnerId?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  linkedIn?: Maybe<Scalars['String']>;
}>;


export type CreateOrUpdatePrivContactMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdatePrivContact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'email' | 'department' | 'workPhone' | 'mobilePhone' | 'title' | 'metadata' | 'privOrgId'>
    & { privOrg?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'name'>
    )> }
  )> }
);

export type GetContactTasksQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetContactTasksQuery = (
  { __typename?: 'Query' }
  & { getContact: (
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'contactId'>
    & { tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  ) }
);

export type GetContactsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  LEGACY_ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { getContacts: (
    { __typename?: 'GetContactsResolverResult' }
    & Pick<GetContactsResolverResult, 'total'>
    & { contacts: Array<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'department' | 'title' | 'workPhone' | 'mobilePhone' | 'linkedIn' | 'metadata' | 'contactOwnerId' | 'managerId' | 'privOrgId' | 'relatedOpps' | 'searchOpps' | 'documents'>
      & { tags: Array<(
        { __typename?: 'ContactTag' }
        & Pick<ContactTag, 'id' | 'name'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
        & { org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'documents' | 'createdAt'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        )>, stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )>, captureTeam?: Maybe<(
          { __typename?: 'CaptureTeam' }
          & Pick<CaptureTeam, 'id' | 'name'>
        )>, subtasks: Array<(
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
          & { org?: Maybe<(
            { __typename?: 'PrivOrg' }
            & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
          )>, contact?: Maybe<(
            { __typename?: 'PrivContact' }
            & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
          )>, assignees: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      )>>>, notes?: Maybe<Array<Maybe<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )> }
      )>>>, contactOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, manager?: Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
      )>, privOrg?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'industry' | 'type'>
        & { orgTags: Array<(
          { __typename?: 'OrgTag' }
          & Pick<OrgTag, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type GetContactsFilterOptionsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetContactsFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { getContactsFilterOptions: (
    { __typename?: 'GetContactsFilterOptionsResolverResult' }
    & Pick<GetContactsFilterOptionsResolverResult, 'orgIndustry' | 'orgNames' | 'orgTags' | 'tags' | 'cursor'>
  ) }
);

export type GetContactsPaginationQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgIndustries?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetContactsPaginationQuery = (
  { __typename?: 'Query' }
  & { getContactsPagination: (
    { __typename?: 'GetContactsPaginationResolverResult' }
    & Pick<GetContactsPaginationResolverResult, 'total' | 'order'>
  ) }
);

export type UploadContactsOrgsMutationVariables = Exact<{
  data?: Maybe<Scalars['Json']>;
}>;


export type UploadContactsOrgsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadContactsOrgs'>
);

export type SearchPrivContactQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type SearchPrivContactQuery = (
  { __typename?: 'Query' }
  & { searchPrivContact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName' | 'email'>
  )> }
);

export type SearchContactsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgIndustries?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchContactsQuery = (
  { __typename?: 'Query' }
  & { searchContacts: (
    { __typename?: 'SearchContactsResolverResult' }
    & Pick<SearchContactsResolverResult, 'total'>
    & { contacts: Array<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'department' | 'title' | 'workPhone' | 'mobilePhone' | 'linkedIn' | 'metadata' | 'contactOwnerId' | 'managerId' | 'privOrgId' | 'relatedOpps' | 'searchOpps' | 'documents'>
      & { tags: Array<(
        { __typename?: 'ContactTag' }
        & Pick<ContactTag, 'id' | 'name'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
        & { org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'documents' | 'createdAt'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        )>, stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )>, captureTeam?: Maybe<(
          { __typename?: 'CaptureTeam' }
          & Pick<CaptureTeam, 'id' | 'name'>
        )>, subtasks: Array<(
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
          & { org?: Maybe<(
            { __typename?: 'PrivOrg' }
            & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
          )>, contact?: Maybe<(
            { __typename?: 'PrivContact' }
            & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
          )>, assignees: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      )>>>, notes?: Maybe<Array<Maybe<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>>>, contactOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, manager?: Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
      )>, privOrg?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'industry'>
      )> }
    )> }
  ) }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id'>
  )> }
);

export type AddContactTagMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddContactTagMutation = (
  { __typename?: 'Mutation' }
  & { addContactTag?: Maybe<(
    { __typename?: 'ContactTag' }
    & Pick<ContactTag, 'id' | 'name'>
  )> }
);

export type UpdateContactTagMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateContactTagMutation = (
  { __typename?: 'Mutation' }
  & { updateContactTag?: Maybe<(
    { __typename?: 'ContactTag' }
    & Pick<ContactTag, 'id' | 'name'>
  )> }
);

export type DeleteContactTagMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteContactTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteContactTag?: Maybe<(
    { __typename?: 'ContactTag' }
    & Pick<ContactTag, 'id' | 'name'>
  )> }
);

export type CreateContactOnPrivOppsMutationVariables = Exact<{
  role: Scalars['String'];
  privOppUUId: Scalars['String'];
  contactUUId: Scalars['String'];
}>;


export type CreateContactOnPrivOppsMutation = (
  { __typename?: 'Mutation' }
  & { createContactOnPrivOpps?: Maybe<(
    { __typename?: 'ContactOnPrivOpps' }
    & Pick<ContactOnPrivOpps, 'id' | 'role'>
    & { contact?: Maybe<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type GetContactTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactTagsQuery = (
  { __typename?: 'Query' }
  & { getContactTags: Array<Maybe<(
    { __typename?: 'ContactTag' }
    & Pick<ContactTag, 'id' | 'name'>
  )>> }
);

export type AddSectionMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionName: Scalars['String'];
}>;


export type AddSectionMutation = (
  { __typename?: 'Mutation' }
  & { addSection?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type RenameSectionMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
}>;


export type RenameSectionMutation = (
  { __typename?: 'Mutation' }
  & { renameSection?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type RemoveSectionMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
}>;


export type RemoveSectionMutation = (
  { __typename?: 'Mutation' }
  & { removeSection?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type ReorderSectionMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  destination: Scalars['Int'];
}>;


export type ReorderSectionMutation = (
  { __typename?: 'Mutation' }
  & { reorderSection?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type ReorderFieldMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  field: Scalars['String'];
  destination: Scalars['Int'];
}>;


export type ReorderFieldMutation = (
  { __typename?: 'Mutation' }
  & { reorderField?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type AddFieldMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  field: Scalars['String'];
}>;


export type AddFieldMutation = (
  { __typename?: 'Mutation' }
  & { addField?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type RemoveFieldMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  sectionId: Scalars['String'];
  field: Scalars['String'];
}>;


export type RemoveFieldMutation = (
  { __typename?: 'Mutation' }
  & { removeField?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'dataSummaryTemplate'>
  )> }
);

export type GetUploadLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type GetUploadLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getUploadLink'>
);

export type DeleteDocsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDocsMutation = (
  { __typename?: 'Mutation' }
  & { pubDeleteDocs?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  )> }
);

export type CreateDocsMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  spId?: Maybe<Scalars['String']>;
}>;


export type CreateDocsMutation = (
  { __typename?: 'Mutation' }
  & { pubUpdateDocs?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'link'>
  )> }
);

export type GetEventsQueryVariables = Exact<{
  oppId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { getEvents?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'eventTs' | 'eventCode' | 'eventProps'>
    & { note?: Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'payload' | 'privOppId' | 'privOrgId' | 'createdAt'>
      & { contacts: Array<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'email' | 'title'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, privOpp?: Maybe<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'canonId' | 'name' | 'pipelineId'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName' | 'email'>
    )> }
  )>>> }
);

export type QueryPlacesQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type QueryPlacesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'queryPlaces'>
);

export type CreateNoteMutationVariables = Exact<{
  payload: Scalars['Json'];
  payloadType: NotePayloadType;
  noteType: NoteType;
  contactIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  privOppId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['String']>;
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'payload' | 'payloadType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'taskId' | 'createdAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['String'];
  payload?: Maybe<Scalars['Json']>;
  payloadType?: Maybe<NotePayloadType>;
  noteType?: Maybe<NoteType>;
  contactIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  privOrgId?: Maybe<Scalars['Int']>;
  privOppId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['String']>;
}>;


export type UpdateNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'payload' | 'payloadType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'taskId' | 'createdAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CreateOpportunityMutationVariables = Exact<{
  name: Scalars['String'];
  pipelineId: Scalars['String'];
  captureManagerId?: Maybe<Scalars['String']>;
  captureTeamIds?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  stageId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  initialMetadata?: Maybe<Scalars['Json']>;
  externalSourceId?: Maybe<Scalars['String']>;
  externalSourceType?: Maybe<ExternalSourceNexusType>;
}>;


export type CreateOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { createOpportunity?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'pipelineId'>
  )> }
);

export type GetDocumentQueryVariables = Exact<{
  path: Scalars['String'];
  fileName: Scalars['String'];
  source?: Maybe<ExternalSourceNexusType>;
  docType?: Maybe<Scalars['String']>;
}>;


export type GetDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocument'>
);

export type GetDocumentRequestLinkQueryVariables = Exact<{
  documentRequestURL: Scalars['String'];
}>;


export type GetDocumentRequestLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocumentRequestLink'>
);

export type GetOppDocsQueryVariables = Exact<{
  canonId: Scalars['String'];
}>;


export type GetOppDocsQuery = (
  { __typename?: 'Query' }
  & { getPrivOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'documents'>
  )> }
);

export type GetOpportunitiesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  estRfp?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  paged?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type GetOpportunitiesQuery = (
  { __typename?: 'Query' }
  & { getOpportunities: (
    { __typename?: 'GetOpportunitiesResolverResult' }
    & Pick<GetOpportunitiesResolverResult, 'total' | 'order'>
    & { opps: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId' | 'isArchived' | 'isBid' | 'canonId' | 'name' | 'createdAt' | 'metadata' | 'govWinId'>
      & { client?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
      )>, captureTeam: Array<(
        { __typename?: 'CaptureTeam' }
        & Pick<CaptureTeam, 'id' | 'name'>
        & { users: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
        )> }
      )>, tags: Array<(
        { __typename?: 'OppTag' }
        & Pick<OppTag, 'id' | 'tagId' | 'name' | 'color'>
      )>, pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
        & { stages: Array<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'stageId' | 'name' | 'stageIndex'>
        )> }
      ), privOppStage?: Maybe<(
        { __typename?: 'PrivOppStage' }
        & Pick<PrivOppStage, 'id'>
        & { stage: (
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'stageId' | 'name'>
        ) }
      )>, tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'parentId' | 'description' | 'status' | 'dueDate'>
        & { stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )> }
      )> }
    )> }
  ) }
);

export type GetOpportunityTasksQueryVariables = Exact<{
  canonId: Scalars['String'];
}>;


export type GetOpportunityTasksQuery = (
  { __typename?: 'Query' }
  & { getPrivOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )> }
  )> }
);

export type GetPipelineOpportunitiesQueryVariables = Exact<{
  pipelineUUId: Scalars['String'];
  uuids?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  estimatedRFP?: Maybe<Scalars['String']>;
  hasProposal?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;


export type GetPipelineOpportunitiesQuery = (
  { __typename?: 'Query' }
  & { searchPipelineOpportunities: (
    { __typename?: 'GetPipelineOpportunitiesResolverResult' }
    & Pick<GetPipelineOpportunitiesResolverResult, 'total'>
    & { results: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId' | 'isArchived' | 'canonId' | 'name'>
    )> }
  ) }
);

export type GetPrivOppQueryVariables = Exact<{
  canonId: Scalars['String'];
}>;


export type GetPrivOppQuery = (
  { __typename?: 'Query' }
  & { getPrivOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'pipelineId' | 'canonId' | 'name' | 'currentScores' | 'isBid' | 'isWin' | 'isArchived' | 'metadata' | 'solType' | 'solicitationDetails' | 'rfiDetails' | 'histAwardDetails' | 'sol' | 'internalSolId' | 'award' | 'internalAwardId' | 'cachedGovWinDetails' | 'prevSol' | 'internalPrevSolId' | 'documents' | 'oppScoreTs' | 'winningPrice' | 'lossReason' | 'govWinId' | 'scoreTemplateId'>
    & { scoreTemplate?: Maybe<(
      { __typename?: 'ScoreTemplate' }
      & Pick<ScoreTemplate, 'id'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreTemplateCategory' }
        & Pick<ScoreTemplateCategory, 'id' | 'position' | 'scoreTemplateCategoryId' | 'name'>
        & { fields?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreTemplateField' }
          & Pick<ScoreTemplateField, 'id' | 'index' | 'description' | 'name' | 'range' | 'scoreTemplateFieldId' | 'weight'>
        )>>> }
      )>>> }
    )>, scoreValues?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreFieldValue' }
      & Pick<ScoreFieldValue, 'id' | 'value' | 'fieldId' | 'scoreFieldValueId'>
    )>>>, client?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, tags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'color' | 'name'>
    )>, sources?: Maybe<Array<(
      { __typename?: 'PrivOppSourceNexusType' }
      & Pick<PrivOppSourceNexusType, 'privOppSourceUUId' | 'label' | 'sourceType' | 'source'>
    )>>, teams: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'teamId' | 'incumbentTeam' | 'isWinningTeam' | 'workshare' | 'workspaceTeam' | 'nda' | 'ta' | 'primeId' | 'privOppId' | 'privOrgId'>
      & { prime?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'teamId'>
      )>, privOrg?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName'>
      )>>>, privContacts?: Maybe<Array<Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName'>
      )>>>, subcontractors?: Maybe<Array<Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'teamId' | 'incumbentTeam' | 'isWinningTeam' | 'privOrgId' | 'workspaceTeam' | 'nda'>
        & { prime?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'teamId'>
        )>, privOrg?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'name'>
        )>, contacts?: Maybe<Array<Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName'>
        )>>>, privContacts?: Maybe<Array<Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName'>
        )>>> }
      )>>> }
    )>, winner?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'name' | 'orgId'>
    )>, events?: Maybe<Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'eventTs' | 'eventCode' | 'eventProps' | 'captureTeamId' | 'documentId' | 'taskId' | 'privOppId' | 'userFirstName' | 'userLastName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'fullName'>
      )> }
    )>>>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'customFields' | 'captureTeamTemplate' | 'dataSummaryTemplate'>
      & { oppTags: Array<(
        { __typename?: 'OppTag' }
        & Pick<OppTag, 'id' | 'color' | 'name'>
      )>, stages: Array<(
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name' | 'stageId' | 'stageIndex'>
      )>, scoreTemplates: Array<(
        { __typename?: 'ScoreTemplate' }
        & Pick<ScoreTemplate, 'id' | 'scoreTemplateId'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreTemplateCategory' }
          & Pick<ScoreTemplateCategory, 'id' | 'position' | 'scoreTemplateCategoryId' | 'name'>
          & { fields?: Maybe<Array<Maybe<(
            { __typename?: 'ScoreTemplateField' }
            & Pick<ScoreTemplateField, 'id' | 'index' | 'description' | 'name' | 'range' | 'scoreTemplateId' | 'scoreTemplateFieldId' | 'weight'>
          )>>> }
        )>>>, fields?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreTemplateField' }
          & Pick<ScoreTemplateField, 'id' | 'scoreTemplateFieldId' | 'description' | 'name'>
        )>>> }
      )>, updateTemplates: Array<(
        { __typename?: 'UpdateTemplate' }
        & Pick<UpdateTemplate, 'id' | 'updateTemplateId' | 'payload' | 'payloadV2'>
      )> }
    ), privOppStage?: Maybe<(
      { __typename?: 'PrivOppStage' }
      & Pick<PrivOppStage, 'id' | 'stageId'>
      & { stage: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name' | 'stageId'>
      ) }
    )>, stage?: Maybe<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'name' | 'taskConfig'>
    )>, proposalStage?: Maybe<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt' | 'parentId'>
      & { parent?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'taskId' | 'description'>
      )>, assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName' | 'email'>
      )>, stage?: Maybe<(
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name' | 'stageIndex'>
      )>, captureTeam?: Maybe<(
        { __typename?: 'CaptureTeam' }
        & Pick<CaptureTeam, 'id' | 'name'>
      )>, subtasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'taskId' | 'createdAt' | 'description' | 'status' | 'dueDate' | 'completedAt' | 'parentId'>
        & { assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        )>, stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )> }
      )> }
    )>, notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt' | 'createdByFirstName' | 'createdByLastName' | 'documents'>
      & { contacts: Array<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, governmentContacts?: Maybe<Array<Maybe<(
      { __typename?: 'ContactOnPrivOpps' }
      & Pick<ContactOnPrivOpps, 'id' | 'role'>
      & { contact?: Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'title' | 'email' | 'metadata'>
      )> }
    )>>>, teamingPartners: Array<(
      { __typename?: 'PrivOrgsOnPrivOpps' }
      & Pick<PrivOrgsOnPrivOpps, 'id' | 'role'>
      & { teamMembers?: Maybe<Array<Maybe<(
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'id' | 'nda' | 'ta' | 'workshare'>
        & { privOrg?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'name'>
        )>, contacts?: Maybe<Array<Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'fullName'>
        )>>> }
      )>>>, privOrgs?: Maybe<Array<Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'name'>
      )>>> }
    )>, captureTeam: Array<(
      { __typename?: 'CaptureTeam' }
      & Pick<CaptureTeam, 'id' | 'name'>
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isZombie'>
      )> }
    )>, updates: Array<(
      { __typename?: 'Update' }
      & Pick<Update, 'id' | 'updateId' | 'hasUpdateV2Payload'>
    )> }
  )> }
);

export type SearchPrivOppQueryVariables = Exact<{
  type: Scalars['String'];
  pubOppMetaData?: Maybe<Scalars['Json']>;
  pipelineId: Scalars['Int'];
  listing?: Maybe<Scalars['Json']>;
}>;


export type SearchPrivOppQuery = (
  { __typename?: 'Query' }
  & { searchPrivOpp?: Maybe<Array<Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'pipelineId'>
  )>>> }
);

export type DuplicateOppMutationVariables = Exact<{
  oppId: Scalars['Int'];
}>;


export type DuplicateOppMutation = (
  { __typename?: 'Mutation' }
  & { duplicateOpportunity?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'pipelineId'>
  )> }
);

export type PubPrivOppUpdateMutationVariables = Exact<{
  oppId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  sol?: Maybe<Scalars['Json']>;
  award?: Maybe<Scalars['Json']>;
  prevSol?: Maybe<Scalars['Json']>;
  updateCustomFields?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type PubPrivOppUpdateMutation = (
  { __typename?: 'Mutation' }
  & { pubWorkspacePrivOppUpdate?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'award' | 'sol' | 'prevSol'>
    & { sources?: Maybe<Array<(
      { __typename?: 'PrivOppSourceNexusType' }
      & Pick<PrivOppSourceNexusType, 'privOppSourceUUId' | 'label' | 'sourceType' | 'source'>
    )>> }
  )> }
);

export type PubPrivOppUpdateGovContactsMutationVariables = Exact<{
  id: Scalars['Int'];
  privContactId?: Maybe<Scalars['Int']>;
}>;


export type PubPrivOppUpdateGovContactsMutation = (
  { __typename?: 'Mutation' }
  & { pubPrivOppUpdateGovContacts?: Maybe<(
    { __typename?: 'ContactOnPrivOpps' }
    & Pick<ContactOnPrivOpps, 'id'>
  )> }
);

export type PubPrivOppDeleteGovContactsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PubPrivOppDeleteGovContactsMutation = (
  { __typename?: 'Mutation' }
  & { pubPrivOppDeleteGovContacts?: Maybe<(
    { __typename?: 'ContactOnPrivOpps' }
    & Pick<ContactOnPrivOpps, 'id'>
  )> }
);

export type PubAddTaskMutationVariables = Exact<{
  stageId?: Maybe<Scalars['Int']>;
  oppId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['Json']>;
}>;


export type PubAddTaskMutation = (
  { __typename?: 'Mutation' }
  & { pubWorkspaceAddTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'taskId' | 'description' | 'dueDate' | 'status'>
    & { org?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
    )>, privOpp?: Maybe<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'canonId' | 'oppId' | 'name' | 'pipelineId'>
      & { pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'name' | 'customFields'>
      ), privOppStage?: Maybe<(
        { __typename?: 'PrivOppStage' }
        & Pick<PrivOppStage, 'id'>
        & { stage: (
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name'>
        ) }
      )> }
    )>, stage?: Maybe<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'name'>
    )>, assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type PubDeleteTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PubDeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { pubWorkSpaceDeleteTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type PubUpdateTaskMutationVariables = Exact<{
  taskId: Scalars['String'];
  task: Scalars['Json'];
  contactId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  oppId?: Maybe<Scalars['String']>;
}>;


export type PubUpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { pubWorkspaceUpdateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'taskId' | 'description' | 'status' | 'dueDate' | 'createdAt'>
    & { org?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
    )>, privOpp?: Maybe<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'name' | 'pipelineId'>
    )>, assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'createdAt'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetGovWinPrivOppQueryVariables = Exact<{
  canonId: Scalars['String'];
}>;


export type GetGovWinPrivOppQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGovWinPrivOpp'>
);

export type GetGovWinOppQueryVariables = Exact<{
  govWinId: Scalars['String'];
}>;


export type GetGovWinOppQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGovWinOpp'>
);

export type CreatePrivOrgMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  samId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  workspaceOrg?: Maybe<Scalars['Boolean']>;
}>;


export type CreatePrivOrgMutation = (
  { __typename?: 'Mutation' }
  & { createPrivOrg?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'samId' | 'type' | 'description' | 'industry' | 'website' | 'phoneNumber' | 'addresses' | 'thumbnail' | 'metadata'>
    & { accountOwner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'userId' | 'firstName' | 'lastName'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'email' | 'title' | 'metadata'>
    )>>>, notes?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>>> }
  )> }
);

export type DeletePrivOrgMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePrivOrgMutation = (
  { __typename?: 'Mutation' }
  & { deletePrivOrg?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id'>
  )> }
);

export type GetOrganizationTasksQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetOrganizationTasksQuery = (
  { __typename?: 'Query' }
  & { getOrganization?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId'>
    & { tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type GetPrivOrgsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  LEGACY_ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetPrivOrgsQuery = (
  { __typename?: 'Query' }
  & { getPrivOrgs: (
    { __typename?: 'GetPrivOrgsResolverResult' }
    & Pick<GetPrivOrgsResolverResult, 'total'>
    & { orgs: Array<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'samId' | 'name' | 'type' | 'description' | 'industry' | 'website' | 'phoneNumber' | 'addresses' | 'thumbnail' | 'metadata' | 'tags' | 'relatedOpps' | 'workspaceOrg' | 'documents'>
      & { parentOrganization?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'name'>
      )>, orgTags: Array<(
        { __typename?: 'OrgTag' }
        & Pick<OrgTag, 'id' | 'name'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
        & { org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'createdAt'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        )>, stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )>, captureTeam?: Maybe<(
          { __typename?: 'CaptureTeam' }
          & Pick<CaptureTeam, 'id' | 'name'>
        )>, subtasks: Array<(
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
          & { org?: Maybe<(
            { __typename?: 'PrivOrg' }
            & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
          )>, contact?: Maybe<(
            { __typename?: 'PrivContact' }
            & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
          )>, assignees: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      )>>>, selfOrg?: Maybe<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'id' | 'workspaceId'>
      )>, accountOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'email' | 'department' | 'title' | 'workPhone' | 'mobilePhone' | 'linkedIn' | 'metadata'>
      )>>>, notes?: Maybe<Array<Maybe<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>>> }
    )> }
  ) }
);

export type GetPrivOrgsFilterOptionsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetPrivOrgsFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { getPrivOrgsFilterOptions: (
    { __typename?: 'GetPrivOrgsFilterOptionsResolverResult' }
    & Pick<GetPrivOrgsFilterOptionsResolverResult, 'industry' | 'tags' | 'types' | 'accountOwnersFirstLastSeparatedByColonColon' | 'cursor'>
  ) }
);

export type GetPrivOrgsPaginationQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  accountOwnersFirstLastSeparatedByColonColon?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetPrivOrgsPaginationQuery = (
  { __typename?: 'Query' }
  & { getPrivOrgsPagination: (
    { __typename?: 'GetPrivOrgsPaginationResolverResult' }
    & Pick<GetPrivOrgsPaginationResolverResult, 'total' | 'order'>
  ) }
);

export type SearchPrivOrgsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  accountOwnersFirstLastSeparatedByColonColon?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchPrivOrgsQuery = (
  { __typename?: 'Query' }
  & { searchPrivOrgs: (
    { __typename?: 'SearchPrivOrgsResolverResult' }
    & Pick<SearchPrivOrgsResolverResult, 'total'>
    & { orgs: Array<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'samId' | 'name' | 'type' | 'description' | 'industry' | 'website' | 'phoneNumber' | 'addresses' | 'thumbnail' | 'metadata' | 'tags' | 'relatedOpps' | 'workspaceOrg' | 'documents'>
      & { parentOrganization?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'name'>
      )>, orgTags: Array<(
        { __typename?: 'OrgTag' }
        & Pick<OrgTag, 'id' | 'name'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
        & { org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'documents' | 'createdAt'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        )>, stage?: Maybe<(
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'name' | 'stageIndex'>
        )>, captureTeam?: Maybe<(
          { __typename?: 'CaptureTeam' }
          & Pick<CaptureTeam, 'id' | 'name'>
        )>, subtasks: Array<(
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'status' | 'dueDate' | 'completedAt'>
          & { org?: Maybe<(
            { __typename?: 'PrivOrg' }
            & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
          )>, contact?: Maybe<(
            { __typename?: 'PrivContact' }
            & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
          )>, assignees: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      )>>>, selfOrg?: Maybe<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'id' | 'workspaceId'>
      )>, accountOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName' | 'email' | 'department' | 'title' | 'workPhone' | 'mobilePhone' | 'linkedIn' | 'metadata'>
      )>>>, notes?: Maybe<Array<Maybe<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>>> }
    )> }
  ) }
);

export type UpdatePrivOrgMutationVariables = Exact<{
  orgId: Scalars['Int'];
  accountOwnerId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  samId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  tags?: Maybe<Scalars['Json']>;
  parentOrganizationId?: Maybe<Scalars['String']>;
}>;


export type UpdatePrivOrgMutation = (
  { __typename?: 'Mutation' }
  & { updatePrivOrg?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId'>
  )> }
);

export type AddOrgTagMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddOrgTagMutation = (
  { __typename?: 'Mutation' }
  & { addOrgTag?: Maybe<(
    { __typename?: 'OrgTag' }
    & Pick<OrgTag, 'id' | 'name'>
  )> }
);

export type UpdateOrgTagMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateOrgTagMutation = (
  { __typename?: 'Mutation' }
  & { updateOrgTag?: Maybe<(
    { __typename?: 'OrgTag' }
    & Pick<OrgTag, 'id' | 'name'>
  )> }
);

export type DeleteOrgTagMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOrgTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrgTag?: Maybe<(
    { __typename?: 'OrgTag' }
    & Pick<OrgTag, 'id' | 'name'>
  )> }
);

export type AddTagToOrgMutationVariables = Exact<{
  tagId?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
}>;


export type AddTagToOrgMutation = (
  { __typename?: 'Mutation' }
  & { addTagToOrg?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId'>
    & { orgTags: Array<(
      { __typename?: 'OrgTag' }
      & Pick<OrgTag, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveTagFromOrgMutationVariables = Exact<{
  tagId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type RemoveTagFromOrgMutation = (
  { __typename?: 'Mutation' }
  & { removeTagFromOrg?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId'>
    & { orgTags: Array<(
      { __typename?: 'OrgTag' }
      & Pick<OrgTag, 'id' | 'name'>
    )> }
  )> }
);

export type GetOrgTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgTagsQuery = (
  { __typename?: 'Query' }
  & { getOrgTags: Array<(
    { __typename?: 'OrgTag' }
    & Pick<OrgTag, 'id' | 'name'>
  )> }
);

export type GetInitialPipelinePageDataQueryVariables = Exact<{
  id: Scalars['Int'];
  includeArchivedOpportunities?: Maybe<Scalars['Boolean']>;
}>;


export type GetInitialPipelinePageDataQuery = (
  { __typename?: 'Query' }
  & { getInitialPipelinePageData: (
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId'>
    & { privOpps: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id'>
    )> }
  ) }
);

export type CreatePipelineMutationVariables = Exact<{
  name: Scalars['String'];
  basePipelineId?: Maybe<Scalars['String']>;
}>;


export type CreatePipelineMutation = (
  { __typename?: 'Mutation' }
  & { createPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
    & { requiredFields: Array<(
      { __typename?: 'RequiredField' }
      & Pick<RequiredField, 'requiredFieldUUId' | 'fieldName' | 'storageHint'>
    )>, stages: Array<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'name' | 'pipelineId' | 'stageIndex'>
    )>, oppTags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type EditPipelineMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  customFields?: Maybe<Scalars['Json']>;
  requiredFields?: Maybe<Array<RequiredFieldArgument> | RequiredFieldArgument>;
  oppTags?: Maybe<Scalars['Json']>;
}>;


export type EditPipelineMutation = (
  { __typename?: 'Mutation' }
  & { editPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'createdAt' | 'pipelineId' | 'name' | 'customFields'>
    & { requiredFields: Array<(
      { __typename?: 'RequiredField' }
      & Pick<RequiredField, 'requiredFieldUUId' | 'fieldName' | 'storageHint'>
    )>, stages: Array<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id'>
    )>, oppTags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type EditPipelineUserMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  userId: Scalars['String'];
  roleUUId?: Maybe<Scalars['String']>;
}>;


export type EditPipelineUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editPipelineUser'>
);

export type GetDefaultPipelineQueryVariables = Exact<{
  preferredPipelineId?: Maybe<Scalars['Int']>;
  preferredPipelineUUId?: Maybe<Scalars['String']>;
  preferredViewUUId?: Maybe<Scalars['String']>;
}>;


export type GetDefaultPipelineQuery = (
  { __typename?: 'Query' }
  & { getDefaultPipeline: (
    { __typename?: 'DefaultPipelineNexusType' }
    & Pick<DefaultPipelineNexusType, 'pipelineId' | 'pipelineUUId' | 'pipelineViewUUId'>
  ) }
);

export type GetOutlookOppPreviewsQueryVariables = Exact<{
  id: Scalars['Int'];
  includeArchived?: Maybe<Scalars['Boolean']>;
}>;


export type GetOutlookOppPreviewsQuery = (
  { __typename?: 'Query' }
  & { pipeline: (
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId' | 'createdAt' | 'name'>
    & { privOpps: Array<(
      { __typename: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'createdAt' | 'updatedAt' | 'pipelineId' | 'name' | 'canonId' | 'oppId' | 'sol' | 'createdByUserId'>
      & { captureTeam: Array<(
        { __typename: 'CaptureTeam' }
        & Pick<CaptureTeam, 'id' | 'captureTeamId' | 'name'>
        & { users: Array<(
          { __typename: 'User' }
          & Pick<User, 'id'>
        )> }
      )> }
    )> }
  ) }
);

export type GetPipelineNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelineNamesQuery = (
  { __typename?: 'Query' }
  & { getPipelines: Array<(
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'pipelineId' | 'workspaceId'>
  )> }
);

export type PrivOppPartsFragment = (
  { __typename: 'PrivOpp' }
  & Pick<PrivOpp, 'id' | 'winningPrice' | 'createdAt' | 'updatedAt' | 'isArchived' | 'isBid' | 'isWin' | 'govWinId' | 'pipelineId' | 'name' | 'canonId' | 'oppId' | 'sol' | 'prevSol' | 'award' | 'lossReason' | 'currentScores' | 'metadata' | 'cachedGovWinDetails'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
  )>, notes: Array<(
    { __typename: 'Note' }
    & Pick<Note, 'id' | 'noteId' | 'createdAt' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'createdByFirstName' | 'createdByLastName'>
    & { contacts: Array<(
      { __typename: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'firstName' | 'lastName' | 'fullName' | 'contactId'>
    )>, createdBy?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'userId'>
    )> }
  )>, captureTeam: Array<(
    { __typename: 'CaptureTeam' }
    & Pick<CaptureTeam, 'id' | 'captureTeamId' | 'name'>
    & { users: Array<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )> }
  )>, client?: Maybe<(
    { __typename: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'metadata'>
  )>, privOppStage?: Maybe<(
    { __typename: 'PrivOppStage' }
    & Pick<PrivOppStage, 'id' | 'posId' | 'stageId'>
    & { stage: (
      { __typename: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'name'>
    ) }
  )>, tags: Array<(
    { __typename: 'OppTag' }
    & Pick<OppTag, 'id' | 'color' | 'tagId' | 'name'>
  )>, teams: Array<(
    { __typename: 'Team' }
    & Pick<Team, 'id' | 'teamId' | 'incumbentTeam' | 'isWinningTeam' | 'workspaceTeam' | 'primeId'>
    & { prime?: Maybe<(
      { __typename: 'Team' }
      & Pick<Team, 'id'>
    )>, privOrg?: Maybe<(
      { __typename: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )> }
  )> }
);

export type GetPipelineOppsQueryVariables = Exact<{
  id: Scalars['Int'];
  includeArchived?: Maybe<Scalars['Boolean']>;
  oppIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetPipelineOppsQuery = (
  { __typename?: 'Query' }
  & { pipeline: (
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId' | 'createdAt' | 'workspaceId' | 'default' | 'name'>
    & { privOpps: Array<(
      { __typename?: 'PrivOpp' }
      & PrivOppPartsFragment
    )>, stages: Array<(
      { __typename: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'layout' | 'name' | 'stageIndex'>
    )> }
  ) }
);

export type GetPipelinePermissionSettingsQueryVariables = Exact<{
  pipelineUUId: Scalars['String'];
}>;


export type GetPipelinePermissionSettingsQuery = (
  { __typename?: 'Query' }
  & { getPipelinePermissionSettings: (
    { __typename?: 'PipelinePermissionsSettingsNexusType' }
    & Pick<PipelinePermissionsSettingsNexusType, 'isPermissionManagementEnabled'>
    & { roles: Array<(
      { __typename?: 'PipelinePermissionsSettingsRoleNexusType' }
      & Pick<PipelinePermissionsSettingsRoleNexusType, 'roleName' | 'roleUUId' | 'roleDescription'>
    )>, users: Array<(
      { __typename?: 'PipelinePermissionsSettingsUserNexusType' }
      & Pick<PipelinePermissionsSettingsUserNexusType, 'id' | 'email' | 'firstName' | 'lastName' | 'pipelineRoleUUId'>
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'name'>
    ) }
  ) }
);

export type GetPipelinesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelinesQuery = (
  { __typename?: 'Query' }
  & { getPipelines: Array<(
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'customFields' | 'captureTeamTemplate' | 'id' | 'name' | 'pipelineId' | 'workspaceId' | 'stageLayout'>
    & { requiredFields: Array<(
      { __typename?: 'RequiredField' }
      & Pick<RequiredField, 'requiredFieldUUId' | 'fieldName' | 'storageHint'>
    )>, scoreTemplates: Array<(
      { __typename?: 'ScoreTemplate' }
      & Pick<ScoreTemplate, 'id'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreTemplateCategory' }
        & Pick<ScoreTemplateCategory, 'name' | 'scoreTemplateCategoryId'>
      )>>> }
    )>, oppTags: Array<(
      { __typename: 'OppTag' }
      & Pick<OppTag, 'id' | 'color' | 'tagId' | 'name'>
    )>, stages: Array<(
      { __typename: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'layout' | 'name' | 'stageIndex' | 'taskConfig' | 'pipelineCustomFields' | 'stageColumnFields' | 'showNotes' | 'pipelineId' | 'defaultPwin'>
    )>, pipelineViews: Array<(
      { __typename: 'PipelineView' }
      & Pick<PipelineView, 'id' | 'name' | 'pipelineViewId' | 'defaultViewMode' | 'sortColumn' | 'sortDirection' | 'tableColumnOverrides' | 'filters' | 'filterMode' | 'query'>
    )> }
  )> }
);

export type GetWorkspacePipelineUuIdQueryVariables = Exact<{
  pipelineId: Scalars['Int'];
}>;


export type GetWorkspacePipelineUuIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkspacePipelineUUId'>
);

export type PipelineQueryVariables = Exact<{
  id: Scalars['Int'];
  includeArchived?: Maybe<Scalars['Boolean']>;
  oppIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type PipelineQuery = (
  { __typename?: 'Query' }
  & { pipeline: (
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'customFields' | 'captureTeamTemplate' | 'id' | 'name' | 'pipelineId' | 'workspaceId' | 'stageLayout' | 'default'>
    & { requiredFields: Array<(
      { __typename?: 'RequiredField' }
      & Pick<RequiredField, 'requiredFieldUUId' | 'fieldName' | 'storageHint'>
    )>, scoreTemplates: Array<(
      { __typename?: 'ScoreTemplate' }
      & Pick<ScoreTemplate, 'id'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreTemplateCategory' }
        & Pick<ScoreTemplateCategory, 'name' | 'scoreTemplateCategoryId'>
      )>>> }
    )>, oppTags: Array<(
      { __typename: 'OppTag' }
      & Pick<OppTag, 'id' | 'color' | 'tagId' | 'name'>
    )>, stages: Array<(
      { __typename: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'layout' | 'name' | 'stageIndex' | 'taskConfig' | 'pipelineCustomFields' | 'stageColumnFields' | 'showNotes' | 'pipelineId' | 'defaultPwin'>
    )>, pipelineViews: Array<(
      { __typename: 'PipelineView' }
      & Pick<PipelineView, 'id' | 'name' | 'pipelineViewId' | 'defaultViewMode' | 'sortColumn' | 'sortDirection' | 'tableColumnOverrides' | 'filters' | 'filterMode' | 'query'>
    )> }
  ) }
);

export type SetOpportunityStageMutationVariables = Exact<{
  id: Scalars['Int'];
  stageId: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
}>;


export type SetOpportunityStageMutation = (
  { __typename?: 'Mutation' }
  & { setOpportunityStage?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'name'>
      & { stages: Array<(
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'stageId' | 'stageIndex' | 'layout'>
      )> }
    ) }
  )> }
);

export type ToggleUserLevelPermissionsMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  enable: Scalars['Boolean'];
}>;


export type ToggleUserLevelPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleUserLevelPermissions'>
);

export type UpdatePipelineMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['Json']>;
  stageLayout?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  oppTags?: Maybe<Scalars['Json']>;
  summaryTemplate?: Maybe<Scalars['Json']>;
  proposalTemplate?: Maybe<Scalars['Json']>;
}>;


export type UpdatePipelineMutation = (
  { __typename?: 'Mutation' }
  & { editPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'createdAt' | 'pipelineId' | 'name' | 'customFields'>
    & { stages: Array<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id'>
    )>, oppTags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type AddFieldOptionMutationVariables = Exact<{
  pipelineId: Scalars['String'];
  field: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddFieldOptionMutation = (
  { __typename?: 'Mutation' }
  & { addFieldOption?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'customFields'>
  )> }
);

export type DuplicatePipelineMutationVariables = Exact<{
  referencePipelineId: Scalars['String'];
}>;


export type DuplicatePipelineMutation = (
  { __typename?: 'Mutation' }
  & { duplicatePipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
    & { stages: Array<(
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'stageId' | 'name' | 'pipelineId' | 'stageIndex'>
    )>, oppTags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type DeletePipelineMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePipelineMutation = (
  { __typename?: 'Mutation' }
  & { deletePipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id'>
  )> }
);

export type CreatePipelineViewMutationVariables = Exact<{
  name: Scalars['String'];
  filters: Scalars['Json'];
  query?: Maybe<Scalars['String']>;
  filterMode?: Maybe<FilterMode>;
  pipelineId: Scalars['String'];
  defaultViewMode?: Maybe<PipelineViewMode>;
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  tableColumnOverrides?: Maybe<Scalars['Json']>;
}>;


export type CreatePipelineViewMutation = (
  { __typename?: 'Mutation' }
  & { createPipelineView?: Maybe<(
    { __typename?: 'PipelineView' }
    & Pick<PipelineView, 'id' | 'name' | 'filters' | 'query' | 'pipelineId'>
  )> }
);

export type UpdatePipelineViewMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  filters: Scalars['Json'];
  filterMode?: Maybe<FilterMode>;
  query?: Maybe<Scalars['String']>;
  defaultViewMode?: Maybe<PipelineViewMode>;
  sortColumn?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
  tableColumnOverrides?: Maybe<Scalars['Json']>;
}>;


export type UpdatePipelineViewMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineView?: Maybe<(
    { __typename?: 'PipelineView' }
    & Pick<PipelineView, 'id' | 'name' | 'filters' | 'query' | 'pipelineId'>
  )> }
);

export type DeletePipelineViewMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePipelineViewMutation = (
  { __typename?: 'Mutation' }
  & { deletePipelineView?: Maybe<(
    { __typename?: 'PipelineView' }
    & Pick<PipelineView, 'id' | 'name' | 'filters' | 'pipelineId'>
  )> }
);

export type CreatePrivOppSourceMutationVariables = Exact<{
  privOppUUId: Scalars['String'];
  source?: Maybe<Scalars['Json']>;
  label: Scalars['String'];
  type: PrivOppSourceEnum;
}>;


export type CreatePrivOppSourceMutation = (
  { __typename?: 'Mutation' }
  & { createPrivOppSource?: Maybe<(
    { __typename: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId'>
    & { sources?: Maybe<Array<(
      { __typename?: 'PrivOppSourceNexusType' }
      & Pick<PrivOppSourceNexusType, 'privOppSourceUUId' | 'label' | 'sourceType' | 'source'>
    )>> }
  )> }
);

export type GetOppSummaryViewDataQueryVariables = Exact<{
  canonId: Scalars['String'];
}>;


export type GetOppSummaryViewDataQuery = (
  { __typename?: 'Query' }
  & { getPrivOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'pipelineId' | 'updatedAt' | 'createdAt' | 'canonId' | 'name' | 'isBid' | 'isWin' | 'isArchived' | 'metadata' | 'solType' | 'solicitationDetails' | 'rfiDetails' | 'histAwardDetails' | 'sol' | 'cachedGovWinDetails' | 'internalSolId' | 'award' | 'internalAwardId' | 'prevSol' | 'internalPrevSolId' | 'documents' | 'oppScoreTs' | 'winningPrice' | 'lossReason' | 'govWinId'>
    & { client?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, sources?: Maybe<Array<(
      { __typename?: 'PrivOppSourceNexusType' }
      & Pick<PrivOppSourceNexusType, 'privOppSourceUUId' | 'label' | 'sourceType' | 'source'>
    )>>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'customFields' | 'captureTeamTemplate'>
      & { summaryTemplate: (
        { __typename?: 'SummaryTemplate' }
        & Pick<SummaryTemplate, 'definition'>
      ), proposalTemplate?: Maybe<(
        { __typename?: 'SummaryTemplate' }
        & Pick<SummaryTemplate, 'definition'>
      )>, scoreTemplates: Array<(
        { __typename?: 'ScoreTemplate' }
        & Pick<ScoreTemplate, 'id' | 'scoreTemplateId'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreTemplateCategory' }
          & Pick<ScoreTemplateCategory, 'id' | 'position' | 'scoreTemplateCategoryId' | 'name'>
          & { fields?: Maybe<Array<Maybe<(
            { __typename?: 'ScoreTemplateField' }
            & Pick<ScoreTemplateField, 'id' | 'index' | 'description' | 'name' | 'range' | 'scoreTemplateId' | 'scoreTemplateFieldId' | 'weight'>
          )>>> }
        )>>>, fields?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreTemplateField' }
          & Pick<ScoreTemplateField, 'id' | 'scoreTemplateFieldId' | 'description' | 'name'>
        )>>> }
      )> }
    ), notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt' | 'documents' | 'createdByFirstName' | 'createdByLastName'>
      & { contacts: Array<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, captureTeam: Array<(
      { __typename?: 'CaptureTeam' }
      & Pick<CaptureTeam, 'id' | 'name'>
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isZombie'>
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type UpdateMetadataMutationVariables = Exact<{
  id: Scalars['Int'];
  field: Scalars['String'];
  value: Scalars['Json'];
}>;


export type UpdateMetadataMutation = (
  { __typename?: 'Mutation' }
  & { updateMetadata?: Maybe<{ __typename: 'PrivOpp' }> }
);

export type UpdatePrivOppSourceMutationVariables = Exact<{
  privOppUUId: Scalars['String'];
  privOppSourceUUId: Scalars['String'];
  source?: Maybe<Scalars['Json']>;
  label?: Maybe<Scalars['String']>;
}>;


export type UpdatePrivOppSourceMutation = (
  { __typename?: 'Mutation' }
  & { updatePrivOppSource?: Maybe<(
    { __typename: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'rfiDetails'>
    & { sources?: Maybe<Array<(
      { __typename?: 'PrivOppSourceNexusType' }
      & Pick<PrivOppSourceNexusType, 'privOppSourceUUId' | 'label' | 'sourceType' | 'source'>
    )>> }
  )> }
);

export type PubWorkspaceDeletePrivOppMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type PubWorkspaceDeletePrivOppMutation = (
  { __typename?: 'Mutation' }
  & { pubWorkspaceDeletePrivOpp?: Maybe<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id'>
  )> }
);

export type DeleteManyPrivOppsMutationVariables = Exact<{
  privOppIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type DeleteManyPrivOppsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteManyPrivOpps'>
);

export type UpdatePrivOppStageMutationVariables = Exact<{
  privOppId: Scalars['Int'];
  destStageId: Scalars['Int'];
  index: Scalars['Int'];
}>;


export type UpdatePrivOppStageMutation = (
  { __typename?: 'Mutation' }
  & { updatePrivOppStage?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id'>
  )> }
);

export type UpdatePrivOppMutationVariables = Exact<{
  id: Scalars['Int'];
  awardId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  govWinId?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isBid?: Maybe<Scalars['Boolean']>;
  isWin?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  pipelineId?: Maybe<Scalars['String']>;
  stageId?: Maybe<Scalars['String']>;
  solId?: Maybe<Scalars['String']>;
  prevSolId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  winnerId?: Maybe<Scalars['String']>;
  winningPrice?: Maybe<Scalars['Float']>;
}>;


export type UpdatePrivOppMutation = (
  { __typename?: 'Mutation' }
  & { updatePrivOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'isBid' | 'isWin' | 'name' | 'metadata' | 'govWinId' | 'winningPrice' | 'lossReason'>
    & { privOppStage?: Maybe<(
      { __typename?: 'PrivOppStage' }
      & Pick<PrivOppStage, 'id' | 'stageId'>
      & { stage: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name'>
      ) }
    )>, client?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, tags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type SearchPromptTemplateGroupsQueryVariables = Exact<{
  type?: Maybe<GqlPromptType>;
}>;


export type SearchPromptTemplateGroupsQuery = (
  { __typename?: 'Query' }
  & { searchPromptTemplateGroups: (
    { __typename?: 'GqlPromptTemplateGroupSearchResult' }
    & Pick<GqlPromptTemplateGroupSearchResult, 'total'>
    & { results: Array<(
      { __typename?: 'GqlPromptTemplateGroup' }
      & Pick<GqlPromptTemplateGroup, 'uuid' | 'name' | 'description' | 'type'>
      & { templates?: Maybe<Array<Maybe<(
        { __typename?: 'GqlPromptTemplate' }
        & Pick<GqlPromptTemplate, 'uuid' | 'name'>
        & { schema?: Maybe<(
          { __typename?: 'GqlPromptTemplateSchema' }
          & Pick<GqlPromptTemplateSchema, 'label' | 'description' | 'guide'>
          & { fields: Array<Maybe<(
            { __typename?: 'GqlPromptTemplateSchemaField' }
            & Pick<GqlPromptTemplateSchemaField, 'key' | 'type' | 'label' | 'guide' | 'placeholder' | 'required' | 'defaultValue'>
          )>>, additionalInstructions?: Maybe<(
            { __typename?: 'GqlPromptTemplateSchemaAdditionalInstructions' }
            & Pick<GqlPromptTemplateSchemaAdditionalInstructions, 'label' | 'guide'>
          )> }
        )> }
      )>>> }
    )> }
  ) }
);

export type CreateProposalDocumentMutationVariables = Exact<{
  opportunityUUId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateProposalDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createProposalDocument: (
    { __typename?: 'GqlProposalDocument' }
    & Pick<GqlProposalDocument, 'id' | 'uuid' | 'name' | 'createdAt' | 'updatedAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type CreateProposalStageMutationVariables = Exact<{
  pipelineUUId: Scalars['String'];
  name: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
}>;


export type CreateProposalStageMutation = (
  { __typename?: 'Mutation' }
  & { createProposalStage: (
    { __typename?: 'ProposalStage' }
    & Pick<ProposalStage, 'id' | 'uuid' | 'name' | 'index'>
  ) }
);

export type DeleteProposalDocumentMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteProposalDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteProposalDocument: (
    { __typename?: 'GqlProposalDocument' }
    & Pick<GqlProposalDocument, 'uuid'>
  ) }
);

export type DeleteProposalStageMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteProposalStageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProposalStage'>
);

export type GetPipelineProposalOptionsQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetPipelineProposalOptionsQuery = (
  { __typename?: 'Query' }
  & { getPipelineByUUId: (
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'pipelineId'>
    & { privOpps: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'name' | 'oppId' | 'canonId' | 'isArchived'>
    )>, proposalStages: Array<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id' | 'uuid' | 'name' | 'index'>
    )> }
  ) }
);

export type GetProposalQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetProposalQuery = (
  { __typename?: 'Query' }
  & { getOpportunity: (
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'name' | 'canonId' | 'isArchived' | 'proposalStagePosition' | 'complianceMatrixDocumentRequestUrl'>
    & { captureTeam: Array<(
      { __typename?: 'CaptureTeam' }
      & Pick<CaptureTeam, 'id' | 'name' | 'isProposalContributor'>
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isZombie'>
      )> }
    )>, proposalStage?: Maybe<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id' | 'uuid' | 'name' | 'index'>
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'name' | 'pipelineId'>
      & { proposalTemplate?: Maybe<(
        { __typename?: 'SummaryTemplate' }
        & Pick<SummaryTemplate, 'definition'>
      )> }
    ), proposalDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'GqlProposalDocument' }
      & Pick<GqlProposalDocument, 'id' | 'uuid' | 'name' | 'createdAt' | 'updatedAt'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, opportunity?: Maybe<(
        { __typename?: 'PrivOpp' }
        & Pick<PrivOpp, 'oppId'>
      )>, richTextDocument: (
        { __typename?: 'GqlRichTextDocument' }
        & Pick<GqlRichTextDocument, 'uuid'>
      ) }
    )>>>, tags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'name'>
    )> }
  ) }
);

export type GetProposalDocumentQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetProposalDocumentQuery = (
  { __typename?: 'Query' }
  & { getProposalDocument: (
    { __typename?: 'GqlProposalDocument' }
    & Pick<GqlProposalDocument, 'id' | 'uuid' | 'name' | 'createdAt' | 'updatedAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, opportunity?: Maybe<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'name' | 'oppId'>
      & { pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'name'>
      ) }
    )>, richTextDocument: (
      { __typename?: 'GqlRichTextDocument' }
      & Pick<GqlRichTextDocument, 'uuid'>
    ) }
  ) }
);

export type GetProposalOpportunityOptionsQueryVariables = Exact<{
  pipelineUUId: Scalars['String'];
  uuids?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  estimatedRFP?: Maybe<Scalars['String']>;
  hasProposal?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;


export type GetProposalOpportunityOptionsQuery = (
  { __typename?: 'Query' }
  & { searchPipelineOpportunities: (
    { __typename?: 'GetPipelineOpportunitiesResolverResult' }
    & Pick<GetPipelineOpportunitiesResolverResult, 'total'>
    & { results: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId' | 'isArchived' | 'canonId' | 'name'>
    )> }
  ) }
);

export type GetProposalPipelineKanbanQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetProposalPipelineKanbanQuery = (
  { __typename?: 'Query' }
  & { getPipelineByUUId: (
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'customFields' | 'captureTeamTemplate' | 'id' | 'name' | 'pipelineId' | 'workspaceId' | 'stageLayout' | 'default'>
    & { requiredFields: Array<(
      { __typename?: 'RequiredField' }
      & Pick<RequiredField, 'requiredFieldUUId' | 'fieldName' | 'storageHint'>
    )>, scoreTemplates: Array<(
      { __typename?: 'ScoreTemplate' }
      & Pick<ScoreTemplate, 'id'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreTemplateCategory' }
        & Pick<ScoreTemplateCategory, 'name' | 'scoreTemplateCategoryId'>
      )>>> }
    )>, oppTags: Array<(
      { __typename?: 'OppTag' }
      & Pick<OppTag, 'id' | 'color' | 'tagId' | 'name'>
    )>, proposalStages: Array<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id' | 'uuid' | 'index' | 'name' | 'deletedAt'>
      & { opportunities: Array<Maybe<(
        { __typename?: 'PrivOpp' }
        & Pick<PrivOpp, 'id' | 'oppId' | 'name' | 'proposalStagePosition' | 'metadata'>
        & { proposalStage?: Maybe<(
          { __typename?: 'ProposalStage' }
          & Pick<ProposalStage, 'id' | 'uuid' | 'name'>
        )>, proposalDocuments?: Maybe<Array<Maybe<(
          { __typename?: 'GqlProposalDocument' }
          & Pick<GqlProposalDocument, 'uuid' | 'name'>
        )>>>, captureTeam: Array<(
          { __typename?: 'CaptureTeam' }
          & Pick<CaptureTeam, 'id' | 'name'>
          & { users: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )> }
      )>> }
    )>, pipelineViews: Array<(
      { __typename?: 'PipelineView' }
      & Pick<PipelineView, 'id' | 'name' | 'pipelineViewId' | 'defaultViewMode' | 'sortColumn' | 'sortDirection' | 'tableColumnOverrides' | 'filters' | 'filterMode' | 'query'>
    )> }
  ) }
);

export type RemoveOpportunityProposalStageMutationVariables = Exact<{
  opportunityUUId: Scalars['String'];
}>;


export type RemoveOpportunityProposalStageMutation = (
  { __typename?: 'Mutation' }
  & { removeOpportunityProposalStage?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
  )> }
);

export type ReorderProposalStageMutationVariables = Exact<{
  uuid: Scalars['String'];
  index: Scalars['Int'];
}>;


export type ReorderProposalStageMutation = (
  { __typename?: 'Mutation' }
  & { reorderProposalStage: (
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'pipelineId' | 'name'>
    & { proposalStages: Array<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id' | 'uuid' | 'index' | 'name'>
      & { opportunities: Array<Maybe<(
        { __typename?: 'PrivOpp' }
        & Pick<PrivOpp, 'id' | 'oppId' | 'name' | 'proposalStagePosition'>
        & { proposalStage?: Maybe<(
          { __typename?: 'ProposalStage' }
          & Pick<ProposalStage, 'uuid' | 'name'>
        )> }
      )>> }
    )> }
  ) }
);

export type SearchProposalDocumentsQueryVariables = Exact<{
  pipelineUUId: Scalars['String'];
  opportunityUUId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<GqlProposalDocumentSearchSortBy>;
  sortOrder?: Maybe<SortDirection>;
}>;


export type SearchProposalDocumentsQuery = (
  { __typename?: 'Query' }
  & { searchProposalDocuments: (
    { __typename?: 'GqlProposalDocumentSearchResult' }
    & Pick<GqlProposalDocumentSearchResult, 'total'>
    & { results: Array<(
      { __typename?: 'GqlProposalDocument' }
      & Pick<GqlProposalDocument, 'id' | 'uuid' | 'name' | 'createdAt' | 'updatedAt' | 'opportunityUUId'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, pipeline?: Maybe<(
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'name' | 'pipelineId'>
      )> }
    )> }
  ) }
);

export type SetComplianceMatrixMutationVariables = Exact<{
  privOppUUId: Scalars['String'];
  complianceMatrixDocumentRequestUrl?: Maybe<Scalars['String']>;
}>;


export type SetComplianceMatrixMutation = (
  { __typename?: 'Mutation' }
  & { setComplianceMatrix?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'name' | 'complianceMatrixDocumentRequestUrl'>
  )> }
);

export type SetOpportunityProposalStageMutationVariables = Exact<{
  opportunityUUId: Scalars['String'];
  proposalStageUUId: Scalars['String'];
  proposalStagePosition?: Maybe<Scalars['Float']>;
}>;


export type SetOpportunityProposalStageMutation = (
  { __typename?: 'Mutation' }
  & { setOpportunityProposalStage?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'name'>
    & { proposalStage?: Maybe<(
      { __typename?: 'ProposalStage' }
      & Pick<ProposalStage, 'id' | 'uuid' | 'name' | 'index'>
    )> }
  )> }
);

export type SetOpportunityProposalStageNoReturnMutationVariables = Exact<{
  opportunityUUId: Scalars['String'];
  proposalStageUUId: Scalars['String'];
  proposalStagePosition?: Maybe<Scalars['Float']>;
}>;


export type SetOpportunityProposalStageNoReturnMutation = (
  { __typename?: 'Mutation' }
  & { setOpportunityProposalStage?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id'>
  )> }
);

export type UpdateProposalDocumentMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateProposalDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateProposalDocument: (
    { __typename?: 'GqlProposalDocument' }
    & Pick<GqlProposalDocument, 'id' | 'uuid' | 'name' | 'createdAt' | 'updatedAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type UpdateProposalStageMutationVariables = Exact<{
  uuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateProposalStageMutation = (
  { __typename?: 'Mutation' }
  & { updateProposalStage: (
    { __typename?: 'ProposalStage' }
    & Pick<ProposalStage, 'id' | 'uuid' | 'index' | 'name'>
  ) }
);

export type AddChartMutationVariables = Exact<{
  title: Scalars['String'];
  query?: Maybe<Scalars['Json']>;
  chartType: Scalars['String'];
  dashboardId: Scalars['Int'];
  showValueLabels?: Maybe<Scalars['Boolean']>;
}>;


export type AddChartMutation = (
  { __typename?: 'Mutation' }
  & { addChart?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'id'>
  )> }
);

export type DeleteChartMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChartMutation = (
  { __typename?: 'Mutation' }
  & { deleteChart?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'id'>
  )> }
);

export type RebuildCacheMutationVariables = Exact<{ [key: string]: never; }>;


export type RebuildCacheMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rebuildCache'>
);

export type UpdateChartMutationVariables = Exact<{
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['Json']>;
  chartType?: Maybe<Scalars['String']>;
  showValueLabels?: Maybe<Scalars['Boolean']>;
  dashboardId?: Maybe<Scalars['Int']>;
}>;


export type UpdateChartMutation = (
  { __typename?: 'Mutation' }
  & { updateChart?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'id'>
  )> }
);

export type UpdateChartLayoutMutationVariables = Exact<{
  id: Scalars['Int'];
  layout?: Maybe<Scalars['Json']>;
}>;


export type UpdateChartLayoutMutation = (
  { __typename?: 'Mutation' }
  & { updateChartLayout?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'id' | 'layout' | 'query' | 'chartType' | 'showValueLabels'>
  )> }
);

export type GetDashboardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDashboards'>
);

export type GetDashboardQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDashboardQuery = (
  { __typename?: 'Query' }
  & { getDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'id' | 'name'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, charts?: Maybe<Array<Maybe<(
      { __typename?: 'Chart' }
      & Pick<Chart, 'id' | 'title' | 'query' | 'chartType' | 'showValueLabels' | 'layout'>
    )>>> }
  )> }
);

export type UpdateDashboardMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDashboardMutation = (
  { __typename?: 'Mutation' }
  & { updateDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'id' | 'name'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, charts?: Maybe<Array<Maybe<(
      { __typename?: 'Chart' }
      & Pick<Chart, 'id' | 'title' | 'query' | 'chartType' | 'showValueLabels' | 'layout'>
    )>>> }
  )> }
);

export type DeleteDashboardMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type DeleteDashboardMutation = (
  { __typename?: 'Mutation' }
  & { deleteDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'id' | 'name'>
  )> }
);

export type FavoriteDashboardMutationVariables = Exact<{
  dashboardId?: Maybe<Scalars['Int']>;
}>;


export type FavoriteDashboardMutation = (
  { __typename?: 'Mutation' }
  & { favoriteDashboard?: Maybe<(
    { __typename?: 'FavoriteDashboards' }
    & Pick<FavoriteDashboards, 'id'>
  )> }
);

export type UnFavoriteDashboardMutationVariables = Exact<{
  dashboardId?: Maybe<Scalars['Int']>;
}>;


export type UnFavoriteDashboardMutation = (
  { __typename?: 'Mutation' }
  & { unFavoriteDashboard?: Maybe<(
    { __typename?: 'FavoriteDashboards' }
    & Pick<FavoriteDashboards, 'id'>
  )> }
);

export type AddDashboardMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
}>;


export type AddDashboardMutation = (
  { __typename?: 'Mutation' }
  & { addDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'id' | 'name'>
  )> }
);

export type GetChartQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetChartQuery = (
  { __typename?: 'Query' }
  & { chart?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'id' | 'title' | 'query' | 'chartType' | 'showValueLabels'>
    & { dashboard?: Maybe<(
      { __typename?: 'Dashboard' }
      & Pick<Dashboard, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & { deleteSavedSearch?: Maybe<(
    { __typename?: 'SavedSearch' }
    & Pick<SavedSearch, 'id'>
  )> }
);

export type SubscribeSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SubscribeSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & { subscribeSavedSearch?: Maybe<(
    { __typename?: 'SavedSearchSubscriber' }
    & Pick<SavedSearchSubscriber, 'savedSearchId' | 'subscriberId'>
  )> }
);

export type UnsubscribeSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UnsubscribeSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeSavedSearch?: Maybe<(
    { __typename?: 'SavedSearchSubscriber' }
    & Pick<SavedSearchSubscriber, 'savedSearchId' | 'subscriberId'>
  )> }
);

export type UpsertSavedSearch2MutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  query: Scalars['Json'];
  subscribe?: Maybe<Scalars['Boolean']>;
  savedSearchUUId?: Maybe<Scalars['String']>;
}>;


export type UpsertSavedSearch2Mutation = (
  { __typename?: 'Mutation' }
  & { upsertSavedSearch2?: Maybe<(
    { __typename?: 'SavedSearch' }
    & Pick<SavedSearch, 'savedSearchId'>
  )> }
);

export type UpdateScoreTemplateMutationVariables = Exact<{
  scoreTemplateId: Scalars['String'];
  newTemplate: Scalars['Json'];
}>;


export type UpdateScoreTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateScoreTemplate?: Maybe<(
    { __typename?: 'ScoreTemplate' }
    & Pick<ScoreTemplate, 'scoreTemplateId'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreTemplateCategory' }
      & Pick<ScoreTemplateCategory, 'id' | 'position' | 'scoreTemplateCategoryId' | 'name'>
      & { fields?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreTemplateField' }
        & Pick<ScoreTemplateField, 'id' | 'index' | 'description' | 'name' | 'range' | 'scoreTemplateId' | 'scoreTemplateFieldId' | 'weight'>
      )>>> }
    )>>>, fields?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreTemplateField' }
      & Pick<ScoreTemplateField, 'id' | 'scoreTemplateFieldId' | 'description' | 'name'>
    )>>> }
  )> }
);

export type GetAwardTaskOrdersQueryVariables = Exact<{
  awardId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
}>;


export type GetAwardTaskOrdersQuery = (
  { __typename?: 'Query' }
  & { getAwardTaskOrders: (
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'aggregations' | 'numHits'>
  ) }
);

export type GetAwardTaskOrderAnalysisQueryVariables = Exact<{
  awardId: Scalars['String'];
}>;


export type GetAwardTaskOrderAnalysisQuery = (
  { __typename?: 'Query' }
  & { getAwardTaskOrderAnalysis: (
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'aggregations' | 'numHits'>
  ) }
);

export type BookmarkMutationVariables = Exact<{
  source: ExternalSourceNexusType;
  index?: Maybe<Scalars['String']>;
  bookmarkReason: BookmarkReason;
  externalId: Scalars['String'];
}>;


export type BookmarkMutation = (
  { __typename?: 'Mutation' }
  & { upsertBookmark?: Maybe<(
    { __typename?: 'Bookmark' }
    & Pick<Bookmark, 'id' | 'reason' | 'externalId' | 'source' | 'updatedAt'>
    & { notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
      & { contacts: Array<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'fullName'>
    )> }
  )> }
);

export type BookmarkNoteMutationVariables = Exact<{
  source: ExternalSourceNexusType;
  index?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['Json']>;
  externalId: Scalars['String'];
  noteUUId?: Maybe<Scalars['String']>;
}>;


export type BookmarkNoteMutation = (
  { __typename?: 'Mutation' }
  & { upsertBookmarkNote?: Maybe<(
    { __typename?: 'Bookmark' }
    & Pick<Bookmark, 'id' | 'reason' | 'externalId' | 'source' | 'updatedAt'>
    & { notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
      & { contacts: Array<(
        { __typename?: 'PrivContact' }
        & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'fullName'>
    )> }
  )> }
);

export type ContractVehicleTaskOrderAnalysisQueryVariables = Exact<{
  contractVehicleId: Scalars['String'];
  awardYears: Array<Scalars['Int']> | Scalars['Int'];
  expirationYears: Array<Scalars['Int']> | Scalars['Int'];
  awardingAgencies: Array<Scalars['String']> | Scalars['String'];
  naics: Array<Scalars['String']> | Scalars['String'];
  psc: Array<Scalars['String']> | Scalars['String'];
}>;


export type ContractVehicleTaskOrderAnalysisQuery = (
  { __typename?: 'Query' }
  & { contractVehicleTaskOrderAnalysis: (
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'aggregations' | 'numHits'>
  ) }
);

export type ContractVehicleTaskOrdersQueryVariables = Exact<{
  contractVehicleId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  awardYears: Array<Scalars['Int']> | Scalars['Int'];
  expirationYears: Array<Scalars['Int']> | Scalars['Int'];
  awardingAgencies: Array<Scalars['String']> | Scalars['String'];
  naics: Array<Scalars['String']> | Scalars['String'];
  psc: Array<Scalars['String']> | Scalars['String'];
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
}>;


export type ContractVehicleTaskOrdersQuery = (
  { __typename?: 'Query' }
  & { contractVehicleTaskOrders: (
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'aggregations' | 'numHits'>
  ) }
);

export type ContractVehiclePrimeAwardsQueryVariables = Exact<{
  contractVehicleId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
}>;


export type ContractVehiclePrimeAwardsQuery = (
  { __typename?: 'Query' }
  & { contractVehiclePrimeAwards: (
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'aggregations' | 'numHits'>
  ) }
);

export type GetContractVehicleQueryVariables = Exact<{
  contractVehicleId: Scalars['String'];
}>;


export type GetContractVehicleQuery = (
  { __typename?: 'Query' }
  & { getContractVehicle?: Maybe<(
    { __typename?: 'ContractVehicle' }
    & Pick<ContractVehicle, 'id' | 'basePeriod' | 'contractSharedCeiling' | 'contractVehicleId' | 'description' | 'endDate' | 'informationWebsite' | 'name' | 'optionPeriod' | 'solicitationIds' | 'startDate' | 'summaryCountPrimeAwards' | 'summaryCountTaskOrders' | 'summaryPotentialValue' | 'summaryCurrentValue' | 'summaryFederalActionObligation' | 'type' | '_nsSolicitationIds'>
  )> }
);

export type EsDropdownSearchQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Json']>;
  field?: Maybe<Scalars['Json']>;
  _source?: Maybe<Scalars['Json']>;
  alexandriaSource?: Maybe<Scalars['String']>;
}>;


export type EsDropdownSearchQuery = (
  { __typename?: 'Query' }
  & { searchOpps?: Maybe<(
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'numHits'>
  )> }
);

export type GetAwardAgenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAwardAgenciesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'esAwardAgencies'>
);

export type EsGetListingsMultiIndexQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
  indexes: Array<Scalars['String']> | Scalars['String'];
}>;


export type EsGetListingsMultiIndexQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'esGetListingsMultiIndex'>
);

export type EsSearchQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  filters?: Maybe<Scalars['Json']>;
  sort?: Maybe<Scalars['Json']>;
  index?: Maybe<Scalars['Json']>;
  advanced?: Maybe<Scalars['Boolean']>;
  trackTotalHits?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  alexandriaSource?: Maybe<Scalars['String']>;
  mergeableOnly?: Maybe<Scalars['Boolean']>;
}>;


export type EsSearchQuery = (
  { __typename?: 'Query' }
  & { searchOpps?: Maybe<(
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'numHits'>
    & { bookmarks: Array<Maybe<(
      { __typename?: 'Bookmark' }
      & Pick<Bookmark, 'id' | 'bookmarkId' | 'source' | 'index' | 'externalId' | 'reason' | 'createdAt' | 'updatedAt'>
      & { notes: Array<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'fullName'>
      )> }
    )>> }
  )> }
);

export type VendorAwardsSearchQueryVariables = Exact<{
  vendorId: Scalars['String'];
}>;


export type VendorAwardsSearchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vendorAwardsSearch'>
);

export type EsGetListingQueryVariables = Exact<{
  awardId: Scalars['String'];
  index: Scalars['String'];
}>;


export type EsGetListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'esGetListing'>
);

export type FilterSearchQueryVariables = Exact<{
  index: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  field: Scalars['String'];
}>;


export type FilterSearchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'filterSearch'>
);

export type GetSavedSearchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedSearchesQuery = (
  { __typename?: 'Query' }
  & { getSavedSearches: Array<(
    { __typename?: 'SavedSearch' }
    & Pick<SavedSearch, 'id' | 'name' | 'url' | 'payload' | 'index'>
    & { savedSearchSubscriber: Array<(
      { __typename?: 'SavedSearchSubscriber' }
      & Pick<SavedSearchSubscriber, 'savedSearchId' | 'subscriberId'>
      & { subscriber: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GetSavedSearchMetaQueryVariables = Exact<{
  savedSearchIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetSavedSearchMetaQuery = (
  { __typename?: 'Query' }
  & { getSavedSearchMeta: Array<(
    { __typename?: 'SavedSearchMeta' }
    & Pick<SavedSearchMeta, 'recentCount' | 'totalCount'>
    & { savedSearch: (
      { __typename?: 'SavedSearch' }
      & Pick<SavedSearch, 'id' | 'name' | 'url' | 'ownerId' | 'payload' | 'index'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), savedSearchSubscriber: Array<(
        { __typename?: 'SavedSearchSubscriber' }
        & Pick<SavedSearchSubscriber, 'savedSearchId' | 'subscriberId'>
        & { subscriber: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        ) }
      )> }
    ) }
  )> }
);

export type GetSubscribedSavedSearchMetaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscribedSavedSearchMetaQuery = (
  { __typename?: 'Query' }
  & { getSubscribedSavedSearchMeta: Array<(
    { __typename?: 'SavedSearchMeta' }
    & Pick<SavedSearchMeta, 'recentCount' | 'totalCount'>
    & { savedSearch: (
      { __typename?: 'SavedSearch' }
      & Pick<SavedSearch, 'id' | 'name' | 'url'>
    ) }
  )> }
);

export type SearchSourcesQueryVariables = Exact<{
  searchSpec: Scalars['Json'];
}>;


export type SearchSourcesQuery = (
  { __typename?: 'Query' }
  & { searchSources?: Maybe<(
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'hits' | 'numHits'>
    & { bookmarks: Array<Maybe<(
      { __typename?: 'Bookmark' }
      & Pick<Bookmark, 'id' | 'bookmarkId' | 'source' | 'index' | 'externalId' | 'reason' | 'createdAt' | 'updatedAt'>
      & { notes: Array<(
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'privOppId' | 'privOrgId' | 'createdAt'>
        & { contacts: Array<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'title'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'fullName'>
      )> }
    )>> }
  )> }
);

export type DeleteStageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStageMutation = (
  { __typename?: 'Mutation' }
  & { deleteStage?: Maybe<(
    { __typename?: 'Stage' }
    & Pick<Stage, 'id'>
  )> }
);

export type UpdateStageSettingsMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  stageIndex?: Maybe<Scalars['Int']>;
  taskConfig?: Maybe<Scalars['Json']>;
}>;


export type UpdateStageSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateStageSettings?: Maybe<(
    { __typename?: 'Stage' }
    & Pick<Stage, 'id' | 'stageId' | 'name' | 'stageIndex' | 'layout' | 'taskConfig' | 'pipelineId'>
  )> }
);

export type CreateStageMutationVariables = Exact<{
  name: Scalars['String'];
  stageIndex: Scalars['Int'];
  defaultPwin?: Maybe<Scalars['Int']>;
  pipelineId: Scalars['Int'];
}>;


export type CreateStageMutation = (
  { __typename?: 'Mutation' }
  & { createStage?: Maybe<(
    { __typename?: 'Stage' }
    & Pick<Stage, 'id' | 'name'>
  )> }
);

export type GetCacheOnlyGetAllPlanningContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCacheOnlyGetAllPlanningContactsQuery = (
  { __typename?: 'Query' }
  & { getPlanningContacts: Array<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
    & { contactOwner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, privOrg?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCacheOnlyGetAllPlanningOpportunitiesQuery = (
  { __typename?: 'Query' }
  & { getPlanningOpportunities: Array<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'sol' | 'award' | 'name' | 'isArchived' | 'isWin' | 'metadata' | 'pipelineId'>
    & { captureTeam: Array<(
      { __typename?: 'CaptureTeam' }
      & Pick<CaptureTeam, 'id' | 'name'>
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )> }
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
    ), privOppStage?: Maybe<(
      { __typename?: 'PrivOppStage' }
      & Pick<PrivOppStage, 'id'>
      & { stage: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'stageId' | 'name' | 'stageIndex'>
      ) }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )> }
  )> }
);

export type GetCacheOnlyGetAllPlanningOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCacheOnlyGetAllPlanningOrganizationsQuery = (
  { __typename?: 'Query' }
  & { getPlanningOrganizations: Array<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'industry' | 'type'>
    & { orgTags: Array<(
      { __typename?: 'OrgTag' }
      & Pick<OrgTag, 'id' | 'name'>
    )>, accountOwner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type GetInitialPlanningContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitialPlanningContactsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInitialPlanningContacts'>
);

export type GetInitialPlanningOpportunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitialPlanningOpportunitiesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInitialPlanningOpportunities'>
);

export type GetInitialPlanningOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitialPlanningOrganizationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInitialPlanningOrganizations'>
);

export type GetPlanningQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlanningQuery = (
  { __typename?: 'Query' }
  & { getPlanning: (
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id'>
    & { contacts: Array<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
      & { contactOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, privOrg?: Maybe<(
        { __typename?: 'PrivOrg' }
        & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'dueDate' | 'status' | 'description'>
        & { privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'canonId' | 'name' | 'pipelineId'>
          & { privOppStage?: Maybe<(
            { __typename?: 'PrivOppStage' }
            & Pick<PrivOppStage, 'id'>
            & { stage: (
              { __typename?: 'Stage' }
              & Pick<Stage, 'id' | 'name'>
            ) }
          )>, pipeline: (
            { __typename?: 'Pipeline' }
            & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
          ) }
        )>, org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'name'>
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'createdAt'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )> }
      )>>> }
    )>, privOrgs: Array<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'industry' | 'type'>
      & { orgTags: Array<(
        { __typename?: 'OrgTag' }
        & Pick<OrgTag, 'id' | 'name'>
      )>, accountOwner?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'dueDate' | 'status' | 'description'>
        & { contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
        )>, privOpp?: Maybe<(
          { __typename?: 'PrivOpp' }
          & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'name' | 'pipelineId'>
          & { privOppStage?: Maybe<(
            { __typename?: 'PrivOppStage' }
            & Pick<PrivOppStage, 'id'>
            & { stage: (
              { __typename?: 'Stage' }
              & Pick<Stage, 'id' | 'name'>
            ) }
          )>, pipeline: (
            { __typename?: 'Pipeline' }
            & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
          ) }
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'noteId' | 'createdAt' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )> }
      )>>> }
    )>, privOpps: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'sol' | 'award' | 'name' | 'isArchived' | 'isWin' | 'metadata' | 'pipelineId'>
      & { captureTeam: Array<(
        { __typename?: 'CaptureTeam' }
        & Pick<CaptureTeam, 'id' | 'name'>
        & { users: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
        )> }
      )>, pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
      ), privOppStage?: Maybe<(
        { __typename?: 'PrivOppStage' }
        & Pick<PrivOppStage, 'id'>
        & { stage: (
          { __typename?: 'Stage' }
          & Pick<Stage, 'id' | 'stageId' | 'name' | 'stageIndex'>
        ) }
      )>, tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'dueDate' | 'status' | 'description'>
        & { parent?: Maybe<(
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'taskId' | 'description'>
        )>, contact?: Maybe<(
          { __typename?: 'PrivContact' }
          & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
        )>, org?: Maybe<(
          { __typename?: 'PrivOrg' }
          & Pick<PrivOrg, 'id' | 'name'>
        )>, assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
        )>, notes: Array<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'createdAt' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent'>
          & { createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetPlanningContactsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPlanningContactsQuery = (
  { __typename?: 'Query' }
  & { getPlanningContacts: Array<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'contactId' | 'firstName' | 'lastName'>
    & { contactOwner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, privOrg?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type GetPlanningOpportunitiesQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetPlanningOpportunitiesQuery = (
  { __typename?: 'Query' }
  & { getPlanningOpportunities: Array<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'oppId' | 'canonId' | 'sol' | 'award' | 'name' | 'isArchived' | 'isWin' | 'metadata' | 'pipelineId'>
    & { captureTeam: Array<(
      { __typename?: 'CaptureTeam' }
      & Pick<CaptureTeam, 'id' | 'name'>
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
      )> }
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId' | 'name' | 'customFields'>
    ), privOppStage?: Maybe<(
      { __typename?: 'PrivOppStage' }
      & Pick<PrivOppStage, 'id'>
      & { stage: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'stageId' | 'name' | 'stageIndex'>
      ) }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )> }
  )> }
);

export type GetPlanningOrganizationsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPlanningOrganizationsQuery = (
  { __typename?: 'Query' }
  & { getPlanningOrganizations: Array<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'orgId' | 'name' | 'industry' | 'type'>
    & { orgTags: Array<(
      { __typename?: 'OrgTag' }
      & Pick<OrgTag, 'id' | 'name'>
    )>, accountOwner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type GetTaskQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { getTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'taskId' | 'createdAt' | 'description' | 'dueDate' | 'status'>
    & { parent?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'taskId' | 'description' | 'status' | 'dueDate'>
    )>, subtasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'description' | 'dueDate' | 'status'>
      & { privOpp?: Maybe<(
        { __typename?: 'PrivOpp' }
        & Pick<PrivOpp, 'id' | 'name'>
      )> }
    )>, org?: Maybe<(
      { __typename?: 'PrivOrg' }
      & Pick<PrivOrg, 'id' | 'orgId' | 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'PrivContact' }
      & Pick<PrivContact, 'id' | 'contactId' | 'fullName' | 'firstName' | 'lastName'>
    )>, privOpp?: Maybe<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'canonId' | 'name'>
      & { stage?: Maybe<(
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name'>
      )> }
    )>, assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
    )>, notes: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'noteId' | 'payload' | 'payloadType' | 'noteType' | 'plainTextContent' | 'createdAt'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type TaskFragmentFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'createdAt' | 'taskId' | 'dueDate' | 'status' | 'description'>
  & { parent?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'taskId' | 'description'>
  )>, contact?: Maybe<(
    { __typename?: 'PrivContact' }
    & Pick<PrivContact, 'id' | 'firstName' | 'lastName'>
  )>, org?: Maybe<(
    { __typename?: 'PrivOrg' }
    & Pick<PrivOrg, 'id' | 'name'>
  )>, privOpp?: Maybe<(
    { __typename?: 'PrivOpp' }
    & Pick<PrivOpp, 'id' | 'canonId'>
  )>, assignees: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName'>
  )> }
);

export type AddSubToTeamMutationVariables = Exact<{
  primeId: Scalars['String'];
  privOrgId: Scalars['Int'];
  workshare?: Maybe<Scalars['Int']>;
  contactIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type AddSubToTeamMutation = (
  { __typename?: 'Mutation' }
  & { addSubToTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'teamId'>
  )> }
);

export type AddTeamMutationVariables = Exact<{
  privOrgId?: Maybe<Scalars['Int']>;
  privOppId?: Maybe<Scalars['Int']>;
  workshare?: Maybe<Scalars['Int']>;
  subcontractorIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  contactIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  incumbentTeam?: Maybe<Scalars['Boolean']>;
  isWinningTeam?: Maybe<Scalars['Boolean']>;
  workspaceTeam?: Maybe<Scalars['Boolean']>;
}>;


export type AddTeamMutation = (
  { __typename?: 'Mutation' }
  & { addTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'teamId'>
  )> }
);

export type UpdateTeamMutationVariables = Exact<{
  teamId: Scalars['String'];
  orgId?: Maybe<Scalars['String']>;
  workshare?: Maybe<Scalars['Int']>;
  contactIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  incumbentTeam?: Maybe<Scalars['Boolean']>;
  isWinningTeam?: Maybe<Scalars['Boolean']>;
  nda?: Maybe<Scalars['Boolean']>;
  ta?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamId' | 'nda' | 'ta'>
  )> }
);

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type DeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'teamId'>
  )> }
);

export type DeleteTimelineViewMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTimelineViewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTimelineView'>
);

export type GetPipelineViewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelineViewsQuery = (
  { __typename?: 'Query' }
  & { getPipelineViews: Array<(
    { __typename?: 'PipelineView' }
    & Pick<PipelineView, 'filters' | 'filterMode' | 'id' | 'pipelineViewId' | 'sortColumn' | 'sortDirection' | 'query' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'name' | 'pipelineId' | 'id'>
    ) }
  )> }
);

export type GetTimelineViewQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTimelineViewQuery = (
  { __typename?: 'Query' }
  & { getTimelineView: (
    { __typename?: 'TimelineViewNexusType' }
    & Pick<TimelineViewNexusType, 'id' | 'timelineViewUUId' | 'name' | 'query'>
    & { definition?: Maybe<(
      { __typename?: 'TimelineViewConfigNexusType' }
      & { filters: Array<(
        { __typename?: 'FieldValuesNexusType' }
        & Pick<FieldValuesNexusType, 'field' | 'values'>
      )>, dateFilters: Array<(
        { __typename?: 'TimelineViewConfigDateFilterNexusType' }
        & Pick<TimelineViewConfigDateFilterNexusType, 'field' | 'mode' | 'valueISOString'>
      )>, displayDates: Array<(
        { __typename?: 'TimelineViewConfigDisplayDateNexusType' }
        & Pick<TimelineViewConfigDisplayDateNexusType, 'field' | 'color'>
      )>, sort?: Maybe<(
        { __typename?: 'TimelineViewConfigOrderByNexusType' }
        & Pick<TimelineViewConfigOrderByNexusType, 'field' | 'direction'>
      )> }
    )> }
  ) }
);

export type GetTimelineViewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimelineViewsQuery = (
  { __typename?: 'Query' }
  & { getTimelineViews: Array<(
    { __typename?: 'TimelineViewNexusType' }
    & Pick<TimelineViewNexusType, 'id' | 'timelineViewUUId' | 'name' | 'query'>
    & { definition?: Maybe<(
      { __typename?: 'TimelineViewConfigNexusType' }
      & { filters: Array<(
        { __typename?: 'FieldValuesNexusType' }
        & Pick<FieldValuesNexusType, 'field' | 'values'>
      )>, dateFilters: Array<(
        { __typename?: 'TimelineViewConfigDateFilterNexusType' }
        & Pick<TimelineViewConfigDateFilterNexusType, 'field' | 'mode' | 'valueISOString'>
      )>, displayDates: Array<(
        { __typename?: 'TimelineViewConfigDisplayDateNexusType' }
        & Pick<TimelineViewConfigDisplayDateNexusType, 'field' | 'color'>
      )>, sort?: Maybe<(
        { __typename?: 'TimelineViewConfigOrderByNexusType' }
        & Pick<TimelineViewConfigOrderByNexusType, 'field' | 'direction'>
      )> }
    )> }
  )> }
);

export type SetDefaultTimelineViewMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type SetDefaultTimelineViewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultTimelineView'>
);

export type UpsertTimelineViewMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  query: Scalars['String'];
  config: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpsertTimelineViewMutation = (
  { __typename?: 'Mutation' }
  & { upsertTimelineView: (
    { __typename?: 'TimelineViewNexusType' }
    & Pick<TimelineViewNexusType, 'id' | 'timelineViewUUId' | 'name' | 'query'>
    & { definition?: Maybe<(
      { __typename?: 'TimelineViewConfigNexusType' }
      & { filters: Array<(
        { __typename?: 'FieldValuesNexusType' }
        & Pick<FieldValuesNexusType, 'field' | 'values'>
      )>, displayDates: Array<(
        { __typename?: 'TimelineViewConfigDisplayDateNexusType' }
        & Pick<TimelineViewConfigDisplayDateNexusType, 'field' | 'color'>
      )>, sort?: Maybe<(
        { __typename?: 'TimelineViewConfigOrderByNexusType' }
        & Pick<TimelineViewConfigOrderByNexusType, 'field' | 'direction'>
      )> }
    )> }
  ) }
);

export type CreateUpdateMutationVariables = Exact<{
  privOppId: Scalars['String'];
}>;


export type CreateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { createUpdate: (
    { __typename?: 'Update' }
    & Pick<Update, 'id'>
  ) }
);

export type CreateUpdateFromTemplateMutationVariables = Exact<{
  privOppId: Scalars['String'];
  updateTemplateUUId: Scalars['String'];
}>;


export type CreateUpdateFromTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateFromTemplate: (
    { __typename?: 'Update' }
    & Pick<Update, 'id'>
  ) }
);

export type GetUpdateQueryVariables = Exact<{
  updateUUId: Scalars['String'];
}>;


export type GetUpdateQuery = (
  { __typename?: 'Query' }
  & { getUpdate: (
    { __typename?: 'Update' }
    & Pick<Update, 'id' | 'updateId' | 'hasUpdateV2Payload' | 'payload'>
    & { updateTemplate?: Maybe<(
      { __typename?: 'UpdateTemplate' }
      & Pick<UpdateTemplate, 'id' | 'updateTemplateId' | 'payloadV2'>
    )> }
  ) }
);

export type CreateUpdateTemplateMutationVariables = Exact<{
  updateTemplateHTML: Scalars['String'];
  pipelineId: Scalars['Int'];
}>;


export type CreateUpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateTemplate: (
    { __typename?: 'UpdateTemplate' }
    & Pick<UpdateTemplate, 'updateTemplateId'>
  ) }
);

export type GetUpdateTemplateQueryVariables = Exact<{
  updateTemplateUUId: Scalars['String'];
}>;


export type GetUpdateTemplateQuery = (
  { __typename?: 'Query' }
  & { getUpdateTemplate: (
    { __typename?: 'UpdateTemplate' }
    & Pick<UpdateTemplate, 'id' | 'updateTemplateId' | 'payloadV2'>
  ) }
);

export type UpdateUpdateTemplateMutationVariables = Exact<{
  updateTemplateUUId: Scalars['String'];
  updateTemplateHTML: Scalars['String'];
}>;


export type UpdateUpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateUpdateTemplate: (
    { __typename?: 'UpdateTemplate' }
    & Pick<UpdateTemplate, 'updateTemplateId'>
  ) }
);

export type GetUploadPipelineJobQueryVariables = Exact<{
  uploadId: Scalars['String'];
}>;


export type GetUploadPipelineJobQuery = (
  { __typename?: 'Query' }
  & { getUploadPipelineJob?: Maybe<(
    { __typename?: 'UploadPipelineJob' }
    & Pick<UploadPipelineJob, 'id' | 'uploadId' | 'status' | 'progressPercent'>
  )> }
);

export type CreateUploadPipelineJobMutationVariables = Exact<{
  pipelineId: Scalars['String'];
  flatfileBatchId: Scalars['String'];
  mode: UploaderMode;
}>;


export type CreateUploadPipelineJobMutation = (
  { __typename?: 'Mutation' }
  & { createUploadPipelineJob?: Maybe<(
    { __typename?: 'UploadPipelineJob' }
    & Pick<UploadPipelineJob, 'uploadId' | 'status'>
  )> }
);

export type CheckVerificationMutationVariables = Exact<{
  method: Scalars['String'];
  code: Scalars['String'];
}>;


export type CheckVerificationMutation = (
  { __typename?: 'Mutation' }
  & { checkVerification?: Maybe<(
    { __typename?: 'MeGraphQLType' }
    & { workspace: (
      { __typename?: 'Workspace' }
      & WorkspaceConfigFragment
    ) }
    & MeFragment
  )> }
);

export type GetEmailPrefsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailPrefsQuery = (
  { __typename?: 'Query' }
  & { getMe: (
    { __typename?: 'MeGraphQLType' }
    & Pick<MeGraphQlType, 'id' | 'userId' | 'emailPreferences' | 'fullName' | 'isActive' | 'isZombie' | 'emailSubscriptions'>
  ) }
);

export type GetUsersQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userId' | 'email' | 'firstName' | 'lastName'>
  )>> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'isVerifiedEmail' | 'workPhone' | 'firstName' | 'lastName' | 'mfaMethods'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'scope' | 'crudType'>
        )> }
      )>, workspace: (
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'id' | 'workspaceId' | 'ciospIndex' | 'ebuyIndex' | 'symphonyIndex' | 'enableGoogleSSO' | 'enableMicrosoftSSO' | 'enablePasswordLogin' | 'enforceMfa' | 'govWinEnabled' | 'inviteOnly' | 'mfaMethods' | 'name' | 'primaryLogo' | 'seaportIndex' | 'sidebarLogo' | 'workspaceAbbrev'>
      ) }
    ) }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPasswordEmail'>
);

export type SearchUsersQueryVariables = Exact<{
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchUsers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userId' | 'email' | 'firstName' | 'lastName'>
  )>> }
);

export type SignupMutationVariables = Exact<{
  recaptchaToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  companyName: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signup'>
);

export type UpdateUserEmailPreferencesMutationVariables = Exact<{
  emailPreferences: Scalars['Json'];
}>;


export type UpdateUserEmailPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateUserEmailPreferences?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'emailPreferences'>
  )> }
);

export type UpdateCurrentUserWorkspaceMutationVariables = Exact<{
  userWorkspaceId: Scalars['String'];
}>;


export type UpdateCurrentUserWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUserWorkspace?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { workspace: (
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'id' | 'name'>
    ) }
  )> }
);

export type InviteUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  invite?: Maybe<Scalars['Boolean']>;
  stripeProductId?: Maybe<Scalars['String']>;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type SetDefaultPipelineMutationVariables = Exact<{
  pipelineId: Scalars['String'];
  viewId?: Maybe<Scalars['String']>;
}>;


export type SetDefaultPipelineMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultPipeline?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userId'>
    & { defaultPipelineView?: Maybe<(
      { __typename?: 'PipelineView' }
      & Pick<PipelineView, 'id' | 'name'>
    )>, defaultPipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'name' | 'pipelineId'>
    )> }
  )> }
);

export type RemoveDefaultPipelineMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveDefaultPipelineMutation = (
  { __typename?: 'Mutation' }
  & { removeDefaultPipeline?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'userId'>
    & { defaultPipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'pipelineId'>
    )> }
  )> }
);

export type SendVerificationMutationVariables = Exact<{
  method: Scalars['String'];
}>;


export type SendVerificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendVerification'>
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'userId' | 'firstName' | 'lastName' | 'fullName'>
  )> }
);

export type ActivateInviteMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  invitationToken: Scalars['String'];
}>;


export type ActivateInviteMutation = (
  { __typename?: 'Mutation' }
  & { activateInvite?: Maybe<(
    { __typename?: 'AuthPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'email' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type ToggleEmailSubscriptionMutationVariables = Exact<{
  oppId: Scalars['String'];
}>;


export type ToggleEmailSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { toggleEmailSubscription?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { emailSubscriptions: Array<(
      { __typename?: 'PrivOpp' }
      & Pick<PrivOpp, 'id' | 'oppId'>
    )> }
  )> }
);

export type ActivateUserWorkspaceMutationVariables = Exact<{
  userWorkspaceUUId: Scalars['String'];
}>;


export type ActivateUserWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { activateUserWorkspace?: Maybe<(
    { __typename?: 'UserWorkspace' }
    & Pick<UserWorkspace, 'id' | 'userWorkspaceUUId' | 'workspaceName' | 'workspaceId' | 'workspaceUUId' | 'userFullName' | 'userFirstName' | 'userLastName' | 'roleName'>
  )> }
);

export type DeactivateUserWorkspaceMutationVariables = Exact<{
  userWorkspaceUUId: Scalars['String'];
}>;


export type DeactivateUserWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { deactivateUserWorkspace?: Maybe<(
    { __typename?: 'UserWorkspace' }
    & Pick<UserWorkspace, 'id' | 'workspaceName' | 'workspaceId' | 'workspaceUUId' | 'userFullName' | 'userFirstName' | 'userLastName' | 'roleName'>
  )> }
);

export type DeleteUserWorkspaceMutationVariables = Exact<{
  userWorkspaceUUId: Scalars['String'];
}>;


export type DeleteUserWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserWorkspace?: Maybe<(
    { __typename?: 'UserWorkspace' }
    & Pick<UserWorkspace, 'id' | 'userWorkspaceUUId' | 'workspaceName' | 'workspaceId' | 'workspaceUUId' | 'userFullName' | 'userFirstName' | 'userLastName' | 'roleName'>
  )> }
);

export type UpdateUserWorkspaceRoleMutationVariables = Exact<{
  userWorkspaceUUId: Scalars['String'];
  roleId: Scalars['String'];
}>;


export type UpdateUserWorkspaceRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateUserWorkspaceRole?: Maybe<(
    { __typename?: 'UserWorkspace' }
    & Pick<UserWorkspace, 'id' | 'userWorkspaceUUId' | 'workspaceName' | 'workspaceId' | 'workspaceUUId' | 'userFullName' | 'userFirstName' | 'userLastName' | 'roleName'>
  )> }
);

export type GenerateZapierApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateZapierApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { generateZapierApiKey?: Maybe<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'zapierApiKey'>
  )> }
);

export type WorkspaceConfigFragment = (
  { __typename?: 'Workspace' }
  & Pick<Workspace, 'ciospIndex' | 'ebuyIndex' | 'symphonyIndex' | 'enableGoogleSSO' | 'enableMicrosoftSSO' | 'enablePasswordLogin' | 'enforceMfa' | 'govWinEnabled' | 'id' | 'inviteOnly' | 'mfaMethods' | 'name' | 'primaryLogo' | 'seaportIndex' | 'sidebarLogo' | 'workspaceAbbrev' | 'workspaceId' | 'alexandria' | 'zapier' | 'zapierApiKey' | 'documentChat' | 'updatesV2' | 'enforceDirectoryProvisioning' | 'restrictRoleProvisioningToDirectory' | 'enableProposalManagement'>
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'isZombie'>
  )> }
);

export type GetWorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspaceQuery = (
  { __typename?: 'Query' }
  & { getWorkspace: (
    { __typename?: 'Workspace' }
    & WorkspaceConfigFragment
  ) }
);

export type WorkspaceBillingPortalMutationVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceBillingPortalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'workspaceBillingPortal'>
);

export type CreateWorkspaceMutationVariables = Exact<{
  workspaceName: Scalars['String'];
  emailDomain: Scalars['String'];
}>;


export type CreateWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { createWorkspace?: Maybe<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'name' | 'workspaceId'>
  )> }
);

export type UpdateWorkspaceMutationVariables = Exact<{
  ciospEmail?: Maybe<Scalars['String']>;
  ciospPassword?: Maybe<Scalars['String']>;
  ebuyPassword?: Maybe<Scalars['String']>;
  symphonyPassword?: Maybe<Scalars['String']>;
  enableGoogleSSO?: Maybe<Scalars['Boolean']>;
  enableMicrosoftSSO?: Maybe<Scalars['Boolean']>;
  enablePasswordLogin?: Maybe<Scalars['Boolean']>;
  enforceMfa?: Maybe<Scalars['Boolean']>;
  govWinClientId?: Maybe<Scalars['String']>;
  govWinClientSecret?: Maybe<Scalars['String']>;
  govWinPassword?: Maybe<Scalars['String']>;
  govWinUsername?: Maybe<Scalars['String']>;
  inviteOnly?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  workspaceAbbrev?: Maybe<Scalars['String']>;
}>;


export type UpdateWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkspace?: Maybe<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'workspaceId' | 'name' | 'workspaceAbbrev' | 'enforceMfa' | 'inviteOnly' | 'enableGoogleSSO' | 'enableMicrosoftSSO' | 'enablePasswordLogin' | 'primaryLogo' | 'sidebarLogo'>
  )> }
);

export type GetWorkspaceSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspaceSubscriptionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkspaceSubscription'>
);

export type GetAdminPortalLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminPortalLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAdminPortalLink'>
);

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProducts'>
);

export type GetWorkspaceLogosQueryVariables = Exact<{
  workspaceHumanId: Scalars['String'];
}>;


export type GetWorkspaceLogosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkspaceLogos'>
);

export type CheckGovWinAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckGovWinAuthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkGovWinAuth'>
);

export type GetWorkspaceRolesAndUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspaceRolesAndUsersQuery = (
  { __typename?: 'Query' }
  & { getWorkspaceRolesAndUsers: (
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'name' | 'workspaceAbbrev' | 'enforceMfa' | 'ebuyEmail' | 'ciospEmail' | 'ciospEmailEndpoint' | 'hasEbuyPassword' | 'inviteOnly' | 'ebuyIndex' | 'symphonyIndex' | 'symphonyEmail' | 'enableGoogleSSO' | 'enableMicrosoftSSO' | 'enablePasswordLogin' | 'freeTrial' | 'govWinUsername' | 'govWinClientId' | 'hasGovWinClientSecret' | 'hasGovWinPassword' | 'hasStripeSubscription' | 'isPayingCustomer' | 'enforceDirectoryProvisioning' | 'restrictRoleProvisioningToDirectory'>
    & { userWorkspaces: Array<(
      { __typename?: 'UserWorkspace' }
      & Pick<UserWorkspace, 'id' | 'userWorkspaceUUId' | 'isActive' | 'createdAt' | 'userUUId' | 'userFullName' | 'userFirstName' | 'userLastName' | 'userEmail' | 'userIsVerifiedEmail' | 'userIsZombie' | 'productName' | 'userIsActive' | 'roleName' | 'roleUUId'>
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userId' | 'firstName' | 'lastName' | 'email' | 'invited' | 'invitedAt' | 'isActive' | 'isZombie' | 'productName' | 'isVerifiedEmail'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'scope' | 'crudType'>
        )> }
      )> }
    )>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'roleId' | 'name' | 'usersCount'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'scope' | 'crudType'>
      )> }
    )> }
  ) }
);

export const MeFragmentDoc = gql`
    fragment Me on MeGraphQLType {
  id
  userId
  email
  fullName
  firstName
  lastName
  isVerifiedEmail
  isActive
  mfaMethods
  TOBEREMOVED_pipelinesWithUserManagementEnabled
  TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess
  permissions {
    includeResourceId
    excludeResourceId
    includeOperationType
    excludeOperationType
    includeResourceType
    excludeResourceType
  }
  defaultPipeline {
    id
    pipelineId
  }
  defaultPipelineView {
    id
    name
  }
  defaultTimelineView {
    id
    timelineViewUUId
    name
  }
  emailPreferences
  userWorkspaces {
    id
    workspaceName
    workspaceId
    workspaceUUId
  }
  workspace {
    id
    name
    workspaceId
    freeTrial
    isPayingCustomer
    alexandria
  }
  role {
    id
    name
    permissions {
      id
      scope
      crudType
    }
  }
  workspaceId
}
    `;
export const PrivOppPartsFragmentDoc = gql`
    fragment PrivOppParts on PrivOpp {
  __typename
  id
  winningPrice
  createdAt
  updatedAt
  isArchived
  isBid
  isWin
  govWinId
  pipelineId
  name
  canonId
  oppId
  sol
  prevSol
  award
  lossReason
  currentScores
  metadata
  cachedGovWinDetails
  createdBy {
    id
    email
    firstName
    lastName
  }
  notes {
    __typename
    id
    noteId
    createdAt
    payload
    payloadType
    noteType
    plainTextContent
    contacts {
      __typename
      id
      firstName
      lastName
      fullName
      contactId
    }
    createdByFirstName
    createdByLastName
    createdBy {
      __typename
      id
      firstName
      lastName
      userId
    }
  }
  captureTeam {
    __typename
    id
    captureTeamId
    name
    users {
      __typename
      id
      userId
      firstName
      lastName
    }
  }
  client {
    __typename
    id
    orgId
    name
    metadata
  }
  privOppStage {
    __typename
    id
    posId
    stageId
    stage {
      __typename
      id
      stageId
      stageId
      name
    }
  }
  tags {
    __typename
    id
    color
    tagId
    name
  }
  teams {
    __typename
    id
    teamId
    incumbentTeam
    isWinningTeam
    workspaceTeam
    primeId
    prime {
      __typename
      id
    }
    privOrg {
      __typename
      id
      orgId
      name
    }
  }
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  createdAt
  taskId
  dueDate
  status
  description
  parent {
    id
    taskId
    description
  }
  contact {
    id
    firstName
    lastName
  }
  org {
    id
    name
  }
  privOpp {
    id
    canonId
  }
  assignees {
    id
    userId
    firstName
    lastName
  }
}
    `;
export const WorkspaceConfigFragmentDoc = gql`
    fragment WorkspaceConfig on Workspace {
  ciospIndex
  ebuyIndex
  symphonyIndex
  enableGoogleSSO
  enableMicrosoftSSO
  enablePasswordLogin
  enforceMfa
  govWinEnabled
  id
  inviteOnly
  mfaMethods
  name
  primaryLogo
  seaportIndex
  sidebarLogo
  workspaceAbbrev
  workspaceId
  alexandria
  zapier
  zapierApiKey
  documentChat
  updatesV2
  enforceDirectoryProvisioning
  restrictRoleProvisioningToDirectory
  enableProposalManagement
  users {
    id
    userId
    email
    firstName
    lastName
    isZombie
  }
}
    `;
export const ChatDocumentChatDocument = gql`
    mutation chatDocumentChat($query: String!, $documentChatUUId: String!) {
  chatDocumentChat(query: $query, documentChatUUId: $documentChatUUId) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type ChatDocumentChatMutationFn = Apollo.MutationFunction<ChatDocumentChatMutation, ChatDocumentChatMutationVariables>;

/**
 * __useChatDocumentChatMutation__
 *
 * To run a mutation, you first call `useChatDocumentChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatDocumentChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatDocumentChatMutation, { data, loading, error }] = useChatDocumentChatMutation({
 *   variables: {
 *      query: // value for 'query'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useChatDocumentChatMutation(baseOptions?: Apollo.MutationHookOptions<ChatDocumentChatMutation, ChatDocumentChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatDocumentChatMutation, ChatDocumentChatMutationVariables>(ChatDocumentChatDocument, options);
      }
export type ChatDocumentChatMutationHookResult = ReturnType<typeof useChatDocumentChatMutation>;
export type ChatDocumentChatMutationResult = Apollo.MutationResult<ChatDocumentChatMutation>;
export type ChatDocumentChatMutationOptions = Apollo.BaseMutationOptions<ChatDocumentChatMutation, ChatDocumentChatMutationVariables>;
export const ChatDocumentChatStreamingDocument = gql`
    mutation chatDocumentChatStreaming($query: String!, $documentChatUUId: String!) {
  chatDocumentChatStreaming(query: $query, documentChatUUId: $documentChatUUId) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type ChatDocumentChatStreamingMutationFn = Apollo.MutationFunction<ChatDocumentChatStreamingMutation, ChatDocumentChatStreamingMutationVariables>;

/**
 * __useChatDocumentChatStreamingMutation__
 *
 * To run a mutation, you first call `useChatDocumentChatStreamingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatDocumentChatStreamingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatDocumentChatStreamingMutation, { data, loading, error }] = useChatDocumentChatStreamingMutation({
 *   variables: {
 *      query: // value for 'query'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useChatDocumentChatStreamingMutation(baseOptions?: Apollo.MutationHookOptions<ChatDocumentChatStreamingMutation, ChatDocumentChatStreamingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatDocumentChatStreamingMutation, ChatDocumentChatStreamingMutationVariables>(ChatDocumentChatStreamingDocument, options);
      }
export type ChatDocumentChatStreamingMutationHookResult = ReturnType<typeof useChatDocumentChatStreamingMutation>;
export type ChatDocumentChatStreamingMutationResult = Apollo.MutationResult<ChatDocumentChatStreamingMutation>;
export type ChatDocumentChatStreamingMutationOptions = Apollo.BaseMutationOptions<ChatDocumentChatStreamingMutation, ChatDocumentChatStreamingMutationVariables>;
export const CreateDocumentChatDocument = gql`
    mutation createDocumentChat($documents: [GqlChatDocumentRequest!]!, $listings: [GqlListingRequest!], $systemPrompt: GqlDocumentChatSystemPrompt!, $title: String, $sourcesKey: String!) {
  createDocumentChat(
    documents: $documents
    listings: $listings
    systemPrompt: $systemPrompt
    title: $title
    sourcesKey: $sourcesKey
  ) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type CreateDocumentChatMutationFn = Apollo.MutationFunction<CreateDocumentChatMutation, CreateDocumentChatMutationVariables>;

/**
 * __useCreateDocumentChatMutation__
 *
 * To run a mutation, you first call `useCreateDocumentChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentChatMutation, { data, loading, error }] = useCreateDocumentChatMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *      listings: // value for 'listings'
 *      systemPrompt: // value for 'systemPrompt'
 *      title: // value for 'title'
 *      sourcesKey: // value for 'sourcesKey'
 *   },
 * });
 */
export function useCreateDocumentChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentChatMutation, CreateDocumentChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentChatMutation, CreateDocumentChatMutationVariables>(CreateDocumentChatDocument, options);
      }
export type CreateDocumentChatMutationHookResult = ReturnType<typeof useCreateDocumentChatMutation>;
export type CreateDocumentChatMutationResult = Apollo.MutationResult<CreateDocumentChatMutation>;
export type CreateDocumentChatMutationOptions = Apollo.BaseMutationOptions<CreateDocumentChatMutation, CreateDocumentChatMutationVariables>;
export const CreateInlinePromptUsageDocument = gql`
    mutation createInlinePromptUsage($name: String, $additionalInstructions: String, $proposalDocumentUUId: String, $opportunityUUId: String, $updateUUId: String, $richTextDocumentUUId: String, $documentType: GqlInlinePromptUsageDocumentKind!, $promptUsageInput: [GqlPromptUsageInput!]!) {
  createInlinePromptUsage(
    name: $name
    additionalInstructions: $additionalInstructions
    proposalDocumentUUId: $proposalDocumentUUId
    opportunityUUId: $opportunityUUId
    documentType: $documentType
    richTextDocumentUUId: $richTextDocumentUUId
    updateUUId: $updateUUId
    promptUsageInput: $promptUsageInput
  ) {
    id
    uuid
    name
    inputs
    additionalInstructions
    chats {
      documentChatUUId
      title
      lockUntil
      createdByUserId
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateInlinePromptUsageMutationFn = Apollo.MutationFunction<CreateInlinePromptUsageMutation, CreateInlinePromptUsageMutationVariables>;

/**
 * __useCreateInlinePromptUsageMutation__
 *
 * To run a mutation, you first call `useCreateInlinePromptUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInlinePromptUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInlinePromptUsageMutation, { data, loading, error }] = useCreateInlinePromptUsageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      additionalInstructions: // value for 'additionalInstructions'
 *      proposalDocumentUUId: // value for 'proposalDocumentUUId'
 *      opportunityUUId: // value for 'opportunityUUId'
 *      updateUUId: // value for 'updateUUId'
 *      richTextDocumentUUId: // value for 'richTextDocumentUUId'
 *      documentType: // value for 'documentType'
 *      promptUsageInput: // value for 'promptUsageInput'
 *   },
 * });
 */
export function useCreateInlinePromptUsageMutation(baseOptions?: Apollo.MutationHookOptions<CreateInlinePromptUsageMutation, CreateInlinePromptUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInlinePromptUsageMutation, CreateInlinePromptUsageMutationVariables>(CreateInlinePromptUsageDocument, options);
      }
export type CreateInlinePromptUsageMutationHookResult = ReturnType<typeof useCreateInlinePromptUsageMutation>;
export type CreateInlinePromptUsageMutationResult = Apollo.MutationResult<CreateInlinePromptUsageMutation>;
export type CreateInlinePromptUsageMutationOptions = Apollo.BaseMutationOptions<CreateInlinePromptUsageMutation, CreateInlinePromptUsageMutationVariables>;
export const CreatePromptUsageDocument = gql`
    mutation createPromptUsage($templateUUId: String!, $promptUsageInput: [GqlPromptUsageInput!]!, $name: String, $additionalInstructions: String, $proposalDocumentUUId: String, $opportunityUUId: String, $authorization: GqlDocumentChatAuthorizationContext) {
  createPromptUsage(
    templateUUId: $templateUUId
    promptUsageInput: $promptUsageInput
    name: $name
    additionalInstructions: $additionalInstructions
    proposalDocumentUUId: $proposalDocumentUUId
    opportunityUUId: $opportunityUUId
    authorization: $authorization
  ) {
    id
    uuid
    name
    additionalInstructions
    chats {
      documentChatUUId
      title
      lockUntil
      createdByUserId
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreatePromptUsageMutationFn = Apollo.MutationFunction<CreatePromptUsageMutation, CreatePromptUsageMutationVariables>;

/**
 * __useCreatePromptUsageMutation__
 *
 * To run a mutation, you first call `useCreatePromptUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromptUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromptUsageMutation, { data, loading, error }] = useCreatePromptUsageMutation({
 *   variables: {
 *      templateUUId: // value for 'templateUUId'
 *      promptUsageInput: // value for 'promptUsageInput'
 *      name: // value for 'name'
 *      additionalInstructions: // value for 'additionalInstructions'
 *      proposalDocumentUUId: // value for 'proposalDocumentUUId'
 *      opportunityUUId: // value for 'opportunityUUId'
 *      authorization: // value for 'authorization'
 *   },
 * });
 */
export function useCreatePromptUsageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromptUsageMutation, CreatePromptUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromptUsageMutation, CreatePromptUsageMutationVariables>(CreatePromptUsageDocument, options);
      }
export type CreatePromptUsageMutationHookResult = ReturnType<typeof useCreatePromptUsageMutation>;
export type CreatePromptUsageMutationResult = Apollo.MutationResult<CreatePromptUsageMutation>;
export type CreatePromptUsageMutationOptions = Apollo.BaseMutationOptions<CreatePromptUsageMutation, CreatePromptUsageMutationVariables>;
export const DeleteDocumentChatDocument = gql`
    mutation deleteDocumentChat($documentChatUUId: String!) {
  deleteDocumentChat(documentChatUUId: $documentChatUUId)
}
    `;
export type DeleteDocumentChatMutationFn = Apollo.MutationFunction<DeleteDocumentChatMutation, DeleteDocumentChatMutationVariables>;

/**
 * __useDeleteDocumentChatMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentChatMutation, { data, loading, error }] = useDeleteDocumentChatMutation({
 *   variables: {
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useDeleteDocumentChatMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentChatMutation, DeleteDocumentChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentChatMutation, DeleteDocumentChatMutationVariables>(DeleteDocumentChatDocument, options);
      }
export type DeleteDocumentChatMutationHookResult = ReturnType<typeof useDeleteDocumentChatMutation>;
export type DeleteDocumentChatMutationResult = Apollo.MutationResult<DeleteDocumentChatMutation>;
export type DeleteDocumentChatMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentChatMutation, DeleteDocumentChatMutationVariables>;
export const GenerateComplianceMatrixDocument = gql`
    mutation generateComplianceMatrix($filename: String, $promptUsageInput: [GqlPromptUsageInput!]!, $sharepointSiteId: String!, $opportunityUUId: String!, $authorization: GqlDocumentChatAuthorizationContext) {
  generateComplianceMatrix(
    filename: $filename
    promptUsageInput: $promptUsageInput
    sharepointSiteId: $sharepointSiteId
    opportunityUUId: $opportunityUUId
    authorization: $authorization
  ) {
    uuid
    status
  }
}
    `;
export type GenerateComplianceMatrixMutationFn = Apollo.MutationFunction<GenerateComplianceMatrixMutation, GenerateComplianceMatrixMutationVariables>;

/**
 * __useGenerateComplianceMatrixMutation__
 *
 * To run a mutation, you first call `useGenerateComplianceMatrixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateComplianceMatrixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateComplianceMatrixMutation, { data, loading, error }] = useGenerateComplianceMatrixMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      promptUsageInput: // value for 'promptUsageInput'
 *      sharepointSiteId: // value for 'sharepointSiteId'
 *      opportunityUUId: // value for 'opportunityUUId'
 *      authorization: // value for 'authorization'
 *   },
 * });
 */
export function useGenerateComplianceMatrixMutation(baseOptions?: Apollo.MutationHookOptions<GenerateComplianceMatrixMutation, GenerateComplianceMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateComplianceMatrixMutation, GenerateComplianceMatrixMutationVariables>(GenerateComplianceMatrixDocument, options);
      }
export type GenerateComplianceMatrixMutationHookResult = ReturnType<typeof useGenerateComplianceMatrixMutation>;
export type GenerateComplianceMatrixMutationResult = Apollo.MutationResult<GenerateComplianceMatrixMutation>;
export type GenerateComplianceMatrixMutationOptions = Apollo.BaseMutationOptions<GenerateComplianceMatrixMutation, GenerateComplianceMatrixMutationVariables>;
export const GetComplianceMatrixPromptTemplateDocument = gql`
    query getComplianceMatrixPromptTemplate {
  getComplianceMatrixPromptTemplate {
    id
    uuid
    name
    schema {
      label
      description
      guide
      fields {
        key
        type
        label
        guide
        placeholder
        required
        defaultValue
      }
      additionalInstructions {
        label
        guide
      }
    }
  }
}
    `;

/**
 * __useGetComplianceMatrixPromptTemplateQuery__
 *
 * To run a query within a React component, call `useGetComplianceMatrixPromptTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceMatrixPromptTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceMatrixPromptTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetComplianceMatrixPromptTemplateQuery(baseOptions?: Apollo.QueryHookOptions<GetComplianceMatrixPromptTemplateQuery, GetComplianceMatrixPromptTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComplianceMatrixPromptTemplateQuery, GetComplianceMatrixPromptTemplateQueryVariables>(GetComplianceMatrixPromptTemplateDocument, options);
      }
export function useGetComplianceMatrixPromptTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComplianceMatrixPromptTemplateQuery, GetComplianceMatrixPromptTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComplianceMatrixPromptTemplateQuery, GetComplianceMatrixPromptTemplateQueryVariables>(GetComplianceMatrixPromptTemplateDocument, options);
        }
export type GetComplianceMatrixPromptTemplateQueryHookResult = ReturnType<typeof useGetComplianceMatrixPromptTemplateQuery>;
export type GetComplianceMatrixPromptTemplateLazyQueryHookResult = ReturnType<typeof useGetComplianceMatrixPromptTemplateLazyQuery>;
export type GetComplianceMatrixPromptTemplateQueryResult = Apollo.QueryResult<GetComplianceMatrixPromptTemplateQuery, GetComplianceMatrixPromptTemplateQueryVariables>;
export function refetchGetComplianceMatrixPromptTemplateQuery(variables?: GetComplianceMatrixPromptTemplateQueryVariables) {
      return { query: GetComplianceMatrixPromptTemplateDocument, variables: variables }
    }
export const GetDocumentChatDocument = gql`
    query getDocumentChat($documentChatUUId: String!) {
  getDocumentChat(documentChatUUId: $documentChatUUId) {
    documentChatUUId
    title
    chat
    lockUntil
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetDocumentChatQuery__
 *
 * To run a query within a React component, call `useGetDocumentChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentChatQuery({
 *   variables: {
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useGetDocumentChatQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentChatQuery, GetDocumentChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentChatQuery, GetDocumentChatQueryVariables>(GetDocumentChatDocument, options);
      }
export function useGetDocumentChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentChatQuery, GetDocumentChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentChatQuery, GetDocumentChatQueryVariables>(GetDocumentChatDocument, options);
        }
export type GetDocumentChatQueryHookResult = ReturnType<typeof useGetDocumentChatQuery>;
export type GetDocumentChatLazyQueryHookResult = ReturnType<typeof useGetDocumentChatLazyQuery>;
export type GetDocumentChatQueryResult = Apollo.QueryResult<GetDocumentChatQuery, GetDocumentChatQueryVariables>;
export function refetchGetDocumentChatQuery(variables?: GetDocumentChatQueryVariables) {
      return { query: GetDocumentChatDocument, variables: variables }
    }
export const GetDocumentChatsBySourcesKeyDocument = gql`
    query getDocumentChatsBySourcesKey($sourcesKey: String!) {
  getDocumentChatsBySourcesKey(sourcesKey: $sourcesKey) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
    createdBy {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetDocumentChatsBySourcesKeyQuery__
 *
 * To run a query within a React component, call `useGetDocumentChatsBySourcesKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentChatsBySourcesKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentChatsBySourcesKeyQuery({
 *   variables: {
 *      sourcesKey: // value for 'sourcesKey'
 *   },
 * });
 */
export function useGetDocumentChatsBySourcesKeyQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentChatsBySourcesKeyQuery, GetDocumentChatsBySourcesKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentChatsBySourcesKeyQuery, GetDocumentChatsBySourcesKeyQueryVariables>(GetDocumentChatsBySourcesKeyDocument, options);
      }
export function useGetDocumentChatsBySourcesKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentChatsBySourcesKeyQuery, GetDocumentChatsBySourcesKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentChatsBySourcesKeyQuery, GetDocumentChatsBySourcesKeyQueryVariables>(GetDocumentChatsBySourcesKeyDocument, options);
        }
export type GetDocumentChatsBySourcesKeyQueryHookResult = ReturnType<typeof useGetDocumentChatsBySourcesKeyQuery>;
export type GetDocumentChatsBySourcesKeyLazyQueryHookResult = ReturnType<typeof useGetDocumentChatsBySourcesKeyLazyQuery>;
export type GetDocumentChatsBySourcesKeyQueryResult = Apollo.QueryResult<GetDocumentChatsBySourcesKeyQuery, GetDocumentChatsBySourcesKeyQueryVariables>;
export function refetchGetDocumentChatsBySourcesKeyQuery(variables?: GetDocumentChatsBySourcesKeyQueryVariables) {
      return { query: GetDocumentChatsBySourcesKeyDocument, variables: variables }
    }
export const GetOpportunityChatOptionsDocument = gql`
    query getOpportunityChatOptions($opportunityUUId: String, $proposalDocumentUUId: String, $authorization: GqlDocumentChatAuthorizationContext) {
  getOpportunityChatOptions(
    opportunityUUId: $opportunityUUId
    proposalDocumentUUId: $proposalDocumentUUId
    authorization: $authorization
  ) {
    kind
    document {
      documentUrl
      associationKind
      documentId
      listingUrl
      documentIdentifierHelper
    }
    unreachableDocument {
      documentUrl
      associationKind
      documentId
      listingUrl
      documentIdentifierHelper
      reason
    }
    listing {
      listingUrl
      listingTitle
      associatedDocumentUrls
      associationKind
      legacyAssociationKind
      privOppSourceUUId
      privOppSourceName
      listingIdentifierHelper
    }
    sharePointSite {
      name
      siteId
      associatedDocumentUrls
      documentUrlsToFolderPaths
    }
    sharePointSiteError {
      message
    }
    sharePointSiteNotSignedIn {
      name
      siteId
    }
  }
}
    `;

/**
 * __useGetOpportunityChatOptionsQuery__
 *
 * To run a query within a React component, call `useGetOpportunityChatOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityChatOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityChatOptionsQuery({
 *   variables: {
 *      opportunityUUId: // value for 'opportunityUUId'
 *      proposalDocumentUUId: // value for 'proposalDocumentUUId'
 *      authorization: // value for 'authorization'
 *   },
 * });
 */
export function useGetOpportunityChatOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetOpportunityChatOptionsQuery, GetOpportunityChatOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpportunityChatOptionsQuery, GetOpportunityChatOptionsQueryVariables>(GetOpportunityChatOptionsDocument, options);
      }
export function useGetOpportunityChatOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpportunityChatOptionsQuery, GetOpportunityChatOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpportunityChatOptionsQuery, GetOpportunityChatOptionsQueryVariables>(GetOpportunityChatOptionsDocument, options);
        }
export type GetOpportunityChatOptionsQueryHookResult = ReturnType<typeof useGetOpportunityChatOptionsQuery>;
export type GetOpportunityChatOptionsLazyQueryHookResult = ReturnType<typeof useGetOpportunityChatOptionsLazyQuery>;
export type GetOpportunityChatOptionsQueryResult = Apollo.QueryResult<GetOpportunityChatOptionsQuery, GetOpportunityChatOptionsQueryVariables>;
export function refetchGetOpportunityChatOptionsQuery(variables?: GetOpportunityChatOptionsQueryVariables) {
      return { query: GetOpportunityChatOptionsDocument, variables: variables }
    }
export const GetPromptUsageDocument = gql`
    query getPromptUsage($promptUsageUUId: String!) {
  getPromptUsage(promptUsageUUId: $promptUsageUUId) {
    id
    uuid
    name
    inputs
    additionalInstructions
    chats {
      documentChatUUId
      title
      lockUntil
      createdByUserId
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPromptUsageQuery__
 *
 * To run a query within a React component, call `useGetPromptUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromptUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromptUsageQuery({
 *   variables: {
 *      promptUsageUUId: // value for 'promptUsageUUId'
 *   },
 * });
 */
export function useGetPromptUsageQuery(baseOptions: Apollo.QueryHookOptions<GetPromptUsageQuery, GetPromptUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromptUsageQuery, GetPromptUsageQueryVariables>(GetPromptUsageDocument, options);
      }
export function useGetPromptUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromptUsageQuery, GetPromptUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromptUsageQuery, GetPromptUsageQueryVariables>(GetPromptUsageDocument, options);
        }
export type GetPromptUsageQueryHookResult = ReturnType<typeof useGetPromptUsageQuery>;
export type GetPromptUsageLazyQueryHookResult = ReturnType<typeof useGetPromptUsageLazyQuery>;
export type GetPromptUsageQueryResult = Apollo.QueryResult<GetPromptUsageQuery, GetPromptUsageQueryVariables>;
export function refetchGetPromptUsageQuery(variables?: GetPromptUsageQueryVariables) {
      return { query: GetPromptUsageDocument, variables: variables }
    }
export const ModifyOpportunityChatOptionDocument = gql`
    mutation modifyOpportunityChatOption($documentChatUUId: String!, $request: GqlOpportunityOptionRequest!, $mode: String!, $authorization: GqlDocumentChatAuthorizationContext) {
  modifyOpportunityChatOption(
    mode: $mode
    request: $request
    documentChatUUId: $documentChatUUId
    authorization: $authorization
  ) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type ModifyOpportunityChatOptionMutationFn = Apollo.MutationFunction<ModifyOpportunityChatOptionMutation, ModifyOpportunityChatOptionMutationVariables>;

/**
 * __useModifyOpportunityChatOptionMutation__
 *
 * To run a mutation, you first call `useModifyOpportunityChatOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOpportunityChatOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOpportunityChatOptionMutation, { data, loading, error }] = useModifyOpportunityChatOptionMutation({
 *   variables: {
 *      documentChatUUId: // value for 'documentChatUUId'
 *      request: // value for 'request'
 *      mode: // value for 'mode'
 *      authorization: // value for 'authorization'
 *   },
 * });
 */
export function useModifyOpportunityChatOptionMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOpportunityChatOptionMutation, ModifyOpportunityChatOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOpportunityChatOptionMutation, ModifyOpportunityChatOptionMutationVariables>(ModifyOpportunityChatOptionDocument, options);
      }
export type ModifyOpportunityChatOptionMutationHookResult = ReturnType<typeof useModifyOpportunityChatOptionMutation>;
export type ModifyOpportunityChatOptionMutationResult = Apollo.MutationResult<ModifyOpportunityChatOptionMutation>;
export type ModifyOpportunityChatOptionMutationOptions = Apollo.BaseMutationOptions<ModifyOpportunityChatOptionMutation, ModifyOpportunityChatOptionMutationVariables>;
export const QueryWithProposalContentDocumentChatStreamingDocument = gql`
    mutation queryWithProposalContentDocumentChatStreaming($request: GqlQueryWithProposalContentRequest!, $documentChatUUId: String!) {
  queryWithProposalContentDocumentChatStreaming(
    request: $request
    documentChatUUId: $documentChatUUId
  ) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type QueryWithProposalContentDocumentChatStreamingMutationFn = Apollo.MutationFunction<QueryWithProposalContentDocumentChatStreamingMutation, QueryWithProposalContentDocumentChatStreamingMutationVariables>;

/**
 * __useQueryWithProposalContentDocumentChatStreamingMutation__
 *
 * To run a mutation, you first call `useQueryWithProposalContentDocumentChatStreamingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueryWithProposalContentDocumentChatStreamingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queryWithProposalContentDocumentChatStreamingMutation, { data, loading, error }] = useQueryWithProposalContentDocumentChatStreamingMutation({
 *   variables: {
 *      request: // value for 'request'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useQueryWithProposalContentDocumentChatStreamingMutation(baseOptions?: Apollo.MutationHookOptions<QueryWithProposalContentDocumentChatStreamingMutation, QueryWithProposalContentDocumentChatStreamingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QueryWithProposalContentDocumentChatStreamingMutation, QueryWithProposalContentDocumentChatStreamingMutationVariables>(QueryWithProposalContentDocumentChatStreamingDocument, options);
      }
export type QueryWithProposalContentDocumentChatStreamingMutationHookResult = ReturnType<typeof useQueryWithProposalContentDocumentChatStreamingMutation>;
export type QueryWithProposalContentDocumentChatStreamingMutationResult = Apollo.MutationResult<QueryWithProposalContentDocumentChatStreamingMutation>;
export type QueryWithProposalContentDocumentChatStreamingMutationOptions = Apollo.BaseMutationOptions<QueryWithProposalContentDocumentChatStreamingMutation, QueryWithProposalContentDocumentChatStreamingMutationVariables>;
export const RewritePromptDocumentChatStreamingDocument = gql`
    mutation rewritePromptDocumentChatStreaming($request: GqlProposalPromptRewriteRequest!, $documentChatUUId: String!) {
  rewritePromptDocumentChatStreaming(
    request: $request
    documentChatUUId: $documentChatUUId
  ) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type RewritePromptDocumentChatStreamingMutationFn = Apollo.MutationFunction<RewritePromptDocumentChatStreamingMutation, RewritePromptDocumentChatStreamingMutationVariables>;

/**
 * __useRewritePromptDocumentChatStreamingMutation__
 *
 * To run a mutation, you first call `useRewritePromptDocumentChatStreamingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewritePromptDocumentChatStreamingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewritePromptDocumentChatStreamingMutation, { data, loading, error }] = useRewritePromptDocumentChatStreamingMutation({
 *   variables: {
 *      request: // value for 'request'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useRewritePromptDocumentChatStreamingMutation(baseOptions?: Apollo.MutationHookOptions<RewritePromptDocumentChatStreamingMutation, RewritePromptDocumentChatStreamingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RewritePromptDocumentChatStreamingMutation, RewritePromptDocumentChatStreamingMutationVariables>(RewritePromptDocumentChatStreamingDocument, options);
      }
export type RewritePromptDocumentChatStreamingMutationHookResult = ReturnType<typeof useRewritePromptDocumentChatStreamingMutation>;
export type RewritePromptDocumentChatStreamingMutationResult = Apollo.MutationResult<RewritePromptDocumentChatStreamingMutation>;
export type RewritePromptDocumentChatStreamingMutationOptions = Apollo.BaseMutationOptions<RewritePromptDocumentChatStreamingMutation, RewritePromptDocumentChatStreamingMutationVariables>;
export const StreamComplianceMatrixJobDocument = gql`
    subscription streamComplianceMatrixJob($complianceMatrixJobUUId: String!) {
  streamComplianceMatrixJob(complianceMatrixJobUUId: $complianceMatrixJobUUId) {
    uuid
    status
    subscriptionError
    updatedAt
  }
}
    `;

/**
 * __useStreamComplianceMatrixJobSubscription__
 *
 * To run a query within a React component, call `useStreamComplianceMatrixJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamComplianceMatrixJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamComplianceMatrixJobSubscription({
 *   variables: {
 *      complianceMatrixJobUUId: // value for 'complianceMatrixJobUUId'
 *   },
 * });
 */
export function useStreamComplianceMatrixJobSubscription(baseOptions: Apollo.SubscriptionHookOptions<StreamComplianceMatrixJobSubscription, StreamComplianceMatrixJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StreamComplianceMatrixJobSubscription, StreamComplianceMatrixJobSubscriptionVariables>(StreamComplianceMatrixJobDocument, options);
      }
export type StreamComplianceMatrixJobSubscriptionHookResult = ReturnType<typeof useStreamComplianceMatrixJobSubscription>;
export type StreamComplianceMatrixJobSubscriptionResult = Apollo.SubscriptionResult<StreamComplianceMatrixJobSubscription>;
export const StreamDocumentChatQueryDocument = gql`
    subscription streamDocumentChatQuery($documentChatUUId: String!) {
  streamChatDocumentChatQuery(documentChatUUId: $documentChatUUId) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    subscriptionError
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useStreamDocumentChatQuerySubscription__
 *
 * To run a query within a React component, call `useStreamDocumentChatQuerySubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamDocumentChatQuerySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamDocumentChatQuerySubscription({
 *   variables: {
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useStreamDocumentChatQuerySubscription(baseOptions: Apollo.SubscriptionHookOptions<StreamDocumentChatQuerySubscription, StreamDocumentChatQuerySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StreamDocumentChatQuerySubscription, StreamDocumentChatQuerySubscriptionVariables>(StreamDocumentChatQueryDocument, options);
      }
export type StreamDocumentChatQuerySubscriptionHookResult = ReturnType<typeof useStreamDocumentChatQuerySubscription>;
export type StreamDocumentChatQuerySubscriptionResult = Apollo.SubscriptionResult<StreamDocumentChatQuerySubscription>;
export const UpdateDocumentChatDocumentsDocument = gql`
    mutation updateDocumentChatDocuments($documents: [GqlChatDocumentRequest!]!, $documentChatUUId: String!) {
  updateDocumentChatDocuments(
    documents: $documents
    documentChatUUId: $documentChatUUId
  ) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDocumentChatDocumentsMutationFn = Apollo.MutationFunction<UpdateDocumentChatDocumentsMutation, UpdateDocumentChatDocumentsMutationVariables>;

/**
 * __useUpdateDocumentChatDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentChatDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentChatDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentChatDocumentsMutation, { data, loading, error }] = useUpdateDocumentChatDocumentsMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useUpdateDocumentChatDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentChatDocumentsMutation, UpdateDocumentChatDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentChatDocumentsMutation, UpdateDocumentChatDocumentsMutationVariables>(UpdateDocumentChatDocumentsDocument, options);
      }
export type UpdateDocumentChatDocumentsMutationHookResult = ReturnType<typeof useUpdateDocumentChatDocumentsMutation>;
export type UpdateDocumentChatDocumentsMutationResult = Apollo.MutationResult<UpdateDocumentChatDocumentsMutation>;
export type UpdateDocumentChatDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentChatDocumentsMutation, UpdateDocumentChatDocumentsMutationVariables>;
export const UpdateDocumentChatTitleDocument = gql`
    mutation updateDocumentChatTitle($documentChatUUId: String!, $title: String!) {
  updateDocumentChatTitle(documentChatUUId: $documentChatUUId, title: $title) {
    documentChatUUId
    title
    lockUntil
    chat
    createdByUserId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDocumentChatTitleMutationFn = Apollo.MutationFunction<UpdateDocumentChatTitleMutation, UpdateDocumentChatTitleMutationVariables>;

/**
 * __useUpdateDocumentChatTitleMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentChatTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentChatTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentChatTitleMutation, { data, loading, error }] = useUpdateDocumentChatTitleMutation({
 *   variables: {
 *      documentChatUUId: // value for 'documentChatUUId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateDocumentChatTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentChatTitleMutation, UpdateDocumentChatTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentChatTitleMutation, UpdateDocumentChatTitleMutationVariables>(UpdateDocumentChatTitleDocument, options);
      }
export type UpdateDocumentChatTitleMutationHookResult = ReturnType<typeof useUpdateDocumentChatTitleMutation>;
export type UpdateDocumentChatTitleMutationResult = Apollo.MutationResult<UpdateDocumentChatTitleMutation>;
export type UpdateDocumentChatTitleMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentChatTitleMutation, UpdateDocumentChatTitleMutationVariables>;
export const UpdatePromptUsageDocument = gql`
    mutation updatePromptUsage($promptUsageUUId: String!, $additionalUserInstructions: String, $documentChatUUId: String!) {
  updatePromptUsage(
    promptUsageUUId: $promptUsageUUId
    documentChatUUId: $documentChatUUId
    additionalUserInstructions: $additionalUserInstructions
  ) {
    id
    uuid
    name
    inputs
    additionalInstructions
    chats {
      documentChatUUId
      title
      lockUntil
      createdByUserId
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdatePromptUsageMutationFn = Apollo.MutationFunction<UpdatePromptUsageMutation, UpdatePromptUsageMutationVariables>;

/**
 * __useUpdatePromptUsageMutation__
 *
 * To run a mutation, you first call `useUpdatePromptUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromptUsageMutation, { data, loading, error }] = useUpdatePromptUsageMutation({
 *   variables: {
 *      promptUsageUUId: // value for 'promptUsageUUId'
 *      additionalUserInstructions: // value for 'additionalUserInstructions'
 *      documentChatUUId: // value for 'documentChatUUId'
 *   },
 * });
 */
export function useUpdatePromptUsageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromptUsageMutation, UpdatePromptUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromptUsageMutation, UpdatePromptUsageMutationVariables>(UpdatePromptUsageDocument, options);
      }
export type UpdatePromptUsageMutationHookResult = ReturnType<typeof useUpdatePromptUsageMutation>;
export type UpdatePromptUsageMutationResult = Apollo.MutationResult<UpdatePromptUsageMutation>;
export type UpdatePromptUsageMutationOptions = Apollo.BaseMutationOptions<UpdatePromptUsageMutation, UpdatePromptUsageMutationVariables>;
export const ConvertToTaskOrderDocument = gql`
    mutation convertToTaskOrder($searchOppUUId: String!) {
  convertToTaskOrder(searchOppUUId: $searchOppUUId)
}
    `;
export type ConvertToTaskOrderMutationFn = Apollo.MutationFunction<ConvertToTaskOrderMutation, ConvertToTaskOrderMutationVariables>;

/**
 * __useConvertToTaskOrderMutation__
 *
 * To run a mutation, you first call `useConvertToTaskOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertToTaskOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertToTaskOrderMutation, { data, loading, error }] = useConvertToTaskOrderMutation({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *   },
 * });
 */
export function useConvertToTaskOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConvertToTaskOrderMutation, ConvertToTaskOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertToTaskOrderMutation, ConvertToTaskOrderMutationVariables>(ConvertToTaskOrderDocument, options);
      }
export type ConvertToTaskOrderMutationHookResult = ReturnType<typeof useConvertToTaskOrderMutation>;
export type ConvertToTaskOrderMutationResult = Apollo.MutationResult<ConvertToTaskOrderMutation>;
export type ConvertToTaskOrderMutationOptions = Apollo.BaseMutationOptions<ConvertToTaskOrderMutation, ConvertToTaskOrderMutationVariables>;
export const DeleteKeywordRuleDocument = gql`
    mutation deleteKeywordRule($searchOppUUId: String!, $ruleUUIds: [String!]) {
  deleteKeywordRule(searchOppUUId: $searchOppUUId, ruleUUIds: $ruleUUIds)
}
    `;
export type DeleteKeywordRuleMutationFn = Apollo.MutationFunction<DeleteKeywordRuleMutation, DeleteKeywordRuleMutationVariables>;

/**
 * __useDeleteKeywordRuleMutation__
 *
 * To run a mutation, you first call `useDeleteKeywordRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeywordRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeywordRuleMutation, { data, loading, error }] = useDeleteKeywordRuleMutation({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *      ruleUUIds: // value for 'ruleUUIds'
 *   },
 * });
 */
export function useDeleteKeywordRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeywordRuleMutation, DeleteKeywordRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeywordRuleMutation, DeleteKeywordRuleMutationVariables>(DeleteKeywordRuleDocument, options);
      }
export type DeleteKeywordRuleMutationHookResult = ReturnType<typeof useDeleteKeywordRuleMutation>;
export type DeleteKeywordRuleMutationResult = Apollo.MutationResult<DeleteKeywordRuleMutation>;
export type DeleteKeywordRuleMutationOptions = Apollo.BaseMutationOptions<DeleteKeywordRuleMutation, DeleteKeywordRuleMutationVariables>;
export const DeleteTaskOrderDocument = gql`
    mutation deleteTaskOrder($searchOppUUId: String!) {
  deleteTaskOrder(searchOppUUId: $searchOppUUId)
}
    `;
export type DeleteTaskOrderMutationFn = Apollo.MutationFunction<DeleteTaskOrderMutation, DeleteTaskOrderMutationVariables>;

/**
 * __useDeleteTaskOrderMutation__
 *
 * To run a mutation, you first call `useDeleteTaskOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskOrderMutation, { data, loading, error }] = useDeleteTaskOrderMutation({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *   },
 * });
 */
export function useDeleteTaskOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskOrderMutation, DeleteTaskOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskOrderMutation, DeleteTaskOrderMutationVariables>(DeleteTaskOrderDocument, options);
      }
export type DeleteTaskOrderMutationHookResult = ReturnType<typeof useDeleteTaskOrderMutation>;
export type DeleteTaskOrderMutationResult = Apollo.MutationResult<DeleteTaskOrderMutation>;
export type DeleteTaskOrderMutationOptions = Apollo.BaseMutationOptions<DeleteTaskOrderMutation, DeleteTaskOrderMutationVariables>;
export const ExcludeEmailDocument = gql`
    mutation excludeEmail($searchOppUUId: String!, $baseOppUUId: String!) {
  excludeEmail(searchOppUUId: $searchOppUUId, baseOppUUId: $baseOppUUId)
}
    `;
export type ExcludeEmailMutationFn = Apollo.MutationFunction<ExcludeEmailMutation, ExcludeEmailMutationVariables>;

/**
 * __useExcludeEmailMutation__
 *
 * To run a mutation, you first call `useExcludeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeEmailMutation, { data, loading, error }] = useExcludeEmailMutation({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *      baseOppUUId: // value for 'baseOppUUId'
 *   },
 * });
 */
export function useExcludeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ExcludeEmailMutation, ExcludeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludeEmailMutation, ExcludeEmailMutationVariables>(ExcludeEmailDocument, options);
      }
export type ExcludeEmailMutationHookResult = ReturnType<typeof useExcludeEmailMutation>;
export type ExcludeEmailMutationResult = Apollo.MutationResult<ExcludeEmailMutation>;
export type ExcludeEmailMutationOptions = Apollo.BaseMutationOptions<ExcludeEmailMutation, ExcludeEmailMutationVariables>;
export const GetEmailEndpointsDocument = gql`
    query getEmailEndpoints {
  getEmailEndpoints
}
    `;

/**
 * __useGetEmailEndpointsQuery__
 *
 * To run a query within a React component, call `useGetEmailEndpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailEndpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailEndpointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailEndpointsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailEndpointsQuery, GetEmailEndpointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailEndpointsQuery, GetEmailEndpointsQueryVariables>(GetEmailEndpointsDocument, options);
      }
export function useGetEmailEndpointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailEndpointsQuery, GetEmailEndpointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailEndpointsQuery, GetEmailEndpointsQueryVariables>(GetEmailEndpointsDocument, options);
        }
export type GetEmailEndpointsQueryHookResult = ReturnType<typeof useGetEmailEndpointsQuery>;
export type GetEmailEndpointsLazyQueryHookResult = ReturnType<typeof useGetEmailEndpointsLazyQuery>;
export type GetEmailEndpointsQueryResult = Apollo.QueryResult<GetEmailEndpointsQuery, GetEmailEndpointsQueryVariables>;
export function refetchGetEmailEndpointsQuery(variables?: GetEmailEndpointsQueryVariables) {
      return { query: GetEmailEndpointsDocument, variables: variables }
    }
export const GetOpportunitiesWithSearchOppDocument = gql`
    query getOpportunitiesWithSearchOpp($searchOppUUId: String!) {
  getOpportunitiesWithSearchOpp(searchOppUUId: $searchOppUUId) {
    id
    canonId
    name
    pipeline {
      id
      pipelineId
      name
    }
  }
}
    `;

/**
 * __useGetOpportunitiesWithSearchOppQuery__
 *
 * To run a query within a React component, call `useGetOpportunitiesWithSearchOppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunitiesWithSearchOppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunitiesWithSearchOppQuery({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *   },
 * });
 */
export function useGetOpportunitiesWithSearchOppQuery(baseOptions: Apollo.QueryHookOptions<GetOpportunitiesWithSearchOppQuery, GetOpportunitiesWithSearchOppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpportunitiesWithSearchOppQuery, GetOpportunitiesWithSearchOppQueryVariables>(GetOpportunitiesWithSearchOppDocument, options);
      }
export function useGetOpportunitiesWithSearchOppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpportunitiesWithSearchOppQuery, GetOpportunitiesWithSearchOppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpportunitiesWithSearchOppQuery, GetOpportunitiesWithSearchOppQueryVariables>(GetOpportunitiesWithSearchOppDocument, options);
        }
export type GetOpportunitiesWithSearchOppQueryHookResult = ReturnType<typeof useGetOpportunitiesWithSearchOppQuery>;
export type GetOpportunitiesWithSearchOppLazyQueryHookResult = ReturnType<typeof useGetOpportunitiesWithSearchOppLazyQuery>;
export type GetOpportunitiesWithSearchOppQueryResult = Apollo.QueryResult<GetOpportunitiesWithSearchOppQuery, GetOpportunitiesWithSearchOppQueryVariables>;
export function refetchGetOpportunitiesWithSearchOppQuery(variables?: GetOpportunitiesWithSearchOppQueryVariables) {
      return { query: GetOpportunitiesWithSearchOppDocument, variables: variables }
    }
export const GetOppRulesDocument = gql`
    query getOppRules($searchOppUUId: String!) {
  getOppRules(searchOppUUId: $searchOppUUId)
}
    `;

/**
 * __useGetOppRulesQuery__
 *
 * To run a query within a React component, call `useGetOppRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOppRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOppRulesQuery({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *   },
 * });
 */
export function useGetOppRulesQuery(baseOptions: Apollo.QueryHookOptions<GetOppRulesQuery, GetOppRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOppRulesQuery, GetOppRulesQueryVariables>(GetOppRulesDocument, options);
      }
export function useGetOppRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOppRulesQuery, GetOppRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOppRulesQuery, GetOppRulesQueryVariables>(GetOppRulesDocument, options);
        }
export type GetOppRulesQueryHookResult = ReturnType<typeof useGetOppRulesQuery>;
export type GetOppRulesLazyQueryHookResult = ReturnType<typeof useGetOppRulesLazyQuery>;
export type GetOppRulesQueryResult = Apollo.QueryResult<GetOppRulesQuery, GetOppRulesQueryVariables>;
export function refetchGetOppRulesQuery(variables?: GetOppRulesQueryVariables) {
      return { query: GetOppRulesDocument, variables: variables }
    }
export const GetTaskOrderSourcesDocument = gql`
    query getTaskOrderSources {
  getTaskOrderSources
}
    `;

/**
 * __useGetTaskOrderSourcesQuery__
 *
 * To run a query within a React component, call `useGetTaskOrderSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskOrderSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskOrderSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskOrderSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskOrderSourcesQuery, GetTaskOrderSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskOrderSourcesQuery, GetTaskOrderSourcesQueryVariables>(GetTaskOrderSourcesDocument, options);
      }
export function useGetTaskOrderSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskOrderSourcesQuery, GetTaskOrderSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskOrderSourcesQuery, GetTaskOrderSourcesQueryVariables>(GetTaskOrderSourcesDocument, options);
        }
export type GetTaskOrderSourcesQueryHookResult = ReturnType<typeof useGetTaskOrderSourcesQuery>;
export type GetTaskOrderSourcesLazyQueryHookResult = ReturnType<typeof useGetTaskOrderSourcesLazyQuery>;
export type GetTaskOrderSourcesQueryResult = Apollo.QueryResult<GetTaskOrderSourcesQuery, GetTaskOrderSourcesQueryVariables>;
export function refetchGetTaskOrderSourcesQuery(variables?: GetTaskOrderSourcesQueryVariables) {
      return { query: GetTaskOrderSourcesDocument, variables: variables }
    }
export const UpsertKeywordRuleDocument = gql`
    mutation upsertKeywordRule($searchOppUUId: String!, $keyword: String!) {
  upsertKeywordRule(searchOppUUId: $searchOppUUId, keyword: $keyword)
}
    `;
export type UpsertKeywordRuleMutationFn = Apollo.MutationFunction<UpsertKeywordRuleMutation, UpsertKeywordRuleMutationVariables>;

/**
 * __useUpsertKeywordRuleMutation__
 *
 * To run a mutation, you first call `useUpsertKeywordRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertKeywordRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertKeywordRuleMutation, { data, loading, error }] = useUpsertKeywordRuleMutation({
 *   variables: {
 *      searchOppUUId: // value for 'searchOppUUId'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useUpsertKeywordRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertKeywordRuleMutation, UpsertKeywordRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertKeywordRuleMutation, UpsertKeywordRuleMutationVariables>(UpsertKeywordRuleDocument, options);
      }
export type UpsertKeywordRuleMutationHookResult = ReturnType<typeof useUpsertKeywordRuleMutation>;
export type UpsertKeywordRuleMutationResult = Apollo.MutationResult<UpsertKeywordRuleMutation>;
export type UpsertKeywordRuleMutationOptions = Apollo.BaseMutationOptions<UpsertKeywordRuleMutation, UpsertKeywordRuleMutationVariables>;
export const GetMeDocument = gql`
    query getMe {
  getMe {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export function refetchGetMeQuery(variables?: GetMeQueryVariables) {
      return { query: GetMeDocument, variables: variables }
    }
export const GetAuthInfoDocument = gql`
    query getAuthInfo($anonymousId: String, $email: String, $invitationToken: String, $mode: AuthMode, $recaptchaToken: String, $redirect: String) {
  getAuthInfo(
    anonymousId: $anonymousId
    email: $email
    invitationToken: $invitationToken
    mode: $mode
    recaptchaToken: $recaptchaToken
    redirect: $redirect
  ) {
    authUrls {
      type
      url
    }
    allowPasswordAuth
    allowMicrosoftSSO
    allowGoogleSSO
    inviteOnly
    isExistingAccount
    isVerifiedEmail
    hasPendingInvite
  }
}
    `;

/**
 * __useGetAuthInfoQuery__
 *
 * To run a query within a React component, call `useGetAuthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthInfoQuery({
 *   variables: {
 *      anonymousId: // value for 'anonymousId'
 *      email: // value for 'email'
 *      invitationToken: // value for 'invitationToken'
 *      mode: // value for 'mode'
 *      recaptchaToken: // value for 'recaptchaToken'
 *      redirect: // value for 'redirect'
 *   },
 * });
 */
export function useGetAuthInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthInfoQuery, GetAuthInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthInfoQuery, GetAuthInfoQueryVariables>(GetAuthInfoDocument, options);
      }
export function useGetAuthInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthInfoQuery, GetAuthInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthInfoQuery, GetAuthInfoQueryVariables>(GetAuthInfoDocument, options);
        }
export type GetAuthInfoQueryHookResult = ReturnType<typeof useGetAuthInfoQuery>;
export type GetAuthInfoLazyQueryHookResult = ReturnType<typeof useGetAuthInfoLazyQuery>;
export type GetAuthInfoQueryResult = Apollo.QueryResult<GetAuthInfoQuery, GetAuthInfoQueryVariables>;
export function refetchGetAuthInfoQuery(variables?: GetAuthInfoQueryVariables) {
      return { query: GetAuthInfoDocument, variables: variables }
    }
export const GetJwtDocument = gql`
    query getJWT {
  getJWT
}
    `;

/**
 * __useGetJwtQuery__
 *
 * To run a query within a React component, call `useGetJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJwtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJwtQuery(baseOptions?: Apollo.QueryHookOptions<GetJwtQuery, GetJwtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJwtQuery, GetJwtQueryVariables>(GetJwtDocument, options);
      }
export function useGetJwtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJwtQuery, GetJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJwtQuery, GetJwtQueryVariables>(GetJwtDocument, options);
        }
export type GetJwtQueryHookResult = ReturnType<typeof useGetJwtQuery>;
export type GetJwtLazyQueryHookResult = ReturnType<typeof useGetJwtLazyQuery>;
export type GetJwtQueryResult = Apollo.QueryResult<GetJwtQuery, GetJwtQueryVariables>;
export function refetchGetJwtQuery(variables?: GetJwtQueryVariables) {
      return { query: GetJwtDocument, variables: variables }
    }
export const CaptchaDocument = gql`
    query captcha($token: String!) {
  captcha(captchaToken: $token)
}
    `;

/**
 * __useCaptchaQuery__
 *
 * To run a query within a React component, call `useCaptchaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaptchaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaptchaQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCaptchaQuery(baseOptions: Apollo.QueryHookOptions<CaptchaQuery, CaptchaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaptchaQuery, CaptchaQueryVariables>(CaptchaDocument, options);
      }
export function useCaptchaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaptchaQuery, CaptchaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaptchaQuery, CaptchaQueryVariables>(CaptchaDocument, options);
        }
export type CaptchaQueryHookResult = ReturnType<typeof useCaptchaQuery>;
export type CaptchaLazyQueryHookResult = ReturnType<typeof useCaptchaLazyQuery>;
export type CaptchaQueryResult = Apollo.QueryResult<CaptchaQuery, CaptchaQueryVariables>;
export function refetchCaptchaQuery(variables?: CaptchaQueryVariables) {
      return { query: CaptchaDocument, variables: variables }
    }
export const WhitelistDocument = gql`
    query whitelist {
  whitelist
}
    `;

/**
 * __useWhitelistQuery__
 *
 * To run a query within a React component, call `useWhitelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhitelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhitelistQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhitelistQuery(baseOptions?: Apollo.QueryHookOptions<WhitelistQuery, WhitelistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhitelistQuery, WhitelistQueryVariables>(WhitelistDocument, options);
      }
export function useWhitelistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhitelistQuery, WhitelistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhitelistQuery, WhitelistQueryVariables>(WhitelistDocument, options);
        }
export type WhitelistQueryHookResult = ReturnType<typeof useWhitelistQuery>;
export type WhitelistLazyQueryHookResult = ReturnType<typeof useWhitelistLazyQuery>;
export type WhitelistQueryResult = Apollo.QueryResult<WhitelistQuery, WhitelistQueryVariables>;
export function refetchWhitelistQuery(variables?: WhitelistQueryVariables) {
      return { query: WhitelistDocument, variables: variables }
    }
export const CreateCaptureTeamDocument = gql`
    mutation createCaptureTeam($oppId: Int!, $name: String!, $isProposalContributor: Boolean) {
  createCaptureTeam(
    oppId: $oppId
    name: $name
    isProposalContributor: $isProposalContributor
  ) {
    id
  }
}
    `;
export type CreateCaptureTeamMutationFn = Apollo.MutationFunction<CreateCaptureTeamMutation, CreateCaptureTeamMutationVariables>;

/**
 * __useCreateCaptureTeamMutation__
 *
 * To run a mutation, you first call `useCreateCaptureTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaptureTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaptureTeamMutation, { data, loading, error }] = useCreateCaptureTeamMutation({
 *   variables: {
 *      oppId: // value for 'oppId'
 *      name: // value for 'name'
 *      isProposalContributor: // value for 'isProposalContributor'
 *   },
 * });
 */
export function useCreateCaptureTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateCaptureTeamMutation, CreateCaptureTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCaptureTeamMutation, CreateCaptureTeamMutationVariables>(CreateCaptureTeamDocument, options);
      }
export type CreateCaptureTeamMutationHookResult = ReturnType<typeof useCreateCaptureTeamMutation>;
export type CreateCaptureTeamMutationResult = Apollo.MutationResult<CreateCaptureTeamMutation>;
export type CreateCaptureTeamMutationOptions = Apollo.BaseMutationOptions<CreateCaptureTeamMutation, CreateCaptureTeamMutationVariables>;
export const DeleteCaptureTeamDocument = gql`
    mutation deleteCaptureTeam($teamName: String!, $pipelineId: Int!) {
  deleteCaptureTeam(teamName: $teamName, pipelineId: $pipelineId)
}
    `;
export type DeleteCaptureTeamMutationFn = Apollo.MutationFunction<DeleteCaptureTeamMutation, DeleteCaptureTeamMutationVariables>;

/**
 * __useDeleteCaptureTeamMutation__
 *
 * To run a mutation, you first call `useDeleteCaptureTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaptureTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaptureTeamMutation, { data, loading, error }] = useDeleteCaptureTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useDeleteCaptureTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCaptureTeamMutation, DeleteCaptureTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCaptureTeamMutation, DeleteCaptureTeamMutationVariables>(DeleteCaptureTeamDocument, options);
      }
export type DeleteCaptureTeamMutationHookResult = ReturnType<typeof useDeleteCaptureTeamMutation>;
export type DeleteCaptureTeamMutationResult = Apollo.MutationResult<DeleteCaptureTeamMutation>;
export type DeleteCaptureTeamMutationOptions = Apollo.BaseMutationOptions<DeleteCaptureTeamMutation, DeleteCaptureTeamMutationVariables>;
export const UpdateCaptureTeamDocument = gql`
    mutation updateCaptureTeam($oppId: Int!, $teamName: String!, $users: Json, $isProposalContributor: Boolean) {
  updateCaptureTeam(
    oppId: $oppId
    teamName: $teamName
    users: $users
    isProposalContributor: $isProposalContributor
  ) {
    id
    name
    users {
      id
      firstName
      lastName
      email
    }
    isProposalContributor
    privOppId
  }
}
    `;
export type UpdateCaptureTeamMutationFn = Apollo.MutationFunction<UpdateCaptureTeamMutation, UpdateCaptureTeamMutationVariables>;

/**
 * __useUpdateCaptureTeamMutation__
 *
 * To run a mutation, you first call `useUpdateCaptureTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaptureTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaptureTeamMutation, { data, loading, error }] = useUpdateCaptureTeamMutation({
 *   variables: {
 *      oppId: // value for 'oppId'
 *      teamName: // value for 'teamName'
 *      users: // value for 'users'
 *      isProposalContributor: // value for 'isProposalContributor'
 *   },
 * });
 */
export function useUpdateCaptureTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaptureTeamMutation, UpdateCaptureTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaptureTeamMutation, UpdateCaptureTeamMutationVariables>(UpdateCaptureTeamDocument, options);
      }
export type UpdateCaptureTeamMutationHookResult = ReturnType<typeof useUpdateCaptureTeamMutation>;
export type UpdateCaptureTeamMutationResult = Apollo.MutationResult<UpdateCaptureTeamMutation>;
export type UpdateCaptureTeamMutationOptions = Apollo.BaseMutationOptions<UpdateCaptureTeamMutation, UpdateCaptureTeamMutationVariables>;
export const UpdateCaptureTeamsDocument = gql`
    mutation updateCaptureTeams($pipelineId: Int!, $captureTeams: Json!) {
  updateCaptureTeams(pipelineId: $pipelineId, captureTeams: $captureTeams)
}
    `;
export type UpdateCaptureTeamsMutationFn = Apollo.MutationFunction<UpdateCaptureTeamsMutation, UpdateCaptureTeamsMutationVariables>;

/**
 * __useUpdateCaptureTeamsMutation__
 *
 * To run a mutation, you first call `useUpdateCaptureTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaptureTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaptureTeamsMutation, { data, loading, error }] = useUpdateCaptureTeamsMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      captureTeams: // value for 'captureTeams'
 *   },
 * });
 */
export function useUpdateCaptureTeamsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaptureTeamsMutation, UpdateCaptureTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaptureTeamsMutation, UpdateCaptureTeamsMutationVariables>(UpdateCaptureTeamsDocument, options);
      }
export type UpdateCaptureTeamsMutationHookResult = ReturnType<typeof useUpdateCaptureTeamsMutation>;
export type UpdateCaptureTeamsMutationResult = Apollo.MutationResult<UpdateCaptureTeamsMutation>;
export type UpdateCaptureTeamsMutationOptions = Apollo.BaseMutationOptions<UpdateCaptureTeamsMutation, UpdateCaptureTeamsMutationVariables>;
export const RemoveContactTagToContactDocument = gql`
    mutation removeContactTagToContact($contactUUId: String!, $contactTagUUId: String!) {
  removeContactTagToContact(
    contactUUId: $contactUUId
    contactTagUUId: $contactTagUUId
  ) {
    id
  }
}
    `;
export type RemoveContactTagToContactMutationFn = Apollo.MutationFunction<RemoveContactTagToContactMutation, RemoveContactTagToContactMutationVariables>;

/**
 * __useRemoveContactTagToContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactTagToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactTagToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactTagToContactMutation, { data, loading, error }] = useRemoveContactTagToContactMutation({
 *   variables: {
 *      contactUUId: // value for 'contactUUId'
 *      contactTagUUId: // value for 'contactTagUUId'
 *   },
 * });
 */
export function useRemoveContactTagToContactMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactTagToContactMutation, RemoveContactTagToContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContactTagToContactMutation, RemoveContactTagToContactMutationVariables>(RemoveContactTagToContactDocument, options);
      }
export type RemoveContactTagToContactMutationHookResult = ReturnType<typeof useRemoveContactTagToContactMutation>;
export type RemoveContactTagToContactMutationResult = Apollo.MutationResult<RemoveContactTagToContactMutation>;
export type RemoveContactTagToContactMutationOptions = Apollo.BaseMutationOptions<RemoveContactTagToContactMutation, RemoveContactTagToContactMutationVariables>;
export const AddContactTagToContactDocument = gql`
    mutation addContactTagToContact($contactUUId: String!, $contactTagUUId: String!) {
  addContactTagToContact(
    contactUUId: $contactUUId
    contactTagUUId: $contactTagUUId
  ) {
    id
  }
}
    `;
export type AddContactTagToContactMutationFn = Apollo.MutationFunction<AddContactTagToContactMutation, AddContactTagToContactMutationVariables>;

/**
 * __useAddContactTagToContactMutation__
 *
 * To run a mutation, you first call `useAddContactTagToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactTagToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactTagToContactMutation, { data, loading, error }] = useAddContactTagToContactMutation({
 *   variables: {
 *      contactUUId: // value for 'contactUUId'
 *      contactTagUUId: // value for 'contactTagUUId'
 *   },
 * });
 */
export function useAddContactTagToContactMutation(baseOptions?: Apollo.MutationHookOptions<AddContactTagToContactMutation, AddContactTagToContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContactTagToContactMutation, AddContactTagToContactMutationVariables>(AddContactTagToContactDocument, options);
      }
export type AddContactTagToContactMutationHookResult = ReturnType<typeof useAddContactTagToContactMutation>;
export type AddContactTagToContactMutationResult = Apollo.MutationResult<AddContactTagToContactMutation>;
export type AddContactTagToContactMutationOptions = Apollo.BaseMutationOptions<AddContactTagToContactMutation, AddContactTagToContactMutationVariables>;
export const CreateOrUpdatePrivContactDocument = gql`
    mutation createOrUpdatePrivContact($id: Int, $firstName: String, $lastName: String, $email: String, $department: String, $workPhone: String, $mobilePhone: String, $title: String, $metadata: Json, $contactOwnerId: Int, $managerId: Int, $privOrgId: Int, $linkedIn: String) {
  createOrUpdatePrivContact(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    department: $department
    workPhone: $workPhone
    mobilePhone: $mobilePhone
    title: $title
    metadata: $metadata
    contactOwnerId: $contactOwnerId
    managerId: $managerId
    privOrgId: $privOrgId
    linkedIn: $linkedIn
  ) {
    id
    contactId
    firstName
    lastName
    email
    department
    workPhone
    mobilePhone
    title
    metadata
    privOrgId
    privOrg {
      id
      name
    }
  }
}
    `;
export type CreateOrUpdatePrivContactMutationFn = Apollo.MutationFunction<CreateOrUpdatePrivContactMutation, CreateOrUpdatePrivContactMutationVariables>;

/**
 * __useCreateOrUpdatePrivContactMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePrivContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePrivContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePrivContactMutation, { data, loading, error }] = useCreateOrUpdatePrivContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      department: // value for 'department'
 *      workPhone: // value for 'workPhone'
 *      mobilePhone: // value for 'mobilePhone'
 *      title: // value for 'title'
 *      metadata: // value for 'metadata'
 *      contactOwnerId: // value for 'contactOwnerId'
 *      managerId: // value for 'managerId'
 *      privOrgId: // value for 'privOrgId'
 *      linkedIn: // value for 'linkedIn'
 *   },
 * });
 */
export function useCreateOrUpdatePrivContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdatePrivContactMutation, CreateOrUpdatePrivContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdatePrivContactMutation, CreateOrUpdatePrivContactMutationVariables>(CreateOrUpdatePrivContactDocument, options);
      }
export type CreateOrUpdatePrivContactMutationHookResult = ReturnType<typeof useCreateOrUpdatePrivContactMutation>;
export type CreateOrUpdatePrivContactMutationResult = Apollo.MutationResult<CreateOrUpdatePrivContactMutation>;
export type CreateOrUpdatePrivContactMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdatePrivContactMutation, CreateOrUpdatePrivContactMutationVariables>;
export const GetContactTasksDocument = gql`
    query getContactTasks($uuid: String!) {
  getContact(uuid: $uuid) {
    id
    contactId
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetContactTasksQuery__
 *
 * To run a query within a React component, call `useGetContactTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTasksQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetContactTasksQuery(baseOptions: Apollo.QueryHookOptions<GetContactTasksQuery, GetContactTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactTasksQuery, GetContactTasksQueryVariables>(GetContactTasksDocument, options);
      }
export function useGetContactTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactTasksQuery, GetContactTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactTasksQuery, GetContactTasksQueryVariables>(GetContactTasksDocument, options);
        }
export type GetContactTasksQueryHookResult = ReturnType<typeof useGetContactTasksQuery>;
export type GetContactTasksLazyQueryHookResult = ReturnType<typeof useGetContactTasksLazyQuery>;
export type GetContactTasksQueryResult = Apollo.QueryResult<GetContactTasksQuery, GetContactTasksQueryVariables>;
export function refetchGetContactTasksQuery(variables?: GetContactTasksQueryVariables) {
      return { query: GetContactTasksDocument, variables: variables }
    }
export const GetContactsDocument = gql`
    query getContacts($ids: [String!], $LEGACY_ids: [Int!]) {
  getContacts(ids: $ids, LEGACY_ids: $LEGACY_ids) {
    total
    contacts {
      id
      contactId
      firstName
      lastName
      fullName
      email
      department
      title
      workPhone
      mobilePhone
      linkedIn
      metadata
      contactOwnerId
      managerId
      privOrgId
      metadata
      relatedOpps
      searchOpps
      documents
      tags {
        id
        name
      }
      tasks {
        id
        createdAt
        taskId
        description
        status
        dueDate
        completedAt
        org {
          id
          orgId
          name
        }
        contact {
          id
          contactId
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          name
        }
        notes {
          id
          noteId
          payload
          payloadType
          noteType
          plainTextContent
          documents
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
        assignees {
          id
          firstName
          lastName
          email
        }
        stage {
          id
          name
          stageIndex
        }
        captureTeam {
          id
          name
        }
        subtasks {
          id
          createdAt
          taskId
          description
          status
          dueDate
          completedAt
          org {
            id
            orgId
            name
          }
          contact {
            id
            contactId
            firstName
            lastName
          }
          assignees {
            id
            firstName
            lastName
            email
          }
        }
      }
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          name
        }
      }
      contactOwner {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      privOrg {
        id
        orgId
        name
        industry
        type
        orgTags {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      LEGACY_ids: // value for 'LEGACY_ids'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export function refetchGetContactsQuery(variables?: GetContactsQueryVariables) {
      return { query: GetContactsDocument, variables: variables }
    }
export const GetContactsFilterOptionsDocument = gql`
    query getContactsFilterOptions($cursor: String, $limit: Int) {
  getContactsFilterOptions(cursor: $cursor, limit: $limit) {
    orgIndustry
    orgNames
    orgTags
    tags
    cursor
  }
}
    `;

/**
 * __useGetContactsFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetContactsFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsFilterOptionsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetContactsFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsFilterOptionsQuery, GetContactsFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsFilterOptionsQuery, GetContactsFilterOptionsQueryVariables>(GetContactsFilterOptionsDocument, options);
      }
export function useGetContactsFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsFilterOptionsQuery, GetContactsFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsFilterOptionsQuery, GetContactsFilterOptionsQueryVariables>(GetContactsFilterOptionsDocument, options);
        }
export type GetContactsFilterOptionsQueryHookResult = ReturnType<typeof useGetContactsFilterOptionsQuery>;
export type GetContactsFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetContactsFilterOptionsLazyQuery>;
export type GetContactsFilterOptionsQueryResult = Apollo.QueryResult<GetContactsFilterOptionsQuery, GetContactsFilterOptionsQueryVariables>;
export function refetchGetContactsFilterOptionsQuery(variables?: GetContactsFilterOptionsQueryVariables) {
      return { query: GetContactsFilterOptionsDocument, variables: variables }
    }
export const GetContactsPaginationDocument = gql`
    query getContactsPagination($query: String, $tags: [String!], $orgTags: [String!], $orgNames: [String!], $orgIndustries: [String!], $cursor: String, $limit: Int) {
  getContactsPagination(
    query: $query
    tags: $tags
    orgTags: $orgTags
    orgNames: $orgNames
    orgIndustry: $orgIndustries
    cursor: $cursor
    limit: $limit
  ) {
    total
    order
  }
}
    `;

/**
 * __useGetContactsPaginationQuery__
 *
 * To run a query within a React component, call `useGetContactsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsPaginationQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tags: // value for 'tags'
 *      orgTags: // value for 'orgTags'
 *      orgNames: // value for 'orgNames'
 *      orgIndustries: // value for 'orgIndustries'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetContactsPaginationQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsPaginationQuery, GetContactsPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsPaginationQuery, GetContactsPaginationQueryVariables>(GetContactsPaginationDocument, options);
      }
export function useGetContactsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsPaginationQuery, GetContactsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsPaginationQuery, GetContactsPaginationQueryVariables>(GetContactsPaginationDocument, options);
        }
export type GetContactsPaginationQueryHookResult = ReturnType<typeof useGetContactsPaginationQuery>;
export type GetContactsPaginationLazyQueryHookResult = ReturnType<typeof useGetContactsPaginationLazyQuery>;
export type GetContactsPaginationQueryResult = Apollo.QueryResult<GetContactsPaginationQuery, GetContactsPaginationQueryVariables>;
export function refetchGetContactsPaginationQuery(variables?: GetContactsPaginationQueryVariables) {
      return { query: GetContactsPaginationDocument, variables: variables }
    }
export const UploadContactsOrgsDocument = gql`
    mutation uploadContactsOrgs($data: Json) {
  uploadContactsOrgs(data: $data)
}
    `;
export type UploadContactsOrgsMutationFn = Apollo.MutationFunction<UploadContactsOrgsMutation, UploadContactsOrgsMutationVariables>;

/**
 * __useUploadContactsOrgsMutation__
 *
 * To run a mutation, you first call `useUploadContactsOrgsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContactsOrgsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContactsOrgsMutation, { data, loading, error }] = useUploadContactsOrgsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadContactsOrgsMutation(baseOptions?: Apollo.MutationHookOptions<UploadContactsOrgsMutation, UploadContactsOrgsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadContactsOrgsMutation, UploadContactsOrgsMutationVariables>(UploadContactsOrgsDocument, options);
      }
export type UploadContactsOrgsMutationHookResult = ReturnType<typeof useUploadContactsOrgsMutation>;
export type UploadContactsOrgsMutationResult = Apollo.MutationResult<UploadContactsOrgsMutation>;
export type UploadContactsOrgsMutationOptions = Apollo.BaseMutationOptions<UploadContactsOrgsMutation, UploadContactsOrgsMutationVariables>;
export const SearchPrivContactDocument = gql`
    query searchPrivContact($email: String!) {
  searchPrivContact(email: $email) {
    id
    contactId
    firstName
    lastName
    fullName
    email
  }
}
    `;

/**
 * __useSearchPrivContactQuery__
 *
 * To run a query within a React component, call `useSearchPrivContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPrivContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPrivContactQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSearchPrivContactQuery(baseOptions: Apollo.QueryHookOptions<SearchPrivContactQuery, SearchPrivContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPrivContactQuery, SearchPrivContactQueryVariables>(SearchPrivContactDocument, options);
      }
export function useSearchPrivContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPrivContactQuery, SearchPrivContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPrivContactQuery, SearchPrivContactQueryVariables>(SearchPrivContactDocument, options);
        }
export type SearchPrivContactQueryHookResult = ReturnType<typeof useSearchPrivContactQuery>;
export type SearchPrivContactLazyQueryHookResult = ReturnType<typeof useSearchPrivContactLazyQuery>;
export type SearchPrivContactQueryResult = Apollo.QueryResult<SearchPrivContactQuery, SearchPrivContactQueryVariables>;
export function refetchSearchPrivContactQuery(variables?: SearchPrivContactQueryVariables) {
      return { query: SearchPrivContactDocument, variables: variables }
    }
export const SearchContactsDocument = gql`
    query searchContacts($query: String, $tags: [String!], $orgTags: [String!], $orgNames: [String!], $orgIndustries: [String!], $cursor: String, $limit: Int) {
  searchContacts(
    query: $query
    tags: $tags
    orgTags: $orgTags
    orgNames: $orgNames
    orgIndustry: $orgIndustries
    cursor: $cursor
    limit: $limit
  ) {
    total
    contacts {
      id
      contactId
      firstName
      lastName
      fullName
      email
      department
      title
      workPhone
      mobilePhone
      linkedIn
      metadata
      contactOwnerId
      managerId
      privOrgId
      metadata
      relatedOpps
      searchOpps
      documents
      tags {
        id
        name
      }
      tasks {
        id
        createdAt
        taskId
        description
        status
        dueDate
        completedAt
        org {
          id
          orgId
          name
        }
        contact {
          id
          contactId
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          name
        }
        notes {
          id
          noteId
          payload
          payloadType
          noteType
          plainTextContent
          documents
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
        assignees {
          id
          firstName
          lastName
          email
        }
        stage {
          id
          name
          stageIndex
        }
        captureTeam {
          id
          name
        }
        subtasks {
          id
          createdAt
          taskId
          description
          status
          dueDate
          completedAt
          org {
            id
            orgId
            name
          }
          contact {
            id
            contactId
            firstName
            lastName
          }
          assignees {
            id
            firstName
            lastName
            email
          }
        }
      }
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
      contactOwner {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      privOrg {
        id
        orgId
        name
        industry
      }
    }
  }
}
    `;

/**
 * __useSearchContactsQuery__
 *
 * To run a query within a React component, call `useSearchContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContactsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tags: // value for 'tags'
 *      orgTags: // value for 'orgTags'
 *      orgNames: // value for 'orgNames'
 *      orgIndustries: // value for 'orgIndustries'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchContactsQuery(baseOptions?: Apollo.QueryHookOptions<SearchContactsQuery, SearchContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContactsQuery, SearchContactsQueryVariables>(SearchContactsDocument, options);
      }
export function useSearchContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContactsQuery, SearchContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContactsQuery, SearchContactsQueryVariables>(SearchContactsDocument, options);
        }
export type SearchContactsQueryHookResult = ReturnType<typeof useSearchContactsQuery>;
export type SearchContactsLazyQueryHookResult = ReturnType<typeof useSearchContactsLazyQuery>;
export type SearchContactsQueryResult = Apollo.QueryResult<SearchContactsQuery, SearchContactsQueryVariables>;
export function refetchSearchContactsQuery(variables?: SearchContactsQueryVariables) {
      return { query: SearchContactsDocument, variables: variables }
    }
export const DeleteContactDocument = gql`
    mutation deleteContact($id: Int!) {
  deleteContact(id: $id) {
    id
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const AddContactTagDocument = gql`
    mutation addContactTag($name: String!) {
  addContactTag(name: $name) {
    id
    name
  }
}
    `;
export type AddContactTagMutationFn = Apollo.MutationFunction<AddContactTagMutation, AddContactTagMutationVariables>;

/**
 * __useAddContactTagMutation__
 *
 * To run a mutation, you first call `useAddContactTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactTagMutation, { data, loading, error }] = useAddContactTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddContactTagMutation(baseOptions?: Apollo.MutationHookOptions<AddContactTagMutation, AddContactTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContactTagMutation, AddContactTagMutationVariables>(AddContactTagDocument, options);
      }
export type AddContactTagMutationHookResult = ReturnType<typeof useAddContactTagMutation>;
export type AddContactTagMutationResult = Apollo.MutationResult<AddContactTagMutation>;
export type AddContactTagMutationOptions = Apollo.BaseMutationOptions<AddContactTagMutation, AddContactTagMutationVariables>;
export const UpdateContactTagDocument = gql`
    mutation updateContactTag($id: String!, $name: String) {
  updateContactTag(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateContactTagMutationFn = Apollo.MutationFunction<UpdateContactTagMutation, UpdateContactTagMutationVariables>;

/**
 * __useUpdateContactTagMutation__
 *
 * To run a mutation, you first call `useUpdateContactTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactTagMutation, { data, loading, error }] = useUpdateContactTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateContactTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactTagMutation, UpdateContactTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactTagMutation, UpdateContactTagMutationVariables>(UpdateContactTagDocument, options);
      }
export type UpdateContactTagMutationHookResult = ReturnType<typeof useUpdateContactTagMutation>;
export type UpdateContactTagMutationResult = Apollo.MutationResult<UpdateContactTagMutation>;
export type UpdateContactTagMutationOptions = Apollo.BaseMutationOptions<UpdateContactTagMutation, UpdateContactTagMutationVariables>;
export const DeleteContactTagDocument = gql`
    mutation deleteContactTag($id: String!) {
  deleteContactTag(id: $id) {
    id
    name
  }
}
    `;
export type DeleteContactTagMutationFn = Apollo.MutationFunction<DeleteContactTagMutation, DeleteContactTagMutationVariables>;

/**
 * __useDeleteContactTagMutation__
 *
 * To run a mutation, you first call `useDeleteContactTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactTagMutation, { data, loading, error }] = useDeleteContactTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactTagMutation, DeleteContactTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactTagMutation, DeleteContactTagMutationVariables>(DeleteContactTagDocument, options);
      }
export type DeleteContactTagMutationHookResult = ReturnType<typeof useDeleteContactTagMutation>;
export type DeleteContactTagMutationResult = Apollo.MutationResult<DeleteContactTagMutation>;
export type DeleteContactTagMutationOptions = Apollo.BaseMutationOptions<DeleteContactTagMutation, DeleteContactTagMutationVariables>;
export const CreateContactOnPrivOppsDocument = gql`
    mutation createContactOnPrivOpps($role: String!, $privOppUUId: String!, $contactUUId: String!) {
  createContactOnPrivOpps(
    role: $role
    privOppUUId: $privOppUUId
    contactUUId: $contactUUId
  ) {
    id
    role
    contact {
      id
      firstName
      lastName
    }
  }
}
    `;
export type CreateContactOnPrivOppsMutationFn = Apollo.MutationFunction<CreateContactOnPrivOppsMutation, CreateContactOnPrivOppsMutationVariables>;

/**
 * __useCreateContactOnPrivOppsMutation__
 *
 * To run a mutation, you first call `useCreateContactOnPrivOppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactOnPrivOppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactOnPrivOppsMutation, { data, loading, error }] = useCreateContactOnPrivOppsMutation({
 *   variables: {
 *      role: // value for 'role'
 *      privOppUUId: // value for 'privOppUUId'
 *      contactUUId: // value for 'contactUUId'
 *   },
 * });
 */
export function useCreateContactOnPrivOppsMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactOnPrivOppsMutation, CreateContactOnPrivOppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactOnPrivOppsMutation, CreateContactOnPrivOppsMutationVariables>(CreateContactOnPrivOppsDocument, options);
      }
export type CreateContactOnPrivOppsMutationHookResult = ReturnType<typeof useCreateContactOnPrivOppsMutation>;
export type CreateContactOnPrivOppsMutationResult = Apollo.MutationResult<CreateContactOnPrivOppsMutation>;
export type CreateContactOnPrivOppsMutationOptions = Apollo.BaseMutationOptions<CreateContactOnPrivOppsMutation, CreateContactOnPrivOppsMutationVariables>;
export const GetContactTagsDocument = gql`
    query getContactTags {
  getContactTags {
    id
    name
  }
}
    `;

/**
 * __useGetContactTagsQuery__
 *
 * To run a query within a React component, call `useGetContactTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactTagsQuery, GetContactTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactTagsQuery, GetContactTagsQueryVariables>(GetContactTagsDocument, options);
      }
export function useGetContactTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactTagsQuery, GetContactTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactTagsQuery, GetContactTagsQueryVariables>(GetContactTagsDocument, options);
        }
export type GetContactTagsQueryHookResult = ReturnType<typeof useGetContactTagsQuery>;
export type GetContactTagsLazyQueryHookResult = ReturnType<typeof useGetContactTagsLazyQuery>;
export type GetContactTagsQueryResult = Apollo.QueryResult<GetContactTagsQuery, GetContactTagsQueryVariables>;
export function refetchGetContactTagsQuery(variables?: GetContactTagsQueryVariables) {
      return { query: GetContactTagsDocument, variables: variables }
    }
export const AddSectionDocument = gql`
    mutation addSection($pipelineUUId: String!, $sectionName: String!) {
  addSection(pipelineUUId: $pipelineUUId, sectionName: $sectionName) {
    id
    dataSummaryTemplate
  }
}
    `;
export type AddSectionMutationFn = Apollo.MutationFunction<AddSectionMutation, AddSectionMutationVariables>;

/**
 * __useAddSectionMutation__
 *
 * To run a mutation, you first call `useAddSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSectionMutation, { data, loading, error }] = useAddSectionMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionName: // value for 'sectionName'
 *   },
 * });
 */
export function useAddSectionMutation(baseOptions?: Apollo.MutationHookOptions<AddSectionMutation, AddSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSectionMutation, AddSectionMutationVariables>(AddSectionDocument, options);
      }
export type AddSectionMutationHookResult = ReturnType<typeof useAddSectionMutation>;
export type AddSectionMutationResult = Apollo.MutationResult<AddSectionMutation>;
export type AddSectionMutationOptions = Apollo.BaseMutationOptions<AddSectionMutation, AddSectionMutationVariables>;
export const RenameSectionDocument = gql`
    mutation renameSection($pipelineUUId: String!, $sectionId: String!, $sectionName: String!) {
  renameSection(
    pipelineUUId: $pipelineUUId
    sectionId: $sectionId
    sectionName: $sectionName
  ) {
    id
    dataSummaryTemplate
  }
}
    `;
export type RenameSectionMutationFn = Apollo.MutationFunction<RenameSectionMutation, RenameSectionMutationVariables>;

/**
 * __useRenameSectionMutation__
 *
 * To run a mutation, you first call `useRenameSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameSectionMutation, { data, loading, error }] = useRenameSectionMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *      sectionName: // value for 'sectionName'
 *   },
 * });
 */
export function useRenameSectionMutation(baseOptions?: Apollo.MutationHookOptions<RenameSectionMutation, RenameSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameSectionMutation, RenameSectionMutationVariables>(RenameSectionDocument, options);
      }
export type RenameSectionMutationHookResult = ReturnType<typeof useRenameSectionMutation>;
export type RenameSectionMutationResult = Apollo.MutationResult<RenameSectionMutation>;
export type RenameSectionMutationOptions = Apollo.BaseMutationOptions<RenameSectionMutation, RenameSectionMutationVariables>;
export const RemoveSectionDocument = gql`
    mutation removeSection($pipelineUUId: String!, $sectionId: String!) {
  removeSection(pipelineUUId: $pipelineUUId, sectionId: $sectionId) {
    id
    dataSummaryTemplate
  }
}
    `;
export type RemoveSectionMutationFn = Apollo.MutationFunction<RemoveSectionMutation, RemoveSectionMutationVariables>;

/**
 * __useRemoveSectionMutation__
 *
 * To run a mutation, you first call `useRemoveSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSectionMutation, { data, loading, error }] = useRemoveSectionMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useRemoveSectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSectionMutation, RemoveSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSectionMutation, RemoveSectionMutationVariables>(RemoveSectionDocument, options);
      }
export type RemoveSectionMutationHookResult = ReturnType<typeof useRemoveSectionMutation>;
export type RemoveSectionMutationResult = Apollo.MutationResult<RemoveSectionMutation>;
export type RemoveSectionMutationOptions = Apollo.BaseMutationOptions<RemoveSectionMutation, RemoveSectionMutationVariables>;
export const ReorderSectionDocument = gql`
    mutation reorderSection($pipelineUUId: String!, $sectionId: String!, $destination: Int!) {
  reorderSection(
    pipelineUUId: $pipelineUUId
    swapSectionId: $sectionId
    swapDestination: $destination
  ) {
    id
    dataSummaryTemplate
  }
}
    `;
export type ReorderSectionMutationFn = Apollo.MutationFunction<ReorderSectionMutation, ReorderSectionMutationVariables>;

/**
 * __useReorderSectionMutation__
 *
 * To run a mutation, you first call `useReorderSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderSectionMutation, { data, loading, error }] = useReorderSectionMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useReorderSectionMutation(baseOptions?: Apollo.MutationHookOptions<ReorderSectionMutation, ReorderSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderSectionMutation, ReorderSectionMutationVariables>(ReorderSectionDocument, options);
      }
export type ReorderSectionMutationHookResult = ReturnType<typeof useReorderSectionMutation>;
export type ReorderSectionMutationResult = Apollo.MutationResult<ReorderSectionMutation>;
export type ReorderSectionMutationOptions = Apollo.BaseMutationOptions<ReorderSectionMutation, ReorderSectionMutationVariables>;
export const ReorderFieldDocument = gql`
    mutation reorderField($pipelineUUId: String!, $sectionId: String!, $field: String!, $destination: Int!) {
  reorderField(
    pipelineUUId: $pipelineUUId
    swapSectionId: $sectionId
    swapField: $field
    swapDestination: $destination
  ) {
    id
    dataSummaryTemplate
  }
}
    `;
export type ReorderFieldMutationFn = Apollo.MutationFunction<ReorderFieldMutation, ReorderFieldMutationVariables>;

/**
 * __useReorderFieldMutation__
 *
 * To run a mutation, you first call `useReorderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderFieldMutation, { data, loading, error }] = useReorderFieldMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *      field: // value for 'field'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useReorderFieldMutation(baseOptions?: Apollo.MutationHookOptions<ReorderFieldMutation, ReorderFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderFieldMutation, ReorderFieldMutationVariables>(ReorderFieldDocument, options);
      }
export type ReorderFieldMutationHookResult = ReturnType<typeof useReorderFieldMutation>;
export type ReorderFieldMutationResult = Apollo.MutationResult<ReorderFieldMutation>;
export type ReorderFieldMutationOptions = Apollo.BaseMutationOptions<ReorderFieldMutation, ReorderFieldMutationVariables>;
export const AddFieldDocument = gql`
    mutation addField($pipelineUUId: String!, $sectionId: String!, $field: String!) {
  addField(pipelineUUId: $pipelineUUId, sectionId: $sectionId, field: $field) {
    id
    dataSummaryTemplate
  }
}
    `;
export type AddFieldMutationFn = Apollo.MutationFunction<AddFieldMutation, AddFieldMutationVariables>;

/**
 * __useAddFieldMutation__
 *
 * To run a mutation, you first call `useAddFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldMutation, { data, loading, error }] = useAddFieldMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *      field: // value for 'field'
 *   },
 * });
 */
export function useAddFieldMutation(baseOptions?: Apollo.MutationHookOptions<AddFieldMutation, AddFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFieldMutation, AddFieldMutationVariables>(AddFieldDocument, options);
      }
export type AddFieldMutationHookResult = ReturnType<typeof useAddFieldMutation>;
export type AddFieldMutationResult = Apollo.MutationResult<AddFieldMutation>;
export type AddFieldMutationOptions = Apollo.BaseMutationOptions<AddFieldMutation, AddFieldMutationVariables>;
export const RemoveFieldDocument = gql`
    mutation removeField($pipelineUUId: String!, $sectionId: String!, $field: String!) {
  removeField(pipelineUUId: $pipelineUUId, sectionId: $sectionId, field: $field) {
    id
    dataSummaryTemplate
  }
}
    `;
export type RemoveFieldMutationFn = Apollo.MutationFunction<RemoveFieldMutation, RemoveFieldMutationVariables>;

/**
 * __useRemoveFieldMutation__
 *
 * To run a mutation, you first call `useRemoveFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFieldMutation, { data, loading, error }] = useRemoveFieldMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      sectionId: // value for 'sectionId'
 *      field: // value for 'field'
 *   },
 * });
 */
export function useRemoveFieldMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFieldMutation, RemoveFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFieldMutation, RemoveFieldMutationVariables>(RemoveFieldDocument, options);
      }
export type RemoveFieldMutationHookResult = ReturnType<typeof useRemoveFieldMutation>;
export type RemoveFieldMutationResult = Apollo.MutationResult<RemoveFieldMutation>;
export type RemoveFieldMutationOptions = Apollo.BaseMutationOptions<RemoveFieldMutation, RemoveFieldMutationVariables>;
export const GetUploadLinkDocument = gql`
    mutation getUploadLink {
  getUploadLink
}
    `;
export type GetUploadLinkMutationFn = Apollo.MutationFunction<GetUploadLinkMutation, GetUploadLinkMutationVariables>;

/**
 * __useGetUploadLinkMutation__
 *
 * To run a mutation, you first call `useGetUploadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUploadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUploadLinkMutation, { data, loading, error }] = useGetUploadLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetUploadLinkMutation, GetUploadLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUploadLinkMutation, GetUploadLinkMutationVariables>(GetUploadLinkDocument, options);
      }
export type GetUploadLinkMutationHookResult = ReturnType<typeof useGetUploadLinkMutation>;
export type GetUploadLinkMutationResult = Apollo.MutationResult<GetUploadLinkMutation>;
export type GetUploadLinkMutationOptions = Apollo.BaseMutationOptions<GetUploadLinkMutation, GetUploadLinkMutationVariables>;
export const DeleteDocsDocument = gql`
    mutation deleteDocs($id: Int!) {
  pubDeleteDocs(id: $id) {
    id
  }
}
    `;
export type DeleteDocsMutationFn = Apollo.MutationFunction<DeleteDocsMutation, DeleteDocsMutationVariables>;

/**
 * __useDeleteDocsMutation__
 *
 * To run a mutation, you first call `useDeleteDocsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocsMutation, { data, loading, error }] = useDeleteDocsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocsMutation, DeleteDocsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocsMutation, DeleteDocsMutationVariables>(DeleteDocsDocument, options);
      }
export type DeleteDocsMutationHookResult = ReturnType<typeof useDeleteDocsMutation>;
export type DeleteDocsMutationResult = Apollo.MutationResult<DeleteDocsMutation>;
export type DeleteDocsMutationOptions = Apollo.BaseMutationOptions<DeleteDocsMutation, DeleteDocsMutationVariables>;
export const CreateDocsDocument = gql`
    mutation createDocs($id: Int, $type: String, $name: String, $oppId: Int, $contactId: Int, $orgId: Int, $link: String, $file: String, $spId: String) {
  pubUpdateDocs(
    id: $id
    type: $type
    name: $name
    oppId: $oppId
    orgId: $orgId
    contactId: $contactId
    link: $link
    file: $file
    spId: $spId
  ) {
    id
    name
    type
    link
  }
}
    `;
export type CreateDocsMutationFn = Apollo.MutationFunction<CreateDocsMutation, CreateDocsMutationVariables>;

/**
 * __useCreateDocsMutation__
 *
 * To run a mutation, you first call `useCreateDocsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocsMutation, { data, loading, error }] = useCreateDocsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      oppId: // value for 'oppId'
 *      contactId: // value for 'contactId'
 *      orgId: // value for 'orgId'
 *      link: // value for 'link'
 *      file: // value for 'file'
 *      spId: // value for 'spId'
 *   },
 * });
 */
export function useCreateDocsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocsMutation, CreateDocsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocsMutation, CreateDocsMutationVariables>(CreateDocsDocument, options);
      }
export type CreateDocsMutationHookResult = ReturnType<typeof useCreateDocsMutation>;
export type CreateDocsMutationResult = Apollo.MutationResult<CreateDocsMutation>;
export type CreateDocsMutationOptions = Apollo.BaseMutationOptions<CreateDocsMutation, CreateDocsMutationVariables>;
export const GetEventsDocument = gql`
    query getEvents($oppId: Int, $startDate: String, $endDate: String) {
  getEvents(oppId: $oppId, startDate: $startDate, endDate: $endDate) {
    eventTs
    eventCode
    eventProps
    note {
      id
      payload
      contacts {
        id
        contactId
        firstName
        lastName
        email
        title
      }
      privOppId
      privOrgId
      createdAt
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    privOpp {
      id
      canonId
      name
      pipelineId
    }
    user {
      id
      fullName
      email
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      oppId: // value for 'oppId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export function refetchGetEventsQuery(variables?: GetEventsQueryVariables) {
      return { query: GetEventsDocument, variables: variables }
    }
export const QueryPlacesDocument = gql`
    query queryPlaces($query: String!) {
  queryPlaces(query: $query)
}
    `;

/**
 * __useQueryPlacesQuery__
 *
 * To run a query within a React component, call `useQueryPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPlacesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useQueryPlacesQuery(baseOptions: Apollo.QueryHookOptions<QueryPlacesQuery, QueryPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryPlacesQuery, QueryPlacesQueryVariables>(QueryPlacesDocument, options);
      }
export function useQueryPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryPlacesQuery, QueryPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryPlacesQuery, QueryPlacesQueryVariables>(QueryPlacesDocument, options);
        }
export type QueryPlacesQueryHookResult = ReturnType<typeof useQueryPlacesQuery>;
export type QueryPlacesLazyQueryHookResult = ReturnType<typeof useQueryPlacesLazyQuery>;
export type QueryPlacesQueryResult = Apollo.QueryResult<QueryPlacesQuery, QueryPlacesQueryVariables>;
export function refetchQueryPlacesQuery(variables?: QueryPlacesQueryVariables) {
      return { query: QueryPlacesDocument, variables: variables }
    }
export const CreateNoteDocument = gql`
    mutation createNote($payload: Json!, $payloadType: NotePayloadType!, $noteType: NoteType!, $contactIds: [Int!], $privOrgId: Int, $privOppId: Int, $taskId: String) {
  createNote(
    payload: $payload
    payloadType: $payloadType
    noteType: $noteType
    contactIds: $contactIds
    privOrgId: $privOrgId
    privOppId: $privOppId
    taskId: $taskId
  ) {
    id
    payload
    payloadType
    plainTextContent
    privOppId
    privOrgId
    taskId
    createdAt
    createdBy {
      id
      email
      firstName
      lastName
    }
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      payloadType: // value for 'payloadType'
 *      noteType: // value for 'noteType'
 *      contactIds: // value for 'contactIds'
 *      privOrgId: // value for 'privOrgId'
 *      privOppId: // value for 'privOppId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: String!, $payload: Json, $payloadType: NotePayloadType, $noteType: NoteType, $contactIds: [Int!], $privOrgId: Int, $privOppId: Int, $taskId: String) {
  updateNote(
    id: $id
    payload: $payload
    payloadType: $payloadType
    noteType: $noteType
    contactIds: $contactIds
    privOrgId: $privOrgId
    privOppId: $privOppId
    taskId: $taskId
  ) {
    id
    payload
    payloadType
    plainTextContent
    privOppId
    privOrgId
    taskId
    createdAt
    createdBy {
      id
      email
      firstName
      lastName
    }
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *      payloadType: // value for 'payloadType'
 *      noteType: // value for 'noteType'
 *      contactIds: // value for 'contactIds'
 *      privOrgId: // value for 'privOrgId'
 *      privOppId: // value for 'privOppId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const CreateOpportunityDocument = gql`
    mutation createOpportunity($name: String!, $pipelineId: String!, $captureManagerId: String, $captureTeamIds: String, $tags: [String!], $stageId: String, $clientId: String, $initialMetadata: Json, $externalSourceId: String, $externalSourceType: ExternalSourceNexusType) {
  createOpportunity(
    name: $name
    pipelineId: $pipelineId
    tags: $tags
    stageId: $stageId
    clientId: $clientId
    captureManagerId: $captureManagerId
    captureTeamIds: $captureTeamIds
    initialMetadata: $initialMetadata
    externalSourceId: $externalSourceId
    externalSourceType: $externalSourceType
  ) {
    id
    oppId
    canonId
    pipelineId
  }
}
    `;
export type CreateOpportunityMutationFn = Apollo.MutationFunction<CreateOpportunityMutation, CreateOpportunityMutationVariables>;

/**
 * __useCreateOpportunityMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityMutation, { data, loading, error }] = useCreateOpportunityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      pipelineId: // value for 'pipelineId'
 *      captureManagerId: // value for 'captureManagerId'
 *      captureTeamIds: // value for 'captureTeamIds'
 *      tags: // value for 'tags'
 *      stageId: // value for 'stageId'
 *      clientId: // value for 'clientId'
 *      initialMetadata: // value for 'initialMetadata'
 *      externalSourceId: // value for 'externalSourceId'
 *      externalSourceType: // value for 'externalSourceType'
 *   },
 * });
 */
export function useCreateOpportunityMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpportunityMutation, CreateOpportunityMutationVariables>(CreateOpportunityDocument, options);
      }
export type CreateOpportunityMutationHookResult = ReturnType<typeof useCreateOpportunityMutation>;
export type CreateOpportunityMutationResult = Apollo.MutationResult<CreateOpportunityMutation>;
export type CreateOpportunityMutationOptions = Apollo.BaseMutationOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>;
export const GetDocumentDocument = gql`
    query getDocument($path: String!, $fileName: String!, $source: ExternalSourceNexusType, $docType: String) {
  getDocument(
    path: $path
    fileName: $fileName
    source: $source
    docType: $docType
  )
}
    `;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      path: // value for 'path'
 *      fileName: // value for 'fileName'
 *      source: // value for 'source'
 *      docType: // value for 'docType'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
      }
export function useGetDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = Apollo.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export function refetchGetDocumentQuery(variables?: GetDocumentQueryVariables) {
      return { query: GetDocumentDocument, variables: variables }
    }
export const GetDocumentRequestLinkDocument = gql`
    query getDocumentRequestLink($documentRequestURL: String!) {
  getDocumentRequestLink(documentRequestURL: $documentRequestURL)
}
    `;

/**
 * __useGetDocumentRequestLinkQuery__
 *
 * To run a query within a React component, call `useGetDocumentRequestLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentRequestLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentRequestLinkQuery({
 *   variables: {
 *      documentRequestURL: // value for 'documentRequestURL'
 *   },
 * });
 */
export function useGetDocumentRequestLinkQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentRequestLinkQuery, GetDocumentRequestLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentRequestLinkQuery, GetDocumentRequestLinkQueryVariables>(GetDocumentRequestLinkDocument, options);
      }
export function useGetDocumentRequestLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentRequestLinkQuery, GetDocumentRequestLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentRequestLinkQuery, GetDocumentRequestLinkQueryVariables>(GetDocumentRequestLinkDocument, options);
        }
export type GetDocumentRequestLinkQueryHookResult = ReturnType<typeof useGetDocumentRequestLinkQuery>;
export type GetDocumentRequestLinkLazyQueryHookResult = ReturnType<typeof useGetDocumentRequestLinkLazyQuery>;
export type GetDocumentRequestLinkQueryResult = Apollo.QueryResult<GetDocumentRequestLinkQuery, GetDocumentRequestLinkQueryVariables>;
export function refetchGetDocumentRequestLinkQuery(variables?: GetDocumentRequestLinkQueryVariables) {
      return { query: GetDocumentRequestLinkDocument, variables: variables }
    }
export const GetOppDocsDocument = gql`
    query getOppDocs($canonId: String!) {
  getPrivOpp(canonId: $canonId) {
    id
    oppId
    canonId
    documents
  }
}
    `;

/**
 * __useGetOppDocsQuery__
 *
 * To run a query within a React component, call `useGetOppDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOppDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOppDocsQuery({
 *   variables: {
 *      canonId: // value for 'canonId'
 *   },
 * });
 */
export function useGetOppDocsQuery(baseOptions: Apollo.QueryHookOptions<GetOppDocsQuery, GetOppDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOppDocsQuery, GetOppDocsQueryVariables>(GetOppDocsDocument, options);
      }
export function useGetOppDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOppDocsQuery, GetOppDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOppDocsQuery, GetOppDocsQueryVariables>(GetOppDocsDocument, options);
        }
export type GetOppDocsQueryHookResult = ReturnType<typeof useGetOppDocsQuery>;
export type GetOppDocsLazyQueryHookResult = ReturnType<typeof useGetOppDocsLazyQuery>;
export type GetOppDocsQueryResult = Apollo.QueryResult<GetOppDocsQuery, GetOppDocsQueryVariables>;
export function refetchGetOppDocsQuery(variables?: GetOppDocsQueryVariables) {
      return { query: GetOppDocsDocument, variables: variables }
    }
export const GetOpportunitiesDocument = gql`
    query getOpportunities($query: String, $ids: [String!], $cursor: String, $estRfp: String, $limit: Int, $paged: Boolean, $isArchived: Boolean) {
  getOpportunities(
    query: $query
    ids: $ids
    cursor: $cursor
    limit: $limit
    estRfp: $estRfp
    paged: $paged
    isArchived: $isArchived
  ) {
    opps {
      id
      oppId
      isArchived
      isBid
      canonId
      name
      createdAt
      metadata
      govWinId
      client {
        id
        orgId
        name
      }
      captureTeam {
        id
        name
        users {
          id
          userId
          firstName
          lastName
        }
      }
      tags {
        id
        tagId
        name
        color
      }
      pipeline {
        id
        pipelineId
        name
        customFields
        stages {
          id
          stageId
          name
          stageIndex
        }
      }
      privOppStage {
        id
        stage {
          id
          stageId
          name
        }
      }
      tasks {
        id
        createdAt
        taskId
        parentId
        description
        status
        dueDate
        stage {
          id
          name
          stageIndex
        }
      }
    }
    total
    order
  }
}
    `;

/**
 * __useGetOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunitiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      ids: // value for 'ids'
 *      cursor: // value for 'cursor'
 *      estRfp: // value for 'estRfp'
 *      limit: // value for 'limit'
 *      paged: // value for 'paged'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>(GetOpportunitiesDocument, options);
      }
export function useGetOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>(GetOpportunitiesDocument, options);
        }
export type GetOpportunitiesQueryHookResult = ReturnType<typeof useGetOpportunitiesQuery>;
export type GetOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetOpportunitiesLazyQuery>;
export type GetOpportunitiesQueryResult = Apollo.QueryResult<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>;
export function refetchGetOpportunitiesQuery(variables?: GetOpportunitiesQueryVariables) {
      return { query: GetOpportunitiesDocument, variables: variables }
    }
export const GetOpportunityTasksDocument = gql`
    query getOpportunityTasks($canonId: String!) {
  getPrivOpp(canonId: $canonId) {
    id
    oppId
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetOpportunityTasksQuery__
 *
 * To run a query within a React component, call `useGetOpportunityTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityTasksQuery({
 *   variables: {
 *      canonId: // value for 'canonId'
 *   },
 * });
 */
export function useGetOpportunityTasksQuery(baseOptions: Apollo.QueryHookOptions<GetOpportunityTasksQuery, GetOpportunityTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpportunityTasksQuery, GetOpportunityTasksQueryVariables>(GetOpportunityTasksDocument, options);
      }
export function useGetOpportunityTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpportunityTasksQuery, GetOpportunityTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpportunityTasksQuery, GetOpportunityTasksQueryVariables>(GetOpportunityTasksDocument, options);
        }
export type GetOpportunityTasksQueryHookResult = ReturnType<typeof useGetOpportunityTasksQuery>;
export type GetOpportunityTasksLazyQueryHookResult = ReturnType<typeof useGetOpportunityTasksLazyQuery>;
export type GetOpportunityTasksQueryResult = Apollo.QueryResult<GetOpportunityTasksQuery, GetOpportunityTasksQueryVariables>;
export function refetchGetOpportunityTasksQuery(variables?: GetOpportunityTasksQueryVariables) {
      return { query: GetOpportunityTasksDocument, variables: variables }
    }
export const GetPipelineOpportunitiesDocument = gql`
    query getPipelineOpportunities($pipelineUUId: String!, $uuids: [String], $limit: Int, $cursor: String, $query: String, $isArchived: Boolean, $estimatedRFP: String, $hasProposal: Boolean, $sortBy: String, $sortOrder: String) {
  searchPipelineOpportunities(
    pipelineUUId: $pipelineUUId
    uuids: $uuids
    cursor: $cursor
    limit: $limit
    query: $query
    isArchived: $isArchived
    estimatedRFP: $estimatedRFP
    hasProposal: $hasProposal
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    results {
      id
      oppId
      isArchived
      canonId
      name
    }
    total
  }
}
    `;

/**
 * __useGetPipelineOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetPipelineOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineOpportunitiesQuery({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      uuids: // value for 'uuids'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *      isArchived: // value for 'isArchived'
 *      estimatedRFP: // value for 'estimatedRFP'
 *      hasProposal: // value for 'hasProposal'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetPipelineOpportunitiesQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineOpportunitiesQuery, GetPipelineOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineOpportunitiesQuery, GetPipelineOpportunitiesQueryVariables>(GetPipelineOpportunitiesDocument, options);
      }
export function useGetPipelineOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineOpportunitiesQuery, GetPipelineOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineOpportunitiesQuery, GetPipelineOpportunitiesQueryVariables>(GetPipelineOpportunitiesDocument, options);
        }
export type GetPipelineOpportunitiesQueryHookResult = ReturnType<typeof useGetPipelineOpportunitiesQuery>;
export type GetPipelineOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetPipelineOpportunitiesLazyQuery>;
export type GetPipelineOpportunitiesQueryResult = Apollo.QueryResult<GetPipelineOpportunitiesQuery, GetPipelineOpportunitiesQueryVariables>;
export function refetchGetPipelineOpportunitiesQuery(variables?: GetPipelineOpportunitiesQueryVariables) {
      return { query: GetPipelineOpportunitiesDocument, variables: variables }
    }
export const GetPrivOppDocument = gql`
    query getPrivOpp($canonId: String!) {
  getPrivOpp(canonId: $canonId) {
    id
    oppId
    pipelineId
    canonId
    name
    currentScores
    isBid
    isWin
    isArchived
    metadata
    solType
    solicitationDetails
    rfiDetails
    histAwardDetails
    sol
    internalSolId
    award
    internalAwardId
    cachedGovWinDetails
    prevSol
    internalPrevSolId
    documents
    oppScoreTs
    winningPrice
    lossReason
    govWinId
    scoreTemplateId
    scoreTemplate {
      id
      categories {
        id
        position
        scoreTemplateCategoryId
        name
        fields {
          id
          index
          description
          name
          range
          scoreTemplateFieldId
          weight
        }
      }
    }
    scoreValues {
      id
      value
      fieldId
      scoreFieldValueId
    }
    client {
      id
      orgId
      name
    }
    tags {
      id
      color
      name
    }
    sources {
      privOppSourceUUId
      label
      sourceType
      source
    }
    teams {
      id
      teamId
      incumbentTeam
      isWinningTeam
      workshare
      workspaceTeam
      nda
      ta
      prime {
        id
        teamId
      }
      primeId
      privOppId
      privOrgId
      privOrg {
        id
        orgId
        name
      }
      contacts {
        id
        contactId
        firstName
        lastName
        fullName
      }
      privContacts {
        id
        contactId
        firstName
        lastName
        fullName
      }
      subcontractors {
        id
        teamId
        incumbentTeam
        isWinningTeam
        privOrgId
        workspaceTeam
        nda
        prime {
          id
          teamId
        }
        privOrg {
          id
          name
        }
        contacts {
          id
          contactId
          firstName
          lastName
          fullName
        }
        privContacts {
          id
          contactId
          firstName
          lastName
          fullName
        }
      }
    }
    winner {
      id
      name
      orgId
    }
    events {
      id
      eventTs
      eventCode
      eventProps
      captureTeamId
      documentId
      taskId
      privOppId
      userFirstName
      userLastName
      user {
        id
        fullName
      }
    }
    pipeline {
      id
      pipelineId
      customFields
      captureTeamTemplate
      dataSummaryTemplate
      oppTags {
        id
        color
        name
      }
      stages {
        id
        name
        stageId
        stageIndex
      }
      scoreTemplates {
        id
        scoreTemplateId
        categories {
          id
          position
          scoreTemplateCategoryId
          name
          fields {
            id
            index
            description
            name
            range
            scoreTemplateId
            scoreTemplateFieldId
            weight
          }
        }
        fields {
          id
          scoreTemplateFieldId
          description
          name
        }
      }
      updateTemplates {
        id
        updateTemplateId
        payload
        payloadV2
      }
      stages {
        id
        name
        stageIndex
      }
    }
    privOppStage {
      id
      stageId
      stage {
        id
        name
        stageId
      }
    }
    stage {
      id
      name
      taskConfig
    }
    proposalStage {
      id
    }
    tasks {
      id
      createdAt
      taskId
      description
      status
      dueDate
      completedAt
      parentId
      parent {
        id
        taskId
        description
      }
      assignees {
        id
        userId
        firstName
        lastName
        email
      }
      stage {
        id
        name
        stageIndex
      }
      captureTeam {
        id
        name
      }
      subtasks {
        id
        taskId
        createdAt
        description
        status
        dueDate
        completedAt
        parentId
        assignees {
          id
          firstName
          lastName
          email
        }
        stage {
          id
          name
          stageIndex
        }
      }
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      privOppId
      privOrgId
      createdAt
      createdByFirstName
      createdByLastName
      documents
      contacts {
        id
        contactId
        fullName
        firstName
        lastName
        email
        title
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    governmentContacts {
      id
      role
      contact {
        id
        contactId
        firstName
        lastName
        title
        email
        metadata
      }
    }
    teamingPartners {
      id
      role
      teamMembers {
        id
        nda
        ta
        workshare
        privOrg {
          id
          name
        }
        contacts {
          id
          contactId
          firstName
          lastName
          fullName
        }
      }
      privOrgs {
        id
        name
      }
    }
    captureTeam {
      id
      name
      users {
        id
        firstName
        lastName
        email
        isZombie
      }
    }
    updates {
      id
      updateId
      hasUpdateV2Payload
    }
  }
}
    `;

/**
 * __useGetPrivOppQuery__
 *
 * To run a query within a React component, call `useGetPrivOppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivOppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivOppQuery({
 *   variables: {
 *      canonId: // value for 'canonId'
 *   },
 * });
 */
export function useGetPrivOppQuery(baseOptions: Apollo.QueryHookOptions<GetPrivOppQuery, GetPrivOppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivOppQuery, GetPrivOppQueryVariables>(GetPrivOppDocument, options);
      }
export function useGetPrivOppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivOppQuery, GetPrivOppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivOppQuery, GetPrivOppQueryVariables>(GetPrivOppDocument, options);
        }
export type GetPrivOppQueryHookResult = ReturnType<typeof useGetPrivOppQuery>;
export type GetPrivOppLazyQueryHookResult = ReturnType<typeof useGetPrivOppLazyQuery>;
export type GetPrivOppQueryResult = Apollo.QueryResult<GetPrivOppQuery, GetPrivOppQueryVariables>;
export function refetchGetPrivOppQuery(variables?: GetPrivOppQueryVariables) {
      return { query: GetPrivOppDocument, variables: variables }
    }
export const SearchPrivOppDocument = gql`
    query searchPrivOpp($type: String!, $pubOppMetaData: Json, $pipelineId: Int!, $listing: Json) {
  searchPrivOpp(
    type: $type
    pubOppMetaData: $pubOppMetaData
    pipelineId: $pipelineId
    listing: $listing
  ) {
    id
    oppId
    canonId
    pipelineId
  }
}
    `;

/**
 * __useSearchPrivOppQuery__
 *
 * To run a query within a React component, call `useSearchPrivOppQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPrivOppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPrivOppQuery({
 *   variables: {
 *      type: // value for 'type'
 *      pubOppMetaData: // value for 'pubOppMetaData'
 *      pipelineId: // value for 'pipelineId'
 *      listing: // value for 'listing'
 *   },
 * });
 */
export function useSearchPrivOppQuery(baseOptions: Apollo.QueryHookOptions<SearchPrivOppQuery, SearchPrivOppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPrivOppQuery, SearchPrivOppQueryVariables>(SearchPrivOppDocument, options);
      }
export function useSearchPrivOppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPrivOppQuery, SearchPrivOppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPrivOppQuery, SearchPrivOppQueryVariables>(SearchPrivOppDocument, options);
        }
export type SearchPrivOppQueryHookResult = ReturnType<typeof useSearchPrivOppQuery>;
export type SearchPrivOppLazyQueryHookResult = ReturnType<typeof useSearchPrivOppLazyQuery>;
export type SearchPrivOppQueryResult = Apollo.QueryResult<SearchPrivOppQuery, SearchPrivOppQueryVariables>;
export function refetchSearchPrivOppQuery(variables?: SearchPrivOppQueryVariables) {
      return { query: SearchPrivOppDocument, variables: variables }
    }
export const DuplicateOppDocument = gql`
    mutation duplicateOpp($oppId: Int!) {
  duplicateOpportunity(oppId: $oppId) {
    id
    oppId
    canonId
    pipelineId
  }
}
    `;
export type DuplicateOppMutationFn = Apollo.MutationFunction<DuplicateOppMutation, DuplicateOppMutationVariables>;

/**
 * __useDuplicateOppMutation__
 *
 * To run a mutation, you first call `useDuplicateOppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOppMutation, { data, loading, error }] = useDuplicateOppMutation({
 *   variables: {
 *      oppId: // value for 'oppId'
 *   },
 * });
 */
export function useDuplicateOppMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateOppMutation, DuplicateOppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateOppMutation, DuplicateOppMutationVariables>(DuplicateOppDocument, options);
      }
export type DuplicateOppMutationHookResult = ReturnType<typeof useDuplicateOppMutation>;
export type DuplicateOppMutationResult = Apollo.MutationResult<DuplicateOppMutation>;
export type DuplicateOppMutationOptions = Apollo.BaseMutationOptions<DuplicateOppMutation, DuplicateOppMutationVariables>;
export const PubPrivOppUpdateDocument = gql`
    mutation pubPrivOppUpdate($oppId: String!, $name: String, $metadata: Json, $sol: Json, $award: Json, $prevSol: Json, $updateCustomFields: Boolean, $isArchived: Boolean) {
  pubWorkspacePrivOppUpdate(
    oppId: $oppId
    name: $name
    metadata: $metadata
    sol: $sol
    award: $award
    prevSol: $prevSol
    updateCustomFields: $updateCustomFields
    isArchived: $isArchived
  ) {
    id
    award
    sol
    prevSol
    sources {
      privOppSourceUUId
      label
      sourceType
      source
    }
  }
}
    `;
export type PubPrivOppUpdateMutationFn = Apollo.MutationFunction<PubPrivOppUpdateMutation, PubPrivOppUpdateMutationVariables>;

/**
 * __usePubPrivOppUpdateMutation__
 *
 * To run a mutation, you first call `usePubPrivOppUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubPrivOppUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubPrivOppUpdateMutation, { data, loading, error }] = usePubPrivOppUpdateMutation({
 *   variables: {
 *      oppId: // value for 'oppId'
 *      name: // value for 'name'
 *      metadata: // value for 'metadata'
 *      sol: // value for 'sol'
 *      award: // value for 'award'
 *      prevSol: // value for 'prevSol'
 *      updateCustomFields: // value for 'updateCustomFields'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function usePubPrivOppUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PubPrivOppUpdateMutation, PubPrivOppUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubPrivOppUpdateMutation, PubPrivOppUpdateMutationVariables>(PubPrivOppUpdateDocument, options);
      }
export type PubPrivOppUpdateMutationHookResult = ReturnType<typeof usePubPrivOppUpdateMutation>;
export type PubPrivOppUpdateMutationResult = Apollo.MutationResult<PubPrivOppUpdateMutation>;
export type PubPrivOppUpdateMutationOptions = Apollo.BaseMutationOptions<PubPrivOppUpdateMutation, PubPrivOppUpdateMutationVariables>;
export const PubPrivOppUpdateGovContactsDocument = gql`
    mutation pubPrivOppUpdateGovContacts($id: Int!, $privContactId: Int) {
  pubPrivOppUpdateGovContacts(id: $id, privContactId: $privContactId) {
    id
  }
}
    `;
export type PubPrivOppUpdateGovContactsMutationFn = Apollo.MutationFunction<PubPrivOppUpdateGovContactsMutation, PubPrivOppUpdateGovContactsMutationVariables>;

/**
 * __usePubPrivOppUpdateGovContactsMutation__
 *
 * To run a mutation, you first call `usePubPrivOppUpdateGovContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubPrivOppUpdateGovContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubPrivOppUpdateGovContactsMutation, { data, loading, error }] = usePubPrivOppUpdateGovContactsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      privContactId: // value for 'privContactId'
 *   },
 * });
 */
export function usePubPrivOppUpdateGovContactsMutation(baseOptions?: Apollo.MutationHookOptions<PubPrivOppUpdateGovContactsMutation, PubPrivOppUpdateGovContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubPrivOppUpdateGovContactsMutation, PubPrivOppUpdateGovContactsMutationVariables>(PubPrivOppUpdateGovContactsDocument, options);
      }
export type PubPrivOppUpdateGovContactsMutationHookResult = ReturnType<typeof usePubPrivOppUpdateGovContactsMutation>;
export type PubPrivOppUpdateGovContactsMutationResult = Apollo.MutationResult<PubPrivOppUpdateGovContactsMutation>;
export type PubPrivOppUpdateGovContactsMutationOptions = Apollo.BaseMutationOptions<PubPrivOppUpdateGovContactsMutation, PubPrivOppUpdateGovContactsMutationVariables>;
export const PubPrivOppDeleteGovContactsDocument = gql`
    mutation pubPrivOppDeleteGovContacts($id: Int!) {
  pubPrivOppDeleteGovContacts(id: $id) {
    id
  }
}
    `;
export type PubPrivOppDeleteGovContactsMutationFn = Apollo.MutationFunction<PubPrivOppDeleteGovContactsMutation, PubPrivOppDeleteGovContactsMutationVariables>;

/**
 * __usePubPrivOppDeleteGovContactsMutation__
 *
 * To run a mutation, you first call `usePubPrivOppDeleteGovContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubPrivOppDeleteGovContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubPrivOppDeleteGovContactsMutation, { data, loading, error }] = usePubPrivOppDeleteGovContactsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePubPrivOppDeleteGovContactsMutation(baseOptions?: Apollo.MutationHookOptions<PubPrivOppDeleteGovContactsMutation, PubPrivOppDeleteGovContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubPrivOppDeleteGovContactsMutation, PubPrivOppDeleteGovContactsMutationVariables>(PubPrivOppDeleteGovContactsDocument, options);
      }
export type PubPrivOppDeleteGovContactsMutationHookResult = ReturnType<typeof usePubPrivOppDeleteGovContactsMutation>;
export type PubPrivOppDeleteGovContactsMutationResult = Apollo.MutationResult<PubPrivOppDeleteGovContactsMutation>;
export type PubPrivOppDeleteGovContactsMutationOptions = Apollo.BaseMutationOptions<PubPrivOppDeleteGovContactsMutation, PubPrivOppDeleteGovContactsMutationVariables>;
export const PubAddTaskDocument = gql`
    mutation pubAddTask($stageId: Int, $oppId: String, $orgId: String, $contactId: String, $task: Json) {
  pubWorkspaceAddTask(
    stageId: $stageId
    oppId: $oppId
    orgId: $orgId
    contactId: $contactId
    task: $task
  ) {
    id
    taskId
    description
    dueDate
    status
    org {
      id
      orgId
      name
    }
    contact {
      id
      contactId
      firstName
      lastName
    }
    privOpp {
      id
      canonId
      oppId
      name
      pipelineId
      pipeline {
        id
        name
        customFields
      }
      privOppStage {
        id
        stage {
          id
          name
        }
      }
    }
    stage {
      id
      name
    }
    assignees {
      id
      firstName
      lastName
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type PubAddTaskMutationFn = Apollo.MutationFunction<PubAddTaskMutation, PubAddTaskMutationVariables>;

/**
 * __usePubAddTaskMutation__
 *
 * To run a mutation, you first call `usePubAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubAddTaskMutation, { data, loading, error }] = usePubAddTaskMutation({
 *   variables: {
 *      stageId: // value for 'stageId'
 *      oppId: // value for 'oppId'
 *      orgId: // value for 'orgId'
 *      contactId: // value for 'contactId'
 *      task: // value for 'task'
 *   },
 * });
 */
export function usePubAddTaskMutation(baseOptions?: Apollo.MutationHookOptions<PubAddTaskMutation, PubAddTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubAddTaskMutation, PubAddTaskMutationVariables>(PubAddTaskDocument, options);
      }
export type PubAddTaskMutationHookResult = ReturnType<typeof usePubAddTaskMutation>;
export type PubAddTaskMutationResult = Apollo.MutationResult<PubAddTaskMutation>;
export type PubAddTaskMutationOptions = Apollo.BaseMutationOptions<PubAddTaskMutation, PubAddTaskMutationVariables>;
export const PubDeleteTaskDocument = gql`
    mutation pubDeleteTask($id: Int!) {
  pubWorkSpaceDeleteTask(id: $id) {
    id
  }
}
    `;
export type PubDeleteTaskMutationFn = Apollo.MutationFunction<PubDeleteTaskMutation, PubDeleteTaskMutationVariables>;

/**
 * __usePubDeleteTaskMutation__
 *
 * To run a mutation, you first call `usePubDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubDeleteTaskMutation, { data, loading, error }] = usePubDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePubDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<PubDeleteTaskMutation, PubDeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubDeleteTaskMutation, PubDeleteTaskMutationVariables>(PubDeleteTaskDocument, options);
      }
export type PubDeleteTaskMutationHookResult = ReturnType<typeof usePubDeleteTaskMutation>;
export type PubDeleteTaskMutationResult = Apollo.MutationResult<PubDeleteTaskMutation>;
export type PubDeleteTaskMutationOptions = Apollo.BaseMutationOptions<PubDeleteTaskMutation, PubDeleteTaskMutationVariables>;
export const PubUpdateTaskDocument = gql`
    mutation pubUpdateTask($taskId: String!, $task: Json!, $contactId: String, $orgId: String, $oppId: String) {
  pubWorkspaceUpdateTask(
    taskId: $taskId
    task: $task
    contactId: $contactId
    orgId: $orgId
    oppId: $oppId
  ) {
    id
    taskId
    description
    status
    dueDate
    createdAt
    org {
      id
      orgId
      name
    }
    contact {
      id
      contactId
      firstName
      lastName
    }
    privOpp {
      id
      oppId
      canonId
      name
      pipelineId
    }
    assignees {
      id
      firstName
      lastName
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type PubUpdateTaskMutationFn = Apollo.MutationFunction<PubUpdateTaskMutation, PubUpdateTaskMutationVariables>;

/**
 * __usePubUpdateTaskMutation__
 *
 * To run a mutation, you first call `usePubUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubUpdateTaskMutation, { data, loading, error }] = usePubUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      task: // value for 'task'
 *      contactId: // value for 'contactId'
 *      orgId: // value for 'orgId'
 *      oppId: // value for 'oppId'
 *   },
 * });
 */
export function usePubUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<PubUpdateTaskMutation, PubUpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubUpdateTaskMutation, PubUpdateTaskMutationVariables>(PubUpdateTaskDocument, options);
      }
export type PubUpdateTaskMutationHookResult = ReturnType<typeof usePubUpdateTaskMutation>;
export type PubUpdateTaskMutationResult = Apollo.MutationResult<PubUpdateTaskMutation>;
export type PubUpdateTaskMutationOptions = Apollo.BaseMutationOptions<PubUpdateTaskMutation, PubUpdateTaskMutationVariables>;
export const GetGovWinPrivOppDocument = gql`
    query getGovWinPrivOpp($canonId: String!) {
  getGovWinPrivOpp(canonId: $canonId)
}
    `;

/**
 * __useGetGovWinPrivOppQuery__
 *
 * To run a query within a React component, call `useGetGovWinPrivOppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGovWinPrivOppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGovWinPrivOppQuery({
 *   variables: {
 *      canonId: // value for 'canonId'
 *   },
 * });
 */
export function useGetGovWinPrivOppQuery(baseOptions: Apollo.QueryHookOptions<GetGovWinPrivOppQuery, GetGovWinPrivOppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGovWinPrivOppQuery, GetGovWinPrivOppQueryVariables>(GetGovWinPrivOppDocument, options);
      }
export function useGetGovWinPrivOppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGovWinPrivOppQuery, GetGovWinPrivOppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGovWinPrivOppQuery, GetGovWinPrivOppQueryVariables>(GetGovWinPrivOppDocument, options);
        }
export type GetGovWinPrivOppQueryHookResult = ReturnType<typeof useGetGovWinPrivOppQuery>;
export type GetGovWinPrivOppLazyQueryHookResult = ReturnType<typeof useGetGovWinPrivOppLazyQuery>;
export type GetGovWinPrivOppQueryResult = Apollo.QueryResult<GetGovWinPrivOppQuery, GetGovWinPrivOppQueryVariables>;
export function refetchGetGovWinPrivOppQuery(variables?: GetGovWinPrivOppQueryVariables) {
      return { query: GetGovWinPrivOppDocument, variables: variables }
    }
export const GetGovWinOppDocument = gql`
    query getGovWinOpp($govWinId: String!) {
  getGovWinOpp(govWinId: $govWinId)
}
    `;

/**
 * __useGetGovWinOppQuery__
 *
 * To run a query within a React component, call `useGetGovWinOppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGovWinOppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGovWinOppQuery({
 *   variables: {
 *      govWinId: // value for 'govWinId'
 *   },
 * });
 */
export function useGetGovWinOppQuery(baseOptions: Apollo.QueryHookOptions<GetGovWinOppQuery, GetGovWinOppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGovWinOppQuery, GetGovWinOppQueryVariables>(GetGovWinOppDocument, options);
      }
export function useGetGovWinOppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGovWinOppQuery, GetGovWinOppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGovWinOppQuery, GetGovWinOppQueryVariables>(GetGovWinOppDocument, options);
        }
export type GetGovWinOppQueryHookResult = ReturnType<typeof useGetGovWinOppQuery>;
export type GetGovWinOppLazyQueryHookResult = ReturnType<typeof useGetGovWinOppLazyQuery>;
export type GetGovWinOppQueryResult = Apollo.QueryResult<GetGovWinOppQuery, GetGovWinOppQueryVariables>;
export function refetchGetGovWinOppQuery(variables?: GetGovWinOppQueryVariables) {
      return { query: GetGovWinOppDocument, variables: variables }
    }
export const CreatePrivOrgDocument = gql`
    mutation createPrivOrg($id: Int, $name: String!, $type: String, $industry: String, $website: String, $phoneNumber: String, $samId: String, $metadata: Json, $workspaceOrg: Boolean) {
  createPrivOrg(
    id: $id
    name: $name
    type: $type
    industry: $industry
    website: $website
    phoneNumber: $phoneNumber
    samId: $samId
    metadata: $metadata
    workspaceOrg: $workspaceOrg
  ) {
    id
    orgId
    name
    samId
    type
    description
    industry
    website
    phoneNumber
    addresses
    thumbnail
    metadata
    accountOwner {
      userId
      firstName
      lastName
    }
    contacts {
      id
      contactId
      firstName
      lastName
      email
      title
      metadata
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      privOppId
      privOrgId
      createdAt
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreatePrivOrgMutationFn = Apollo.MutationFunction<CreatePrivOrgMutation, CreatePrivOrgMutationVariables>;

/**
 * __useCreatePrivOrgMutation__
 *
 * To run a mutation, you first call `useCreatePrivOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivOrgMutation, { data, loading, error }] = useCreatePrivOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      industry: // value for 'industry'
 *      website: // value for 'website'
 *      phoneNumber: // value for 'phoneNumber'
 *      samId: // value for 'samId'
 *      metadata: // value for 'metadata'
 *      workspaceOrg: // value for 'workspaceOrg'
 *   },
 * });
 */
export function useCreatePrivOrgMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrivOrgMutation, CreatePrivOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrivOrgMutation, CreatePrivOrgMutationVariables>(CreatePrivOrgDocument, options);
      }
export type CreatePrivOrgMutationHookResult = ReturnType<typeof useCreatePrivOrgMutation>;
export type CreatePrivOrgMutationResult = Apollo.MutationResult<CreatePrivOrgMutation>;
export type CreatePrivOrgMutationOptions = Apollo.BaseMutationOptions<CreatePrivOrgMutation, CreatePrivOrgMutationVariables>;
export const DeletePrivOrgDocument = gql`
    mutation deletePrivOrg($id: Int!) {
  deletePrivOrg(id: $id) {
    id
  }
}
    `;
export type DeletePrivOrgMutationFn = Apollo.MutationFunction<DeletePrivOrgMutation, DeletePrivOrgMutationVariables>;

/**
 * __useDeletePrivOrgMutation__
 *
 * To run a mutation, you first call `useDeletePrivOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrivOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrivOrgMutation, { data, loading, error }] = useDeletePrivOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePrivOrgMutation(baseOptions?: Apollo.MutationHookOptions<DeletePrivOrgMutation, DeletePrivOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePrivOrgMutation, DeletePrivOrgMutationVariables>(DeletePrivOrgDocument, options);
      }
export type DeletePrivOrgMutationHookResult = ReturnType<typeof useDeletePrivOrgMutation>;
export type DeletePrivOrgMutationResult = Apollo.MutationResult<DeletePrivOrgMutation>;
export type DeletePrivOrgMutationOptions = Apollo.BaseMutationOptions<DeletePrivOrgMutation, DeletePrivOrgMutationVariables>;
export const GetOrganizationTasksDocument = gql`
    query getOrganizationTasks($uuid: String!) {
  getOrganization(uuid: $uuid) {
    id
    orgId
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetOrganizationTasksQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTasksQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetOrganizationTasksQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationTasksQuery, GetOrganizationTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationTasksQuery, GetOrganizationTasksQueryVariables>(GetOrganizationTasksDocument, options);
      }
export function useGetOrganizationTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationTasksQuery, GetOrganizationTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationTasksQuery, GetOrganizationTasksQueryVariables>(GetOrganizationTasksDocument, options);
        }
export type GetOrganizationTasksQueryHookResult = ReturnType<typeof useGetOrganizationTasksQuery>;
export type GetOrganizationTasksLazyQueryHookResult = ReturnType<typeof useGetOrganizationTasksLazyQuery>;
export type GetOrganizationTasksQueryResult = Apollo.QueryResult<GetOrganizationTasksQuery, GetOrganizationTasksQueryVariables>;
export function refetchGetOrganizationTasksQuery(variables?: GetOrganizationTasksQueryVariables) {
      return { query: GetOrganizationTasksDocument, variables: variables }
    }
export const GetPrivOrgsDocument = gql`
    query getPrivOrgs($ids: [String!], $LEGACY_ids: [Int!]) {
  getPrivOrgs(ids: $ids, LEGACY_ids: $LEGACY_ids) {
    total
    orgs {
      id
      orgId
      samId
      name
      type
      description
      industry
      website
      phoneNumber
      addresses
      thumbnail
      metadata
      tags
      relatedOpps
      workspaceOrg
      documents
      parentOrganization {
        id
        name
      }
      orgTags {
        id
        name
      }
      tasks {
        id
        createdAt
        taskId
        description
        status
        dueDate
        completedAt
        org {
          id
          orgId
          name
        }
        contact {
          id
          contactId
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          name
        }
        notes {
          id
          noteId
          payload
          payloadType
          noteType
          plainTextContent
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
        assignees {
          id
          firstName
          lastName
          email
        }
        stage {
          id
          name
          stageIndex
        }
        captureTeam {
          id
          name
        }
        subtasks {
          id
          createdAt
          taskId
          description
          status
          dueDate
          completedAt
          org {
            id
            orgId
            name
          }
          contact {
            id
            contactId
            firstName
            lastName
          }
          assignees {
            id
            firstName
            lastName
            email
          }
        }
      }
      selfOrg {
        id
        workspaceId
      }
      accountOwner {
        id
        userId
        firstName
        lastName
      }
      contacts {
        id
        contactId
        firstName
        lastName
        email
        department
        title
        workPhone
        mobilePhone
        linkedIn
        metadata
      }
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        privOpp {
          id
          oppId
          name
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetPrivOrgsQuery__
 *
 * To run a query within a React component, call `useGetPrivOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivOrgsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      LEGACY_ids: // value for 'LEGACY_ids'
 *   },
 * });
 */
export function useGetPrivOrgsQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivOrgsQuery, GetPrivOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivOrgsQuery, GetPrivOrgsQueryVariables>(GetPrivOrgsDocument, options);
      }
export function useGetPrivOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivOrgsQuery, GetPrivOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivOrgsQuery, GetPrivOrgsQueryVariables>(GetPrivOrgsDocument, options);
        }
export type GetPrivOrgsQueryHookResult = ReturnType<typeof useGetPrivOrgsQuery>;
export type GetPrivOrgsLazyQueryHookResult = ReturnType<typeof useGetPrivOrgsLazyQuery>;
export type GetPrivOrgsQueryResult = Apollo.QueryResult<GetPrivOrgsQuery, GetPrivOrgsQueryVariables>;
export function refetchGetPrivOrgsQuery(variables?: GetPrivOrgsQueryVariables) {
      return { query: GetPrivOrgsDocument, variables: variables }
    }
export const GetPrivOrgsFilterOptionsDocument = gql`
    query getPrivOrgsFilterOptions($cursor: String, $limit: Int) {
  getPrivOrgsFilterOptions(cursor: $cursor, limit: $limit) {
    industry
    tags
    types
    accountOwnersFirstLastSeparatedByColonColon
    cursor
  }
}
    `;

/**
 * __useGetPrivOrgsFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetPrivOrgsFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivOrgsFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivOrgsFilterOptionsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPrivOrgsFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivOrgsFilterOptionsQuery, GetPrivOrgsFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivOrgsFilterOptionsQuery, GetPrivOrgsFilterOptionsQueryVariables>(GetPrivOrgsFilterOptionsDocument, options);
      }
export function useGetPrivOrgsFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivOrgsFilterOptionsQuery, GetPrivOrgsFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivOrgsFilterOptionsQuery, GetPrivOrgsFilterOptionsQueryVariables>(GetPrivOrgsFilterOptionsDocument, options);
        }
export type GetPrivOrgsFilterOptionsQueryHookResult = ReturnType<typeof useGetPrivOrgsFilterOptionsQuery>;
export type GetPrivOrgsFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetPrivOrgsFilterOptionsLazyQuery>;
export type GetPrivOrgsFilterOptionsQueryResult = Apollo.QueryResult<GetPrivOrgsFilterOptionsQuery, GetPrivOrgsFilterOptionsQueryVariables>;
export function refetchGetPrivOrgsFilterOptionsQuery(variables?: GetPrivOrgsFilterOptionsQueryVariables) {
      return { query: GetPrivOrgsFilterOptionsDocument, variables: variables }
    }
export const GetPrivOrgsPaginationDocument = gql`
    query getPrivOrgsPagination($query: String, $tags: [String!], $types: [String!], $industry: [String!], $accountOwnersFirstLastSeparatedByColonColon: [String!], $cursor: String, $limit: Int) {
  getPrivOrgsPagination(
    query: $query
    tags: $tags
    types: $types
    industry: $industry
    accountOwnersFirstLastSeparatedByColonColon: $accountOwnersFirstLastSeparatedByColonColon
    cursor: $cursor
    limit: $limit
  ) {
    total
    order
  }
}
    `;

/**
 * __useGetPrivOrgsPaginationQuery__
 *
 * To run a query within a React component, call `useGetPrivOrgsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivOrgsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivOrgsPaginationQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tags: // value for 'tags'
 *      types: // value for 'types'
 *      industry: // value for 'industry'
 *      accountOwnersFirstLastSeparatedByColonColon: // value for 'accountOwnersFirstLastSeparatedByColonColon'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPrivOrgsPaginationQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivOrgsPaginationQuery, GetPrivOrgsPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivOrgsPaginationQuery, GetPrivOrgsPaginationQueryVariables>(GetPrivOrgsPaginationDocument, options);
      }
export function useGetPrivOrgsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivOrgsPaginationQuery, GetPrivOrgsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivOrgsPaginationQuery, GetPrivOrgsPaginationQueryVariables>(GetPrivOrgsPaginationDocument, options);
        }
export type GetPrivOrgsPaginationQueryHookResult = ReturnType<typeof useGetPrivOrgsPaginationQuery>;
export type GetPrivOrgsPaginationLazyQueryHookResult = ReturnType<typeof useGetPrivOrgsPaginationLazyQuery>;
export type GetPrivOrgsPaginationQueryResult = Apollo.QueryResult<GetPrivOrgsPaginationQuery, GetPrivOrgsPaginationQueryVariables>;
export function refetchGetPrivOrgsPaginationQuery(variables?: GetPrivOrgsPaginationQueryVariables) {
      return { query: GetPrivOrgsPaginationDocument, variables: variables }
    }
export const SearchPrivOrgsDocument = gql`
    query searchPrivOrgs($query: String, $tags: [String!], $types: [String!], $industry: [String!], $accountOwnersFirstLastSeparatedByColonColon: [String!], $cursor: String, $limit: Int) {
  searchPrivOrgs(
    query: $query
    tags: $tags
    types: $types
    industry: $industry
    accountOwnersFirstLastSeparatedByColonColon: $accountOwnersFirstLastSeparatedByColonColon
    cursor: $cursor
    limit: $limit
  ) {
    total
    orgs {
      id
      orgId
      samId
      name
      type
      description
      industry
      website
      phoneNumber
      addresses
      thumbnail
      metadata
      tags
      relatedOpps
      workspaceOrg
      documents
      parentOrganization {
        id
        name
      }
      orgTags {
        id
        name
      }
      tasks {
        id
        createdAt
        taskId
        description
        status
        dueDate
        completedAt
        org {
          id
          orgId
          name
        }
        contact {
          id
          contactId
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          name
        }
        notes {
          id
          noteId
          payload
          payloadType
          noteType
          plainTextContent
          documents
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
        assignees {
          id
          firstName
          lastName
          email
        }
        stage {
          id
          name
          stageIndex
        }
        captureTeam {
          id
          name
        }
        subtasks {
          id
          createdAt
          taskId
          description
          status
          dueDate
          completedAt
          org {
            id
            orgId
            name
          }
          contact {
            id
            contactId
            firstName
            lastName
          }
          assignees {
            id
            firstName
            lastName
            email
          }
        }
      }
      selfOrg {
        id
        workspaceId
      }
      accountOwner {
        id
        userId
        firstName
        lastName
      }
      contacts {
        id
        contactId
        firstName
        lastName
        email
        department
        title
        workPhone
        mobilePhone
        linkedIn
        metadata
      }
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useSearchPrivOrgsQuery__
 *
 * To run a query within a React component, call `useSearchPrivOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPrivOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPrivOrgsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tags: // value for 'tags'
 *      types: // value for 'types'
 *      industry: // value for 'industry'
 *      accountOwnersFirstLastSeparatedByColonColon: // value for 'accountOwnersFirstLastSeparatedByColonColon'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchPrivOrgsQuery(baseOptions?: Apollo.QueryHookOptions<SearchPrivOrgsQuery, SearchPrivOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPrivOrgsQuery, SearchPrivOrgsQueryVariables>(SearchPrivOrgsDocument, options);
      }
export function useSearchPrivOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPrivOrgsQuery, SearchPrivOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPrivOrgsQuery, SearchPrivOrgsQueryVariables>(SearchPrivOrgsDocument, options);
        }
export type SearchPrivOrgsQueryHookResult = ReturnType<typeof useSearchPrivOrgsQuery>;
export type SearchPrivOrgsLazyQueryHookResult = ReturnType<typeof useSearchPrivOrgsLazyQuery>;
export type SearchPrivOrgsQueryResult = Apollo.QueryResult<SearchPrivOrgsQuery, SearchPrivOrgsQueryVariables>;
export function refetchSearchPrivOrgsQuery(variables?: SearchPrivOrgsQueryVariables) {
      return { query: SearchPrivOrgsDocument, variables: variables }
    }
export const UpdatePrivOrgDocument = gql`
    mutation updatePrivOrg($orgId: Int!, $accountOwnerId: Int, $name: String, $type: String, $industry: String, $website: String, $phoneNumber: String, $samId: String, $metadata: Json, $tags: Json, $parentOrganizationId: String) {
  updatePrivOrg(
    orgId: $orgId
    accountOwnerId: $accountOwnerId
    name: $name
    type: $type
    industry: $industry
    website: $website
    phoneNumber: $phoneNumber
    samId: $samId
    metadata: $metadata
    tags: $tags
    parentOrganizationId: $parentOrganizationId
  ) {
    id
    orgId
  }
}
    `;
export type UpdatePrivOrgMutationFn = Apollo.MutationFunction<UpdatePrivOrgMutation, UpdatePrivOrgMutationVariables>;

/**
 * __useUpdatePrivOrgMutation__
 *
 * To run a mutation, you first call `useUpdatePrivOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivOrgMutation, { data, loading, error }] = useUpdatePrivOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      accountOwnerId: // value for 'accountOwnerId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      industry: // value for 'industry'
 *      website: // value for 'website'
 *      phoneNumber: // value for 'phoneNumber'
 *      samId: // value for 'samId'
 *      metadata: // value for 'metadata'
 *      tags: // value for 'tags'
 *      parentOrganizationId: // value for 'parentOrganizationId'
 *   },
 * });
 */
export function useUpdatePrivOrgMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivOrgMutation, UpdatePrivOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivOrgMutation, UpdatePrivOrgMutationVariables>(UpdatePrivOrgDocument, options);
      }
export type UpdatePrivOrgMutationHookResult = ReturnType<typeof useUpdatePrivOrgMutation>;
export type UpdatePrivOrgMutationResult = Apollo.MutationResult<UpdatePrivOrgMutation>;
export type UpdatePrivOrgMutationOptions = Apollo.BaseMutationOptions<UpdatePrivOrgMutation, UpdatePrivOrgMutationVariables>;
export const AddOrgTagDocument = gql`
    mutation addOrgTag($name: String!) {
  addOrgTag(name: $name) {
    id
    name
  }
}
    `;
export type AddOrgTagMutationFn = Apollo.MutationFunction<AddOrgTagMutation, AddOrgTagMutationVariables>;

/**
 * __useAddOrgTagMutation__
 *
 * To run a mutation, you first call `useAddOrgTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgTagMutation, { data, loading, error }] = useAddOrgTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddOrgTagMutation(baseOptions?: Apollo.MutationHookOptions<AddOrgTagMutation, AddOrgTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrgTagMutation, AddOrgTagMutationVariables>(AddOrgTagDocument, options);
      }
export type AddOrgTagMutationHookResult = ReturnType<typeof useAddOrgTagMutation>;
export type AddOrgTagMutationResult = Apollo.MutationResult<AddOrgTagMutation>;
export type AddOrgTagMutationOptions = Apollo.BaseMutationOptions<AddOrgTagMutation, AddOrgTagMutationVariables>;
export const UpdateOrgTagDocument = gql`
    mutation updateOrgTag($id: String!, $name: String!) {
  updateOrgTag(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateOrgTagMutationFn = Apollo.MutationFunction<UpdateOrgTagMutation, UpdateOrgTagMutationVariables>;

/**
 * __useUpdateOrgTagMutation__
 *
 * To run a mutation, you first call `useUpdateOrgTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgTagMutation, { data, loading, error }] = useUpdateOrgTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateOrgTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgTagMutation, UpdateOrgTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgTagMutation, UpdateOrgTagMutationVariables>(UpdateOrgTagDocument, options);
      }
export type UpdateOrgTagMutationHookResult = ReturnType<typeof useUpdateOrgTagMutation>;
export type UpdateOrgTagMutationResult = Apollo.MutationResult<UpdateOrgTagMutation>;
export type UpdateOrgTagMutationOptions = Apollo.BaseMutationOptions<UpdateOrgTagMutation, UpdateOrgTagMutationVariables>;
export const DeleteOrgTagDocument = gql`
    mutation deleteOrgTag($id: String!) {
  deleteOrgTag(id: $id) {
    id
    name
  }
}
    `;
export type DeleteOrgTagMutationFn = Apollo.MutationFunction<DeleteOrgTagMutation, DeleteOrgTagMutationVariables>;

/**
 * __useDeleteOrgTagMutation__
 *
 * To run a mutation, you first call `useDeleteOrgTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgTagMutation, { data, loading, error }] = useDeleteOrgTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrgTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgTagMutation, DeleteOrgTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgTagMutation, DeleteOrgTagMutationVariables>(DeleteOrgTagDocument, options);
      }
export type DeleteOrgTagMutationHookResult = ReturnType<typeof useDeleteOrgTagMutation>;
export type DeleteOrgTagMutationResult = Apollo.MutationResult<DeleteOrgTagMutation>;
export type DeleteOrgTagMutationOptions = Apollo.BaseMutationOptions<DeleteOrgTagMutation, DeleteOrgTagMutationVariables>;
export const AddTagToOrgDocument = gql`
    mutation addTagToOrg($tagId: String, $tagName: String, $orgId: String!) {
  addTagToOrg(tagId: $tagId, tagName: $tagName, orgId: $orgId) {
    id
    orgId
    orgTags {
      id
      name
    }
  }
}
    `;
export type AddTagToOrgMutationFn = Apollo.MutationFunction<AddTagToOrgMutation, AddTagToOrgMutationVariables>;

/**
 * __useAddTagToOrgMutation__
 *
 * To run a mutation, you first call `useAddTagToOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToOrgMutation, { data, loading, error }] = useAddTagToOrgMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      tagName: // value for 'tagName'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAddTagToOrgMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToOrgMutation, AddTagToOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTagToOrgMutation, AddTagToOrgMutationVariables>(AddTagToOrgDocument, options);
      }
export type AddTagToOrgMutationHookResult = ReturnType<typeof useAddTagToOrgMutation>;
export type AddTagToOrgMutationResult = Apollo.MutationResult<AddTagToOrgMutation>;
export type AddTagToOrgMutationOptions = Apollo.BaseMutationOptions<AddTagToOrgMutation, AddTagToOrgMutationVariables>;
export const RemoveTagFromOrgDocument = gql`
    mutation removeTagFromOrg($tagId: String!, $orgId: String!) {
  removeTagFromOrg(tagId: $tagId, orgId: $orgId) {
    id
    orgId
    orgTags {
      id
      name
    }
  }
}
    `;
export type RemoveTagFromOrgMutationFn = Apollo.MutationFunction<RemoveTagFromOrgMutation, RemoveTagFromOrgMutationVariables>;

/**
 * __useRemoveTagFromOrgMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromOrgMutation, { data, loading, error }] = useRemoveTagFromOrgMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useRemoveTagFromOrgMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagFromOrgMutation, RemoveTagFromOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagFromOrgMutation, RemoveTagFromOrgMutationVariables>(RemoveTagFromOrgDocument, options);
      }
export type RemoveTagFromOrgMutationHookResult = ReturnType<typeof useRemoveTagFromOrgMutation>;
export type RemoveTagFromOrgMutationResult = Apollo.MutationResult<RemoveTagFromOrgMutation>;
export type RemoveTagFromOrgMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromOrgMutation, RemoveTagFromOrgMutationVariables>;
export const GetOrgTagsDocument = gql`
    query getOrgTags {
  getOrgTags {
    id
    name
  }
}
    `;

/**
 * __useGetOrgTagsQuery__
 *
 * To run a query within a React component, call `useGetOrgTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgTagsQuery, GetOrgTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgTagsQuery, GetOrgTagsQueryVariables>(GetOrgTagsDocument, options);
      }
export function useGetOrgTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgTagsQuery, GetOrgTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgTagsQuery, GetOrgTagsQueryVariables>(GetOrgTagsDocument, options);
        }
export type GetOrgTagsQueryHookResult = ReturnType<typeof useGetOrgTagsQuery>;
export type GetOrgTagsLazyQueryHookResult = ReturnType<typeof useGetOrgTagsLazyQuery>;
export type GetOrgTagsQueryResult = Apollo.QueryResult<GetOrgTagsQuery, GetOrgTagsQueryVariables>;
export function refetchGetOrgTagsQuery(variables?: GetOrgTagsQueryVariables) {
      return { query: GetOrgTagsDocument, variables: variables }
    }
export const GetInitialPipelinePageDataDocument = gql`
    query getInitialPipelinePageData($id: Int!, $includeArchivedOpportunities: Boolean) {
  getInitialPipelinePageData(
    id: $id
    includeArchivedOpportunities: $includeArchivedOpportunities
  ) {
    id
    pipelineId
    privOpps {
      id
    }
  }
}
    `;

/**
 * __useGetInitialPipelinePageDataQuery__
 *
 * To run a query within a React component, call `useGetInitialPipelinePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialPipelinePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialPipelinePageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeArchivedOpportunities: // value for 'includeArchivedOpportunities'
 *   },
 * });
 */
export function useGetInitialPipelinePageDataQuery(baseOptions: Apollo.QueryHookOptions<GetInitialPipelinePageDataQuery, GetInitialPipelinePageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialPipelinePageDataQuery, GetInitialPipelinePageDataQueryVariables>(GetInitialPipelinePageDataDocument, options);
      }
export function useGetInitialPipelinePageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialPipelinePageDataQuery, GetInitialPipelinePageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialPipelinePageDataQuery, GetInitialPipelinePageDataQueryVariables>(GetInitialPipelinePageDataDocument, options);
        }
export type GetInitialPipelinePageDataQueryHookResult = ReturnType<typeof useGetInitialPipelinePageDataQuery>;
export type GetInitialPipelinePageDataLazyQueryHookResult = ReturnType<typeof useGetInitialPipelinePageDataLazyQuery>;
export type GetInitialPipelinePageDataQueryResult = Apollo.QueryResult<GetInitialPipelinePageDataQuery, GetInitialPipelinePageDataQueryVariables>;
export function refetchGetInitialPipelinePageDataQuery(variables?: GetInitialPipelinePageDataQueryVariables) {
      return { query: GetInitialPipelinePageDataDocument, variables: variables }
    }
export const CreatePipelineDocument = gql`
    mutation createPipeline($name: String!, $basePipelineId: String) {
  createPipeline(name: $name, basePipelineId: $basePipelineId) {
    id
    pipelineId
    name
    customFields
    requiredFields {
      requiredFieldUUId
      fieldName
      storageHint
    }
    stages {
      id
      stageId
      name
      pipelineId
      stageIndex
    }
    oppTags {
      id
      name
      color
    }
  }
}
    `;
export type CreatePipelineMutationFn = Apollo.MutationFunction<CreatePipelineMutation, CreatePipelineMutationVariables>;

/**
 * __useCreatePipelineMutation__
 *
 * To run a mutation, you first call `useCreatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipelineMutation, { data, loading, error }] = useCreatePipelineMutation({
 *   variables: {
 *      name: // value for 'name'
 *      basePipelineId: // value for 'basePipelineId'
 *   },
 * });
 */
export function useCreatePipelineMutation(baseOptions?: Apollo.MutationHookOptions<CreatePipelineMutation, CreatePipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePipelineMutation, CreatePipelineMutationVariables>(CreatePipelineDocument, options);
      }
export type CreatePipelineMutationHookResult = ReturnType<typeof useCreatePipelineMutation>;
export type CreatePipelineMutationResult = Apollo.MutationResult<CreatePipelineMutation>;
export type CreatePipelineMutationOptions = Apollo.BaseMutationOptions<CreatePipelineMutation, CreatePipelineMutationVariables>;
export const EditPipelineDocument = gql`
    mutation editPipeline($id: String!, $name: String!, $customFields: Json, $requiredFields: [RequiredFieldArgument!], $oppTags: Json) {
  editPipeline(
    id: $id
    name: $name
    requiredFields: $requiredFields
    customFields: $customFields
    oppTags: $oppTags
  ) {
    id
    createdAt
    pipelineId
    name
    customFields
    requiredFields {
      requiredFieldUUId
      fieldName
      storageHint
    }
    stages {
      id
    }
    oppTags {
      id
      name
      color
    }
  }
}
    `;
export type EditPipelineMutationFn = Apollo.MutationFunction<EditPipelineMutation, EditPipelineMutationVariables>;

/**
 * __useEditPipelineMutation__
 *
 * To run a mutation, you first call `useEditPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPipelineMutation, { data, loading, error }] = useEditPipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      customFields: // value for 'customFields'
 *      requiredFields: // value for 'requiredFields'
 *      oppTags: // value for 'oppTags'
 *   },
 * });
 */
export function useEditPipelineMutation(baseOptions?: Apollo.MutationHookOptions<EditPipelineMutation, EditPipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPipelineMutation, EditPipelineMutationVariables>(EditPipelineDocument, options);
      }
export type EditPipelineMutationHookResult = ReturnType<typeof useEditPipelineMutation>;
export type EditPipelineMutationResult = Apollo.MutationResult<EditPipelineMutation>;
export type EditPipelineMutationOptions = Apollo.BaseMutationOptions<EditPipelineMutation, EditPipelineMutationVariables>;
export const EditPipelineUserDocument = gql`
    mutation editPipelineUser($pipelineUUId: String!, $userId: String!, $roleUUId: String) {
  editPipelineUser(
    pipelineUUId: $pipelineUUId
    userUUId: $userId
    roleUUId: $roleUUId
  )
}
    `;
export type EditPipelineUserMutationFn = Apollo.MutationFunction<EditPipelineUserMutation, EditPipelineUserMutationVariables>;

/**
 * __useEditPipelineUserMutation__
 *
 * To run a mutation, you first call `useEditPipelineUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPipelineUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPipelineUserMutation, { data, loading, error }] = useEditPipelineUserMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      userId: // value for 'userId'
 *      roleUUId: // value for 'roleUUId'
 *   },
 * });
 */
export function useEditPipelineUserMutation(baseOptions?: Apollo.MutationHookOptions<EditPipelineUserMutation, EditPipelineUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPipelineUserMutation, EditPipelineUserMutationVariables>(EditPipelineUserDocument, options);
      }
export type EditPipelineUserMutationHookResult = ReturnType<typeof useEditPipelineUserMutation>;
export type EditPipelineUserMutationResult = Apollo.MutationResult<EditPipelineUserMutation>;
export type EditPipelineUserMutationOptions = Apollo.BaseMutationOptions<EditPipelineUserMutation, EditPipelineUserMutationVariables>;
export const GetDefaultPipelineDocument = gql`
    query getDefaultPipeline($preferredPipelineId: Int, $preferredPipelineUUId: String, $preferredViewUUId: String) {
  getDefaultPipeline(
    preferredPipelineId: $preferredPipelineId
    preferredPipelineUUId: $preferredPipelineUUId
    preferredViewUUId: $preferredViewUUId
  ) {
    pipelineId
    pipelineUUId
    pipelineViewUUId
  }
}
    `;

/**
 * __useGetDefaultPipelineQuery__
 *
 * To run a query within a React component, call `useGetDefaultPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultPipelineQuery({
 *   variables: {
 *      preferredPipelineId: // value for 'preferredPipelineId'
 *      preferredPipelineUUId: // value for 'preferredPipelineUUId'
 *      preferredViewUUId: // value for 'preferredViewUUId'
 *   },
 * });
 */
export function useGetDefaultPipelineQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultPipelineQuery, GetDefaultPipelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultPipelineQuery, GetDefaultPipelineQueryVariables>(GetDefaultPipelineDocument, options);
      }
export function useGetDefaultPipelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultPipelineQuery, GetDefaultPipelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultPipelineQuery, GetDefaultPipelineQueryVariables>(GetDefaultPipelineDocument, options);
        }
export type GetDefaultPipelineQueryHookResult = ReturnType<typeof useGetDefaultPipelineQuery>;
export type GetDefaultPipelineLazyQueryHookResult = ReturnType<typeof useGetDefaultPipelineLazyQuery>;
export type GetDefaultPipelineQueryResult = Apollo.QueryResult<GetDefaultPipelineQuery, GetDefaultPipelineQueryVariables>;
export function refetchGetDefaultPipelineQuery(variables?: GetDefaultPipelineQueryVariables) {
      return { query: GetDefaultPipelineDocument, variables: variables }
    }
export const GetOutlookOppPreviewsDocument = gql`
    query getOutlookOppPreviews($id: Int!, $includeArchived: Boolean) {
  pipeline(id: $id, includeArchived: $includeArchived) {
    __typename
    id
    pipelineId
    createdAt
    pipelineId
    name
    privOpps {
      __typename
      id
      createdAt
      updatedAt
      pipelineId
      name
      canonId
      oppId
      sol
      createdByUserId
      captureTeam {
        __typename
        id
        captureTeamId
        name
        users {
          __typename
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetOutlookOppPreviewsQuery__
 *
 * To run a query within a React component, call `useGetOutlookOppPreviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutlookOppPreviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutlookOppPreviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetOutlookOppPreviewsQuery(baseOptions: Apollo.QueryHookOptions<GetOutlookOppPreviewsQuery, GetOutlookOppPreviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutlookOppPreviewsQuery, GetOutlookOppPreviewsQueryVariables>(GetOutlookOppPreviewsDocument, options);
      }
export function useGetOutlookOppPreviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutlookOppPreviewsQuery, GetOutlookOppPreviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutlookOppPreviewsQuery, GetOutlookOppPreviewsQueryVariables>(GetOutlookOppPreviewsDocument, options);
        }
export type GetOutlookOppPreviewsQueryHookResult = ReturnType<typeof useGetOutlookOppPreviewsQuery>;
export type GetOutlookOppPreviewsLazyQueryHookResult = ReturnType<typeof useGetOutlookOppPreviewsLazyQuery>;
export type GetOutlookOppPreviewsQueryResult = Apollo.QueryResult<GetOutlookOppPreviewsQuery, GetOutlookOppPreviewsQueryVariables>;
export function refetchGetOutlookOppPreviewsQuery(variables?: GetOutlookOppPreviewsQueryVariables) {
      return { query: GetOutlookOppPreviewsDocument, variables: variables }
    }
export const GetPipelineNamesDocument = gql`
    query getPipelineNames {
  getPipelines {
    __typename
    id
    name
    pipelineId
    workspaceId
  }
}
    `;

/**
 * __useGetPipelineNamesQuery__
 *
 * To run a query within a React component, call `useGetPipelineNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPipelineNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetPipelineNamesQuery, GetPipelineNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineNamesQuery, GetPipelineNamesQueryVariables>(GetPipelineNamesDocument, options);
      }
export function useGetPipelineNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineNamesQuery, GetPipelineNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineNamesQuery, GetPipelineNamesQueryVariables>(GetPipelineNamesDocument, options);
        }
export type GetPipelineNamesQueryHookResult = ReturnType<typeof useGetPipelineNamesQuery>;
export type GetPipelineNamesLazyQueryHookResult = ReturnType<typeof useGetPipelineNamesLazyQuery>;
export type GetPipelineNamesQueryResult = Apollo.QueryResult<GetPipelineNamesQuery, GetPipelineNamesQueryVariables>;
export function refetchGetPipelineNamesQuery(variables?: GetPipelineNamesQueryVariables) {
      return { query: GetPipelineNamesDocument, variables: variables }
    }
export const GetPipelineOppsDocument = gql`
    query getPipelineOpps($id: Int!, $includeArchived: Boolean, $oppIds: [Int!]) {
  pipeline(id: $id, includeArchived: $includeArchived, oppIds: $oppIds) {
    __typename
    id
    pipelineId
    createdAt
    pipelineId
    workspaceId
    default
    name
    privOpps {
      ...PrivOppParts
    }
    stages {
      __typename
      id
      stageId
      layout
      name
      stageId
      stageIndex
    }
  }
}
    ${PrivOppPartsFragmentDoc}`;

/**
 * __useGetPipelineOppsQuery__
 *
 * To run a query within a React component, call `useGetPipelineOppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineOppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineOppsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeArchived: // value for 'includeArchived'
 *      oppIds: // value for 'oppIds'
 *   },
 * });
 */
export function useGetPipelineOppsQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineOppsQuery, GetPipelineOppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineOppsQuery, GetPipelineOppsQueryVariables>(GetPipelineOppsDocument, options);
      }
export function useGetPipelineOppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineOppsQuery, GetPipelineOppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineOppsQuery, GetPipelineOppsQueryVariables>(GetPipelineOppsDocument, options);
        }
export type GetPipelineOppsQueryHookResult = ReturnType<typeof useGetPipelineOppsQuery>;
export type GetPipelineOppsLazyQueryHookResult = ReturnType<typeof useGetPipelineOppsLazyQuery>;
export type GetPipelineOppsQueryResult = Apollo.QueryResult<GetPipelineOppsQuery, GetPipelineOppsQueryVariables>;
export function refetchGetPipelineOppsQuery(variables?: GetPipelineOppsQueryVariables) {
      return { query: GetPipelineOppsDocument, variables: variables }
    }
export const GetPipelinePermissionSettingsDocument = gql`
    query getPipelinePermissionSettings($pipelineUUId: String!) {
  getPipelinePermissionSettings(pipelineUUId: $pipelineUUId) {
    isPermissionManagementEnabled
    roles {
      roleName
      roleUUId
      roleDescription
    }
    users {
      id
      email
      firstName
      lastName
      pipelineRoleUUId
    }
    pipeline {
      id
      pipelineId
      name
    }
  }
}
    `;

/**
 * __useGetPipelinePermissionSettingsQuery__
 *
 * To run a query within a React component, call `useGetPipelinePermissionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelinePermissionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelinePermissionSettingsQuery({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *   },
 * });
 */
export function useGetPipelinePermissionSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetPipelinePermissionSettingsQuery, GetPipelinePermissionSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelinePermissionSettingsQuery, GetPipelinePermissionSettingsQueryVariables>(GetPipelinePermissionSettingsDocument, options);
      }
export function useGetPipelinePermissionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelinePermissionSettingsQuery, GetPipelinePermissionSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelinePermissionSettingsQuery, GetPipelinePermissionSettingsQueryVariables>(GetPipelinePermissionSettingsDocument, options);
        }
export type GetPipelinePermissionSettingsQueryHookResult = ReturnType<typeof useGetPipelinePermissionSettingsQuery>;
export type GetPipelinePermissionSettingsLazyQueryHookResult = ReturnType<typeof useGetPipelinePermissionSettingsLazyQuery>;
export type GetPipelinePermissionSettingsQueryResult = Apollo.QueryResult<GetPipelinePermissionSettingsQuery, GetPipelinePermissionSettingsQueryVariables>;
export function refetchGetPipelinePermissionSettingsQuery(variables?: GetPipelinePermissionSettingsQueryVariables) {
      return { query: GetPipelinePermissionSettingsDocument, variables: variables }
    }
export const GetPipelinesDocument = gql`
    query getPipelines {
  getPipelines {
    __typename
    customFields
    requiredFields {
      requiredFieldUUId
      fieldName
      storageHint
    }
    scoreTemplates {
      id
      categories {
        name
        scoreTemplateCategoryId
      }
    }
    captureTeamTemplate
    id
    name
    pipelineId
    workspaceId
    stageLayout
    oppTags {
      __typename
      id
      color
      tagId
      name
    }
    stages {
      __typename
      id
      stageId
      layout
      name
      stageId
      stageIndex
      taskConfig
      pipelineCustomFields
      stageColumnFields
      showNotes
      pipelineId
      defaultPwin
    }
    pipelineViews {
      __typename
      id
      name
      pipelineViewId
      defaultViewMode
      sortColumn
      sortDirection
      tableColumnOverrides
      filters
      filterMode
      query
    }
  }
}
    `;

/**
 * __useGetPipelinesQuery__
 *
 * To run a query within a React component, call `useGetPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPipelinesQuery(baseOptions?: Apollo.QueryHookOptions<GetPipelinesQuery, GetPipelinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelinesQuery, GetPipelinesQueryVariables>(GetPipelinesDocument, options);
      }
export function useGetPipelinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelinesQuery, GetPipelinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelinesQuery, GetPipelinesQueryVariables>(GetPipelinesDocument, options);
        }
export type GetPipelinesQueryHookResult = ReturnType<typeof useGetPipelinesQuery>;
export type GetPipelinesLazyQueryHookResult = ReturnType<typeof useGetPipelinesLazyQuery>;
export type GetPipelinesQueryResult = Apollo.QueryResult<GetPipelinesQuery, GetPipelinesQueryVariables>;
export function refetchGetPipelinesQuery(variables?: GetPipelinesQueryVariables) {
      return { query: GetPipelinesDocument, variables: variables }
    }
export const GetWorkspacePipelineUuIdDocument = gql`
    query getWorkspacePipelineUUId($pipelineId: Int!) {
  getWorkspacePipelineUUId(pipelineId: $pipelineId)
}
    `;

/**
 * __useGetWorkspacePipelineUuIdQuery__
 *
 * To run a query within a React component, call `useGetWorkspacePipelineUuIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacePipelineUuIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspacePipelineUuIdQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useGetWorkspacePipelineUuIdQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspacePipelineUuIdQuery, GetWorkspacePipelineUuIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspacePipelineUuIdQuery, GetWorkspacePipelineUuIdQueryVariables>(GetWorkspacePipelineUuIdDocument, options);
      }
export function useGetWorkspacePipelineUuIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspacePipelineUuIdQuery, GetWorkspacePipelineUuIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspacePipelineUuIdQuery, GetWorkspacePipelineUuIdQueryVariables>(GetWorkspacePipelineUuIdDocument, options);
        }
export type GetWorkspacePipelineUuIdQueryHookResult = ReturnType<typeof useGetWorkspacePipelineUuIdQuery>;
export type GetWorkspacePipelineUuIdLazyQueryHookResult = ReturnType<typeof useGetWorkspacePipelineUuIdLazyQuery>;
export type GetWorkspacePipelineUuIdQueryResult = Apollo.QueryResult<GetWorkspacePipelineUuIdQuery, GetWorkspacePipelineUuIdQueryVariables>;
export function refetchGetWorkspacePipelineUuIdQuery(variables?: GetWorkspacePipelineUuIdQueryVariables) {
      return { query: GetWorkspacePipelineUuIdDocument, variables: variables }
    }
export const PipelineDocument = gql`
    query pipeline($id: Int!, $includeArchived: Boolean, $oppIds: [Int!]) {
  pipeline(id: $id, includeArchived: $includeArchived, oppIds: $oppIds) {
    __typename
    customFields
    requiredFields {
      requiredFieldUUId
      fieldName
      storageHint
    }
    captureTeamTemplate
    id
    name
    pipelineId
    workspaceId
    stageLayout
    scoreTemplates {
      id
      categories {
        name
        scoreTemplateCategoryId
      }
    }
    default
    oppTags {
      __typename
      id
      color
      tagId
      name
    }
    stages {
      __typename
      id
      stageId
      layout
      name
      stageId
      stageIndex
      taskConfig
      pipelineCustomFields
      stageColumnFields
      showNotes
      pipelineId
      defaultPwin
    }
    customFields
    pipelineViews {
      __typename
      id
      name
      pipelineViewId
      defaultViewMode
      sortColumn
      sortDirection
      tableColumnOverrides
      filters
      filterMode
      query
    }
  }
}
    `;

/**
 * __usePipelineQuery__
 *
 * To run a query within a React component, call `usePipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeArchived: // value for 'includeArchived'
 *      oppIds: // value for 'oppIds'
 *   },
 * });
 */
export function usePipelineQuery(baseOptions: Apollo.QueryHookOptions<PipelineQuery, PipelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PipelineQuery, PipelineQueryVariables>(PipelineDocument, options);
      }
export function usePipelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PipelineQuery, PipelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PipelineQuery, PipelineQueryVariables>(PipelineDocument, options);
        }
export type PipelineQueryHookResult = ReturnType<typeof usePipelineQuery>;
export type PipelineLazyQueryHookResult = ReturnType<typeof usePipelineLazyQuery>;
export type PipelineQueryResult = Apollo.QueryResult<PipelineQuery, PipelineQueryVariables>;
export function refetchPipelineQuery(variables?: PipelineQueryVariables) {
      return { query: PipelineDocument, variables: variables }
    }
export const SetOpportunityStageDocument = gql`
    mutation setOpportunityStage($id: Int!, $stageId: Int!, $index: Int) {
  setOpportunityStage(id: $id, stageId: $stageId, index: $index) {
    id
    oppId
    name
    pipeline {
      id
      pipelineId
      name
      stages {
        id
        stageId
        stageIndex
        layout
      }
    }
  }
}
    `;
export type SetOpportunityStageMutationFn = Apollo.MutationFunction<SetOpportunityStageMutation, SetOpportunityStageMutationVariables>;

/**
 * __useSetOpportunityStageMutation__
 *
 * To run a mutation, you first call `useSetOpportunityStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpportunityStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpportunityStageMutation, { data, loading, error }] = useSetOpportunityStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stageId: // value for 'stageId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useSetOpportunityStageMutation(baseOptions?: Apollo.MutationHookOptions<SetOpportunityStageMutation, SetOpportunityStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOpportunityStageMutation, SetOpportunityStageMutationVariables>(SetOpportunityStageDocument, options);
      }
export type SetOpportunityStageMutationHookResult = ReturnType<typeof useSetOpportunityStageMutation>;
export type SetOpportunityStageMutationResult = Apollo.MutationResult<SetOpportunityStageMutation>;
export type SetOpportunityStageMutationOptions = Apollo.BaseMutationOptions<SetOpportunityStageMutation, SetOpportunityStageMutationVariables>;
export const ToggleUserLevelPermissionsDocument = gql`
    mutation toggleUserLevelPermissions($pipelineUUId: String!, $enable: Boolean!) {
  toggleUserLevelPermissions(pipelineUUId: $pipelineUUId, enable: $enable)
}
    `;
export type ToggleUserLevelPermissionsMutationFn = Apollo.MutationFunction<ToggleUserLevelPermissionsMutation, ToggleUserLevelPermissionsMutationVariables>;

/**
 * __useToggleUserLevelPermissionsMutation__
 *
 * To run a mutation, you first call `useToggleUserLevelPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserLevelPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserLevelPermissionsMutation, { data, loading, error }] = useToggleUserLevelPermissionsMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      enable: // value for 'enable'
 *   },
 * });
 */
export function useToggleUserLevelPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUserLevelPermissionsMutation, ToggleUserLevelPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUserLevelPermissionsMutation, ToggleUserLevelPermissionsMutationVariables>(ToggleUserLevelPermissionsDocument, options);
      }
export type ToggleUserLevelPermissionsMutationHookResult = ReturnType<typeof useToggleUserLevelPermissionsMutation>;
export type ToggleUserLevelPermissionsMutationResult = Apollo.MutationResult<ToggleUserLevelPermissionsMutation>;
export type ToggleUserLevelPermissionsMutationOptions = Apollo.BaseMutationOptions<ToggleUserLevelPermissionsMutation, ToggleUserLevelPermissionsMutationVariables>;
export const UpdatePipelineDocument = gql`
    mutation updatePipeline($id: String!, $name: String, $customFields: Json, $stageLayout: [Int!], $oppTags: Json, $summaryTemplate: Json, $proposalTemplate: Json) {
  editPipeline(
    id: $id
    name: $name
    customFields: $customFields
    stageLayout: $stageLayout
    oppTags: $oppTags
    summaryTemplate: $summaryTemplate
    proposalTemplate: $proposalTemplate
  ) {
    id
    createdAt
    pipelineId
    name
    customFields
    stages {
      id
    }
    oppTags {
      id
      name
      color
    }
  }
}
    `;
export type UpdatePipelineMutationFn = Apollo.MutationFunction<UpdatePipelineMutation, UpdatePipelineMutationVariables>;

/**
 * __useUpdatePipelineMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineMutation, { data, loading, error }] = useUpdatePipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      customFields: // value for 'customFields'
 *      stageLayout: // value for 'stageLayout'
 *      oppTags: // value for 'oppTags'
 *      summaryTemplate: // value for 'summaryTemplate'
 *      proposalTemplate: // value for 'proposalTemplate'
 *   },
 * });
 */
export function useUpdatePipelineMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineMutation, UpdatePipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineMutation, UpdatePipelineMutationVariables>(UpdatePipelineDocument, options);
      }
export type UpdatePipelineMutationHookResult = ReturnType<typeof useUpdatePipelineMutation>;
export type UpdatePipelineMutationResult = Apollo.MutationResult<UpdatePipelineMutation>;
export type UpdatePipelineMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineMutation, UpdatePipelineMutationVariables>;
export const AddFieldOptionDocument = gql`
    mutation addFieldOption($pipelineId: String!, $field: String!, $value: String!) {
  addFieldOption(pipelineId: $pipelineId, field: $field, value: $value) {
    customFields
  }
}
    `;
export type AddFieldOptionMutationFn = Apollo.MutationFunction<AddFieldOptionMutation, AddFieldOptionMutationVariables>;

/**
 * __useAddFieldOptionMutation__
 *
 * To run a mutation, you first call `useAddFieldOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldOptionMutation, { data, loading, error }] = useAddFieldOptionMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      field: // value for 'field'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddFieldOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddFieldOptionMutation, AddFieldOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFieldOptionMutation, AddFieldOptionMutationVariables>(AddFieldOptionDocument, options);
      }
export type AddFieldOptionMutationHookResult = ReturnType<typeof useAddFieldOptionMutation>;
export type AddFieldOptionMutationResult = Apollo.MutationResult<AddFieldOptionMutation>;
export type AddFieldOptionMutationOptions = Apollo.BaseMutationOptions<AddFieldOptionMutation, AddFieldOptionMutationVariables>;
export const DuplicatePipelineDocument = gql`
    mutation duplicatePipeline($referencePipelineId: String!) {
  duplicatePipeline(referencePipelineId: $referencePipelineId) {
    id
    pipelineId
    name
    customFields
    stages {
      id
      stageId
      name
      pipelineId
      stageIndex
    }
    oppTags {
      id
      name
      color
    }
  }
}
    `;
export type DuplicatePipelineMutationFn = Apollo.MutationFunction<DuplicatePipelineMutation, DuplicatePipelineMutationVariables>;

/**
 * __useDuplicatePipelineMutation__
 *
 * To run a mutation, you first call `useDuplicatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePipelineMutation, { data, loading, error }] = useDuplicatePipelineMutation({
 *   variables: {
 *      referencePipelineId: // value for 'referencePipelineId'
 *   },
 * });
 */
export function useDuplicatePipelineMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePipelineMutation, DuplicatePipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePipelineMutation, DuplicatePipelineMutationVariables>(DuplicatePipelineDocument, options);
      }
export type DuplicatePipelineMutationHookResult = ReturnType<typeof useDuplicatePipelineMutation>;
export type DuplicatePipelineMutationResult = Apollo.MutationResult<DuplicatePipelineMutation>;
export type DuplicatePipelineMutationOptions = Apollo.BaseMutationOptions<DuplicatePipelineMutation, DuplicatePipelineMutationVariables>;
export const DeletePipelineDocument = gql`
    mutation deletePipeline($id: String!) {
  deletePipeline(id: $id) {
    id
  }
}
    `;
export type DeletePipelineMutationFn = Apollo.MutationFunction<DeletePipelineMutation, DeletePipelineMutationVariables>;

/**
 * __useDeletePipelineMutation__
 *
 * To run a mutation, you first call `useDeletePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineMutation, { data, loading, error }] = useDeletePipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePipelineMutation(baseOptions?: Apollo.MutationHookOptions<DeletePipelineMutation, DeletePipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePipelineMutation, DeletePipelineMutationVariables>(DeletePipelineDocument, options);
      }
export type DeletePipelineMutationHookResult = ReturnType<typeof useDeletePipelineMutation>;
export type DeletePipelineMutationResult = Apollo.MutationResult<DeletePipelineMutation>;
export type DeletePipelineMutationOptions = Apollo.BaseMutationOptions<DeletePipelineMutation, DeletePipelineMutationVariables>;
export const CreatePipelineViewDocument = gql`
    mutation createPipelineView($name: String!, $filters: Json!, $query: String, $filterMode: FilterMode, $pipelineId: String!, $defaultViewMode: PipelineViewMode, $sortColumn: String, $sortDirection: SortDirection, $tableColumnOverrides: Json) {
  createPipelineView(
    name: $name
    filters: $filters
    filterMode: $filterMode
    query: $query
    pipelineId: $pipelineId
    defaultViewMode: $defaultViewMode
    sortColumn: $sortColumn
    sortDirection: $sortDirection
    tableColumnOverrides: $tableColumnOverrides
  ) {
    id
    name
    filters
    query
    pipelineId
  }
}
    `;
export type CreatePipelineViewMutationFn = Apollo.MutationFunction<CreatePipelineViewMutation, CreatePipelineViewMutationVariables>;

/**
 * __useCreatePipelineViewMutation__
 *
 * To run a mutation, you first call `useCreatePipelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipelineViewMutation, { data, loading, error }] = useCreatePipelineViewMutation({
 *   variables: {
 *      name: // value for 'name'
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *      filterMode: // value for 'filterMode'
 *      pipelineId: // value for 'pipelineId'
 *      defaultViewMode: // value for 'defaultViewMode'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *      tableColumnOverrides: // value for 'tableColumnOverrides'
 *   },
 * });
 */
export function useCreatePipelineViewMutation(baseOptions?: Apollo.MutationHookOptions<CreatePipelineViewMutation, CreatePipelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePipelineViewMutation, CreatePipelineViewMutationVariables>(CreatePipelineViewDocument, options);
      }
export type CreatePipelineViewMutationHookResult = ReturnType<typeof useCreatePipelineViewMutation>;
export type CreatePipelineViewMutationResult = Apollo.MutationResult<CreatePipelineViewMutation>;
export type CreatePipelineViewMutationOptions = Apollo.BaseMutationOptions<CreatePipelineViewMutation, CreatePipelineViewMutationVariables>;
export const UpdatePipelineViewDocument = gql`
    mutation updatePipelineView($id: String!, $name: String, $filters: Json!, $filterMode: FilterMode, $query: String, $defaultViewMode: PipelineViewMode, $sortColumn: String, $sortDirection: SortDirection, $tableColumnOverrides: Json) {
  updatePipelineView(
    id: $id
    name: $name
    filters: $filters
    filterMode: $filterMode
    query: $query
    defaultViewMode: $defaultViewMode
    sortColumn: $sortColumn
    sortDirection: $sortDirection
    tableColumnOverrides: $tableColumnOverrides
  ) {
    id
    name
    filters
    query
    pipelineId
  }
}
    `;
export type UpdatePipelineViewMutationFn = Apollo.MutationFunction<UpdatePipelineViewMutation, UpdatePipelineViewMutationVariables>;

/**
 * __useUpdatePipelineViewMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineViewMutation, { data, loading, error }] = useUpdatePipelineViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      filters: // value for 'filters'
 *      filterMode: // value for 'filterMode'
 *      query: // value for 'query'
 *      defaultViewMode: // value for 'defaultViewMode'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *      tableColumnOverrides: // value for 'tableColumnOverrides'
 *   },
 * });
 */
export function useUpdatePipelineViewMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineViewMutation, UpdatePipelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineViewMutation, UpdatePipelineViewMutationVariables>(UpdatePipelineViewDocument, options);
      }
export type UpdatePipelineViewMutationHookResult = ReturnType<typeof useUpdatePipelineViewMutation>;
export type UpdatePipelineViewMutationResult = Apollo.MutationResult<UpdatePipelineViewMutation>;
export type UpdatePipelineViewMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineViewMutation, UpdatePipelineViewMutationVariables>;
export const DeletePipelineViewDocument = gql`
    mutation deletePipelineView($id: String!) {
  deletePipelineView(id: $id) {
    id
    name
    filters
    pipelineId
  }
}
    `;
export type DeletePipelineViewMutationFn = Apollo.MutationFunction<DeletePipelineViewMutation, DeletePipelineViewMutationVariables>;

/**
 * __useDeletePipelineViewMutation__
 *
 * To run a mutation, you first call `useDeletePipelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineViewMutation, { data, loading, error }] = useDeletePipelineViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePipelineViewMutation(baseOptions?: Apollo.MutationHookOptions<DeletePipelineViewMutation, DeletePipelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePipelineViewMutation, DeletePipelineViewMutationVariables>(DeletePipelineViewDocument, options);
      }
export type DeletePipelineViewMutationHookResult = ReturnType<typeof useDeletePipelineViewMutation>;
export type DeletePipelineViewMutationResult = Apollo.MutationResult<DeletePipelineViewMutation>;
export type DeletePipelineViewMutationOptions = Apollo.BaseMutationOptions<DeletePipelineViewMutation, DeletePipelineViewMutationVariables>;
export const CreatePrivOppSourceDocument = gql`
    mutation createPrivOppSource($privOppUUId: String!, $source: Json, $label: String!, $type: PrivOppSourceEnum!) {
  createPrivOppSource(
    privOppUUId: $privOppUUId
    source: $source
    type: $type
    label: $label
  ) {
    __typename
    id
    oppId
    sources {
      privOppSourceUUId
      label
      sourceType
      source
    }
  }
}
    `;
export type CreatePrivOppSourceMutationFn = Apollo.MutationFunction<CreatePrivOppSourceMutation, CreatePrivOppSourceMutationVariables>;

/**
 * __useCreatePrivOppSourceMutation__
 *
 * To run a mutation, you first call `useCreatePrivOppSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivOppSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivOppSourceMutation, { data, loading, error }] = useCreatePrivOppSourceMutation({
 *   variables: {
 *      privOppUUId: // value for 'privOppUUId'
 *      source: // value for 'source'
 *      label: // value for 'label'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreatePrivOppSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrivOppSourceMutation, CreatePrivOppSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrivOppSourceMutation, CreatePrivOppSourceMutationVariables>(CreatePrivOppSourceDocument, options);
      }
export type CreatePrivOppSourceMutationHookResult = ReturnType<typeof useCreatePrivOppSourceMutation>;
export type CreatePrivOppSourceMutationResult = Apollo.MutationResult<CreatePrivOppSourceMutation>;
export type CreatePrivOppSourceMutationOptions = Apollo.BaseMutationOptions<CreatePrivOppSourceMutation, CreatePrivOppSourceMutationVariables>;
export const GetOppSummaryViewDataDocument = gql`
    query getOppSummaryViewData($canonId: String!) {
  getPrivOpp(canonId: $canonId) {
    id
    oppId
    pipelineId
    updatedAt
    createdAt
    canonId
    name
    isBid
    isWin
    isArchived
    metadata
    solType
    solicitationDetails
    rfiDetails
    histAwardDetails
    sol
    cachedGovWinDetails
    internalSolId
    award
    internalAwardId
    prevSol
    internalPrevSolId
    documents
    oppScoreTs
    winningPrice
    lossReason
    govWinId
    client {
      id
      orgId
      name
    }
    sources {
      privOppSourceUUId
      label
      sourceType
      source
    }
    pipeline {
      id
      pipelineId
      customFields
      captureTeamTemplate
      summaryTemplate {
        definition
      }
      proposalTemplate {
        definition
      }
      scoreTemplates {
        id
        scoreTemplateId
        categories {
          id
          position
          scoreTemplateCategoryId
          name
          fields {
            id
            index
            description
            name
            range
            scoreTemplateId
            scoreTemplateFieldId
            weight
          }
        }
        fields {
          id
          scoreTemplateFieldId
          description
          name
        }
      }
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      privOppId
      privOrgId
      createdAt
      documents
      createdByFirstName
      createdByLastName
      contacts {
        id
        contactId
        fullName
        firstName
        lastName
        email
        title
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    captureTeam {
      id
      name
      users {
        id
        firstName
        lastName
        email
        isZombie
      }
    }
    createdBy {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetOppSummaryViewDataQuery__
 *
 * To run a query within a React component, call `useGetOppSummaryViewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOppSummaryViewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOppSummaryViewDataQuery({
 *   variables: {
 *      canonId: // value for 'canonId'
 *   },
 * });
 */
export function useGetOppSummaryViewDataQuery(baseOptions: Apollo.QueryHookOptions<GetOppSummaryViewDataQuery, GetOppSummaryViewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOppSummaryViewDataQuery, GetOppSummaryViewDataQueryVariables>(GetOppSummaryViewDataDocument, options);
      }
export function useGetOppSummaryViewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOppSummaryViewDataQuery, GetOppSummaryViewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOppSummaryViewDataQuery, GetOppSummaryViewDataQueryVariables>(GetOppSummaryViewDataDocument, options);
        }
export type GetOppSummaryViewDataQueryHookResult = ReturnType<typeof useGetOppSummaryViewDataQuery>;
export type GetOppSummaryViewDataLazyQueryHookResult = ReturnType<typeof useGetOppSummaryViewDataLazyQuery>;
export type GetOppSummaryViewDataQueryResult = Apollo.QueryResult<GetOppSummaryViewDataQuery, GetOppSummaryViewDataQueryVariables>;
export function refetchGetOppSummaryViewDataQuery(variables?: GetOppSummaryViewDataQueryVariables) {
      return { query: GetOppSummaryViewDataDocument, variables: variables }
    }
export const UpdateMetadataDocument = gql`
    mutation updateMetadata($id: Int!, $field: String!, $value: Json!) {
  updateMetadata(id: $id, field: $field, value: $value) {
    __typename
  }
}
    `;
export type UpdateMetadataMutationFn = Apollo.MutationFunction<UpdateMetadataMutation, UpdateMetadataMutationVariables>;

/**
 * __useUpdateMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetadataMutation, { data, loading, error }] = useUpdateMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      field: // value for 'field'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetadataMutation, UpdateMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetadataMutation, UpdateMetadataMutationVariables>(UpdateMetadataDocument, options);
      }
export type UpdateMetadataMutationHookResult = ReturnType<typeof useUpdateMetadataMutation>;
export type UpdateMetadataMutationResult = Apollo.MutationResult<UpdateMetadataMutation>;
export type UpdateMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateMetadataMutation, UpdateMetadataMutationVariables>;
export const UpdatePrivOppSourceDocument = gql`
    mutation updatePrivOppSource($privOppUUId: String!, $privOppSourceUUId: String!, $source: Json, $label: String) {
  updatePrivOppSource(
    privOppUUId: $privOppUUId
    privOppSourceUUId: $privOppSourceUUId
    source: $source
    label: $label
  ) {
    __typename
    id
    oppId
    rfiDetails
    sources {
      privOppSourceUUId
      label
      sourceType
      source
    }
  }
}
    `;
export type UpdatePrivOppSourceMutationFn = Apollo.MutationFunction<UpdatePrivOppSourceMutation, UpdatePrivOppSourceMutationVariables>;

/**
 * __useUpdatePrivOppSourceMutation__
 *
 * To run a mutation, you first call `useUpdatePrivOppSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivOppSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivOppSourceMutation, { data, loading, error }] = useUpdatePrivOppSourceMutation({
 *   variables: {
 *      privOppUUId: // value for 'privOppUUId'
 *      privOppSourceUUId: // value for 'privOppSourceUUId'
 *      source: // value for 'source'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useUpdatePrivOppSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivOppSourceMutation, UpdatePrivOppSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivOppSourceMutation, UpdatePrivOppSourceMutationVariables>(UpdatePrivOppSourceDocument, options);
      }
export type UpdatePrivOppSourceMutationHookResult = ReturnType<typeof useUpdatePrivOppSourceMutation>;
export type UpdatePrivOppSourceMutationResult = Apollo.MutationResult<UpdatePrivOppSourceMutation>;
export type UpdatePrivOppSourceMutationOptions = Apollo.BaseMutationOptions<UpdatePrivOppSourceMutation, UpdatePrivOppSourceMutationVariables>;
export const PubWorkspaceDeletePrivOppDocument = gql`
    mutation pubWorkspaceDeletePrivOpp($id: Int) {
  pubWorkspaceDeletePrivOpp(id: $id) {
    id
  }
}
    `;
export type PubWorkspaceDeletePrivOppMutationFn = Apollo.MutationFunction<PubWorkspaceDeletePrivOppMutation, PubWorkspaceDeletePrivOppMutationVariables>;

/**
 * __usePubWorkspaceDeletePrivOppMutation__
 *
 * To run a mutation, you first call `usePubWorkspaceDeletePrivOppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubWorkspaceDeletePrivOppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubWorkspaceDeletePrivOppMutation, { data, loading, error }] = usePubWorkspaceDeletePrivOppMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePubWorkspaceDeletePrivOppMutation(baseOptions?: Apollo.MutationHookOptions<PubWorkspaceDeletePrivOppMutation, PubWorkspaceDeletePrivOppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubWorkspaceDeletePrivOppMutation, PubWorkspaceDeletePrivOppMutationVariables>(PubWorkspaceDeletePrivOppDocument, options);
      }
export type PubWorkspaceDeletePrivOppMutationHookResult = ReturnType<typeof usePubWorkspaceDeletePrivOppMutation>;
export type PubWorkspaceDeletePrivOppMutationResult = Apollo.MutationResult<PubWorkspaceDeletePrivOppMutation>;
export type PubWorkspaceDeletePrivOppMutationOptions = Apollo.BaseMutationOptions<PubWorkspaceDeletePrivOppMutation, PubWorkspaceDeletePrivOppMutationVariables>;
export const DeleteManyPrivOppsDocument = gql`
    mutation deleteManyPrivOpps($privOppIds: [String]) {
  deleteManyPrivOpps(privOppIds: $privOppIds)
}
    `;
export type DeleteManyPrivOppsMutationFn = Apollo.MutationFunction<DeleteManyPrivOppsMutation, DeleteManyPrivOppsMutationVariables>;

/**
 * __useDeleteManyPrivOppsMutation__
 *
 * To run a mutation, you first call `useDeleteManyPrivOppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPrivOppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPrivOppsMutation, { data, loading, error }] = useDeleteManyPrivOppsMutation({
 *   variables: {
 *      privOppIds: // value for 'privOppIds'
 *   },
 * });
 */
export function useDeleteManyPrivOppsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPrivOppsMutation, DeleteManyPrivOppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPrivOppsMutation, DeleteManyPrivOppsMutationVariables>(DeleteManyPrivOppsDocument, options);
      }
export type DeleteManyPrivOppsMutationHookResult = ReturnType<typeof useDeleteManyPrivOppsMutation>;
export type DeleteManyPrivOppsMutationResult = Apollo.MutationResult<DeleteManyPrivOppsMutation>;
export type DeleteManyPrivOppsMutationOptions = Apollo.BaseMutationOptions<DeleteManyPrivOppsMutation, DeleteManyPrivOppsMutationVariables>;
export const UpdatePrivOppStageDocument = gql`
    mutation updatePrivOppStage($privOppId: Int!, $destStageId: Int!, $index: Int!) {
  updatePrivOppStage(
    privOppId: $privOppId
    destStageId: $destStageId
    index: $index
  ) {
    id
  }
}
    `;
export type UpdatePrivOppStageMutationFn = Apollo.MutationFunction<UpdatePrivOppStageMutation, UpdatePrivOppStageMutationVariables>;

/**
 * __useUpdatePrivOppStageMutation__
 *
 * To run a mutation, you first call `useUpdatePrivOppStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivOppStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivOppStageMutation, { data, loading, error }] = useUpdatePrivOppStageMutation({
 *   variables: {
 *      privOppId: // value for 'privOppId'
 *      destStageId: // value for 'destStageId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useUpdatePrivOppStageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivOppStageMutation, UpdatePrivOppStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivOppStageMutation, UpdatePrivOppStageMutationVariables>(UpdatePrivOppStageDocument, options);
      }
export type UpdatePrivOppStageMutationHookResult = ReturnType<typeof useUpdatePrivOppStageMutation>;
export type UpdatePrivOppStageMutationResult = Apollo.MutationResult<UpdatePrivOppStageMutation>;
export type UpdatePrivOppStageMutationOptions = Apollo.BaseMutationOptions<UpdatePrivOppStageMutation, UpdatePrivOppStageMutationVariables>;
export const UpdatePrivOppDocument = gql`
    mutation updatePrivOpp($id: Int!, $awardId: String, $clientId: String, $govWinId: String, $isArchived: Boolean, $isBid: Boolean, $isWin: String, $metadata: Json, $name: String, $pipelineId: String, $stageId: String, $solId: String, $prevSolId: String, $tags: Json, $winnerId: String, $winningPrice: Float) {
  updatePrivOpp(
    id: $id
    awardId: $awardId
    clientId: $clientId
    govWinId: $govWinId
    isArchived: $isArchived
    isBid: $isBid
    isWin: $isWin
    metadata: $metadata
    name: $name
    pipelineId: $pipelineId
    stageId: $stageId
    solId: $solId
    prevSolId: $prevSolId
    tags: $tags
    winnerId: $winnerId
    winningPrice: $winningPrice
  ) {
    id
    isBid
    isWin
    name
    metadata
    govWinId
    winningPrice
    lossReason
    privOppStage {
      id
      stageId
      stage {
        id
        name
      }
    }
    client {
      id
      orgId
      name
    }
    privOppStage {
      id
      stage {
        id
        name
      }
    }
    tags {
      id
      name
      color
    }
  }
}
    `;
export type UpdatePrivOppMutationFn = Apollo.MutationFunction<UpdatePrivOppMutation, UpdatePrivOppMutationVariables>;

/**
 * __useUpdatePrivOppMutation__
 *
 * To run a mutation, you first call `useUpdatePrivOppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivOppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivOppMutation, { data, loading, error }] = useUpdatePrivOppMutation({
 *   variables: {
 *      id: // value for 'id'
 *      awardId: // value for 'awardId'
 *      clientId: // value for 'clientId'
 *      govWinId: // value for 'govWinId'
 *      isArchived: // value for 'isArchived'
 *      isBid: // value for 'isBid'
 *      isWin: // value for 'isWin'
 *      metadata: // value for 'metadata'
 *      name: // value for 'name'
 *      pipelineId: // value for 'pipelineId'
 *      stageId: // value for 'stageId'
 *      solId: // value for 'solId'
 *      prevSolId: // value for 'prevSolId'
 *      tags: // value for 'tags'
 *      winnerId: // value for 'winnerId'
 *      winningPrice: // value for 'winningPrice'
 *   },
 * });
 */
export function useUpdatePrivOppMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivOppMutation, UpdatePrivOppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivOppMutation, UpdatePrivOppMutationVariables>(UpdatePrivOppDocument, options);
      }
export type UpdatePrivOppMutationHookResult = ReturnType<typeof useUpdatePrivOppMutation>;
export type UpdatePrivOppMutationResult = Apollo.MutationResult<UpdatePrivOppMutation>;
export type UpdatePrivOppMutationOptions = Apollo.BaseMutationOptions<UpdatePrivOppMutation, UpdatePrivOppMutationVariables>;
export const SearchPromptTemplateGroupsDocument = gql`
    query searchPromptTemplateGroups($type: GqlPromptType) {
  searchPromptTemplateGroups(type: $type) {
    results {
      uuid
      name
      description
      type
      templates {
        uuid
        name
        schema {
          label
          description
          guide
          fields {
            key
            type
            label
            guide
            placeholder
            required
            defaultValue
          }
          additionalInstructions {
            label
            guide
          }
        }
      }
    }
    total
  }
}
    `;

/**
 * __useSearchPromptTemplateGroupsQuery__
 *
 * To run a query within a React component, call `useSearchPromptTemplateGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPromptTemplateGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPromptTemplateGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchPromptTemplateGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SearchPromptTemplateGroupsQuery, SearchPromptTemplateGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPromptTemplateGroupsQuery, SearchPromptTemplateGroupsQueryVariables>(SearchPromptTemplateGroupsDocument, options);
      }
export function useSearchPromptTemplateGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPromptTemplateGroupsQuery, SearchPromptTemplateGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPromptTemplateGroupsQuery, SearchPromptTemplateGroupsQueryVariables>(SearchPromptTemplateGroupsDocument, options);
        }
export type SearchPromptTemplateGroupsQueryHookResult = ReturnType<typeof useSearchPromptTemplateGroupsQuery>;
export type SearchPromptTemplateGroupsLazyQueryHookResult = ReturnType<typeof useSearchPromptTemplateGroupsLazyQuery>;
export type SearchPromptTemplateGroupsQueryResult = Apollo.QueryResult<SearchPromptTemplateGroupsQuery, SearchPromptTemplateGroupsQueryVariables>;
export function refetchSearchPromptTemplateGroupsQuery(variables?: SearchPromptTemplateGroupsQueryVariables) {
      return { query: SearchPromptTemplateGroupsDocument, variables: variables }
    }
export const CreateProposalDocumentDocument = gql`
    mutation createProposalDocument($opportunityUUId: String!, $name: String!) {
  createProposalDocument(opportunityUUId: $opportunityUUId, name: $name) {
    id
    uuid
    name
    createdAt
    updatedAt
    createdBy {
      id
      userId
      firstName
      lastName
    }
  }
}
    `;
export type CreateProposalDocumentMutationFn = Apollo.MutationFunction<CreateProposalDocumentMutation, CreateProposalDocumentMutationVariables>;

/**
 * __useCreateProposalDocumentMutation__
 *
 * To run a mutation, you first call `useCreateProposalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalDocumentMutation, { data, loading, error }] = useCreateProposalDocumentMutation({
 *   variables: {
 *      opportunityUUId: // value for 'opportunityUUId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProposalDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalDocumentMutation, CreateProposalDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalDocumentMutation, CreateProposalDocumentMutationVariables>(CreateProposalDocumentDocument, options);
      }
export type CreateProposalDocumentMutationHookResult = ReturnType<typeof useCreateProposalDocumentMutation>;
export type CreateProposalDocumentMutationResult = Apollo.MutationResult<CreateProposalDocumentMutation>;
export type CreateProposalDocumentMutationOptions = Apollo.BaseMutationOptions<CreateProposalDocumentMutation, CreateProposalDocumentMutationVariables>;
export const CreateProposalStageDocument = gql`
    mutation createProposalStage($pipelineUUId: String!, $name: String!, $index: Int) {
  createProposalStage(pipelineUUId: $pipelineUUId, name: $name, index: $index) {
    id
    uuid
    name
    index
  }
}
    `;
export type CreateProposalStageMutationFn = Apollo.MutationFunction<CreateProposalStageMutation, CreateProposalStageMutationVariables>;

/**
 * __useCreateProposalStageMutation__
 *
 * To run a mutation, you first call `useCreateProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalStageMutation, { data, loading, error }] = useCreateProposalStageMutation({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      name: // value for 'name'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useCreateProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalStageMutation, CreateProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalStageMutation, CreateProposalStageMutationVariables>(CreateProposalStageDocument, options);
      }
export type CreateProposalStageMutationHookResult = ReturnType<typeof useCreateProposalStageMutation>;
export type CreateProposalStageMutationResult = Apollo.MutationResult<CreateProposalStageMutation>;
export type CreateProposalStageMutationOptions = Apollo.BaseMutationOptions<CreateProposalStageMutation, CreateProposalStageMutationVariables>;
export const DeleteProposalDocumentDocument = gql`
    mutation deleteProposalDocument($uuid: String!) {
  deleteProposalDocument(uuid: $uuid) {
    uuid
  }
}
    `;
export type DeleteProposalDocumentMutationFn = Apollo.MutationFunction<DeleteProposalDocumentMutation, DeleteProposalDocumentMutationVariables>;

/**
 * __useDeleteProposalDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteProposalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProposalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProposalDocumentMutation, { data, loading, error }] = useDeleteProposalDocumentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteProposalDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProposalDocumentMutation, DeleteProposalDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProposalDocumentMutation, DeleteProposalDocumentMutationVariables>(DeleteProposalDocumentDocument, options);
      }
export type DeleteProposalDocumentMutationHookResult = ReturnType<typeof useDeleteProposalDocumentMutation>;
export type DeleteProposalDocumentMutationResult = Apollo.MutationResult<DeleteProposalDocumentMutation>;
export type DeleteProposalDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteProposalDocumentMutation, DeleteProposalDocumentMutationVariables>;
export const DeleteProposalStageDocument = gql`
    mutation deleteProposalStage($uuid: String!) {
  deleteProposalStage(uuid: $uuid)
}
    `;
export type DeleteProposalStageMutationFn = Apollo.MutationFunction<DeleteProposalStageMutation, DeleteProposalStageMutationVariables>;

/**
 * __useDeleteProposalStageMutation__
 *
 * To run a mutation, you first call `useDeleteProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProposalStageMutation, { data, loading, error }] = useDeleteProposalStageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProposalStageMutation, DeleteProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProposalStageMutation, DeleteProposalStageMutationVariables>(DeleteProposalStageDocument, options);
      }
export type DeleteProposalStageMutationHookResult = ReturnType<typeof useDeleteProposalStageMutation>;
export type DeleteProposalStageMutationResult = Apollo.MutationResult<DeleteProposalStageMutation>;
export type DeleteProposalStageMutationOptions = Apollo.BaseMutationOptions<DeleteProposalStageMutation, DeleteProposalStageMutationVariables>;
export const GetPipelineProposalOptionsDocument = gql`
    query getPipelineProposalOptions($uuid: String!) {
  getPipelineByUUId(uuid: $uuid) {
    id
    name
    pipelineId
    privOpps {
      id
      name
      oppId
      canonId
      isArchived
    }
    proposalStages {
      id
      uuid
      name
      index
    }
  }
}
    `;

/**
 * __useGetPipelineProposalOptionsQuery__
 *
 * To run a query within a React component, call `useGetPipelineProposalOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineProposalOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineProposalOptionsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetPipelineProposalOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineProposalOptionsQuery, GetPipelineProposalOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineProposalOptionsQuery, GetPipelineProposalOptionsQueryVariables>(GetPipelineProposalOptionsDocument, options);
      }
export function useGetPipelineProposalOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineProposalOptionsQuery, GetPipelineProposalOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineProposalOptionsQuery, GetPipelineProposalOptionsQueryVariables>(GetPipelineProposalOptionsDocument, options);
        }
export type GetPipelineProposalOptionsQueryHookResult = ReturnType<typeof useGetPipelineProposalOptionsQuery>;
export type GetPipelineProposalOptionsLazyQueryHookResult = ReturnType<typeof useGetPipelineProposalOptionsLazyQuery>;
export type GetPipelineProposalOptionsQueryResult = Apollo.QueryResult<GetPipelineProposalOptionsQuery, GetPipelineProposalOptionsQueryVariables>;
export function refetchGetPipelineProposalOptionsQuery(variables?: GetPipelineProposalOptionsQueryVariables) {
      return { query: GetPipelineProposalOptionsDocument, variables: variables }
    }
export const GetProposalDocument = gql`
    query getProposal($uuid: String!) {
  getOpportunity(uuid: $uuid) {
    id
    oppId
    name
    canonId
    isArchived
    proposalStagePosition
    captureTeam {
      id
      name
      users {
        id
        firstName
        lastName
        email
        isZombie
      }
      isProposalContributor
    }
    proposalStage {
      id
      uuid
      name
      index
    }
    pipeline {
      id
      name
      proposalTemplate {
        definition
      }
      pipelineId
    }
    proposalDocuments {
      id
      uuid
      name
      createdAt
      updatedAt
      createdBy {
        id
        userId
        firstName
        lastName
      }
      opportunity {
        oppId
      }
      richTextDocument {
        uuid
      }
    }
    complianceMatrixDocumentRequestUrl
    tags {
      name
    }
  }
}
    `;

/**
 * __useGetProposalQuery__
 *
 * To run a query within a React component, call `useGetProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProposalQuery(baseOptions: Apollo.QueryHookOptions<GetProposalQuery, GetProposalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProposalQuery, GetProposalQueryVariables>(GetProposalDocument, options);
      }
export function useGetProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProposalQuery, GetProposalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProposalQuery, GetProposalQueryVariables>(GetProposalDocument, options);
        }
export type GetProposalQueryHookResult = ReturnType<typeof useGetProposalQuery>;
export type GetProposalLazyQueryHookResult = ReturnType<typeof useGetProposalLazyQuery>;
export type GetProposalQueryResult = Apollo.QueryResult<GetProposalQuery, GetProposalQueryVariables>;
export function refetchGetProposalQuery(variables?: GetProposalQueryVariables) {
      return { query: GetProposalDocument, variables: variables }
    }
export const GetProposalDocumentDocument = gql`
    query getProposalDocument($uuid: String!) {
  getProposalDocument(uuid: $uuid) {
    id
    uuid
    name
    createdAt
    updatedAt
    createdBy {
      id
      userId
      firstName
      lastName
    }
    opportunity {
      name
      oppId
      pipeline {
        name
      }
    }
    richTextDocument {
      uuid
    }
  }
}
    `;

/**
 * __useGetProposalDocumentQuery__
 *
 * To run a query within a React component, call `useGetProposalDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalDocumentQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProposalDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetProposalDocumentQuery, GetProposalDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProposalDocumentQuery, GetProposalDocumentQueryVariables>(GetProposalDocumentDocument, options);
      }
export function useGetProposalDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProposalDocumentQuery, GetProposalDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProposalDocumentQuery, GetProposalDocumentQueryVariables>(GetProposalDocumentDocument, options);
        }
export type GetProposalDocumentQueryHookResult = ReturnType<typeof useGetProposalDocumentQuery>;
export type GetProposalDocumentLazyQueryHookResult = ReturnType<typeof useGetProposalDocumentLazyQuery>;
export type GetProposalDocumentQueryResult = Apollo.QueryResult<GetProposalDocumentQuery, GetProposalDocumentQueryVariables>;
export function refetchGetProposalDocumentQuery(variables?: GetProposalDocumentQueryVariables) {
      return { query: GetProposalDocumentDocument, variables: variables }
    }
export const GetProposalOpportunityOptionsDocument = gql`
    query getProposalOpportunityOptions($pipelineUUId: String!, $uuids: [String], $limit: Int, $cursor: String, $query: String, $isArchived: Boolean, $estimatedRFP: String, $hasProposal: Boolean, $sortBy: String, $sortOrder: String) {
  searchPipelineOpportunities(
    pipelineUUId: $pipelineUUId
    uuids: $uuids
    cursor: $cursor
    limit: $limit
    query: $query
    isArchived: $isArchived
    estimatedRFP: $estimatedRFP
    hasProposal: $hasProposal
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    results {
      id
      oppId
      isArchived
      canonId
      name
    }
    total
  }
}
    `;

/**
 * __useGetProposalOpportunityOptionsQuery__
 *
 * To run a query within a React component, call `useGetProposalOpportunityOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalOpportunityOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalOpportunityOptionsQuery({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      uuids: // value for 'uuids'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *      isArchived: // value for 'isArchived'
 *      estimatedRFP: // value for 'estimatedRFP'
 *      hasProposal: // value for 'hasProposal'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetProposalOpportunityOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetProposalOpportunityOptionsQuery, GetProposalOpportunityOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProposalOpportunityOptionsQuery, GetProposalOpportunityOptionsQueryVariables>(GetProposalOpportunityOptionsDocument, options);
      }
export function useGetProposalOpportunityOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProposalOpportunityOptionsQuery, GetProposalOpportunityOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProposalOpportunityOptionsQuery, GetProposalOpportunityOptionsQueryVariables>(GetProposalOpportunityOptionsDocument, options);
        }
export type GetProposalOpportunityOptionsQueryHookResult = ReturnType<typeof useGetProposalOpportunityOptionsQuery>;
export type GetProposalOpportunityOptionsLazyQueryHookResult = ReturnType<typeof useGetProposalOpportunityOptionsLazyQuery>;
export type GetProposalOpportunityOptionsQueryResult = Apollo.QueryResult<GetProposalOpportunityOptionsQuery, GetProposalOpportunityOptionsQueryVariables>;
export function refetchGetProposalOpportunityOptionsQuery(variables?: GetProposalOpportunityOptionsQueryVariables) {
      return { query: GetProposalOpportunityOptionsDocument, variables: variables }
    }
export const GetProposalPipelineKanbanDocument = gql`
    query getProposalPipelineKanban($uuid: String!) {
  getPipelineByUUId(uuid: $uuid) {
    customFields
    requiredFields {
      requiredFieldUUId
      fieldName
      storageHint
    }
    captureTeamTemplate
    id
    name
    pipelineId
    workspaceId
    stageLayout
    scoreTemplates {
      id
      categories {
        name
        scoreTemplateCategoryId
      }
    }
    default
    oppTags {
      id
      color
      tagId
      name
    }
    proposalStages {
      id
      uuid
      index
      name
      deletedAt
      opportunities {
        id
        oppId
        name
        proposalStagePosition
        proposalStage {
          id
          uuid
          name
        }
        proposalDocuments {
          uuid
          name
        }
        captureTeam {
          id
          name
          users {
            id
            firstName
            lastName
          }
        }
        metadata
      }
    }
    pipelineViews {
      id
      name
      pipelineViewId
      defaultViewMode
      sortColumn
      sortDirection
      tableColumnOverrides
      filters
      filterMode
      query
    }
  }
}
    `;

/**
 * __useGetProposalPipelineKanbanQuery__
 *
 * To run a query within a React component, call `useGetProposalPipelineKanbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalPipelineKanbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalPipelineKanbanQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProposalPipelineKanbanQuery(baseOptions: Apollo.QueryHookOptions<GetProposalPipelineKanbanQuery, GetProposalPipelineKanbanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProposalPipelineKanbanQuery, GetProposalPipelineKanbanQueryVariables>(GetProposalPipelineKanbanDocument, options);
      }
export function useGetProposalPipelineKanbanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProposalPipelineKanbanQuery, GetProposalPipelineKanbanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProposalPipelineKanbanQuery, GetProposalPipelineKanbanQueryVariables>(GetProposalPipelineKanbanDocument, options);
        }
export type GetProposalPipelineKanbanQueryHookResult = ReturnType<typeof useGetProposalPipelineKanbanQuery>;
export type GetProposalPipelineKanbanLazyQueryHookResult = ReturnType<typeof useGetProposalPipelineKanbanLazyQuery>;
export type GetProposalPipelineKanbanQueryResult = Apollo.QueryResult<GetProposalPipelineKanbanQuery, GetProposalPipelineKanbanQueryVariables>;
export function refetchGetProposalPipelineKanbanQuery(variables?: GetProposalPipelineKanbanQueryVariables) {
      return { query: GetProposalPipelineKanbanDocument, variables: variables }
    }
export const RemoveOpportunityProposalStageDocument = gql`
    mutation removeOpportunityProposalStage($opportunityUUId: String!) {
  removeOpportunityProposalStage(uuid: $opportunityUUId) {
    id
    oppId
    name
  }
}
    `;
export type RemoveOpportunityProposalStageMutationFn = Apollo.MutationFunction<RemoveOpportunityProposalStageMutation, RemoveOpportunityProposalStageMutationVariables>;

/**
 * __useRemoveOpportunityProposalStageMutation__
 *
 * To run a mutation, you first call `useRemoveOpportunityProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOpportunityProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOpportunityProposalStageMutation, { data, loading, error }] = useRemoveOpportunityProposalStageMutation({
 *   variables: {
 *      opportunityUUId: // value for 'opportunityUUId'
 *   },
 * });
 */
export function useRemoveOpportunityProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOpportunityProposalStageMutation, RemoveOpportunityProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOpportunityProposalStageMutation, RemoveOpportunityProposalStageMutationVariables>(RemoveOpportunityProposalStageDocument, options);
      }
export type RemoveOpportunityProposalStageMutationHookResult = ReturnType<typeof useRemoveOpportunityProposalStageMutation>;
export type RemoveOpportunityProposalStageMutationResult = Apollo.MutationResult<RemoveOpportunityProposalStageMutation>;
export type RemoveOpportunityProposalStageMutationOptions = Apollo.BaseMutationOptions<RemoveOpportunityProposalStageMutation, RemoveOpportunityProposalStageMutationVariables>;
export const ReorderProposalStageDocument = gql`
    mutation reorderProposalStage($uuid: String!, $index: Int!) {
  reorderProposalStage(uuid: $uuid, index: $index) {
    id
    pipelineId
    name
    proposalStages {
      id
      uuid
      index
      name
      opportunities {
        id
        oppId
        name
        proposalStagePosition
        proposalStage {
          uuid
          name
        }
      }
    }
  }
}
    `;
export type ReorderProposalStageMutationFn = Apollo.MutationFunction<ReorderProposalStageMutation, ReorderProposalStageMutationVariables>;

/**
 * __useReorderProposalStageMutation__
 *
 * To run a mutation, you first call `useReorderProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderProposalStageMutation, { data, loading, error }] = useReorderProposalStageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useReorderProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<ReorderProposalStageMutation, ReorderProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderProposalStageMutation, ReorderProposalStageMutationVariables>(ReorderProposalStageDocument, options);
      }
export type ReorderProposalStageMutationHookResult = ReturnType<typeof useReorderProposalStageMutation>;
export type ReorderProposalStageMutationResult = Apollo.MutationResult<ReorderProposalStageMutation>;
export type ReorderProposalStageMutationOptions = Apollo.BaseMutationOptions<ReorderProposalStageMutation, ReorderProposalStageMutationVariables>;
export const SearchProposalDocumentsDocument = gql`
    query searchProposalDocuments($pipelineUUId: String!, $opportunityUUId: String, $limit: Int, $query: String, $isDeleted: Boolean, $sortBy: GqlProposalDocumentSearchSortBy, $sortOrder: SortDirection) {
  searchProposalDocuments(
    pipelineUUId: $pipelineUUId
    opportunityUUId: $opportunityUUId
    limit: $limit
    query: $query
    isDeleted: $isDeleted
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    results {
      id
      uuid
      name
      createdAt
      updatedAt
      createdBy {
        id
        userId
        firstName
        lastName
      }
      opportunityUUId
      pipeline {
        name
        pipelineId
      }
    }
    total
  }
}
    `;

/**
 * __useSearchProposalDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchProposalDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProposalDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProposalDocumentsQuery({
 *   variables: {
 *      pipelineUUId: // value for 'pipelineUUId'
 *      opportunityUUId: // value for 'opportunityUUId'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      isDeleted: // value for 'isDeleted'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSearchProposalDocumentsQuery(baseOptions: Apollo.QueryHookOptions<SearchProposalDocumentsQuery, SearchProposalDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProposalDocumentsQuery, SearchProposalDocumentsQueryVariables>(SearchProposalDocumentsDocument, options);
      }
export function useSearchProposalDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProposalDocumentsQuery, SearchProposalDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProposalDocumentsQuery, SearchProposalDocumentsQueryVariables>(SearchProposalDocumentsDocument, options);
        }
export type SearchProposalDocumentsQueryHookResult = ReturnType<typeof useSearchProposalDocumentsQuery>;
export type SearchProposalDocumentsLazyQueryHookResult = ReturnType<typeof useSearchProposalDocumentsLazyQuery>;
export type SearchProposalDocumentsQueryResult = Apollo.QueryResult<SearchProposalDocumentsQuery, SearchProposalDocumentsQueryVariables>;
export function refetchSearchProposalDocumentsQuery(variables?: SearchProposalDocumentsQueryVariables) {
      return { query: SearchProposalDocumentsDocument, variables: variables }
    }
export const SetComplianceMatrixDocument = gql`
    mutation setComplianceMatrix($privOppUUId: String!, $complianceMatrixDocumentRequestUrl: String) {
  setComplianceMatrix(
    privOppUUId: $privOppUUId
    complianceMatrixDocumentRequestUrl: $complianceMatrixDocumentRequestUrl
  ) {
    id
    oppId
    name
    complianceMatrixDocumentRequestUrl
  }
}
    `;
export type SetComplianceMatrixMutationFn = Apollo.MutationFunction<SetComplianceMatrixMutation, SetComplianceMatrixMutationVariables>;

/**
 * __useSetComplianceMatrixMutation__
 *
 * To run a mutation, you first call `useSetComplianceMatrixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetComplianceMatrixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setComplianceMatrixMutation, { data, loading, error }] = useSetComplianceMatrixMutation({
 *   variables: {
 *      privOppUUId: // value for 'privOppUUId'
 *      complianceMatrixDocumentRequestUrl: // value for 'complianceMatrixDocumentRequestUrl'
 *   },
 * });
 */
export function useSetComplianceMatrixMutation(baseOptions?: Apollo.MutationHookOptions<SetComplianceMatrixMutation, SetComplianceMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetComplianceMatrixMutation, SetComplianceMatrixMutationVariables>(SetComplianceMatrixDocument, options);
      }
export type SetComplianceMatrixMutationHookResult = ReturnType<typeof useSetComplianceMatrixMutation>;
export type SetComplianceMatrixMutationResult = Apollo.MutationResult<SetComplianceMatrixMutation>;
export type SetComplianceMatrixMutationOptions = Apollo.BaseMutationOptions<SetComplianceMatrixMutation, SetComplianceMatrixMutationVariables>;
export const SetOpportunityProposalStageDocument = gql`
    mutation setOpportunityProposalStage($opportunityUUId: String!, $proposalStageUUId: String!, $proposalStagePosition: Float) {
  setOpportunityProposalStage(
    uuid: $opportunityUUId
    proposalStageUUId: $proposalStageUUId
    proposalStagePosition: $proposalStagePosition
  ) {
    id
    oppId
    name
    proposalStage {
      id
      uuid
      name
      index
    }
  }
}
    `;
export type SetOpportunityProposalStageMutationFn = Apollo.MutationFunction<SetOpportunityProposalStageMutation, SetOpportunityProposalStageMutationVariables>;

/**
 * __useSetOpportunityProposalStageMutation__
 *
 * To run a mutation, you first call `useSetOpportunityProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpportunityProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpportunityProposalStageMutation, { data, loading, error }] = useSetOpportunityProposalStageMutation({
 *   variables: {
 *      opportunityUUId: // value for 'opportunityUUId'
 *      proposalStageUUId: // value for 'proposalStageUUId'
 *      proposalStagePosition: // value for 'proposalStagePosition'
 *   },
 * });
 */
export function useSetOpportunityProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<SetOpportunityProposalStageMutation, SetOpportunityProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOpportunityProposalStageMutation, SetOpportunityProposalStageMutationVariables>(SetOpportunityProposalStageDocument, options);
      }
export type SetOpportunityProposalStageMutationHookResult = ReturnType<typeof useSetOpportunityProposalStageMutation>;
export type SetOpportunityProposalStageMutationResult = Apollo.MutationResult<SetOpportunityProposalStageMutation>;
export type SetOpportunityProposalStageMutationOptions = Apollo.BaseMutationOptions<SetOpportunityProposalStageMutation, SetOpportunityProposalStageMutationVariables>;
export const SetOpportunityProposalStageNoReturnDocument = gql`
    mutation setOpportunityProposalStageNoReturn($opportunityUUId: String!, $proposalStageUUId: String!, $proposalStagePosition: Float) {
  setOpportunityProposalStage(
    uuid: $opportunityUUId
    proposalStageUUId: $proposalStageUUId
    proposalStagePosition: $proposalStagePosition
  ) {
    id
  }
}
    `;
export type SetOpportunityProposalStageNoReturnMutationFn = Apollo.MutationFunction<SetOpportunityProposalStageNoReturnMutation, SetOpportunityProposalStageNoReturnMutationVariables>;

/**
 * __useSetOpportunityProposalStageNoReturnMutation__
 *
 * To run a mutation, you first call `useSetOpportunityProposalStageNoReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpportunityProposalStageNoReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpportunityProposalStageNoReturnMutation, { data, loading, error }] = useSetOpportunityProposalStageNoReturnMutation({
 *   variables: {
 *      opportunityUUId: // value for 'opportunityUUId'
 *      proposalStageUUId: // value for 'proposalStageUUId'
 *      proposalStagePosition: // value for 'proposalStagePosition'
 *   },
 * });
 */
export function useSetOpportunityProposalStageNoReturnMutation(baseOptions?: Apollo.MutationHookOptions<SetOpportunityProposalStageNoReturnMutation, SetOpportunityProposalStageNoReturnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOpportunityProposalStageNoReturnMutation, SetOpportunityProposalStageNoReturnMutationVariables>(SetOpportunityProposalStageNoReturnDocument, options);
      }
export type SetOpportunityProposalStageNoReturnMutationHookResult = ReturnType<typeof useSetOpportunityProposalStageNoReturnMutation>;
export type SetOpportunityProposalStageNoReturnMutationResult = Apollo.MutationResult<SetOpportunityProposalStageNoReturnMutation>;
export type SetOpportunityProposalStageNoReturnMutationOptions = Apollo.BaseMutationOptions<SetOpportunityProposalStageNoReturnMutation, SetOpportunityProposalStageNoReturnMutationVariables>;
export const UpdateProposalDocumentDocument = gql`
    mutation updateProposalDocument($uuid: String!, $name: String!) {
  updateProposalDocument(uuid: $uuid, name: $name) {
    id
    uuid
    name
    createdAt
    updatedAt
    createdBy {
      id
      userId
      firstName
      lastName
    }
  }
}
    `;
export type UpdateProposalDocumentMutationFn = Apollo.MutationFunction<UpdateProposalDocumentMutation, UpdateProposalDocumentMutationVariables>;

/**
 * __useUpdateProposalDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateProposalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalDocumentMutation, { data, loading, error }] = useUpdateProposalDocumentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProposalDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProposalDocumentMutation, UpdateProposalDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProposalDocumentMutation, UpdateProposalDocumentMutationVariables>(UpdateProposalDocumentDocument, options);
      }
export type UpdateProposalDocumentMutationHookResult = ReturnType<typeof useUpdateProposalDocumentMutation>;
export type UpdateProposalDocumentMutationResult = Apollo.MutationResult<UpdateProposalDocumentMutation>;
export type UpdateProposalDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateProposalDocumentMutation, UpdateProposalDocumentMutationVariables>;
export const UpdateProposalStageDocument = gql`
    mutation updateProposalStage($uuid: String!, $name: String) {
  updateProposalStage(uuid: $uuid, name: $name) {
    id
    uuid
    index
    name
  }
}
    `;
export type UpdateProposalStageMutationFn = Apollo.MutationFunction<UpdateProposalStageMutation, UpdateProposalStageMutationVariables>;

/**
 * __useUpdateProposalStageMutation__
 *
 * To run a mutation, you first call `useUpdateProposalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalStageMutation, { data, loading, error }] = useUpdateProposalStageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProposalStageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProposalStageMutation, UpdateProposalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProposalStageMutation, UpdateProposalStageMutationVariables>(UpdateProposalStageDocument, options);
      }
export type UpdateProposalStageMutationHookResult = ReturnType<typeof useUpdateProposalStageMutation>;
export type UpdateProposalStageMutationResult = Apollo.MutationResult<UpdateProposalStageMutation>;
export type UpdateProposalStageMutationOptions = Apollo.BaseMutationOptions<UpdateProposalStageMutation, UpdateProposalStageMutationVariables>;
export const AddChartDocument = gql`
    mutation addChart($title: String!, $query: Json, $chartType: String!, $dashboardId: Int!, $showValueLabels: Boolean) {
  addChart(
    title: $title
    query: $query
    chartType: $chartType
    dashboardId: $dashboardId
    showValueLabels: $showValueLabels
  ) {
    id
  }
}
    `;
export type AddChartMutationFn = Apollo.MutationFunction<AddChartMutation, AddChartMutationVariables>;

/**
 * __useAddChartMutation__
 *
 * To run a mutation, you first call `useAddChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChartMutation, { data, loading, error }] = useAddChartMutation({
 *   variables: {
 *      title: // value for 'title'
 *      query: // value for 'query'
 *      chartType: // value for 'chartType'
 *      dashboardId: // value for 'dashboardId'
 *      showValueLabels: // value for 'showValueLabels'
 *   },
 * });
 */
export function useAddChartMutation(baseOptions?: Apollo.MutationHookOptions<AddChartMutation, AddChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChartMutation, AddChartMutationVariables>(AddChartDocument, options);
      }
export type AddChartMutationHookResult = ReturnType<typeof useAddChartMutation>;
export type AddChartMutationResult = Apollo.MutationResult<AddChartMutation>;
export type AddChartMutationOptions = Apollo.BaseMutationOptions<AddChartMutation, AddChartMutationVariables>;
export const DeleteChartDocument = gql`
    mutation deleteChart($id: Int!) {
  deleteChart(id: $id) {
    id
  }
}
    `;
export type DeleteChartMutationFn = Apollo.MutationFunction<DeleteChartMutation, DeleteChartMutationVariables>;

/**
 * __useDeleteChartMutation__
 *
 * To run a mutation, you first call `useDeleteChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartMutation, { data, loading, error }] = useDeleteChartMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChartMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartMutation, DeleteChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartMutation, DeleteChartMutationVariables>(DeleteChartDocument, options);
      }
export type DeleteChartMutationHookResult = ReturnType<typeof useDeleteChartMutation>;
export type DeleteChartMutationResult = Apollo.MutationResult<DeleteChartMutation>;
export type DeleteChartMutationOptions = Apollo.BaseMutationOptions<DeleteChartMutation, DeleteChartMutationVariables>;
export const RebuildCacheDocument = gql`
    mutation rebuildCache {
  rebuildCache
}
    `;
export type RebuildCacheMutationFn = Apollo.MutationFunction<RebuildCacheMutation, RebuildCacheMutationVariables>;

/**
 * __useRebuildCacheMutation__
 *
 * To run a mutation, you first call `useRebuildCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildCacheMutation, { data, loading, error }] = useRebuildCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useRebuildCacheMutation(baseOptions?: Apollo.MutationHookOptions<RebuildCacheMutation, RebuildCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildCacheMutation, RebuildCacheMutationVariables>(RebuildCacheDocument, options);
      }
export type RebuildCacheMutationHookResult = ReturnType<typeof useRebuildCacheMutation>;
export type RebuildCacheMutationResult = Apollo.MutationResult<RebuildCacheMutation>;
export type RebuildCacheMutationOptions = Apollo.BaseMutationOptions<RebuildCacheMutation, RebuildCacheMutationVariables>;
export const UpdateChartDocument = gql`
    mutation updateChart($id: Int!, $title: String, $query: Json, $chartType: String, $showValueLabels: Boolean, $dashboardId: Int) {
  updateChart(
    id: $id
    title: $title
    query: $query
    chartType: $chartType
    showValueLabels: $showValueLabels
    dashboardId: $dashboardId
  ) {
    id
  }
}
    `;
export type UpdateChartMutationFn = Apollo.MutationFunction<UpdateChartMutation, UpdateChartMutationVariables>;

/**
 * __useUpdateChartMutation__
 *
 * To run a mutation, you first call `useUpdateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartMutation, { data, loading, error }] = useUpdateChartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      query: // value for 'query'
 *      chartType: // value for 'chartType'
 *      showValueLabels: // value for 'showValueLabels'
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useUpdateChartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartMutation, UpdateChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartMutation, UpdateChartMutationVariables>(UpdateChartDocument, options);
      }
export type UpdateChartMutationHookResult = ReturnType<typeof useUpdateChartMutation>;
export type UpdateChartMutationResult = Apollo.MutationResult<UpdateChartMutation>;
export type UpdateChartMutationOptions = Apollo.BaseMutationOptions<UpdateChartMutation, UpdateChartMutationVariables>;
export const UpdateChartLayoutDocument = gql`
    mutation updateChartLayout($id: Int!, $layout: Json) {
  updateChartLayout(id: $id, layout: $layout) {
    id
    layout
    query
    chartType
    showValueLabels
  }
}
    `;
export type UpdateChartLayoutMutationFn = Apollo.MutationFunction<UpdateChartLayoutMutation, UpdateChartLayoutMutationVariables>;

/**
 * __useUpdateChartLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateChartLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartLayoutMutation, { data, loading, error }] = useUpdateChartLayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useUpdateChartLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartLayoutMutation, UpdateChartLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartLayoutMutation, UpdateChartLayoutMutationVariables>(UpdateChartLayoutDocument, options);
      }
export type UpdateChartLayoutMutationHookResult = ReturnType<typeof useUpdateChartLayoutMutation>;
export type UpdateChartLayoutMutationResult = Apollo.MutationResult<UpdateChartLayoutMutation>;
export type UpdateChartLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateChartLayoutMutation, UpdateChartLayoutMutationVariables>;
export const GetDashboardsDocument = gql`
    query getDashboards {
  getDashboards
}
    `;

/**
 * __useGetDashboardsQuery__
 *
 * To run a query within a React component, call `useGetDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(GetDashboardsDocument, options);
      }
export function useGetDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(GetDashboardsDocument, options);
        }
export type GetDashboardsQueryHookResult = ReturnType<typeof useGetDashboardsQuery>;
export type GetDashboardsLazyQueryHookResult = ReturnType<typeof useGetDashboardsLazyQuery>;
export type GetDashboardsQueryResult = Apollo.QueryResult<GetDashboardsQuery, GetDashboardsQueryVariables>;
export function refetchGetDashboardsQuery(variables?: GetDashboardsQueryVariables) {
      return { query: GetDashboardsDocument, variables: variables }
    }
export const GetDashboardDocument = gql`
    query getDashboard($id: Int!) {
  getDashboard(id: $id) {
    id
    name
    createdBy {
      id
      firstName
      lastName
    }
    charts {
      id
      title
      query
      chartType
      showValueLabels
      layout
    }
  }
}
    `;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export function refetchGetDashboardQuery(variables?: GetDashboardQueryVariables) {
      return { query: GetDashboardDocument, variables: variables }
    }
export const UpdateDashboardDocument = gql`
    mutation updateDashboard($id: Int, $name: String, $isPublic: Boolean) {
  updateDashboard(id: $id, name: $name, isPublic: $isPublic) {
    id
    name
    createdBy {
      id
      firstName
      lastName
    }
    charts {
      id
      title
      query
      chartType
      showValueLabels
      layout
    }
  }
}
    `;
export type UpdateDashboardMutationFn = Apollo.MutationFunction<UpdateDashboardMutation, UpdateDashboardMutationVariables>;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useUpdateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardMutation, UpdateDashboardMutationVariables>(UpdateDashboardDocument, options);
      }
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export type UpdateDashboardMutationResult = Apollo.MutationResult<UpdateDashboardMutation>;
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>;
export const DeleteDashboardDocument = gql`
    mutation deleteDashboard($id: Int) {
  deleteDashboard(id: $id) {
    id
    name
  }
}
    `;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<DeleteDashboardMutation, DeleteDashboardMutationVariables>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardMutation, DeleteDashboardMutationVariables>(DeleteDashboardDocument, options);
      }
export type DeleteDashboardMutationHookResult = ReturnType<typeof useDeleteDashboardMutation>;
export type DeleteDashboardMutationResult = Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>;
export const FavoriteDashboardDocument = gql`
    mutation favoriteDashboard($dashboardId: Int) {
  favoriteDashboard(dashboardId: $dashboardId) {
    id
  }
}
    `;
export type FavoriteDashboardMutationFn = Apollo.MutationFunction<FavoriteDashboardMutation, FavoriteDashboardMutationVariables>;

/**
 * __useFavoriteDashboardMutation__
 *
 * To run a mutation, you first call `useFavoriteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteDashboardMutation, { data, loading, error }] = useFavoriteDashboardMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useFavoriteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteDashboardMutation, FavoriteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavoriteDashboardMutation, FavoriteDashboardMutationVariables>(FavoriteDashboardDocument, options);
      }
export type FavoriteDashboardMutationHookResult = ReturnType<typeof useFavoriteDashboardMutation>;
export type FavoriteDashboardMutationResult = Apollo.MutationResult<FavoriteDashboardMutation>;
export type FavoriteDashboardMutationOptions = Apollo.BaseMutationOptions<FavoriteDashboardMutation, FavoriteDashboardMutationVariables>;
export const UnFavoriteDashboardDocument = gql`
    mutation unFavoriteDashboard($dashboardId: Int) {
  unFavoriteDashboard(dashboardId: $dashboardId) {
    id
  }
}
    `;
export type UnFavoriteDashboardMutationFn = Apollo.MutationFunction<UnFavoriteDashboardMutation, UnFavoriteDashboardMutationVariables>;

/**
 * __useUnFavoriteDashboardMutation__
 *
 * To run a mutation, you first call `useUnFavoriteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFavoriteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFavoriteDashboardMutation, { data, loading, error }] = useUnFavoriteDashboardMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useUnFavoriteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<UnFavoriteDashboardMutation, UnFavoriteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnFavoriteDashboardMutation, UnFavoriteDashboardMutationVariables>(UnFavoriteDashboardDocument, options);
      }
export type UnFavoriteDashboardMutationHookResult = ReturnType<typeof useUnFavoriteDashboardMutation>;
export type UnFavoriteDashboardMutationResult = Apollo.MutationResult<UnFavoriteDashboardMutation>;
export type UnFavoriteDashboardMutationOptions = Apollo.BaseMutationOptions<UnFavoriteDashboardMutation, UnFavoriteDashboardMutationVariables>;
export const AddDashboardDocument = gql`
    mutation addDashboard($name: String, $isPublic: Boolean) {
  addDashboard(name: $name, isPublic: $isPublic) {
    id
    name
  }
}
    `;
export type AddDashboardMutationFn = Apollo.MutationFunction<AddDashboardMutation, AddDashboardMutationVariables>;

/**
 * __useAddDashboardMutation__
 *
 * To run a mutation, you first call `useAddDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDashboardMutation, { data, loading, error }] = useAddDashboardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useAddDashboardMutation(baseOptions?: Apollo.MutationHookOptions<AddDashboardMutation, AddDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDashboardMutation, AddDashboardMutationVariables>(AddDashboardDocument, options);
      }
export type AddDashboardMutationHookResult = ReturnType<typeof useAddDashboardMutation>;
export type AddDashboardMutationResult = Apollo.MutationResult<AddDashboardMutation>;
export type AddDashboardMutationOptions = Apollo.BaseMutationOptions<AddDashboardMutation, AddDashboardMutationVariables>;
export const GetChartDocument = gql`
    query getChart($id: Int!) {
  chart(id: $id) {
    id
    title
    query
    chartType
    showValueLabels
    query
    dashboard {
      id
      name
    }
  }
}
    `;

/**
 * __useGetChartQuery__
 *
 * To run a query within a React component, call `useGetChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChartQuery(baseOptions: Apollo.QueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, options);
      }
export function useGetChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, options);
        }
export type GetChartQueryHookResult = ReturnType<typeof useGetChartQuery>;
export type GetChartLazyQueryHookResult = ReturnType<typeof useGetChartLazyQuery>;
export type GetChartQueryResult = Apollo.QueryResult<GetChartQuery, GetChartQueryVariables>;
export function refetchGetChartQuery(variables?: GetChartQueryVariables) {
      return { query: GetChartDocument, variables: variables }
    }
export const DeleteSavedSearchDocument = gql`
    mutation deleteSavedSearch($id: String!) {
  deleteSavedSearch(id: $id) {
    id
  }
}
    `;
export type DeleteSavedSearchMutationFn = Apollo.MutationFunction<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;

/**
 * __useDeleteSavedSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedSearchMutation, { data, loading, error }] = useDeleteSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>(DeleteSavedSearchDocument, options);
      }
export type DeleteSavedSearchMutationHookResult = ReturnType<typeof useDeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationResult = Apollo.MutationResult<DeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationOptions = Apollo.BaseMutationOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;
export const SubscribeSavedSearchDocument = gql`
    mutation subscribeSavedSearch($id: String!) {
  subscribeSavedSearch(id: $id) {
    savedSearchId
    subscriberId
  }
}
    `;
export type SubscribeSavedSearchMutationFn = Apollo.MutationFunction<SubscribeSavedSearchMutation, SubscribeSavedSearchMutationVariables>;

/**
 * __useSubscribeSavedSearchMutation__
 *
 * To run a mutation, you first call `useSubscribeSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeSavedSearchMutation, { data, loading, error }] = useSubscribeSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeSavedSearchMutation, SubscribeSavedSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeSavedSearchMutation, SubscribeSavedSearchMutationVariables>(SubscribeSavedSearchDocument, options);
      }
export type SubscribeSavedSearchMutationHookResult = ReturnType<typeof useSubscribeSavedSearchMutation>;
export type SubscribeSavedSearchMutationResult = Apollo.MutationResult<SubscribeSavedSearchMutation>;
export type SubscribeSavedSearchMutationOptions = Apollo.BaseMutationOptions<SubscribeSavedSearchMutation, SubscribeSavedSearchMutationVariables>;
export const UnsubscribeSavedSearchDocument = gql`
    mutation unsubscribeSavedSearch($id: String!) {
  unsubscribeSavedSearch(id: $id) {
    savedSearchId
    subscriberId
  }
}
    `;
export type UnsubscribeSavedSearchMutationFn = Apollo.MutationFunction<UnsubscribeSavedSearchMutation, UnsubscribeSavedSearchMutationVariables>;

/**
 * __useUnsubscribeSavedSearchMutation__
 *
 * To run a mutation, you first call `useUnsubscribeSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeSavedSearchMutation, { data, loading, error }] = useUnsubscribeSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsubscribeSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeSavedSearchMutation, UnsubscribeSavedSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeSavedSearchMutation, UnsubscribeSavedSearchMutationVariables>(UnsubscribeSavedSearchDocument, options);
      }
export type UnsubscribeSavedSearchMutationHookResult = ReturnType<typeof useUnsubscribeSavedSearchMutation>;
export type UnsubscribeSavedSearchMutationResult = Apollo.MutationResult<UnsubscribeSavedSearchMutation>;
export type UnsubscribeSavedSearchMutationOptions = Apollo.BaseMutationOptions<UnsubscribeSavedSearchMutation, UnsubscribeSavedSearchMutationVariables>;
export const UpsertSavedSearch2Document = gql`
    mutation upsertSavedSearch2($name: String, $query: Json!, $subscribe: Boolean, $savedSearchUUId: String) {
  upsertSavedSearch2(
    name: $name
    query: $query
    savedSearchUUId: $savedSearchUUId
    subscribe: $subscribe
  ) {
    savedSearchId
  }
}
    `;
export type UpsertSavedSearch2MutationFn = Apollo.MutationFunction<UpsertSavedSearch2Mutation, UpsertSavedSearch2MutationVariables>;

/**
 * __useUpsertSavedSearch2Mutation__
 *
 * To run a mutation, you first call `useUpsertSavedSearch2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSavedSearch2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSavedSearch2Mutation, { data, loading, error }] = useUpsertSavedSearch2Mutation({
 *   variables: {
 *      name: // value for 'name'
 *      query: // value for 'query'
 *      subscribe: // value for 'subscribe'
 *      savedSearchUUId: // value for 'savedSearchUUId'
 *   },
 * });
 */
export function useUpsertSavedSearch2Mutation(baseOptions?: Apollo.MutationHookOptions<UpsertSavedSearch2Mutation, UpsertSavedSearch2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSavedSearch2Mutation, UpsertSavedSearch2MutationVariables>(UpsertSavedSearch2Document, options);
      }
export type UpsertSavedSearch2MutationHookResult = ReturnType<typeof useUpsertSavedSearch2Mutation>;
export type UpsertSavedSearch2MutationResult = Apollo.MutationResult<UpsertSavedSearch2Mutation>;
export type UpsertSavedSearch2MutationOptions = Apollo.BaseMutationOptions<UpsertSavedSearch2Mutation, UpsertSavedSearch2MutationVariables>;
export const UpdateScoreTemplateDocument = gql`
    mutation updateScoreTemplate($scoreTemplateId: String!, $newTemplate: Json!) {
  updateScoreTemplate(
    scoreTemplateId: $scoreTemplateId
    newTemplate: $newTemplate
  ) {
    scoreTemplateId
    categories {
      id
      position
      scoreTemplateCategoryId
      name
      fields {
        id
        index
        description
        name
        range
        scoreTemplateId
        scoreTemplateFieldId
        weight
      }
    }
    fields {
      id
      scoreTemplateFieldId
      description
      name
    }
  }
}
    `;
export type UpdateScoreTemplateMutationFn = Apollo.MutationFunction<UpdateScoreTemplateMutation, UpdateScoreTemplateMutationVariables>;

/**
 * __useUpdateScoreTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateScoreTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScoreTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoreTemplateMutation, { data, loading, error }] = useUpdateScoreTemplateMutation({
 *   variables: {
 *      scoreTemplateId: // value for 'scoreTemplateId'
 *      newTemplate: // value for 'newTemplate'
 *   },
 * });
 */
export function useUpdateScoreTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScoreTemplateMutation, UpdateScoreTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScoreTemplateMutation, UpdateScoreTemplateMutationVariables>(UpdateScoreTemplateDocument, options);
      }
export type UpdateScoreTemplateMutationHookResult = ReturnType<typeof useUpdateScoreTemplateMutation>;
export type UpdateScoreTemplateMutationResult = Apollo.MutationResult<UpdateScoreTemplateMutation>;
export type UpdateScoreTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateScoreTemplateMutation, UpdateScoreTemplateMutationVariables>;
export const GetAwardTaskOrdersDocument = gql`
    query getAwardTaskOrders($awardId: String!, $limit: Int!, $offset: Int!, $sortField: String, $sortDirection: SortDirection) {
  getAwardTaskOrders(
    awardId: $awardId
    limit: $limit
    offset: $offset
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    hits
    aggregations
    numHits
  }
}
    `;

/**
 * __useGetAwardTaskOrdersQuery__
 *
 * To run a query within a React component, call `useGetAwardTaskOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardTaskOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardTaskOrdersQuery({
 *   variables: {
 *      awardId: // value for 'awardId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetAwardTaskOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetAwardTaskOrdersQuery, GetAwardTaskOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAwardTaskOrdersQuery, GetAwardTaskOrdersQueryVariables>(GetAwardTaskOrdersDocument, options);
      }
export function useGetAwardTaskOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAwardTaskOrdersQuery, GetAwardTaskOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAwardTaskOrdersQuery, GetAwardTaskOrdersQueryVariables>(GetAwardTaskOrdersDocument, options);
        }
export type GetAwardTaskOrdersQueryHookResult = ReturnType<typeof useGetAwardTaskOrdersQuery>;
export type GetAwardTaskOrdersLazyQueryHookResult = ReturnType<typeof useGetAwardTaskOrdersLazyQuery>;
export type GetAwardTaskOrdersQueryResult = Apollo.QueryResult<GetAwardTaskOrdersQuery, GetAwardTaskOrdersQueryVariables>;
export function refetchGetAwardTaskOrdersQuery(variables?: GetAwardTaskOrdersQueryVariables) {
      return { query: GetAwardTaskOrdersDocument, variables: variables }
    }
export const GetAwardTaskOrderAnalysisDocument = gql`
    query getAwardTaskOrderAnalysis($awardId: String!) {
  getAwardTaskOrderAnalysis(awardId: $awardId) {
    hits
    aggregations
    numHits
  }
}
    `;

/**
 * __useGetAwardTaskOrderAnalysisQuery__
 *
 * To run a query within a React component, call `useGetAwardTaskOrderAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardTaskOrderAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardTaskOrderAnalysisQuery({
 *   variables: {
 *      awardId: // value for 'awardId'
 *   },
 * });
 */
export function useGetAwardTaskOrderAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetAwardTaskOrderAnalysisQuery, GetAwardTaskOrderAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAwardTaskOrderAnalysisQuery, GetAwardTaskOrderAnalysisQueryVariables>(GetAwardTaskOrderAnalysisDocument, options);
      }
export function useGetAwardTaskOrderAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAwardTaskOrderAnalysisQuery, GetAwardTaskOrderAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAwardTaskOrderAnalysisQuery, GetAwardTaskOrderAnalysisQueryVariables>(GetAwardTaskOrderAnalysisDocument, options);
        }
export type GetAwardTaskOrderAnalysisQueryHookResult = ReturnType<typeof useGetAwardTaskOrderAnalysisQuery>;
export type GetAwardTaskOrderAnalysisLazyQueryHookResult = ReturnType<typeof useGetAwardTaskOrderAnalysisLazyQuery>;
export type GetAwardTaskOrderAnalysisQueryResult = Apollo.QueryResult<GetAwardTaskOrderAnalysisQuery, GetAwardTaskOrderAnalysisQueryVariables>;
export function refetchGetAwardTaskOrderAnalysisQuery(variables?: GetAwardTaskOrderAnalysisQueryVariables) {
      return { query: GetAwardTaskOrderAnalysisDocument, variables: variables }
    }
export const BookmarkDocument = gql`
    mutation bookmark($source: ExternalSourceNexusType!, $index: String, $bookmarkReason: BookmarkReason!, $externalId: String!) {
  upsertBookmark(
    source: $source
    bookmarkReason: $bookmarkReason
    externalId: $externalId
    index: $index
  ) {
    id
    reason
    externalId
    source
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      privOppId
      privOrgId
      createdAt
      contacts {
        id
        contactId
        fullName
        firstName
        lastName
        email
        title
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    updatedAt
    createdBy {
      fullName
    }
  }
}
    `;
export type BookmarkMutationFn = Apollo.MutationFunction<BookmarkMutation, BookmarkMutationVariables>;

/**
 * __useBookmarkMutation__
 *
 * To run a mutation, you first call `useBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkMutation, { data, loading, error }] = useBookmarkMutation({
 *   variables: {
 *      source: // value for 'source'
 *      index: // value for 'index'
 *      bookmarkReason: // value for 'bookmarkReason'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<BookmarkMutation, BookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookmarkMutation, BookmarkMutationVariables>(BookmarkDocument, options);
      }
export type BookmarkMutationHookResult = ReturnType<typeof useBookmarkMutation>;
export type BookmarkMutationResult = Apollo.MutationResult<BookmarkMutation>;
export type BookmarkMutationOptions = Apollo.BaseMutationOptions<BookmarkMutation, BookmarkMutationVariables>;
export const BookmarkNoteDocument = gql`
    mutation bookmarkNote($source: ExternalSourceNexusType!, $index: String, $note: Json, $externalId: String!, $noteUUId: String) {
  upsertBookmarkNote(
    source: $source
    note: $note
    externalId: $externalId
    index: $index
    noteUUId: $noteUUId
  ) {
    id
    reason
    externalId
    source
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      privOppId
      privOrgId
      createdAt
      contacts {
        id
        contactId
        fullName
        firstName
        lastName
        email
        title
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    updatedAt
    createdBy {
      fullName
    }
  }
}
    `;
export type BookmarkNoteMutationFn = Apollo.MutationFunction<BookmarkNoteMutation, BookmarkNoteMutationVariables>;

/**
 * __useBookmarkNoteMutation__
 *
 * To run a mutation, you first call `useBookmarkNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkNoteMutation, { data, loading, error }] = useBookmarkNoteMutation({
 *   variables: {
 *      source: // value for 'source'
 *      index: // value for 'index'
 *      note: // value for 'note'
 *      externalId: // value for 'externalId'
 *      noteUUId: // value for 'noteUUId'
 *   },
 * });
 */
export function useBookmarkNoteMutation(baseOptions?: Apollo.MutationHookOptions<BookmarkNoteMutation, BookmarkNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookmarkNoteMutation, BookmarkNoteMutationVariables>(BookmarkNoteDocument, options);
      }
export type BookmarkNoteMutationHookResult = ReturnType<typeof useBookmarkNoteMutation>;
export type BookmarkNoteMutationResult = Apollo.MutationResult<BookmarkNoteMutation>;
export type BookmarkNoteMutationOptions = Apollo.BaseMutationOptions<BookmarkNoteMutation, BookmarkNoteMutationVariables>;
export const ContractVehicleTaskOrderAnalysisDocument = gql`
    query contractVehicleTaskOrderAnalysis($contractVehicleId: String!, $awardYears: [Int!]!, $expirationYears: [Int!]!, $awardingAgencies: [String!]!, $naics: [String!]!, $psc: [String!]!) {
  contractVehicleTaskOrderAnalysis(
    contractVehicleId: $contractVehicleId
    naics: $naics
    psc: $psc
    awardingAgencies: $awardingAgencies
    expirationYears: $expirationYears
    awardYears: $awardYears
  ) {
    hits
    aggregations
    numHits
  }
}
    `;

/**
 * __useContractVehicleTaskOrderAnalysisQuery__
 *
 * To run a query within a React component, call `useContractVehicleTaskOrderAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVehicleTaskOrderAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVehicleTaskOrderAnalysisQuery({
 *   variables: {
 *      contractVehicleId: // value for 'contractVehicleId'
 *      awardYears: // value for 'awardYears'
 *      expirationYears: // value for 'expirationYears'
 *      awardingAgencies: // value for 'awardingAgencies'
 *      naics: // value for 'naics'
 *      psc: // value for 'psc'
 *   },
 * });
 */
export function useContractVehicleTaskOrderAnalysisQuery(baseOptions: Apollo.QueryHookOptions<ContractVehicleTaskOrderAnalysisQuery, ContractVehicleTaskOrderAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractVehicleTaskOrderAnalysisQuery, ContractVehicleTaskOrderAnalysisQueryVariables>(ContractVehicleTaskOrderAnalysisDocument, options);
      }
export function useContractVehicleTaskOrderAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractVehicleTaskOrderAnalysisQuery, ContractVehicleTaskOrderAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractVehicleTaskOrderAnalysisQuery, ContractVehicleTaskOrderAnalysisQueryVariables>(ContractVehicleTaskOrderAnalysisDocument, options);
        }
export type ContractVehicleTaskOrderAnalysisQueryHookResult = ReturnType<typeof useContractVehicleTaskOrderAnalysisQuery>;
export type ContractVehicleTaskOrderAnalysisLazyQueryHookResult = ReturnType<typeof useContractVehicleTaskOrderAnalysisLazyQuery>;
export type ContractVehicleTaskOrderAnalysisQueryResult = Apollo.QueryResult<ContractVehicleTaskOrderAnalysisQuery, ContractVehicleTaskOrderAnalysisQueryVariables>;
export function refetchContractVehicleTaskOrderAnalysisQuery(variables?: ContractVehicleTaskOrderAnalysisQueryVariables) {
      return { query: ContractVehicleTaskOrderAnalysisDocument, variables: variables }
    }
export const ContractVehicleTaskOrdersDocument = gql`
    query contractVehicleTaskOrders($contractVehicleId: String!, $limit: Int!, $offset: Int!, $awardYears: [Int!]!, $expirationYears: [Int!]!, $awardingAgencies: [String!]!, $naics: [String!]!, $psc: [String!]!, $sortField: String, $sortDirection: SortDirection) {
  contractVehicleTaskOrders(
    contractVehicleId: $contractVehicleId
    limit: $limit
    offset: $offset
    naics: $naics
    psc: $psc
    expirationYears: $expirationYears
    awardingAgencies: $awardingAgencies
    awardYears: $awardYears
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    hits
    aggregations
    numHits
  }
}
    `;

/**
 * __useContractVehicleTaskOrdersQuery__
 *
 * To run a query within a React component, call `useContractVehicleTaskOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVehicleTaskOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVehicleTaskOrdersQuery({
 *   variables: {
 *      contractVehicleId: // value for 'contractVehicleId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      awardYears: // value for 'awardYears'
 *      expirationYears: // value for 'expirationYears'
 *      awardingAgencies: // value for 'awardingAgencies'
 *      naics: // value for 'naics'
 *      psc: // value for 'psc'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useContractVehicleTaskOrdersQuery(baseOptions: Apollo.QueryHookOptions<ContractVehicleTaskOrdersQuery, ContractVehicleTaskOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractVehicleTaskOrdersQuery, ContractVehicleTaskOrdersQueryVariables>(ContractVehicleTaskOrdersDocument, options);
      }
export function useContractVehicleTaskOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractVehicleTaskOrdersQuery, ContractVehicleTaskOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractVehicleTaskOrdersQuery, ContractVehicleTaskOrdersQueryVariables>(ContractVehicleTaskOrdersDocument, options);
        }
export type ContractVehicleTaskOrdersQueryHookResult = ReturnType<typeof useContractVehicleTaskOrdersQuery>;
export type ContractVehicleTaskOrdersLazyQueryHookResult = ReturnType<typeof useContractVehicleTaskOrdersLazyQuery>;
export type ContractVehicleTaskOrdersQueryResult = Apollo.QueryResult<ContractVehicleTaskOrdersQuery, ContractVehicleTaskOrdersQueryVariables>;
export function refetchContractVehicleTaskOrdersQuery(variables?: ContractVehicleTaskOrdersQueryVariables) {
      return { query: ContractVehicleTaskOrdersDocument, variables: variables }
    }
export const ContractVehiclePrimeAwardsDocument = gql`
    query contractVehiclePrimeAwards($contractVehicleId: String!, $limit: Int!, $offset: Int!, $sortField: String, $sortDirection: SortDirection) {
  contractVehiclePrimeAwards(
    contractVehicleId: $contractVehicleId
    limit: $limit
    offset: $offset
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    hits
    aggregations
    numHits
  }
}
    `;

/**
 * __useContractVehiclePrimeAwardsQuery__
 *
 * To run a query within a React component, call `useContractVehiclePrimeAwardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVehiclePrimeAwardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVehiclePrimeAwardsQuery({
 *   variables: {
 *      contractVehicleId: // value for 'contractVehicleId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useContractVehiclePrimeAwardsQuery(baseOptions: Apollo.QueryHookOptions<ContractVehiclePrimeAwardsQuery, ContractVehiclePrimeAwardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractVehiclePrimeAwardsQuery, ContractVehiclePrimeAwardsQueryVariables>(ContractVehiclePrimeAwardsDocument, options);
      }
export function useContractVehiclePrimeAwardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractVehiclePrimeAwardsQuery, ContractVehiclePrimeAwardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractVehiclePrimeAwardsQuery, ContractVehiclePrimeAwardsQueryVariables>(ContractVehiclePrimeAwardsDocument, options);
        }
export type ContractVehiclePrimeAwardsQueryHookResult = ReturnType<typeof useContractVehiclePrimeAwardsQuery>;
export type ContractVehiclePrimeAwardsLazyQueryHookResult = ReturnType<typeof useContractVehiclePrimeAwardsLazyQuery>;
export type ContractVehiclePrimeAwardsQueryResult = Apollo.QueryResult<ContractVehiclePrimeAwardsQuery, ContractVehiclePrimeAwardsQueryVariables>;
export function refetchContractVehiclePrimeAwardsQuery(variables?: ContractVehiclePrimeAwardsQueryVariables) {
      return { query: ContractVehiclePrimeAwardsDocument, variables: variables }
    }
export const GetContractVehicleDocument = gql`
    query getContractVehicle($contractVehicleId: String!) {
  getContractVehicle(contractVehicleId: $contractVehicleId) {
    id
    basePeriod
    contractSharedCeiling
    contractVehicleId
    description
    endDate
    informationWebsite
    name
    optionPeriod
    solicitationIds
    startDate
    summaryCountPrimeAwards
    summaryCountTaskOrders
    summaryPotentialValue
    summaryCurrentValue
    summaryFederalActionObligation
    type
    _nsSolicitationIds
  }
}
    `;

/**
 * __useGetContractVehicleQuery__
 *
 * To run a query within a React component, call `useGetContractVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVehicleQuery({
 *   variables: {
 *      contractVehicleId: // value for 'contractVehicleId'
 *   },
 * });
 */
export function useGetContractVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetContractVehicleQuery, GetContractVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractVehicleQuery, GetContractVehicleQueryVariables>(GetContractVehicleDocument, options);
      }
export function useGetContractVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractVehicleQuery, GetContractVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractVehicleQuery, GetContractVehicleQueryVariables>(GetContractVehicleDocument, options);
        }
export type GetContractVehicleQueryHookResult = ReturnType<typeof useGetContractVehicleQuery>;
export type GetContractVehicleLazyQueryHookResult = ReturnType<typeof useGetContractVehicleLazyQuery>;
export type GetContractVehicleQueryResult = Apollo.QueryResult<GetContractVehicleQuery, GetContractVehicleQueryVariables>;
export function refetchGetContractVehicleQuery(variables?: GetContractVehicleQueryVariables) {
      return { query: GetContractVehicleDocument, variables: variables }
    }
export const EsDropdownSearchDocument = gql`
    query esDropdownSearch($query: String, $index: Json, $field: Json, $_source: Json, $alexandriaSource: String) {
  searchOpps(
    query: $query
    index: $index
    field: $field
    _source: $_source
    alexandriaSource: $alexandriaSource
  ) {
    hits
    numHits
  }
}
    `;

/**
 * __useEsDropdownSearchQuery__
 *
 * To run a query within a React component, call `useEsDropdownSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsDropdownSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsDropdownSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      index: // value for 'index'
 *      field: // value for 'field'
 *      _source: // value for '_source'
 *      alexandriaSource: // value for 'alexandriaSource'
 *   },
 * });
 */
export function useEsDropdownSearchQuery(baseOptions?: Apollo.QueryHookOptions<EsDropdownSearchQuery, EsDropdownSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EsDropdownSearchQuery, EsDropdownSearchQueryVariables>(EsDropdownSearchDocument, options);
      }
export function useEsDropdownSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EsDropdownSearchQuery, EsDropdownSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EsDropdownSearchQuery, EsDropdownSearchQueryVariables>(EsDropdownSearchDocument, options);
        }
export type EsDropdownSearchQueryHookResult = ReturnType<typeof useEsDropdownSearchQuery>;
export type EsDropdownSearchLazyQueryHookResult = ReturnType<typeof useEsDropdownSearchLazyQuery>;
export type EsDropdownSearchQueryResult = Apollo.QueryResult<EsDropdownSearchQuery, EsDropdownSearchQueryVariables>;
export function refetchEsDropdownSearchQuery(variables?: EsDropdownSearchQueryVariables) {
      return { query: EsDropdownSearchDocument, variables: variables }
    }
export const GetAwardAgenciesDocument = gql`
    query getAwardAgencies {
  esAwardAgencies
}
    `;

/**
 * __useGetAwardAgenciesQuery__
 *
 * To run a query within a React component, call `useGetAwardAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardAgenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAwardAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetAwardAgenciesQuery, GetAwardAgenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAwardAgenciesQuery, GetAwardAgenciesQueryVariables>(GetAwardAgenciesDocument, options);
      }
export function useGetAwardAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAwardAgenciesQuery, GetAwardAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAwardAgenciesQuery, GetAwardAgenciesQueryVariables>(GetAwardAgenciesDocument, options);
        }
export type GetAwardAgenciesQueryHookResult = ReturnType<typeof useGetAwardAgenciesQuery>;
export type GetAwardAgenciesLazyQueryHookResult = ReturnType<typeof useGetAwardAgenciesLazyQuery>;
export type GetAwardAgenciesQueryResult = Apollo.QueryResult<GetAwardAgenciesQuery, GetAwardAgenciesQueryVariables>;
export function refetchGetAwardAgenciesQuery(variables?: GetAwardAgenciesQueryVariables) {
      return { query: GetAwardAgenciesDocument, variables: variables }
    }
export const EsGetListingsMultiIndexDocument = gql`
    query esGetListingsMultiIndex($ids: [String!]!, $indexes: [String!]!) {
  esGetListingsMultiIndex(ids: $ids, indexes: $indexes)
}
    `;

/**
 * __useEsGetListingsMultiIndexQuery__
 *
 * To run a query within a React component, call `useEsGetListingsMultiIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsGetListingsMultiIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsGetListingsMultiIndexQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      indexes: // value for 'indexes'
 *   },
 * });
 */
export function useEsGetListingsMultiIndexQuery(baseOptions: Apollo.QueryHookOptions<EsGetListingsMultiIndexQuery, EsGetListingsMultiIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EsGetListingsMultiIndexQuery, EsGetListingsMultiIndexQueryVariables>(EsGetListingsMultiIndexDocument, options);
      }
export function useEsGetListingsMultiIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EsGetListingsMultiIndexQuery, EsGetListingsMultiIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EsGetListingsMultiIndexQuery, EsGetListingsMultiIndexQueryVariables>(EsGetListingsMultiIndexDocument, options);
        }
export type EsGetListingsMultiIndexQueryHookResult = ReturnType<typeof useEsGetListingsMultiIndexQuery>;
export type EsGetListingsMultiIndexLazyQueryHookResult = ReturnType<typeof useEsGetListingsMultiIndexLazyQuery>;
export type EsGetListingsMultiIndexQueryResult = Apollo.QueryResult<EsGetListingsMultiIndexQuery, EsGetListingsMultiIndexQueryVariables>;
export function refetchEsGetListingsMultiIndexQuery(variables?: EsGetListingsMultiIndexQueryVariables) {
      return { query: EsGetListingsMultiIndexDocument, variables: variables }
    }
export const EsSearchDocument = gql`
    query esSearch($query: String, $from: Int, $filters: Json, $sort: Json, $index: Json, $advanced: Boolean, $trackTotalHits: Boolean, $size: Int, $alexandriaSource: String, $mergeableOnly: Boolean) {
  searchOpps(
    query: $query
    from: $from
    filters: $filters
    sort: $sort
    index: $index
    advanced: $advanced
    trackTotalHits: $trackTotalHits
    size: $size
    alexandriaSource: $alexandriaSource
    mergeableOnly: $mergeableOnly
  ) {
    hits
    numHits
    bookmarks {
      id
      bookmarkId
      source
      index
      externalId
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
      reason
      createdAt
      updatedAt
      createdBy {
        fullName
      }
    }
  }
}
    `;

/**
 * __useEsSearchQuery__
 *
 * To run a query within a React component, call `useEsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      index: // value for 'index'
 *      advanced: // value for 'advanced'
 *      trackTotalHits: // value for 'trackTotalHits'
 *      size: // value for 'size'
 *      alexandriaSource: // value for 'alexandriaSource'
 *      mergeableOnly: // value for 'mergeableOnly'
 *   },
 * });
 */
export function useEsSearchQuery(baseOptions?: Apollo.QueryHookOptions<EsSearchQuery, EsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EsSearchQuery, EsSearchQueryVariables>(EsSearchDocument, options);
      }
export function useEsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EsSearchQuery, EsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EsSearchQuery, EsSearchQueryVariables>(EsSearchDocument, options);
        }
export type EsSearchQueryHookResult = ReturnType<typeof useEsSearchQuery>;
export type EsSearchLazyQueryHookResult = ReturnType<typeof useEsSearchLazyQuery>;
export type EsSearchQueryResult = Apollo.QueryResult<EsSearchQuery, EsSearchQueryVariables>;
export function refetchEsSearchQuery(variables?: EsSearchQueryVariables) {
      return { query: EsSearchDocument, variables: variables }
    }
export const VendorAwardsSearchDocument = gql`
    query vendorAwardsSearch($vendorId: String!) {
  vendorAwardsSearch(vendorId: $vendorId)
}
    `;

/**
 * __useVendorAwardsSearchQuery__
 *
 * To run a query within a React component, call `useVendorAwardsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorAwardsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorAwardsSearchQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useVendorAwardsSearchQuery(baseOptions: Apollo.QueryHookOptions<VendorAwardsSearchQuery, VendorAwardsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorAwardsSearchQuery, VendorAwardsSearchQueryVariables>(VendorAwardsSearchDocument, options);
      }
export function useVendorAwardsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorAwardsSearchQuery, VendorAwardsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorAwardsSearchQuery, VendorAwardsSearchQueryVariables>(VendorAwardsSearchDocument, options);
        }
export type VendorAwardsSearchQueryHookResult = ReturnType<typeof useVendorAwardsSearchQuery>;
export type VendorAwardsSearchLazyQueryHookResult = ReturnType<typeof useVendorAwardsSearchLazyQuery>;
export type VendorAwardsSearchQueryResult = Apollo.QueryResult<VendorAwardsSearchQuery, VendorAwardsSearchQueryVariables>;
export function refetchVendorAwardsSearchQuery(variables?: VendorAwardsSearchQueryVariables) {
      return { query: VendorAwardsSearchDocument, variables: variables }
    }
export const EsGetListingDocument = gql`
    query esGetListing($awardId: String!, $index: String!) {
  esGetListing(awardId: $awardId, index: $index)
}
    `;

/**
 * __useEsGetListingQuery__
 *
 * To run a query within a React component, call `useEsGetListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsGetListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsGetListingQuery({
 *   variables: {
 *      awardId: // value for 'awardId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useEsGetListingQuery(baseOptions: Apollo.QueryHookOptions<EsGetListingQuery, EsGetListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EsGetListingQuery, EsGetListingQueryVariables>(EsGetListingDocument, options);
      }
export function useEsGetListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EsGetListingQuery, EsGetListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EsGetListingQuery, EsGetListingQueryVariables>(EsGetListingDocument, options);
        }
export type EsGetListingQueryHookResult = ReturnType<typeof useEsGetListingQuery>;
export type EsGetListingLazyQueryHookResult = ReturnType<typeof useEsGetListingLazyQuery>;
export type EsGetListingQueryResult = Apollo.QueryResult<EsGetListingQuery, EsGetListingQueryVariables>;
export function refetchEsGetListingQuery(variables?: EsGetListingQueryVariables) {
      return { query: EsGetListingDocument, variables: variables }
    }
export const FilterSearchDocument = gql`
    query filterSearch($index: String!, $query: String, $field: String!) {
  filterSearch(index: $index, query: $query, field: $field)
}
    `;

/**
 * __useFilterSearchQuery__
 *
 * To run a query within a React component, call `useFilterSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterSearchQuery({
 *   variables: {
 *      index: // value for 'index'
 *      query: // value for 'query'
 *      field: // value for 'field'
 *   },
 * });
 */
export function useFilterSearchQuery(baseOptions: Apollo.QueryHookOptions<FilterSearchQuery, FilterSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterSearchQuery, FilterSearchQueryVariables>(FilterSearchDocument, options);
      }
export function useFilterSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterSearchQuery, FilterSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterSearchQuery, FilterSearchQueryVariables>(FilterSearchDocument, options);
        }
export type FilterSearchQueryHookResult = ReturnType<typeof useFilterSearchQuery>;
export type FilterSearchLazyQueryHookResult = ReturnType<typeof useFilterSearchLazyQuery>;
export type FilterSearchQueryResult = Apollo.QueryResult<FilterSearchQuery, FilterSearchQueryVariables>;
export function refetchFilterSearchQuery(variables?: FilterSearchQueryVariables) {
      return { query: FilterSearchDocument, variables: variables }
    }
export const GetSavedSearchesDocument = gql`
    query getSavedSearches {
  getSavedSearches {
    id
    name
    url
    payload
    index
    savedSearchSubscriber {
      savedSearchId
      subscriberId
      subscriber {
        id
        firstName
        lastName
      }
    }
    owner {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetSavedSearchesQuery__
 *
 * To run a query within a React component, call `useGetSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSavedSearchesQuery(baseOptions?: Apollo.QueryHookOptions<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>(GetSavedSearchesDocument, options);
      }
export function useGetSavedSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>(GetSavedSearchesDocument, options);
        }
export type GetSavedSearchesQueryHookResult = ReturnType<typeof useGetSavedSearchesQuery>;
export type GetSavedSearchesLazyQueryHookResult = ReturnType<typeof useGetSavedSearchesLazyQuery>;
export type GetSavedSearchesQueryResult = Apollo.QueryResult<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>;
export function refetchGetSavedSearchesQuery(variables?: GetSavedSearchesQueryVariables) {
      return { query: GetSavedSearchesDocument, variables: variables }
    }
export const GetSavedSearchMetaDocument = gql`
    query getSavedSearchMeta($savedSearchIds: [String!]) {
  getSavedSearchMeta(savedSearchIds: $savedSearchIds) {
    savedSearch {
      id
      name
      url
      owner {
        id
        firstName
        lastName
      }
      ownerId
      savedSearchSubscriber {
        savedSearchId
        subscriberId
        subscriber {
          id
          firstName
          lastName
        }
      }
      payload
      index
    }
    recentCount
    totalCount
  }
}
    `;

/**
 * __useGetSavedSearchMetaQuery__
 *
 * To run a query within a React component, call `useGetSavedSearchMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedSearchMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedSearchMetaQuery({
 *   variables: {
 *      savedSearchIds: // value for 'savedSearchIds'
 *   },
 * });
 */
export function useGetSavedSearchMetaQuery(baseOptions?: Apollo.QueryHookOptions<GetSavedSearchMetaQuery, GetSavedSearchMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedSearchMetaQuery, GetSavedSearchMetaQueryVariables>(GetSavedSearchMetaDocument, options);
      }
export function useGetSavedSearchMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedSearchMetaQuery, GetSavedSearchMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedSearchMetaQuery, GetSavedSearchMetaQueryVariables>(GetSavedSearchMetaDocument, options);
        }
export type GetSavedSearchMetaQueryHookResult = ReturnType<typeof useGetSavedSearchMetaQuery>;
export type GetSavedSearchMetaLazyQueryHookResult = ReturnType<typeof useGetSavedSearchMetaLazyQuery>;
export type GetSavedSearchMetaQueryResult = Apollo.QueryResult<GetSavedSearchMetaQuery, GetSavedSearchMetaQueryVariables>;
export function refetchGetSavedSearchMetaQuery(variables?: GetSavedSearchMetaQueryVariables) {
      return { query: GetSavedSearchMetaDocument, variables: variables }
    }
export const GetSubscribedSavedSearchMetaDocument = gql`
    query getSubscribedSavedSearchMeta {
  getSubscribedSavedSearchMeta {
    savedSearch {
      id
      name
      url
    }
    recentCount
    totalCount
  }
}
    `;

/**
 * __useGetSubscribedSavedSearchMetaQuery__
 *
 * To run a query within a React component, call `useGetSubscribedSavedSearchMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribedSavedSearchMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribedSavedSearchMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribedSavedSearchMetaQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscribedSavedSearchMetaQuery, GetSubscribedSavedSearchMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscribedSavedSearchMetaQuery, GetSubscribedSavedSearchMetaQueryVariables>(GetSubscribedSavedSearchMetaDocument, options);
      }
export function useGetSubscribedSavedSearchMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribedSavedSearchMetaQuery, GetSubscribedSavedSearchMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscribedSavedSearchMetaQuery, GetSubscribedSavedSearchMetaQueryVariables>(GetSubscribedSavedSearchMetaDocument, options);
        }
export type GetSubscribedSavedSearchMetaQueryHookResult = ReturnType<typeof useGetSubscribedSavedSearchMetaQuery>;
export type GetSubscribedSavedSearchMetaLazyQueryHookResult = ReturnType<typeof useGetSubscribedSavedSearchMetaLazyQuery>;
export type GetSubscribedSavedSearchMetaQueryResult = Apollo.QueryResult<GetSubscribedSavedSearchMetaQuery, GetSubscribedSavedSearchMetaQueryVariables>;
export function refetchGetSubscribedSavedSearchMetaQuery(variables?: GetSubscribedSavedSearchMetaQueryVariables) {
      return { query: GetSubscribedSavedSearchMetaDocument, variables: variables }
    }
export const SearchSourcesDocument = gql`
    query searchSources($searchSpec: Json!) {
  searchSources(searchSpec: $searchSpec) {
    hits
    numHits
    bookmarks {
      id
      bookmarkId
      source
      index
      externalId
      notes {
        id
        noteId
        payload
        payloadType
        noteType
        plainTextContent
        privOppId
        privOrgId
        createdAt
        contacts {
          id
          contactId
          fullName
          firstName
          lastName
          email
          title
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
      }
      reason
      createdAt
      updatedAt
      createdBy {
        fullName
      }
    }
  }
}
    `;

/**
 * __useSearchSourcesQuery__
 *
 * To run a query within a React component, call `useSearchSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSourcesQuery({
 *   variables: {
 *      searchSpec: // value for 'searchSpec'
 *   },
 * });
 */
export function useSearchSourcesQuery(baseOptions: Apollo.QueryHookOptions<SearchSourcesQuery, SearchSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSourcesQuery, SearchSourcesQueryVariables>(SearchSourcesDocument, options);
      }
export function useSearchSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSourcesQuery, SearchSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSourcesQuery, SearchSourcesQueryVariables>(SearchSourcesDocument, options);
        }
export type SearchSourcesQueryHookResult = ReturnType<typeof useSearchSourcesQuery>;
export type SearchSourcesLazyQueryHookResult = ReturnType<typeof useSearchSourcesLazyQuery>;
export type SearchSourcesQueryResult = Apollo.QueryResult<SearchSourcesQuery, SearchSourcesQueryVariables>;
export function refetchSearchSourcesQuery(variables?: SearchSourcesQueryVariables) {
      return { query: SearchSourcesDocument, variables: variables }
    }
export const DeleteStageDocument = gql`
    mutation deleteStage($id: String!) {
  deleteStage(id: $id) {
    id
  }
}
    `;
export type DeleteStageMutationFn = Apollo.MutationFunction<DeleteStageMutation, DeleteStageMutationVariables>;

/**
 * __useDeleteStageMutation__
 *
 * To run a mutation, you first call `useDeleteStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStageMutation, { data, loading, error }] = useDeleteStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStageMutation, DeleteStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStageMutation, DeleteStageMutationVariables>(DeleteStageDocument, options);
      }
export type DeleteStageMutationHookResult = ReturnType<typeof useDeleteStageMutation>;
export type DeleteStageMutationResult = Apollo.MutationResult<DeleteStageMutation>;
export type DeleteStageMutationOptions = Apollo.BaseMutationOptions<DeleteStageMutation, DeleteStageMutationVariables>;
export const UpdateStageSettingsDocument = gql`
    mutation updateStageSettings($id: Int!, $name: String, $stageIndex: Int, $taskConfig: Json) {
  updateStageSettings(
    id: $id
    name: $name
    stageIndex: $stageIndex
    taskConfig: $taskConfig
  ) {
    id
    stageId
    name
    stageIndex
    layout
    taskConfig
    pipelineId
  }
}
    `;
export type UpdateStageSettingsMutationFn = Apollo.MutationFunction<UpdateStageSettingsMutation, UpdateStageSettingsMutationVariables>;

/**
 * __useUpdateStageSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateStageSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStageSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStageSettingsMutation, { data, loading, error }] = useUpdateStageSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      stageIndex: // value for 'stageIndex'
 *      taskConfig: // value for 'taskConfig'
 *   },
 * });
 */
export function useUpdateStageSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStageSettingsMutation, UpdateStageSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStageSettingsMutation, UpdateStageSettingsMutationVariables>(UpdateStageSettingsDocument, options);
      }
export type UpdateStageSettingsMutationHookResult = ReturnType<typeof useUpdateStageSettingsMutation>;
export type UpdateStageSettingsMutationResult = Apollo.MutationResult<UpdateStageSettingsMutation>;
export type UpdateStageSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateStageSettingsMutation, UpdateStageSettingsMutationVariables>;
export const CreateStageDocument = gql`
    mutation createStage($name: String!, $stageIndex: Int!, $defaultPwin: Int, $pipelineId: Int!) {
  createStage(
    name: $name
    stageIndex: $stageIndex
    defaultPwin: $defaultPwin
    pipelineId: $pipelineId
  ) {
    id
    name
  }
}
    `;
export type CreateStageMutationFn = Apollo.MutationFunction<CreateStageMutation, CreateStageMutationVariables>;

/**
 * __useCreateStageMutation__
 *
 * To run a mutation, you first call `useCreateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStageMutation, { data, loading, error }] = useCreateStageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      stageIndex: // value for 'stageIndex'
 *      defaultPwin: // value for 'defaultPwin'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useCreateStageMutation(baseOptions?: Apollo.MutationHookOptions<CreateStageMutation, CreateStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStageMutation, CreateStageMutationVariables>(CreateStageDocument, options);
      }
export type CreateStageMutationHookResult = ReturnType<typeof useCreateStageMutation>;
export type CreateStageMutationResult = Apollo.MutationResult<CreateStageMutation>;
export type CreateStageMutationOptions = Apollo.BaseMutationOptions<CreateStageMutation, CreateStageMutationVariables>;
export const GetCacheOnlyGetAllPlanningContactsDocument = gql`
    query getCacheOnlyGetAllPlanningContacts {
  getPlanningContacts {
    id
    contactId
    firstName
    lastName
    contactOwner {
      id
      userId
      firstName
      lastName
    }
    privOrg {
      id
      orgId
      name
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetCacheOnlyGetAllPlanningContactsQuery__
 *
 * To run a query within a React component, call `useGetCacheOnlyGetAllPlanningContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCacheOnlyGetAllPlanningContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCacheOnlyGetAllPlanningContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCacheOnlyGetAllPlanningContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetCacheOnlyGetAllPlanningContactsQuery, GetCacheOnlyGetAllPlanningContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCacheOnlyGetAllPlanningContactsQuery, GetCacheOnlyGetAllPlanningContactsQueryVariables>(GetCacheOnlyGetAllPlanningContactsDocument, options);
      }
export function useGetCacheOnlyGetAllPlanningContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCacheOnlyGetAllPlanningContactsQuery, GetCacheOnlyGetAllPlanningContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCacheOnlyGetAllPlanningContactsQuery, GetCacheOnlyGetAllPlanningContactsQueryVariables>(GetCacheOnlyGetAllPlanningContactsDocument, options);
        }
export type GetCacheOnlyGetAllPlanningContactsQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningContactsQuery>;
export type GetCacheOnlyGetAllPlanningContactsLazyQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningContactsLazyQuery>;
export type GetCacheOnlyGetAllPlanningContactsQueryResult = Apollo.QueryResult<GetCacheOnlyGetAllPlanningContactsQuery, GetCacheOnlyGetAllPlanningContactsQueryVariables>;
export function refetchGetCacheOnlyGetAllPlanningContactsQuery(variables?: GetCacheOnlyGetAllPlanningContactsQueryVariables) {
      return { query: GetCacheOnlyGetAllPlanningContactsDocument, variables: variables }
    }
export const GetCacheOnlyGetAllPlanningOpportunitiesDocument = gql`
    query getCacheOnlyGetAllPlanningOpportunities {
  getPlanningOpportunities {
    id
    oppId
    canonId
    sol
    award
    name
    isArchived
    isWin
    metadata
    pipelineId
    captureTeam {
      id
      name
      users {
        id
        userId
        firstName
        lastName
      }
    }
    pipeline {
      id
      pipelineId
      name
      customFields
    }
    privOppStage {
      id
      stage {
        id
        stageId
        name
        stageIndex
      }
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetCacheOnlyGetAllPlanningOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetCacheOnlyGetAllPlanningOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCacheOnlyGetAllPlanningOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCacheOnlyGetAllPlanningOpportunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCacheOnlyGetAllPlanningOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCacheOnlyGetAllPlanningOpportunitiesQuery, GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCacheOnlyGetAllPlanningOpportunitiesQuery, GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables>(GetCacheOnlyGetAllPlanningOpportunitiesDocument, options);
      }
export function useGetCacheOnlyGetAllPlanningOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCacheOnlyGetAllPlanningOpportunitiesQuery, GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCacheOnlyGetAllPlanningOpportunitiesQuery, GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables>(GetCacheOnlyGetAllPlanningOpportunitiesDocument, options);
        }
export type GetCacheOnlyGetAllPlanningOpportunitiesQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningOpportunitiesQuery>;
export type GetCacheOnlyGetAllPlanningOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningOpportunitiesLazyQuery>;
export type GetCacheOnlyGetAllPlanningOpportunitiesQueryResult = Apollo.QueryResult<GetCacheOnlyGetAllPlanningOpportunitiesQuery, GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables>;
export function refetchGetCacheOnlyGetAllPlanningOpportunitiesQuery(variables?: GetCacheOnlyGetAllPlanningOpportunitiesQueryVariables) {
      return { query: GetCacheOnlyGetAllPlanningOpportunitiesDocument, variables: variables }
    }
export const GetCacheOnlyGetAllPlanningOrganizationsDocument = gql`
    query getCacheOnlyGetAllPlanningOrganizations {
  getPlanningOrganizations {
    id
    orgId
    name
    industry
    type
    orgTags {
      id
      name
    }
    accountOwner {
      id
      userId
      firstName
      lastName
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetCacheOnlyGetAllPlanningOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetCacheOnlyGetAllPlanningOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCacheOnlyGetAllPlanningOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCacheOnlyGetAllPlanningOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCacheOnlyGetAllPlanningOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCacheOnlyGetAllPlanningOrganizationsQuery, GetCacheOnlyGetAllPlanningOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCacheOnlyGetAllPlanningOrganizationsQuery, GetCacheOnlyGetAllPlanningOrganizationsQueryVariables>(GetCacheOnlyGetAllPlanningOrganizationsDocument, options);
      }
export function useGetCacheOnlyGetAllPlanningOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCacheOnlyGetAllPlanningOrganizationsQuery, GetCacheOnlyGetAllPlanningOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCacheOnlyGetAllPlanningOrganizationsQuery, GetCacheOnlyGetAllPlanningOrganizationsQueryVariables>(GetCacheOnlyGetAllPlanningOrganizationsDocument, options);
        }
export type GetCacheOnlyGetAllPlanningOrganizationsQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningOrganizationsQuery>;
export type GetCacheOnlyGetAllPlanningOrganizationsLazyQueryHookResult = ReturnType<typeof useGetCacheOnlyGetAllPlanningOrganizationsLazyQuery>;
export type GetCacheOnlyGetAllPlanningOrganizationsQueryResult = Apollo.QueryResult<GetCacheOnlyGetAllPlanningOrganizationsQuery, GetCacheOnlyGetAllPlanningOrganizationsQueryVariables>;
export function refetchGetCacheOnlyGetAllPlanningOrganizationsQuery(variables?: GetCacheOnlyGetAllPlanningOrganizationsQueryVariables) {
      return { query: GetCacheOnlyGetAllPlanningOrganizationsDocument, variables: variables }
    }
export const GetInitialPlanningContactsDocument = gql`
    query getInitialPlanningContacts {
  getInitialPlanningContacts
}
    `;

/**
 * __useGetInitialPlanningContactsQuery__
 *
 * To run a query within a React component, call `useGetInitialPlanningContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialPlanningContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialPlanningContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialPlanningContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetInitialPlanningContactsQuery, GetInitialPlanningContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialPlanningContactsQuery, GetInitialPlanningContactsQueryVariables>(GetInitialPlanningContactsDocument, options);
      }
export function useGetInitialPlanningContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialPlanningContactsQuery, GetInitialPlanningContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialPlanningContactsQuery, GetInitialPlanningContactsQueryVariables>(GetInitialPlanningContactsDocument, options);
        }
export type GetInitialPlanningContactsQueryHookResult = ReturnType<typeof useGetInitialPlanningContactsQuery>;
export type GetInitialPlanningContactsLazyQueryHookResult = ReturnType<typeof useGetInitialPlanningContactsLazyQuery>;
export type GetInitialPlanningContactsQueryResult = Apollo.QueryResult<GetInitialPlanningContactsQuery, GetInitialPlanningContactsQueryVariables>;
export function refetchGetInitialPlanningContactsQuery(variables?: GetInitialPlanningContactsQueryVariables) {
      return { query: GetInitialPlanningContactsDocument, variables: variables }
    }
export const GetInitialPlanningOpportunitiesDocument = gql`
    query getInitialPlanningOpportunities {
  getInitialPlanningOpportunities
}
    `;

/**
 * __useGetInitialPlanningOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetInitialPlanningOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialPlanningOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialPlanningOpportunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialPlanningOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetInitialPlanningOpportunitiesQuery, GetInitialPlanningOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialPlanningOpportunitiesQuery, GetInitialPlanningOpportunitiesQueryVariables>(GetInitialPlanningOpportunitiesDocument, options);
      }
export function useGetInitialPlanningOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialPlanningOpportunitiesQuery, GetInitialPlanningOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialPlanningOpportunitiesQuery, GetInitialPlanningOpportunitiesQueryVariables>(GetInitialPlanningOpportunitiesDocument, options);
        }
export type GetInitialPlanningOpportunitiesQueryHookResult = ReturnType<typeof useGetInitialPlanningOpportunitiesQuery>;
export type GetInitialPlanningOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetInitialPlanningOpportunitiesLazyQuery>;
export type GetInitialPlanningOpportunitiesQueryResult = Apollo.QueryResult<GetInitialPlanningOpportunitiesQuery, GetInitialPlanningOpportunitiesQueryVariables>;
export function refetchGetInitialPlanningOpportunitiesQuery(variables?: GetInitialPlanningOpportunitiesQueryVariables) {
      return { query: GetInitialPlanningOpportunitiesDocument, variables: variables }
    }
export const GetInitialPlanningOrganizationsDocument = gql`
    query getInitialPlanningOrganizations {
  getInitialPlanningOrganizations
}
    `;

/**
 * __useGetInitialPlanningOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetInitialPlanningOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialPlanningOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialPlanningOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialPlanningOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetInitialPlanningOrganizationsQuery, GetInitialPlanningOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialPlanningOrganizationsQuery, GetInitialPlanningOrganizationsQueryVariables>(GetInitialPlanningOrganizationsDocument, options);
      }
export function useGetInitialPlanningOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialPlanningOrganizationsQuery, GetInitialPlanningOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialPlanningOrganizationsQuery, GetInitialPlanningOrganizationsQueryVariables>(GetInitialPlanningOrganizationsDocument, options);
        }
export type GetInitialPlanningOrganizationsQueryHookResult = ReturnType<typeof useGetInitialPlanningOrganizationsQuery>;
export type GetInitialPlanningOrganizationsLazyQueryHookResult = ReturnType<typeof useGetInitialPlanningOrganizationsLazyQuery>;
export type GetInitialPlanningOrganizationsQueryResult = Apollo.QueryResult<GetInitialPlanningOrganizationsQuery, GetInitialPlanningOrganizationsQueryVariables>;
export function refetchGetInitialPlanningOrganizationsQuery(variables?: GetInitialPlanningOrganizationsQueryVariables) {
      return { query: GetInitialPlanningOrganizationsDocument, variables: variables }
    }
export const GetPlanningDocument = gql`
    query getPlanning {
  getPlanning {
    id
    contacts {
      id
      contactId
      firstName
      lastName
      contactOwner {
        id
        userId
        firstName
        lastName
      }
      privOrg {
        id
        orgId
        name
      }
      tasks {
        id
        createdAt
        taskId
        dueDate
        status
        description
        privOpp {
          id
          canonId
          name
          pipelineId
          privOppStage {
            id
            stage {
              id
              name
            }
          }
          pipeline {
            id
            pipelineId
            name
            customFields
          }
        }
        org {
          id
          name
        }
        assignees {
          id
          userId
          firstName
          lastName
        }
        notes {
          id
          noteId
          payload
          payloadType
          noteType
          plainTextContent
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
    }
    privOrgs {
      id
      orgId
      name
      industry
      type
      orgTags {
        id
        name
      }
      accountOwner {
        id
        userId
        firstName
        lastName
      }
      tasks {
        id
        createdAt
        taskId
        dueDate
        status
        description
        contact {
          id
          firstName
          lastName
        }
        privOpp {
          id
          oppId
          canonId
          name
          pipelineId
          privOppStage {
            id
            stage {
              id
              name
            }
          }
          pipeline {
            id
            pipelineId
            name
            customFields
          }
        }
        assignees {
          id
          userId
          firstName
          lastName
        }
        notes {
          id
          noteId
          createdAt
          payload
          payloadType
          noteType
          plainTextContent
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
    }
    privOpps {
      id
      oppId
      canonId
      sol
      award
      name
      isArchived
      isWin
      metadata
      pipelineId
      captureTeam {
        id
        name
        users {
          id
          userId
          firstName
          lastName
        }
      }
      pipeline {
        id
        pipelineId
        name
        customFields
      }
      privOppStage {
        id
        stage {
          id
          stageId
          name
          stageIndex
        }
      }
      tasks {
        id
        createdAt
        taskId
        dueDate
        status
        description
        parent {
          id
          taskId
          description
        }
        contact {
          id
          firstName
          lastName
        }
        org {
          id
          name
        }
        assignees {
          id
          userId
          firstName
          lastName
        }
        notes {
          id
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          payload
          payloadType
          noteType
          plainTextContent
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlanningQuery__
 *
 * To run a query within a React component, call `useGetPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanningQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanningQuery, GetPlanningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanningQuery, GetPlanningQueryVariables>(GetPlanningDocument, options);
      }
export function useGetPlanningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanningQuery, GetPlanningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanningQuery, GetPlanningQueryVariables>(GetPlanningDocument, options);
        }
export type GetPlanningQueryHookResult = ReturnType<typeof useGetPlanningQuery>;
export type GetPlanningLazyQueryHookResult = ReturnType<typeof useGetPlanningLazyQuery>;
export type GetPlanningQueryResult = Apollo.QueryResult<GetPlanningQuery, GetPlanningQueryVariables>;
export function refetchGetPlanningQuery(variables?: GetPlanningQueryVariables) {
      return { query: GetPlanningDocument, variables: variables }
    }
export const GetPlanningContactsDocument = gql`
    query getPlanningContacts($ids: [String!]!) {
  getPlanningContacts(ids: $ids) @connection(key: "getPlanningContacts") {
    id
    contactId
    firstName
    lastName
    contactOwner {
      id
      userId
      firstName
      lastName
    }
    privOrg {
      id
      orgId
      name
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetPlanningContactsQuery__
 *
 * To run a query within a React component, call `useGetPlanningContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningContactsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPlanningContactsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanningContactsQuery, GetPlanningContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanningContactsQuery, GetPlanningContactsQueryVariables>(GetPlanningContactsDocument, options);
      }
export function useGetPlanningContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanningContactsQuery, GetPlanningContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanningContactsQuery, GetPlanningContactsQueryVariables>(GetPlanningContactsDocument, options);
        }
export type GetPlanningContactsQueryHookResult = ReturnType<typeof useGetPlanningContactsQuery>;
export type GetPlanningContactsLazyQueryHookResult = ReturnType<typeof useGetPlanningContactsLazyQuery>;
export type GetPlanningContactsQueryResult = Apollo.QueryResult<GetPlanningContactsQuery, GetPlanningContactsQueryVariables>;
export function refetchGetPlanningContactsQuery(variables?: GetPlanningContactsQueryVariables) {
      return { query: GetPlanningContactsDocument, variables: variables }
    }
export const GetPlanningOpportunitiesDocument = gql`
    query getPlanningOpportunities($ids: [String!]) {
  getPlanningOpportunities(ids: $ids) @connection(key: "getPlanningOpportunities") {
    id
    oppId
    canonId
    sol
    award
    name
    isArchived
    isWin
    metadata
    pipelineId
    captureTeam {
      id
      name
      users {
        id
        userId
        firstName
        lastName
      }
    }
    pipeline {
      id
      pipelineId
      name
      customFields
    }
    privOppStage {
      id
      stage {
        id
        stageId
        name
        stageIndex
      }
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetPlanningOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetPlanningOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningOpportunitiesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPlanningOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanningOpportunitiesQuery, GetPlanningOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanningOpportunitiesQuery, GetPlanningOpportunitiesQueryVariables>(GetPlanningOpportunitiesDocument, options);
      }
export function useGetPlanningOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanningOpportunitiesQuery, GetPlanningOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanningOpportunitiesQuery, GetPlanningOpportunitiesQueryVariables>(GetPlanningOpportunitiesDocument, options);
        }
export type GetPlanningOpportunitiesQueryHookResult = ReturnType<typeof useGetPlanningOpportunitiesQuery>;
export type GetPlanningOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetPlanningOpportunitiesLazyQuery>;
export type GetPlanningOpportunitiesQueryResult = Apollo.QueryResult<GetPlanningOpportunitiesQuery, GetPlanningOpportunitiesQueryVariables>;
export function refetchGetPlanningOpportunitiesQuery(variables?: GetPlanningOpportunitiesQueryVariables) {
      return { query: GetPlanningOpportunitiesDocument, variables: variables }
    }
export const GetPlanningOrganizationsDocument = gql`
    query getPlanningOrganizations($ids: [String!]!) {
  getPlanningOrganizations(ids: $ids) @connection(key: "getPlanningOrganizations") {
    id
    orgId
    name
    industry
    type
    orgTags {
      id
      name
    }
    accountOwner {
      id
      userId
      firstName
      lastName
    }
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetPlanningOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetPlanningOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningOrganizationsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPlanningOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanningOrganizationsQuery, GetPlanningOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanningOrganizationsQuery, GetPlanningOrganizationsQueryVariables>(GetPlanningOrganizationsDocument, options);
      }
export function useGetPlanningOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanningOrganizationsQuery, GetPlanningOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanningOrganizationsQuery, GetPlanningOrganizationsQueryVariables>(GetPlanningOrganizationsDocument, options);
        }
export type GetPlanningOrganizationsQueryHookResult = ReturnType<typeof useGetPlanningOrganizationsQuery>;
export type GetPlanningOrganizationsLazyQueryHookResult = ReturnType<typeof useGetPlanningOrganizationsLazyQuery>;
export type GetPlanningOrganizationsQueryResult = Apollo.QueryResult<GetPlanningOrganizationsQuery, GetPlanningOrganizationsQueryVariables>;
export function refetchGetPlanningOrganizationsQuery(variables?: GetPlanningOrganizationsQueryVariables) {
      return { query: GetPlanningOrganizationsDocument, variables: variables }
    }
export const GetTaskDocument = gql`
    query getTask($id: String!) {
  getTask(id: $id) {
    id
    taskId
    createdAt
    description
    dueDate
    status
    parent {
      id
      taskId
      description
      status
      dueDate
    }
    subtasks {
      id
      createdAt
      taskId
      description
      dueDate
      status
      privOpp {
        id
        name
      }
    }
    org {
      id
      orgId
      name
    }
    contact {
      id
      contactId
      fullName
      firstName
      lastName
    }
    privOpp {
      id
      canonId
      name
      stage {
        id
        name
      }
    }
    assignees {
      id
      userId
      firstName
      lastName
    }
    notes {
      id
      noteId
      payload
      payloadType
      noteType
      plainTextContent
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export function refetchGetTaskQuery(variables?: GetTaskQueryVariables) {
      return { query: GetTaskDocument, variables: variables }
    }
export const AddSubToTeamDocument = gql`
    mutation addSubToTeam($primeId: String!, $privOrgId: Int!, $workshare: Int, $contactIds: [Int!]) {
  addSubToTeam(
    primeId: $primeId
    privOrgId: $privOrgId
    workshare: $workshare
    contactIds: $contactIds
  ) {
    teamId
  }
}
    `;
export type AddSubToTeamMutationFn = Apollo.MutationFunction<AddSubToTeamMutation, AddSubToTeamMutationVariables>;

/**
 * __useAddSubToTeamMutation__
 *
 * To run a mutation, you first call `useAddSubToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubToTeamMutation, { data, loading, error }] = useAddSubToTeamMutation({
 *   variables: {
 *      primeId: // value for 'primeId'
 *      privOrgId: // value for 'privOrgId'
 *      workshare: // value for 'workshare'
 *      contactIds: // value for 'contactIds'
 *   },
 * });
 */
export function useAddSubToTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddSubToTeamMutation, AddSubToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubToTeamMutation, AddSubToTeamMutationVariables>(AddSubToTeamDocument, options);
      }
export type AddSubToTeamMutationHookResult = ReturnType<typeof useAddSubToTeamMutation>;
export type AddSubToTeamMutationResult = Apollo.MutationResult<AddSubToTeamMutation>;
export type AddSubToTeamMutationOptions = Apollo.BaseMutationOptions<AddSubToTeamMutation, AddSubToTeamMutationVariables>;
export const AddTeamDocument = gql`
    mutation addTeam($privOrgId: Int, $privOppId: Int, $workshare: Int, $subcontractorIds: [Int!], $contactIds: [Int!], $incumbentTeam: Boolean, $isWinningTeam: Boolean, $workspaceTeam: Boolean) {
  addTeam(
    privOrgId: $privOrgId
    privOppId: $privOppId
    workshare: $workshare
    subcontractorIds: $subcontractorIds
    contactIds: $contactIds
    incumbentTeam: $incumbentTeam
    isWinningTeam: $isWinningTeam
    workspaceTeam: $workspaceTeam
  ) {
    teamId
  }
}
    `;
export type AddTeamMutationFn = Apollo.MutationFunction<AddTeamMutation, AddTeamMutationVariables>;

/**
 * __useAddTeamMutation__
 *
 * To run a mutation, you first call `useAddTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMutation, { data, loading, error }] = useAddTeamMutation({
 *   variables: {
 *      privOrgId: // value for 'privOrgId'
 *      privOppId: // value for 'privOppId'
 *      workshare: // value for 'workshare'
 *      subcontractorIds: // value for 'subcontractorIds'
 *      contactIds: // value for 'contactIds'
 *      incumbentTeam: // value for 'incumbentTeam'
 *      isWinningTeam: // value for 'isWinningTeam'
 *      workspaceTeam: // value for 'workspaceTeam'
 *   },
 * });
 */
export function useAddTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMutation, AddTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMutation, AddTeamMutationVariables>(AddTeamDocument, options);
      }
export type AddTeamMutationHookResult = ReturnType<typeof useAddTeamMutation>;
export type AddTeamMutationResult = Apollo.MutationResult<AddTeamMutation>;
export type AddTeamMutationOptions = Apollo.BaseMutationOptions<AddTeamMutation, AddTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($teamId: String!, $orgId: String, $workshare: Int, $contactIds: [Int!], $incumbentTeam: Boolean, $isWinningTeam: Boolean, $nda: Boolean, $ta: Boolean) {
  updateTeam(
    teamId: $teamId
    orgId: $orgId
    workshare: $workshare
    contactIds: $contactIds
    incumbentTeam: $incumbentTeam
    isWinningTeam: $isWinningTeam
    nda: $nda
    ta: $ta
  ) {
    id
    teamId
    nda
    ta
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      orgId: // value for 'orgId'
 *      workshare: // value for 'workshare'
 *      contactIds: // value for 'contactIds'
 *      incumbentTeam: // value for 'incumbentTeam'
 *      isWinningTeam: // value for 'isWinningTeam'
 *      nda: // value for 'nda'
 *      ta: // value for 'ta'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($teamId: String!) {
  deleteTeam(teamId: $teamId) {
    teamId
  }
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const DeleteTimelineViewDocument = gql`
    mutation deleteTimelineView($id: String!) {
  deleteTimelineView(id: $id)
}
    `;
export type DeleteTimelineViewMutationFn = Apollo.MutationFunction<DeleteTimelineViewMutation, DeleteTimelineViewMutationVariables>;

/**
 * __useDeleteTimelineViewMutation__
 *
 * To run a mutation, you first call `useDeleteTimelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimelineViewMutation, { data, loading, error }] = useDeleteTimelineViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimelineViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimelineViewMutation, DeleteTimelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimelineViewMutation, DeleteTimelineViewMutationVariables>(DeleteTimelineViewDocument, options);
      }
export type DeleteTimelineViewMutationHookResult = ReturnType<typeof useDeleteTimelineViewMutation>;
export type DeleteTimelineViewMutationResult = Apollo.MutationResult<DeleteTimelineViewMutation>;
export type DeleteTimelineViewMutationOptions = Apollo.BaseMutationOptions<DeleteTimelineViewMutation, DeleteTimelineViewMutationVariables>;
export const GetPipelineViewsDocument = gql`
    query getPipelineViews {
  getPipelineViews {
    filters
    filterMode
    id
    pipelineViewId
    sortColumn
    sortDirection
    query
    name
    pipeline {
      name
      pipelineId
      id
    }
  }
}
    `;

/**
 * __useGetPipelineViewsQuery__
 *
 * To run a query within a React component, call `useGetPipelineViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPipelineViewsQuery(baseOptions?: Apollo.QueryHookOptions<GetPipelineViewsQuery, GetPipelineViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineViewsQuery, GetPipelineViewsQueryVariables>(GetPipelineViewsDocument, options);
      }
export function useGetPipelineViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineViewsQuery, GetPipelineViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineViewsQuery, GetPipelineViewsQueryVariables>(GetPipelineViewsDocument, options);
        }
export type GetPipelineViewsQueryHookResult = ReturnType<typeof useGetPipelineViewsQuery>;
export type GetPipelineViewsLazyQueryHookResult = ReturnType<typeof useGetPipelineViewsLazyQuery>;
export type GetPipelineViewsQueryResult = Apollo.QueryResult<GetPipelineViewsQuery, GetPipelineViewsQueryVariables>;
export function refetchGetPipelineViewsQuery(variables?: GetPipelineViewsQueryVariables) {
      return { query: GetPipelineViewsDocument, variables: variables }
    }
export const GetTimelineViewDocument = gql`
    query getTimelineView($id: String!) {
  getTimelineView(id: $id) {
    id
    timelineViewUUId
    name
    query
    definition {
      filters {
        field
        values
      }
      dateFilters {
        field
        mode
        valueISOString
      }
      displayDates {
        field
        color
      }
      sort {
        field
        direction
      }
    }
  }
}
    `;

/**
 * __useGetTimelineViewQuery__
 *
 * To run a query within a React component, call `useGetTimelineViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTimelineViewQuery(baseOptions: Apollo.QueryHookOptions<GetTimelineViewQuery, GetTimelineViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineViewQuery, GetTimelineViewQueryVariables>(GetTimelineViewDocument, options);
      }
export function useGetTimelineViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineViewQuery, GetTimelineViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineViewQuery, GetTimelineViewQueryVariables>(GetTimelineViewDocument, options);
        }
export type GetTimelineViewQueryHookResult = ReturnType<typeof useGetTimelineViewQuery>;
export type GetTimelineViewLazyQueryHookResult = ReturnType<typeof useGetTimelineViewLazyQuery>;
export type GetTimelineViewQueryResult = Apollo.QueryResult<GetTimelineViewQuery, GetTimelineViewQueryVariables>;
export function refetchGetTimelineViewQuery(variables?: GetTimelineViewQueryVariables) {
      return { query: GetTimelineViewDocument, variables: variables }
    }
export const GetTimelineViewsDocument = gql`
    query getTimelineViews {
  getTimelineViews {
    id
    timelineViewUUId
    name
    query
    definition {
      filters {
        field
        values
      }
      dateFilters {
        field
        mode
        valueISOString
      }
      displayDates {
        field
        color
      }
      sort {
        field
        direction
      }
    }
  }
}
    `;

/**
 * __useGetTimelineViewsQuery__
 *
 * To run a query within a React component, call `useGetTimelineViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimelineViewsQuery(baseOptions?: Apollo.QueryHookOptions<GetTimelineViewsQuery, GetTimelineViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineViewsQuery, GetTimelineViewsQueryVariables>(GetTimelineViewsDocument, options);
      }
export function useGetTimelineViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineViewsQuery, GetTimelineViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineViewsQuery, GetTimelineViewsQueryVariables>(GetTimelineViewsDocument, options);
        }
export type GetTimelineViewsQueryHookResult = ReturnType<typeof useGetTimelineViewsQuery>;
export type GetTimelineViewsLazyQueryHookResult = ReturnType<typeof useGetTimelineViewsLazyQuery>;
export type GetTimelineViewsQueryResult = Apollo.QueryResult<GetTimelineViewsQuery, GetTimelineViewsQueryVariables>;
export function refetchGetTimelineViewsQuery(variables?: GetTimelineViewsQueryVariables) {
      return { query: GetTimelineViewsDocument, variables: variables }
    }
export const SetDefaultTimelineViewDocument = gql`
    mutation setDefaultTimelineView($id: String) {
  setDefaultTimelineView(id: $id)
}
    `;
export type SetDefaultTimelineViewMutationFn = Apollo.MutationFunction<SetDefaultTimelineViewMutation, SetDefaultTimelineViewMutationVariables>;

/**
 * __useSetDefaultTimelineViewMutation__
 *
 * To run a mutation, you first call `useSetDefaultTimelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultTimelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultTimelineViewMutation, { data, loading, error }] = useSetDefaultTimelineViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultTimelineViewMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultTimelineViewMutation, SetDefaultTimelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultTimelineViewMutation, SetDefaultTimelineViewMutationVariables>(SetDefaultTimelineViewDocument, options);
      }
export type SetDefaultTimelineViewMutationHookResult = ReturnType<typeof useSetDefaultTimelineViewMutation>;
export type SetDefaultTimelineViewMutationResult = Apollo.MutationResult<SetDefaultTimelineViewMutation>;
export type SetDefaultTimelineViewMutationOptions = Apollo.BaseMutationOptions<SetDefaultTimelineViewMutation, SetDefaultTimelineViewMutationVariables>;
export const UpsertTimelineViewDocument = gql`
    mutation upsertTimelineView($id: String, $query: String!, $config: String!, $name: String!) {
  upsertTimelineView(id: $id, query: $query, config: $config, name: $name) {
    id
    timelineViewUUId
    name
    query
    definition {
      filters {
        field
        values
      }
      displayDates {
        field
        color
      }
      sort {
        field
        direction
      }
    }
  }
}
    `;
export type UpsertTimelineViewMutationFn = Apollo.MutationFunction<UpsertTimelineViewMutation, UpsertTimelineViewMutationVariables>;

/**
 * __useUpsertTimelineViewMutation__
 *
 * To run a mutation, you first call `useUpsertTimelineViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTimelineViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTimelineViewMutation, { data, loading, error }] = useUpsertTimelineViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *      config: // value for 'config'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpsertTimelineViewMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTimelineViewMutation, UpsertTimelineViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTimelineViewMutation, UpsertTimelineViewMutationVariables>(UpsertTimelineViewDocument, options);
      }
export type UpsertTimelineViewMutationHookResult = ReturnType<typeof useUpsertTimelineViewMutation>;
export type UpsertTimelineViewMutationResult = Apollo.MutationResult<UpsertTimelineViewMutation>;
export type UpsertTimelineViewMutationOptions = Apollo.BaseMutationOptions<UpsertTimelineViewMutation, UpsertTimelineViewMutationVariables>;
export const CreateUpdateDocument = gql`
    mutation createUpdate($privOppId: String!) {
  createUpdate(privOppId: $privOppId) {
    id
  }
}
    `;
export type CreateUpdateMutationFn = Apollo.MutationFunction<CreateUpdateMutation, CreateUpdateMutationVariables>;

/**
 * __useCreateUpdateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateMutation, { data, loading, error }] = useCreateUpdateMutation({
 *   variables: {
 *      privOppId: // value for 'privOppId'
 *   },
 * });
 */
export function useCreateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateMutation, CreateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateMutation, CreateUpdateMutationVariables>(CreateUpdateDocument, options);
      }
export type CreateUpdateMutationHookResult = ReturnType<typeof useCreateUpdateMutation>;
export type CreateUpdateMutationResult = Apollo.MutationResult<CreateUpdateMutation>;
export type CreateUpdateMutationOptions = Apollo.BaseMutationOptions<CreateUpdateMutation, CreateUpdateMutationVariables>;
export const CreateUpdateFromTemplateDocument = gql`
    mutation createUpdateFromTemplate($privOppId: String!, $updateTemplateUUId: String!) {
  createUpdateFromTemplate(
    privOppId: $privOppId
    updateTemplateUUId: $updateTemplateUUId
  ) {
    id
  }
}
    `;
export type CreateUpdateFromTemplateMutationFn = Apollo.MutationFunction<CreateUpdateFromTemplateMutation, CreateUpdateFromTemplateMutationVariables>;

/**
 * __useCreateUpdateFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateFromTemplateMutation, { data, loading, error }] = useCreateUpdateFromTemplateMutation({
 *   variables: {
 *      privOppId: // value for 'privOppId'
 *      updateTemplateUUId: // value for 'updateTemplateUUId'
 *   },
 * });
 */
export function useCreateUpdateFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateFromTemplateMutation, CreateUpdateFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateFromTemplateMutation, CreateUpdateFromTemplateMutationVariables>(CreateUpdateFromTemplateDocument, options);
      }
export type CreateUpdateFromTemplateMutationHookResult = ReturnType<typeof useCreateUpdateFromTemplateMutation>;
export type CreateUpdateFromTemplateMutationResult = Apollo.MutationResult<CreateUpdateFromTemplateMutation>;
export type CreateUpdateFromTemplateMutationOptions = Apollo.BaseMutationOptions<CreateUpdateFromTemplateMutation, CreateUpdateFromTemplateMutationVariables>;
export const GetUpdateDocument = gql`
    query getUpdate($updateUUId: String!) {
  getUpdate(updateUUId: $updateUUId) {
    id
    updateId
    hasUpdateV2Payload
    payload
    updateTemplate {
      id
      updateTemplateId
      payloadV2
    }
  }
}
    `;

/**
 * __useGetUpdateQuery__
 *
 * To run a query within a React component, call `useGetUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateQuery({
 *   variables: {
 *      updateUUId: // value for 'updateUUId'
 *   },
 * });
 */
export function useGetUpdateQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateQuery, GetUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateQuery, GetUpdateQueryVariables>(GetUpdateDocument, options);
      }
export function useGetUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateQuery, GetUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateQuery, GetUpdateQueryVariables>(GetUpdateDocument, options);
        }
export type GetUpdateQueryHookResult = ReturnType<typeof useGetUpdateQuery>;
export type GetUpdateLazyQueryHookResult = ReturnType<typeof useGetUpdateLazyQuery>;
export type GetUpdateQueryResult = Apollo.QueryResult<GetUpdateQuery, GetUpdateQueryVariables>;
export function refetchGetUpdateQuery(variables?: GetUpdateQueryVariables) {
      return { query: GetUpdateDocument, variables: variables }
    }
export const CreateUpdateTemplateDocument = gql`
    mutation createUpdateTemplate($updateTemplateHTML: String!, $pipelineId: Int!) {
  createUpdateTemplate(
    updateTemplateHTML: $updateTemplateHTML
    pipelineId: $pipelineId
  ) {
    updateTemplateId
  }
}
    `;
export type CreateUpdateTemplateMutationFn = Apollo.MutationFunction<CreateUpdateTemplateMutation, CreateUpdateTemplateMutationVariables>;

/**
 * __useCreateUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateTemplateMutation, { data, loading, error }] = useCreateUpdateTemplateMutation({
 *   variables: {
 *      updateTemplateHTML: // value for 'updateTemplateHTML'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useCreateUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateTemplateMutation, CreateUpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateTemplateMutation, CreateUpdateTemplateMutationVariables>(CreateUpdateTemplateDocument, options);
      }
export type CreateUpdateTemplateMutationHookResult = ReturnType<typeof useCreateUpdateTemplateMutation>;
export type CreateUpdateTemplateMutationResult = Apollo.MutationResult<CreateUpdateTemplateMutation>;
export type CreateUpdateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateUpdateTemplateMutation, CreateUpdateTemplateMutationVariables>;
export const GetUpdateTemplateDocument = gql`
    query getUpdateTemplate($updateTemplateUUId: String!) {
  getUpdateTemplate(updateTemplateUUId: $updateTemplateUUId) {
    id
    updateTemplateId
    payloadV2
  }
}
    `;

/**
 * __useGetUpdateTemplateQuery__
 *
 * To run a query within a React component, call `useGetUpdateTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateTemplateQuery({
 *   variables: {
 *      updateTemplateUUId: // value for 'updateTemplateUUId'
 *   },
 * });
 */
export function useGetUpdateTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateTemplateQuery, GetUpdateTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateTemplateQuery, GetUpdateTemplateQueryVariables>(GetUpdateTemplateDocument, options);
      }
export function useGetUpdateTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateTemplateQuery, GetUpdateTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateTemplateQuery, GetUpdateTemplateQueryVariables>(GetUpdateTemplateDocument, options);
        }
export type GetUpdateTemplateQueryHookResult = ReturnType<typeof useGetUpdateTemplateQuery>;
export type GetUpdateTemplateLazyQueryHookResult = ReturnType<typeof useGetUpdateTemplateLazyQuery>;
export type GetUpdateTemplateQueryResult = Apollo.QueryResult<GetUpdateTemplateQuery, GetUpdateTemplateQueryVariables>;
export function refetchGetUpdateTemplateQuery(variables?: GetUpdateTemplateQueryVariables) {
      return { query: GetUpdateTemplateDocument, variables: variables }
    }
export const UpdateUpdateTemplateDocument = gql`
    mutation updateUpdateTemplate($updateTemplateUUId: String!, $updateTemplateHTML: String!) {
  updateUpdateTemplate(
    updateTemplateUUId: $updateTemplateUUId
    updateTemplateHTML: $updateTemplateHTML
  ) {
    updateTemplateId
  }
}
    `;
export type UpdateUpdateTemplateMutationFn = Apollo.MutationFunction<UpdateUpdateTemplateMutation, UpdateUpdateTemplateMutationVariables>;

/**
 * __useUpdateUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUpdateTemplateMutation, { data, loading, error }] = useUpdateUpdateTemplateMutation({
 *   variables: {
 *      updateTemplateUUId: // value for 'updateTemplateUUId'
 *      updateTemplateHTML: // value for 'updateTemplateHTML'
 *   },
 * });
 */
export function useUpdateUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUpdateTemplateMutation, UpdateUpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUpdateTemplateMutation, UpdateUpdateTemplateMutationVariables>(UpdateUpdateTemplateDocument, options);
      }
export type UpdateUpdateTemplateMutationHookResult = ReturnType<typeof useUpdateUpdateTemplateMutation>;
export type UpdateUpdateTemplateMutationResult = Apollo.MutationResult<UpdateUpdateTemplateMutation>;
export type UpdateUpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateUpdateTemplateMutation, UpdateUpdateTemplateMutationVariables>;
export const GetUploadPipelineJobDocument = gql`
    query getUploadPipelineJob($uploadId: String!) {
  getUploadPipelineJob(uploadId: $uploadId) {
    id
    uploadId
    status
    progressPercent
  }
}
    `;

/**
 * __useGetUploadPipelineJobQuery__
 *
 * To run a query within a React component, call `useGetUploadPipelineJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadPipelineJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadPipelineJobQuery({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useGetUploadPipelineJobQuery(baseOptions: Apollo.QueryHookOptions<GetUploadPipelineJobQuery, GetUploadPipelineJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadPipelineJobQuery, GetUploadPipelineJobQueryVariables>(GetUploadPipelineJobDocument, options);
      }
export function useGetUploadPipelineJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadPipelineJobQuery, GetUploadPipelineJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadPipelineJobQuery, GetUploadPipelineJobQueryVariables>(GetUploadPipelineJobDocument, options);
        }
export type GetUploadPipelineJobQueryHookResult = ReturnType<typeof useGetUploadPipelineJobQuery>;
export type GetUploadPipelineJobLazyQueryHookResult = ReturnType<typeof useGetUploadPipelineJobLazyQuery>;
export type GetUploadPipelineJobQueryResult = Apollo.QueryResult<GetUploadPipelineJobQuery, GetUploadPipelineJobQueryVariables>;
export function refetchGetUploadPipelineJobQuery(variables?: GetUploadPipelineJobQueryVariables) {
      return { query: GetUploadPipelineJobDocument, variables: variables }
    }
export const CreateUploadPipelineJobDocument = gql`
    mutation createUploadPipelineJob($pipelineId: String!, $flatfileBatchId: String!, $mode: UploaderMode!) {
  createUploadPipelineJob(
    pipelineId: $pipelineId
    flatfileBatchId: $flatfileBatchId
    mode: $mode
  ) {
    uploadId
    status
  }
}
    `;
export type CreateUploadPipelineJobMutationFn = Apollo.MutationFunction<CreateUploadPipelineJobMutation, CreateUploadPipelineJobMutationVariables>;

/**
 * __useCreateUploadPipelineJobMutation__
 *
 * To run a mutation, you first call `useCreateUploadPipelineJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadPipelineJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadPipelineJobMutation, { data, loading, error }] = useCreateUploadPipelineJobMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      flatfileBatchId: // value for 'flatfileBatchId'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useCreateUploadPipelineJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadPipelineJobMutation, CreateUploadPipelineJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUploadPipelineJobMutation, CreateUploadPipelineJobMutationVariables>(CreateUploadPipelineJobDocument, options);
      }
export type CreateUploadPipelineJobMutationHookResult = ReturnType<typeof useCreateUploadPipelineJobMutation>;
export type CreateUploadPipelineJobMutationResult = Apollo.MutationResult<CreateUploadPipelineJobMutation>;
export type CreateUploadPipelineJobMutationOptions = Apollo.BaseMutationOptions<CreateUploadPipelineJobMutation, CreateUploadPipelineJobMutationVariables>;
export const CheckVerificationDocument = gql`
    mutation checkVerification($method: String!, $code: String!) {
  checkVerification(method: $method, code: $code) {
    ...Me
    workspace {
      ...WorkspaceConfig
    }
  }
}
    ${MeFragmentDoc}
${WorkspaceConfigFragmentDoc}`;
export type CheckVerificationMutationFn = Apollo.MutationFunction<CheckVerificationMutation, CheckVerificationMutationVariables>;

/**
 * __useCheckVerificationMutation__
 *
 * To run a mutation, you first call `useCheckVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkVerificationMutation, { data, loading, error }] = useCheckVerificationMutation({
 *   variables: {
 *      method: // value for 'method'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CheckVerificationMutation, CheckVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckVerificationMutation, CheckVerificationMutationVariables>(CheckVerificationDocument, options);
      }
export type CheckVerificationMutationHookResult = ReturnType<typeof useCheckVerificationMutation>;
export type CheckVerificationMutationResult = Apollo.MutationResult<CheckVerificationMutation>;
export type CheckVerificationMutationOptions = Apollo.BaseMutationOptions<CheckVerificationMutation, CheckVerificationMutationVariables>;
export const GetEmailPrefsDocument = gql`
    query getEmailPrefs {
  getMe {
    id
    userId
    emailPreferences
    fullName
    isActive
    isZombie
    emailSubscriptions
  }
}
    `;

/**
 * __useGetEmailPrefsQuery__
 *
 * To run a query within a React component, call `useGetEmailPrefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailPrefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailPrefsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailPrefsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailPrefsQuery, GetEmailPrefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailPrefsQuery, GetEmailPrefsQueryVariables>(GetEmailPrefsDocument, options);
      }
export function useGetEmailPrefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailPrefsQuery, GetEmailPrefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailPrefsQuery, GetEmailPrefsQueryVariables>(GetEmailPrefsDocument, options);
        }
export type GetEmailPrefsQueryHookResult = ReturnType<typeof useGetEmailPrefsQuery>;
export type GetEmailPrefsLazyQueryHookResult = ReturnType<typeof useGetEmailPrefsLazyQuery>;
export type GetEmailPrefsQueryResult = Apollo.QueryResult<GetEmailPrefsQuery, GetEmailPrefsQueryVariables>;
export function refetchGetEmailPrefsQuery(variables?: GetEmailPrefsQueryVariables) {
      return { query: GetEmailPrefsDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query getUsers($ids: [String!]) {
  getUsers(ids: $ids) {
    id
    userId
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      email
      isVerifiedEmail
      workPhone
      firstName
      lastName
      mfaMethods
      role {
        id
        permissions {
          id
          scope
          crudType
        }
      }
      workspace {
        id
        workspaceId
        ciospIndex
        ebuyIndex
        symphonyIndex
        enableGoogleSSO
        enableMicrosoftSSO
        enablePasswordLogin
        enforceMfa
        govWinEnabled
        inviteOnly
        mfaMethods
        name
        primaryLogo
        seaportIndex
        sidebarLogo
        workspaceAbbrev
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordEmailDocument = gql`
    mutation resetPasswordEmail($email: String!) {
  resetPasswordEmail(email: $email)
}
    `;
export type ResetPasswordEmailMutationFn = Apollo.MutationFunction<ResetPasswordEmailMutation, ResetPasswordEmailMutationVariables>;

/**
 * __useResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordEmailMutation, { data, loading, error }] = useResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordEmailMutation, ResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordEmailMutation, ResetPasswordEmailMutationVariables>(ResetPasswordEmailDocument, options);
      }
export type ResetPasswordEmailMutationHookResult = ReturnType<typeof useResetPasswordEmailMutation>;
export type ResetPasswordEmailMutationResult = Apollo.MutationResult<ResetPasswordEmailMutation>;
export type ResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<ResetPasswordEmailMutation, ResetPasswordEmailMutationVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($query: String!, $limit: Int) {
  searchUsers(search: $query, limit: $limit) {
    id
    userId
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export function refetchSearchUsersQuery(variables?: SearchUsersQueryVariables) {
      return { query: SearchUsersDocument, variables: variables }
    }
export const SignupDocument = gql`
    mutation signup($recaptchaToken: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!, $companyName: String!, $invitationToken: String, $workPhone: String, $anonymousId: String) {
  signup(
    recaptchaToken: $recaptchaToken
    firstName: $firstName
    lastName: $lastName
    companyName: $companyName
    email: $email
    password: $password
    invitationToken: $invitationToken
    workPhone: $workPhone
    anonymousId: $anonymousId
  )
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      recaptchaToken: // value for 'recaptchaToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      companyName: // value for 'companyName'
 *      invitationToken: // value for 'invitationToken'
 *      workPhone: // value for 'workPhone'
 *      anonymousId: // value for 'anonymousId'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateUserEmailPreferencesDocument = gql`
    mutation updateUserEmailPreferences($emailPreferences: Json!) {
  updateUserEmailPreferences(emailPreferences: $emailPreferences) {
    id
    emailPreferences
  }
}
    `;
export type UpdateUserEmailPreferencesMutationFn = Apollo.MutationFunction<UpdateUserEmailPreferencesMutation, UpdateUserEmailPreferencesMutationVariables>;

/**
 * __useUpdateUserEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailPreferencesMutation, { data, loading, error }] = useUpdateUserEmailPreferencesMutation({
 *   variables: {
 *      emailPreferences: // value for 'emailPreferences'
 *   },
 * });
 */
export function useUpdateUserEmailPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailPreferencesMutation, UpdateUserEmailPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEmailPreferencesMutation, UpdateUserEmailPreferencesMutationVariables>(UpdateUserEmailPreferencesDocument, options);
      }
export type UpdateUserEmailPreferencesMutationHookResult = ReturnType<typeof useUpdateUserEmailPreferencesMutation>;
export type UpdateUserEmailPreferencesMutationResult = Apollo.MutationResult<UpdateUserEmailPreferencesMutation>;
export type UpdateUserEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailPreferencesMutation, UpdateUserEmailPreferencesMutationVariables>;
export const UpdateCurrentUserWorkspaceDocument = gql`
    mutation updateCurrentUserWorkspace($userWorkspaceId: String!) {
  updateCurrentUserWorkspace(userWorkspaceId: $userWorkspaceId) {
    id
    workspace {
      id
      name
    }
  }
}
    `;
export type UpdateCurrentUserWorkspaceMutationFn = Apollo.MutationFunction<UpdateCurrentUserWorkspaceMutation, UpdateCurrentUserWorkspaceMutationVariables>;

/**
 * __useUpdateCurrentUserWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserWorkspaceMutation, { data, loading, error }] = useUpdateCurrentUserWorkspaceMutation({
 *   variables: {
 *      userWorkspaceId: // value for 'userWorkspaceId'
 *   },
 * });
 */
export function useUpdateCurrentUserWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserWorkspaceMutation, UpdateCurrentUserWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserWorkspaceMutation, UpdateCurrentUserWorkspaceMutationVariables>(UpdateCurrentUserWorkspaceDocument, options);
      }
export type UpdateCurrentUserWorkspaceMutationHookResult = ReturnType<typeof useUpdateCurrentUserWorkspaceMutation>;
export type UpdateCurrentUserWorkspaceMutationResult = Apollo.MutationResult<UpdateCurrentUserWorkspaceMutation>;
export type UpdateCurrentUserWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserWorkspaceMutation, UpdateCurrentUserWorkspaceMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($firstName: String!, $lastName: String!, $email: String!, $roleId: String, $invite: Boolean, $stripeProductId: String) {
  inviteUser(
    firstName: $firstName
    lastName: $lastName
    email: $email
    invite: $invite
    roleId: $roleId
    stripeProductId: $stripeProductId
  ) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      roleId: // value for 'roleId'
 *      invite: // value for 'invite'
 *      stripeProductId: // value for 'stripeProductId'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const SetDefaultPipelineDocument = gql`
    mutation setDefaultPipeline($pipelineId: String!, $viewId: String) {
  setDefaultPipeline(pipelineId: $pipelineId, viewId: $viewId) {
    id
    userId
    defaultPipelineView {
      id
      name
    }
    defaultPipeline {
      id
      name
      pipelineId
    }
  }
}
    `;
export type SetDefaultPipelineMutationFn = Apollo.MutationFunction<SetDefaultPipelineMutation, SetDefaultPipelineMutationVariables>;

/**
 * __useSetDefaultPipelineMutation__
 *
 * To run a mutation, you first call `useSetDefaultPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPipelineMutation, { data, loading, error }] = useSetDefaultPipelineMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useSetDefaultPipelineMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultPipelineMutation, SetDefaultPipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultPipelineMutation, SetDefaultPipelineMutationVariables>(SetDefaultPipelineDocument, options);
      }
export type SetDefaultPipelineMutationHookResult = ReturnType<typeof useSetDefaultPipelineMutation>;
export type SetDefaultPipelineMutationResult = Apollo.MutationResult<SetDefaultPipelineMutation>;
export type SetDefaultPipelineMutationOptions = Apollo.BaseMutationOptions<SetDefaultPipelineMutation, SetDefaultPipelineMutationVariables>;
export const RemoveDefaultPipelineDocument = gql`
    mutation removeDefaultPipeline {
  removeDefaultPipeline {
    userId
    defaultPipeline {
      id
      pipelineId
    }
  }
}
    `;
export type RemoveDefaultPipelineMutationFn = Apollo.MutationFunction<RemoveDefaultPipelineMutation, RemoveDefaultPipelineMutationVariables>;

/**
 * __useRemoveDefaultPipelineMutation__
 *
 * To run a mutation, you first call `useRemoveDefaultPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDefaultPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDefaultPipelineMutation, { data, loading, error }] = useRemoveDefaultPipelineMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveDefaultPipelineMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDefaultPipelineMutation, RemoveDefaultPipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDefaultPipelineMutation, RemoveDefaultPipelineMutationVariables>(RemoveDefaultPipelineDocument, options);
      }
export type RemoveDefaultPipelineMutationHookResult = ReturnType<typeof useRemoveDefaultPipelineMutation>;
export type RemoveDefaultPipelineMutationResult = Apollo.MutationResult<RemoveDefaultPipelineMutation>;
export type RemoveDefaultPipelineMutationOptions = Apollo.BaseMutationOptions<RemoveDefaultPipelineMutation, RemoveDefaultPipelineMutationVariables>;
export const SendVerificationDocument = gql`
    mutation sendVerification($method: String!) {
  sendVerification(method: $method)
}
    `;
export type SendVerificationMutationFn = Apollo.MutationFunction<SendVerificationMutation, SendVerificationMutationVariables>;

/**
 * __useSendVerificationMutation__
 *
 * To run a mutation, you first call `useSendVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationMutation, { data, loading, error }] = useSendVerificationMutation({
 *   variables: {
 *      method: // value for 'method'
 *   },
 * });
 */
export function useSendVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationMutation, SendVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationMutation, SendVerificationMutationVariables>(SendVerificationDocument, options);
      }
export type SendVerificationMutationHookResult = ReturnType<typeof useSendVerificationMutation>;
export type SendVerificationMutationResult = Apollo.MutationResult<SendVerificationMutation>;
export type SendVerificationMutationOptions = Apollo.BaseMutationOptions<SendVerificationMutation, SendVerificationMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: String!) {
  deleteUser(userId: $userId) {
    userId
    firstName
    lastName
    fullName
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ActivateInviteDocument = gql`
    mutation activateInvite($email: String!, $password: String!, $invitationToken: String!) {
  activateInvite(
    email: $email
    password: $password
    invitationToken: $invitationToken
  ) {
    user {
      id
      userId
      email
      firstName
      lastName
    }
  }
}
    `;
export type ActivateInviteMutationFn = Apollo.MutationFunction<ActivateInviteMutation, ActivateInviteMutationVariables>;

/**
 * __useActivateInviteMutation__
 *
 * To run a mutation, you first call `useActivateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateInviteMutation, { data, loading, error }] = useActivateInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useActivateInviteMutation(baseOptions?: Apollo.MutationHookOptions<ActivateInviteMutation, ActivateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateInviteMutation, ActivateInviteMutationVariables>(ActivateInviteDocument, options);
      }
export type ActivateInviteMutationHookResult = ReturnType<typeof useActivateInviteMutation>;
export type ActivateInviteMutationResult = Apollo.MutationResult<ActivateInviteMutation>;
export type ActivateInviteMutationOptions = Apollo.BaseMutationOptions<ActivateInviteMutation, ActivateInviteMutationVariables>;
export const ToggleEmailSubscriptionDocument = gql`
    mutation toggleEmailSubscription($oppId: String!) {
  toggleEmailSubscription(oppId: $oppId) {
    id
    emailSubscriptions {
      id
      oppId
    }
  }
}
    `;
export type ToggleEmailSubscriptionMutationFn = Apollo.MutationFunction<ToggleEmailSubscriptionMutation, ToggleEmailSubscriptionMutationVariables>;

/**
 * __useToggleEmailSubscriptionMutation__
 *
 * To run a mutation, you first call `useToggleEmailSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEmailSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEmailSubscriptionMutation, { data, loading, error }] = useToggleEmailSubscriptionMutation({
 *   variables: {
 *      oppId: // value for 'oppId'
 *   },
 * });
 */
export function useToggleEmailSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ToggleEmailSubscriptionMutation, ToggleEmailSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleEmailSubscriptionMutation, ToggleEmailSubscriptionMutationVariables>(ToggleEmailSubscriptionDocument, options);
      }
export type ToggleEmailSubscriptionMutationHookResult = ReturnType<typeof useToggleEmailSubscriptionMutation>;
export type ToggleEmailSubscriptionMutationResult = Apollo.MutationResult<ToggleEmailSubscriptionMutation>;
export type ToggleEmailSubscriptionMutationOptions = Apollo.BaseMutationOptions<ToggleEmailSubscriptionMutation, ToggleEmailSubscriptionMutationVariables>;
export const ActivateUserWorkspaceDocument = gql`
    mutation activateUserWorkspace($userWorkspaceUUId: String!) {
  activateUserWorkspace(userWorkspaceUUId: $userWorkspaceUUId) {
    id
    userWorkspaceUUId
    workspaceName
    workspaceId
    workspaceUUId
    userFullName
    userFirstName
    userLastName
    roleName
  }
}
    `;
export type ActivateUserWorkspaceMutationFn = Apollo.MutationFunction<ActivateUserWorkspaceMutation, ActivateUserWorkspaceMutationVariables>;

/**
 * __useActivateUserWorkspaceMutation__
 *
 * To run a mutation, you first call `useActivateUserWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserWorkspaceMutation, { data, loading, error }] = useActivateUserWorkspaceMutation({
 *   variables: {
 *      userWorkspaceUUId: // value for 'userWorkspaceUUId'
 *   },
 * });
 */
export function useActivateUserWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserWorkspaceMutation, ActivateUserWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserWorkspaceMutation, ActivateUserWorkspaceMutationVariables>(ActivateUserWorkspaceDocument, options);
      }
export type ActivateUserWorkspaceMutationHookResult = ReturnType<typeof useActivateUserWorkspaceMutation>;
export type ActivateUserWorkspaceMutationResult = Apollo.MutationResult<ActivateUserWorkspaceMutation>;
export type ActivateUserWorkspaceMutationOptions = Apollo.BaseMutationOptions<ActivateUserWorkspaceMutation, ActivateUserWorkspaceMutationVariables>;
export const DeactivateUserWorkspaceDocument = gql`
    mutation deactivateUserWorkspace($userWorkspaceUUId: String!) {
  deactivateUserWorkspace(userWorkspaceUUId: $userWorkspaceUUId) {
    id
    workspaceName
    workspaceId
    workspaceUUId
    userFullName
    userFirstName
    userLastName
    roleName
  }
}
    `;
export type DeactivateUserWorkspaceMutationFn = Apollo.MutationFunction<DeactivateUserWorkspaceMutation, DeactivateUserWorkspaceMutationVariables>;

/**
 * __useDeactivateUserWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeactivateUserWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserWorkspaceMutation, { data, loading, error }] = useDeactivateUserWorkspaceMutation({
 *   variables: {
 *      userWorkspaceUUId: // value for 'userWorkspaceUUId'
 *   },
 * });
 */
export function useDeactivateUserWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserWorkspaceMutation, DeactivateUserWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserWorkspaceMutation, DeactivateUserWorkspaceMutationVariables>(DeactivateUserWorkspaceDocument, options);
      }
export type DeactivateUserWorkspaceMutationHookResult = ReturnType<typeof useDeactivateUserWorkspaceMutation>;
export type DeactivateUserWorkspaceMutationResult = Apollo.MutationResult<DeactivateUserWorkspaceMutation>;
export type DeactivateUserWorkspaceMutationOptions = Apollo.BaseMutationOptions<DeactivateUserWorkspaceMutation, DeactivateUserWorkspaceMutationVariables>;
export const DeleteUserWorkspaceDocument = gql`
    mutation deleteUserWorkspace($userWorkspaceUUId: String!) {
  deleteUserWorkspace(userWorkspaceUUId: $userWorkspaceUUId) {
    id
    userWorkspaceUUId
    workspaceName
    workspaceId
    workspaceUUId
    userFullName
    userFirstName
    userLastName
    roleName
  }
}
    `;
export type DeleteUserWorkspaceMutationFn = Apollo.MutationFunction<DeleteUserWorkspaceMutation, DeleteUserWorkspaceMutationVariables>;

/**
 * __useDeleteUserWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteUserWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserWorkspaceMutation, { data, loading, error }] = useDeleteUserWorkspaceMutation({
 *   variables: {
 *      userWorkspaceUUId: // value for 'userWorkspaceUUId'
 *   },
 * });
 */
export function useDeleteUserWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserWorkspaceMutation, DeleteUserWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserWorkspaceMutation, DeleteUserWorkspaceMutationVariables>(DeleteUserWorkspaceDocument, options);
      }
export type DeleteUserWorkspaceMutationHookResult = ReturnType<typeof useDeleteUserWorkspaceMutation>;
export type DeleteUserWorkspaceMutationResult = Apollo.MutationResult<DeleteUserWorkspaceMutation>;
export type DeleteUserWorkspaceMutationOptions = Apollo.BaseMutationOptions<DeleteUserWorkspaceMutation, DeleteUserWorkspaceMutationVariables>;
export const UpdateUserWorkspaceRoleDocument = gql`
    mutation updateUserWorkspaceRole($userWorkspaceUUId: String!, $roleId: String!) {
  updateUserWorkspaceRole(userWorkspaceUUId: $userWorkspaceUUId, roleId: $roleId) {
    id
    userWorkspaceUUId
    workspaceName
    workspaceId
    workspaceUUId
    userFullName
    userFirstName
    userLastName
    roleName
  }
}
    `;
export type UpdateUserWorkspaceRoleMutationFn = Apollo.MutationFunction<UpdateUserWorkspaceRoleMutation, UpdateUserWorkspaceRoleMutationVariables>;

/**
 * __useUpdateUserWorkspaceRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserWorkspaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWorkspaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWorkspaceRoleMutation, { data, loading, error }] = useUpdateUserWorkspaceRoleMutation({
 *   variables: {
 *      userWorkspaceUUId: // value for 'userWorkspaceUUId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useUpdateUserWorkspaceRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserWorkspaceRoleMutation, UpdateUserWorkspaceRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserWorkspaceRoleMutation, UpdateUserWorkspaceRoleMutationVariables>(UpdateUserWorkspaceRoleDocument, options);
      }
export type UpdateUserWorkspaceRoleMutationHookResult = ReturnType<typeof useUpdateUserWorkspaceRoleMutation>;
export type UpdateUserWorkspaceRoleMutationResult = Apollo.MutationResult<UpdateUserWorkspaceRoleMutation>;
export type UpdateUserWorkspaceRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserWorkspaceRoleMutation, UpdateUserWorkspaceRoleMutationVariables>;
export const GenerateZapierApiKeyDocument = gql`
    mutation generateZapierApiKey {
  generateZapierApiKey {
    id
    zapierApiKey
  }
}
    `;
export type GenerateZapierApiKeyMutationFn = Apollo.MutationFunction<GenerateZapierApiKeyMutation, GenerateZapierApiKeyMutationVariables>;

/**
 * __useGenerateZapierApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateZapierApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateZapierApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateZapierApiKeyMutation, { data, loading, error }] = useGenerateZapierApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateZapierApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateZapierApiKeyMutation, GenerateZapierApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateZapierApiKeyMutation, GenerateZapierApiKeyMutationVariables>(GenerateZapierApiKeyDocument, options);
      }
export type GenerateZapierApiKeyMutationHookResult = ReturnType<typeof useGenerateZapierApiKeyMutation>;
export type GenerateZapierApiKeyMutationResult = Apollo.MutationResult<GenerateZapierApiKeyMutation>;
export type GenerateZapierApiKeyMutationOptions = Apollo.BaseMutationOptions<GenerateZapierApiKeyMutation, GenerateZapierApiKeyMutationVariables>;
export const GetWorkspaceDocument = gql`
    query getWorkspace {
  getWorkspace {
    ...WorkspaceConfig
  }
}
    ${WorkspaceConfigFragmentDoc}`;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
      }
export function useGetWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
        }
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<GetWorkspaceQuery, GetWorkspaceQueryVariables>;
export function refetchGetWorkspaceQuery(variables?: GetWorkspaceQueryVariables) {
      return { query: GetWorkspaceDocument, variables: variables }
    }
export const WorkspaceBillingPortalDocument = gql`
    mutation workspaceBillingPortal {
  workspaceBillingPortal
}
    `;
export type WorkspaceBillingPortalMutationFn = Apollo.MutationFunction<WorkspaceBillingPortalMutation, WorkspaceBillingPortalMutationVariables>;

/**
 * __useWorkspaceBillingPortalMutation__
 *
 * To run a mutation, you first call `useWorkspaceBillingPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceBillingPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceBillingPortalMutation, { data, loading, error }] = useWorkspaceBillingPortalMutation({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceBillingPortalMutation(baseOptions?: Apollo.MutationHookOptions<WorkspaceBillingPortalMutation, WorkspaceBillingPortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspaceBillingPortalMutation, WorkspaceBillingPortalMutationVariables>(WorkspaceBillingPortalDocument, options);
      }
export type WorkspaceBillingPortalMutationHookResult = ReturnType<typeof useWorkspaceBillingPortalMutation>;
export type WorkspaceBillingPortalMutationResult = Apollo.MutationResult<WorkspaceBillingPortalMutation>;
export type WorkspaceBillingPortalMutationOptions = Apollo.BaseMutationOptions<WorkspaceBillingPortalMutation, WorkspaceBillingPortalMutationVariables>;
export const CreateWorkspaceDocument = gql`
    mutation createWorkspace($workspaceName: String!, $emailDomain: String!) {
  createWorkspace(workspaceName: $workspaceName, emailDomain: $emailDomain) {
    id
    name
    workspaceId
  }
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      workspaceName: // value for 'workspaceName'
 *      emailDomain: // value for 'emailDomain'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation updateWorkspace($ciospEmail: String, $ciospPassword: String, $ebuyPassword: String, $symphonyPassword: String, $enableGoogleSSO: Boolean, $enableMicrosoftSSO: Boolean, $enablePasswordLogin: Boolean, $enforceMfa: Boolean, $govWinClientId: String, $govWinClientSecret: String, $govWinPassword: String, $govWinUsername: String, $inviteOnly: Boolean, $name: String, $workspaceAbbrev: String) {
  updateWorkspace(
    ciospEmail: $ciospEmail
    ciospPassword: $ciospPassword
    ebuyPassword: $ebuyPassword
    symphonyPassword: $symphonyPassword
    enableGoogleSSO: $enableGoogleSSO
    enableMicrosoftSSO: $enableMicrosoftSSO
    enablePasswordLogin: $enablePasswordLogin
    enforceMfa: $enforceMfa
    govWinClientId: $govWinClientId
    govWinClientSecret: $govWinClientSecret
    govWinPassword: $govWinPassword
    govWinUsername: $govWinUsername
    inviteOnly: $inviteOnly
    name: $name
    workspaceAbbrev: $workspaceAbbrev
  ) {
    id
    workspaceId
    name
    workspaceAbbrev
    enforceMfa
    inviteOnly
    enableGoogleSSO
    enableMicrosoftSSO
    enablePasswordLogin
    primaryLogo
    sidebarLogo
  }
}
    `;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      ciospEmail: // value for 'ciospEmail'
 *      ciospPassword: // value for 'ciospPassword'
 *      ebuyPassword: // value for 'ebuyPassword'
 *      symphonyPassword: // value for 'symphonyPassword'
 *      enableGoogleSSO: // value for 'enableGoogleSSO'
 *      enableMicrosoftSSO: // value for 'enableMicrosoftSSO'
 *      enablePasswordLogin: // value for 'enablePasswordLogin'
 *      enforceMfa: // value for 'enforceMfa'
 *      govWinClientId: // value for 'govWinClientId'
 *      govWinClientSecret: // value for 'govWinClientSecret'
 *      govWinPassword: // value for 'govWinPassword'
 *      govWinUsername: // value for 'govWinUsername'
 *      inviteOnly: // value for 'inviteOnly'
 *      name: // value for 'name'
 *      workspaceAbbrev: // value for 'workspaceAbbrev'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
      }
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const GetWorkspaceSubscriptionDocument = gql`
    query getWorkspaceSubscription {
  getWorkspaceSubscription
}
    `;

/**
 * __useGetWorkspaceSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspaceSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>(GetWorkspaceSubscriptionDocument, options);
      }
export function useGetWorkspaceSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>(GetWorkspaceSubscriptionDocument, options);
        }
export type GetWorkspaceSubscriptionQueryHookResult = ReturnType<typeof useGetWorkspaceSubscriptionQuery>;
export type GetWorkspaceSubscriptionLazyQueryHookResult = ReturnType<typeof useGetWorkspaceSubscriptionLazyQuery>;
export type GetWorkspaceSubscriptionQueryResult = Apollo.QueryResult<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>;
export function refetchGetWorkspaceSubscriptionQuery(variables?: GetWorkspaceSubscriptionQueryVariables) {
      return { query: GetWorkspaceSubscriptionDocument, variables: variables }
    }
export const GetAdminPortalLinkDocument = gql`
    query getAdminPortalLink {
  getAdminPortalLink
}
    `;

/**
 * __useGetAdminPortalLinkQuery__
 *
 * To run a query within a React component, call `useGetAdminPortalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminPortalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminPortalLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminPortalLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminPortalLinkQuery, GetAdminPortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminPortalLinkQuery, GetAdminPortalLinkQueryVariables>(GetAdminPortalLinkDocument, options);
      }
export function useGetAdminPortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminPortalLinkQuery, GetAdminPortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminPortalLinkQuery, GetAdminPortalLinkQueryVariables>(GetAdminPortalLinkDocument, options);
        }
export type GetAdminPortalLinkQueryHookResult = ReturnType<typeof useGetAdminPortalLinkQuery>;
export type GetAdminPortalLinkLazyQueryHookResult = ReturnType<typeof useGetAdminPortalLinkLazyQuery>;
export type GetAdminPortalLinkQueryResult = Apollo.QueryResult<GetAdminPortalLinkQuery, GetAdminPortalLinkQueryVariables>;
export function refetchGetAdminPortalLinkQuery(variables?: GetAdminPortalLinkQueryVariables) {
      return { query: GetAdminPortalLinkDocument, variables: variables }
    }
export const GetProductsDocument = gql`
    query getProducts {
  getProducts
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export function refetchGetProductsQuery(variables?: GetProductsQueryVariables) {
      return { query: GetProductsDocument, variables: variables }
    }
export const GetWorkspaceLogosDocument = gql`
    query getWorkspaceLogos($workspaceHumanId: String!) {
  getWorkspaceLogos(workspaceHumanId: $workspaceHumanId)
}
    `;

/**
 * __useGetWorkspaceLogosQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceLogosQuery({
 *   variables: {
 *      workspaceHumanId: // value for 'workspaceHumanId'
 *   },
 * });
 */
export function useGetWorkspaceLogosQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceLogosQuery, GetWorkspaceLogosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceLogosQuery, GetWorkspaceLogosQueryVariables>(GetWorkspaceLogosDocument, options);
      }
export function useGetWorkspaceLogosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceLogosQuery, GetWorkspaceLogosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceLogosQuery, GetWorkspaceLogosQueryVariables>(GetWorkspaceLogosDocument, options);
        }
export type GetWorkspaceLogosQueryHookResult = ReturnType<typeof useGetWorkspaceLogosQuery>;
export type GetWorkspaceLogosLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLogosLazyQuery>;
export type GetWorkspaceLogosQueryResult = Apollo.QueryResult<GetWorkspaceLogosQuery, GetWorkspaceLogosQueryVariables>;
export function refetchGetWorkspaceLogosQuery(variables?: GetWorkspaceLogosQueryVariables) {
      return { query: GetWorkspaceLogosDocument, variables: variables }
    }
export const CheckGovWinAuthDocument = gql`
    query checkGovWinAuth {
  checkGovWinAuth
}
    `;

/**
 * __useCheckGovWinAuthQuery__
 *
 * To run a query within a React component, call `useCheckGovWinAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckGovWinAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckGovWinAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckGovWinAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckGovWinAuthQuery, CheckGovWinAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckGovWinAuthQuery, CheckGovWinAuthQueryVariables>(CheckGovWinAuthDocument, options);
      }
export function useCheckGovWinAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckGovWinAuthQuery, CheckGovWinAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckGovWinAuthQuery, CheckGovWinAuthQueryVariables>(CheckGovWinAuthDocument, options);
        }
export type CheckGovWinAuthQueryHookResult = ReturnType<typeof useCheckGovWinAuthQuery>;
export type CheckGovWinAuthLazyQueryHookResult = ReturnType<typeof useCheckGovWinAuthLazyQuery>;
export type CheckGovWinAuthQueryResult = Apollo.QueryResult<CheckGovWinAuthQuery, CheckGovWinAuthQueryVariables>;
export function refetchCheckGovWinAuthQuery(variables?: CheckGovWinAuthQueryVariables) {
      return { query: CheckGovWinAuthDocument, variables: variables }
    }
export const GetWorkspaceRolesAndUsersDocument = gql`
    query getWorkspaceRolesAndUsers {
  getWorkspaceRolesAndUsers {
    id
    name
    workspaceAbbrev
    enforceMfa
    ebuyEmail
    ciospEmail
    ciospEmailEndpoint
    hasEbuyPassword
    inviteOnly
    ebuyIndex
    symphonyIndex
    symphonyEmail
    enableGoogleSSO
    enableMicrosoftSSO
    enablePasswordLogin
    freeTrial
    govWinUsername
    govWinClientId
    hasGovWinClientSecret
    hasGovWinPassword
    hasStripeSubscription
    isPayingCustomer
    enforceDirectoryProvisioning
    restrictRoleProvisioningToDirectory
    userWorkspaces {
      id
      userWorkspaceUUId
      isActive
      createdAt
      userUUId
      userFullName
      userFirstName
      userLastName
      userFullName
      userEmail
      userIsVerifiedEmail
      userIsZombie
      productName
      userIsActive
      roleName
      roleUUId
    }
    users {
      id
      userId
      firstName
      lastName
      email
      invited
      invitedAt
      isActive
      isZombie
      productName
      isVerifiedEmail
      role {
        id
        name
        permissions {
          id
          scope
          crudType
        }
      }
    }
    roles {
      id
      roleId
      name
      usersCount
      permissions {
        id
        scope
        crudType
      }
    }
  }
}
    `;

/**
 * __useGetWorkspaceRolesAndUsersQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceRolesAndUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceRolesAndUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceRolesAndUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspaceRolesAndUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspaceRolesAndUsersQuery, GetWorkspaceRolesAndUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceRolesAndUsersQuery, GetWorkspaceRolesAndUsersQueryVariables>(GetWorkspaceRolesAndUsersDocument, options);
      }
export function useGetWorkspaceRolesAndUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceRolesAndUsersQuery, GetWorkspaceRolesAndUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceRolesAndUsersQuery, GetWorkspaceRolesAndUsersQueryVariables>(GetWorkspaceRolesAndUsersDocument, options);
        }
export type GetWorkspaceRolesAndUsersQueryHookResult = ReturnType<typeof useGetWorkspaceRolesAndUsersQuery>;
export type GetWorkspaceRolesAndUsersLazyQueryHookResult = ReturnType<typeof useGetWorkspaceRolesAndUsersLazyQuery>;
export type GetWorkspaceRolesAndUsersQueryResult = Apollo.QueryResult<GetWorkspaceRolesAndUsersQuery, GetWorkspaceRolesAndUsersQueryVariables>;
export function refetchGetWorkspaceRolesAndUsersQuery(variables?: GetWorkspaceRolesAndUsersQueryVariables) {
      return { query: GetWorkspaceRolesAndUsersDocument, variables: variables }
    }