function shouldUseLocationForClientRequests() {
  return (
    process.env.NEXT_PUBLIC_USE_LOCATION_FOR_CLIENT_REQUESTS ||
    process.env.NEXT_PUBLIC_USE_LOCATION_FOR_CLIENT_REQUESTS === 'true'
  )
}

export function getHostOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin
  } else {
    return process.env.NEXT_PUBLIC_HOST || 'http://localhost:3000'
  }
}

export function getWSOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')
  } else {
    return process.env.NEXT_PUBLIC_WS_ORIGIN || 'ws://localhost:4000'
  }
}
export function getGraphQLOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin
  } else {
    return process.env.NEXT_PUBLIC_GRAPHQL_ORIGIN || 'http://backend:4000'
  }
}
export function getCubeOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin
  } else {
    return process.env.NEXT_PUBLIC_CUBE_ORIGIN || 'http://localhost:4001'
  }
}

function _getUpdatesV2HocuspocusOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')
  }
  return (
    process.env.NEXT_PUBLIC_HOCUSPOCUS_UPDATES_V2_ORIGIN ||
    'ws://localhost:1234/opp-updates-v2-sync'
  )
}

export function getUpdatesV2HocuspocusOrigin() {
  const origin = _getUpdatesV2HocuspocusOrigin()
  if (origin.endsWith('/opp-updates-v2-sync')) return origin
  return `${origin}/opp-updates-v2-sync`
}

function _getHocuspocusOrigin() {
  if (typeof window !== 'undefined' && shouldUseLocationForClientRequests()) {
    return window.location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')
  }
  return process.env.NEXT_PUBLIC_HOCUSPOCUS_ORIGIN || 'ws://localhost:2345/document-sync'
}

export function getHocuspocusOrigin() {
  const origin = _getHocuspocusOrigin()
  if (origin.endsWith('/document-sync')) return origin
  return `${origin}/document-sync`
}
