import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageSymphonySliceActions } from '../redux/SearchPageSymphonySlice'
import { useSymphonySortBy, useSymphonySortByDirection } from '../redux/selectors'

export const SYMPHONY_VIEW_SORT_BY_POSTED_ON = 'posted-on'
export const SYMPHONY_VIEW_SORT_BY_RESPOND_BY = 'respond-by'
export const SYMPHONY_VIEW_SORT_BY_LAST_MODIFIED = 'last-modified'
export const SYMPHONY_VIEW_SORT_BY_OPTIONS = [
  {
    id: 'posted-on',
    label: 'Posted On',
    value: SYMPHONY_VIEW_SORT_BY_POSTED_ON,
  },
  {
    id: 'respond-by',
    label: 'Respond By',
    value: SYMPHONY_VIEW_SORT_BY_RESPOND_BY,
  },
  {
    id: 'last-modified',
    label: 'Last Modified',
    value: SYMPHONY_VIEW_SORT_BY_LAST_MODIFIED,
  },
]

interface SymphonyViewSortByDropdownProps {
  disabled?: boolean
}

export const SymphonyViewSortByDropdown = (props: SymphonyViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useSymphonySortBy()
  const direction = useSymphonySortByDirection()
  const onChange = useCallback(
    (
      nextValue: { id: string; label: string; value: any } | null,
      nextDirection: 'asc' | 'desc'
    ) => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageSymphonySliceActions.setSortBy({
            field: null,
            direction: 'asc',
          })
        )
      } else {
        dispatch(
          SearchPageSymphonySliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <SortByDropdown
      onChange={onChange}
      options={SYMPHONY_VIEW_SORT_BY_OPTIONS}
      value={value}
      direction={direction ?? 'asc'}
      disabled={disabled}
    />
  )
}
